import { SingleAnswerStateDto, } from '@mika-bibb/shared/challenge-status-dto';
import { NextQuestionButton } from '@views/challenge-round-view';
import { Popup } from '@components/basic-components/popup';
import { QuestionType } from '@mika-bibb/shared/enums';
import { useBackPath } from '@components/basic-components/back-button';
import { useNestClientContext } from '../../context/nest-client-context';
import PopupFooterButton from '@components/question/popup-footer-button';
import React from 'react';
export default function QuestionViewExplanationPopup({ explanationPopupOpen, answerState, challengeRound, questionIndex, question, setRoundScorePopupOpen, setExplanationPopupOpen, }) {
    const { nestClient } = useNestClientContext();
    // eslint-disable-next-line no-prototype-builtins
    const isKiosk = nestClient.hasOwnProperty('kiosk');
    const backToChallengePath = useBackPath(isKiosk ? 1 : 2);
    const correctText = question.type === QuestionType.TRUE_FALSE
        ? 'Das ist korrekt!'
        : 'Richtig!';
    return (React.createElement(Popup, { popupOpen: explanationPopupOpen, withoutFooter: true, centerScrollPanel: {
            header: (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "text-darkBlue text-xl font-bold mb-4" }, answerState === SingleAnswerStateDto.CORRECT
                    ? correctText
                    : 'Das ist leider falsch!'),
                React.createElement("div", { className: "text-darkBlue/70 font-semibold" }, "Erl\u00E4uterung"))),
            footer: (React.createElement("div", { className: "mb-4" },
                (!isKiosk || challengeRound.nextQuestion === 0) && (React.createElement(PopupFooterButton, { challengeRound: challengeRound, to: isKiosk ? undefined : backToChallengePath, onClick: isKiosk
                        ? () => {
                            setRoundScorePopupOpen(true);
                            setExplanationPopupOpen(false);
                        }
                        : undefined, overrideLabelText: isKiosk ? 'Zum Ergebnis' : undefined })),
                challengeRound.nextQuestion !== undefined &&
                    challengeRound.nextQuestion !== questionIndex &&
                    !(isKiosk && challengeRound.nextQuestion === 0) && (React.createElement(NextQuestionButton, { challengeRound: challengeRound, className: "mb-2" })))),
        } },
        React.createElement("div", { className: "text-green leading-[19px] mb-4" }, question.explanation)));
}
