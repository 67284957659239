import { Avatar } from '@components/basic-components/avatar/avatar';
import { Link } from 'react-router-dom';
import { classNames } from '@utils/class-names';
import { formatScore } from '@utils/formatting';
import { overflowEllipsis } from '@utils/mixed';
import React from 'react';
export const ChallengeUser = ({ align, score, avatarUrl, bannerType, isMe = true, isChallengeOwner, nickname, scoreIsAverage = false, isWinner = false, url, onClick, }) => {
    const content = (React.createElement("div", { "data-testid": "ChallengeDetailsViewHeader", className: classNames('grid gap-2 w-full font-bold text-darkBlue desktop:bg-white desktop:px-4', align === 'wide' && 'grid-cols-[auto_1fr_auto]', align === 'left' && 'grid-cols-[auto_1fr]', align === 'right' && 'grid-cols-[1fr_auto]') },
        React.createElement(Avatar, { className: classNames('w-10 self-center', align === 'right' && 'col-start-2'), sizes: "40px", image: avatarUrl, bannerType: bannerType }),
        React.createElement("div", { className: classNames('self-center min-w-0', align === 'right' && 'col-start-1 row-start-1 text-right') },
            React.createElement("div", { className: overflowEllipsis }, nickname),
            isChallengeOwner && (React.createElement("div", { className: "text-green/70 font-medium text-[10px] leading-tight -mt-[0.2em]" }, "Quiz-Besitzer")),
            isWinner && (React.createElement("div", { className: "text-green/70 font-medium text-[10px] leading-tight -mt-[0.2em]" }, "Gewinner/-in"))),
        React.createElement("div", { className: classNames('text-score font-bold', isMe ? 'text-green' : 'text-orange', align === 'right' && 'justify-self-end', align === 'left' && 'col-start-2', align === 'wide' && 'self-center') },
            scoreIsAverage && (React.createElement("div", { className: "text-xl font-light inline" }, '∅ ')),
            formatScore(score))));
    if (url) {
        return React.createElement(Link, { to: url }, content);
    }
    else if (onClick) {
        return (React.createElement("button", { onClick: onClick, className: "w-full" }, content));
    }
    else {
        return content;
    }
};
