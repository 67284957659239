import { ReactComponent as Beard1a } from '@public/images/avatar-layers/beards/body_3/man_beard_1a.svg';
import { ReactComponent as Beard1b } from '@public/images/avatar-layers/beards/body_3/man_beard_1b.svg';
import { ReactComponent as Beard1c } from '@public/images/avatar-layers/beards/body_3/man_beard_1c.svg';
import { ReactComponent as Beard1d } from '@public/images/avatar-layers/beards/body_3/man_beard_1d.svg';
import { ReactComponent as Beard1e } from '@public/images/avatar-layers/beards/body_3/man_beard_1e.svg';
import { ReactComponent as Beard2a } from '@public/images/avatar-layers/beards/body_3/man_beard_2a.svg';
import { ReactComponent as Beard2b } from '@public/images/avatar-layers/beards/body_3/man_beard_2b.svg';
import { ReactComponent as Beard2c } from '@public/images/avatar-layers/beards/body_3/man_beard_2c.svg';
import { ReactComponent as Beard2d } from '@public/images/avatar-layers/beards/body_3/man_beard_2d.svg';
import { ReactComponent as Beard2e } from '@public/images/avatar-layers/beards/body_3/man_beard_2e.svg';
import { ReactComponent as Beard3a } from '@public/images/avatar-layers/beards/body_3/man_beard_3a.svg';
import { ReactComponent as Beard3b } from '@public/images/avatar-layers/beards/body_3/man_beard_3b.svg';
import { ReactComponent as Beard3c } from '@public/images/avatar-layers/beards/body_3/man_beard_3c.svg';
import { ReactComponent as Beard3d } from '@public/images/avatar-layers/beards/body_3/man_beard_3d.svg';
import { ReactComponent as Beard3e } from '@public/images/avatar-layers/beards/body_3/man_beard_3e.svg';
import { ReactComponent as Beard4a } from '@public/images/avatar-layers/beards/body_3/man_beard_4a.svg';
import { ReactComponent as Beard4b } from '@public/images/avatar-layers/beards/body_3/man_beard_4b.svg';
import { ReactComponent as Beard4c } from '@public/images/avatar-layers/beards/body_3/man_beard_4c.svg';
import { ReactComponent as Beard4d } from '@public/images/avatar-layers/beards/body_3/man_beard_4d.svg';
import { ReactComponent as Beard4e } from '@public/images/avatar-layers/beards/body_3/man_beard_4e.svg';
import { ReactComponent as Beard5a } from '@public/images/avatar-layers/beards/body_3/man_beard_5a.svg';
import { ReactComponent as Beard5b } from '@public/images/avatar-layers/beards/body_3/man_beard_5b.svg';
import { ReactComponent as Beard5c } from '@public/images/avatar-layers/beards/body_3/man_beard_5c.svg';
import { ReactComponent as Beard5d } from '@public/images/avatar-layers/beards/body_3/man_beard_5d.svg';
import { ReactComponent as Beard5e } from '@public/images/avatar-layers/beards/body_3/man_beard_5e.svg';
import { ReactComponent as Beard6a } from '@public/images/avatar-layers/beards/body_3/man_beard_6a.svg';
import { ReactComponent as Beard6b } from '@public/images/avatar-layers/beards/body_3/man_beard_6b.svg';
import { ReactComponent as Beard6c } from '@public/images/avatar-layers/beards/body_3/man_beard_6c.svg';
import { ReactComponent as Beard6d } from '@public/images/avatar-layers/beards/body_3/man_beard_6d.svg';
import { ReactComponent as Beard6e } from '@public/images/avatar-layers/beards/body_3/man_beard_6e.svg';
export const Body3 = {
    Beard1a,
    Beard1b,
    Beard1c,
    Beard1d,
    Beard1e,
    Beard2a,
    Beard2b,
    Beard2c,
    Beard2d,
    Beard2e,
    Beard3a,
    Beard3b,
    Beard3c,
    Beard3d,
    Beard3e,
    Beard4a,
    Beard4b,
    Beard4c,
    Beard4d,
    Beard4e,
    Beard5a,
    Beard5b,
    Beard5c,
    Beard5d,
    Beard5e,
    Beard6a,
    Beard6b,
    Beard6c,
    Beard6d,
    Beard6e,
};
