import { Body1 } from './import-body1-beards';
import { Body2 } from './import-body2-beards';
import { Body3 } from './import-body3-beards';
import { Body4 } from './import-body4-beards';
import { Body5 } from './import-body5-beards';
import React from 'react';
export const beardColorsOrdered = [
    '#862618',
    '#85898B',
    '#DEA65F',
    '#654023',
    '#000000',
];
export const beardShapesOrdered = [
    undefined,
    'Beard1',
    'Beard2',
    'Beard3',
    'Beard4',
    'Beard5',
    'Beard6',
];
export const beardConfigBody1 = {
    '#862618': {
        Beard1: React.createElement(Body1.Beard1a, null),
        Beard2: React.createElement(Body1.Beard2a, null),
        Beard3: React.createElement(Body1.Beard3a, null),
        Beard4: React.createElement(Body1.Beard4a, null),
        Beard5: React.createElement(Body1.Beard5a, null),
        Beard6: React.createElement(Body1.Beard6a, null),
    },
    '#85898B': {
        Beard1: React.createElement(Body1.Beard1b, null),
        Beard2: React.createElement(Body1.Beard2b, null),
        Beard3: React.createElement(Body1.Beard3b, null),
        Beard4: React.createElement(Body1.Beard4b, null),
        Beard5: React.createElement(Body1.Beard5b, null),
        Beard6: React.createElement(Body1.Beard6b, null),
    },
    '#DEA65F': {
        Beard1: React.createElement(Body1.Beard1c, null),
        Beard2: React.createElement(Body1.Beard2c, null),
        Beard3: React.createElement(Body1.Beard3c, null),
        Beard4: React.createElement(Body1.Beard4c, null),
        Beard5: React.createElement(Body1.Beard5c, null),
        Beard6: React.createElement(Body1.Beard6c, null),
    },
    '#654023': {
        Beard1: React.createElement(Body1.Beard1d, null),
        Beard2: React.createElement(Body1.Beard2d, null),
        Beard3: React.createElement(Body1.Beard3d, null),
        Beard4: React.createElement(Body1.Beard4d, null),
        Beard5: React.createElement(Body1.Beard5d, null),
        Beard6: React.createElement(Body1.Beard6d, null),
    },
    '#000000': {
        Beard1: React.createElement(Body1.Beard1e, null),
        Beard2: React.createElement(Body1.Beard2e, null),
        Beard3: React.createElement(Body1.Beard3e, null),
        Beard4: React.createElement(Body1.Beard4e, null),
        Beard5: React.createElement(Body1.Beard5e, null),
        Beard6: React.createElement(Body1.Beard6e, null),
    },
};
export const beardConfigBody2 = {
    '#862618': {
        Beard1: React.createElement(Body2.Beard1a, null),
        Beard2: React.createElement(Body2.Beard2a, null),
        Beard3: React.createElement(Body2.Beard3a, null),
        Beard4: React.createElement(Body2.Beard4a, null),
        Beard5: React.createElement(Body2.Beard5a, null),
        Beard6: React.createElement(Body2.Beard6a, null),
    },
    '#85898B': {
        Beard1: React.createElement(Body2.Beard1b, null),
        Beard2: React.createElement(Body2.Beard2b, null),
        Beard3: React.createElement(Body2.Beard3b, null),
        Beard4: React.createElement(Body2.Beard4b, null),
        Beard5: React.createElement(Body2.Beard5b, null),
        Beard6: React.createElement(Body2.Beard6b, null),
    },
    '#DEA65F': {
        Beard1: React.createElement(Body2.Beard1c, null),
        Beard2: React.createElement(Body2.Beard2c, null),
        Beard3: React.createElement(Body2.Beard3c, null),
        Beard4: React.createElement(Body2.Beard4c, null),
        Beard5: React.createElement(Body2.Beard5c, null),
        Beard6: React.createElement(Body2.Beard6c, null),
    },
    '#654023': {
        Beard1: React.createElement(Body2.Beard1d, null),
        Beard2: React.createElement(Body2.Beard2d, null),
        Beard3: React.createElement(Body2.Beard3d, null),
        Beard4: React.createElement(Body2.Beard4d, null),
        Beard5: React.createElement(Body2.Beard5d, null),
        Beard6: React.createElement(Body2.Beard6d, null),
    },
    '#000000': {
        Beard1: React.createElement(Body2.Beard1e, null),
        Beard2: React.createElement(Body2.Beard2e, null),
        Beard3: React.createElement(Body2.Beard3e, null),
        Beard4: React.createElement(Body2.Beard4e, null),
        Beard5: React.createElement(Body2.Beard5e, null),
        Beard6: React.createElement(Body2.Beard6e, null),
    },
};
export const beardConfigBody3 = {
    '#862618': {
        Beard1: React.createElement(Body3.Beard1a, null),
        Beard2: React.createElement(Body3.Beard2a, null),
        Beard3: React.createElement(Body3.Beard3a, null),
        Beard4: React.createElement(Body3.Beard4a, null),
        Beard5: React.createElement(Body3.Beard5a, null),
        Beard6: React.createElement(Body3.Beard6a, null),
    },
    '#85898B': {
        Beard1: React.createElement(Body3.Beard1b, null),
        Beard2: React.createElement(Body3.Beard2b, null),
        Beard3: React.createElement(Body3.Beard3b, null),
        Beard4: React.createElement(Body3.Beard4b, null),
        Beard5: React.createElement(Body3.Beard5b, null),
        Beard6: React.createElement(Body3.Beard6b, null),
    },
    '#DEA65F': {
        Beard1: React.createElement(Body3.Beard1c, null),
        Beard2: React.createElement(Body3.Beard2c, null),
        Beard3: React.createElement(Body3.Beard3c, null),
        Beard4: React.createElement(Body3.Beard4c, null),
        Beard5: React.createElement(Body3.Beard5c, null),
        Beard6: React.createElement(Body3.Beard6c, null),
    },
    '#654023': {
        Beard1: React.createElement(Body3.Beard1d, null),
        Beard2: React.createElement(Body3.Beard2d, null),
        Beard3: React.createElement(Body3.Beard3d, null),
        Beard4: React.createElement(Body3.Beard4d, null),
        Beard5: React.createElement(Body3.Beard5d, null),
        Beard6: React.createElement(Body3.Beard6d, null),
    },
    '#000000': {
        Beard1: React.createElement(Body3.Beard1e, null),
        Beard2: React.createElement(Body3.Beard2e, null),
        Beard3: React.createElement(Body3.Beard3e, null),
        Beard4: React.createElement(Body3.Beard4e, null),
        Beard5: React.createElement(Body3.Beard5e, null),
        Beard6: React.createElement(Body3.Beard6e, null),
    },
};
export const beardConfigBody4 = {
    '#862618': {
        Beard1: React.createElement(Body4.Beard1a, null),
        Beard2: React.createElement(Body4.Beard2a, null),
        Beard3: React.createElement(Body4.Beard3a, null),
        Beard4: React.createElement(Body4.Beard4a, null),
        Beard5: React.createElement(Body4.Beard5a, null),
        Beard6: React.createElement(Body4.Beard6a, null),
    },
    '#85898B': {
        Beard1: React.createElement(Body4.Beard1b, null),
        Beard2: React.createElement(Body4.Beard2b, null),
        Beard3: React.createElement(Body4.Beard3b, null),
        Beard4: React.createElement(Body4.Beard4b, null),
        Beard5: React.createElement(Body4.Beard5b, null),
        Beard6: React.createElement(Body4.Beard6b, null),
    },
    '#DEA65F': {
        Beard1: React.createElement(Body4.Beard1c, null),
        Beard2: React.createElement(Body4.Beard2c, null),
        Beard3: React.createElement(Body4.Beard3c, null),
        Beard4: React.createElement(Body4.Beard4c, null),
        Beard5: React.createElement(Body4.Beard5c, null),
        Beard6: React.createElement(Body4.Beard6c, null),
    },
    '#654023': {
        Beard1: React.createElement(Body4.Beard1d, null),
        Beard2: React.createElement(Body4.Beard2d, null),
        Beard3: React.createElement(Body4.Beard3d, null),
        Beard4: React.createElement(Body4.Beard4d, null),
        Beard5: React.createElement(Body4.Beard5d, null),
        Beard6: React.createElement(Body4.Beard6d, null),
    },
    '#000000': {
        Beard1: React.createElement(Body4.Beard1e, null),
        Beard2: React.createElement(Body4.Beard2e, null),
        Beard3: React.createElement(Body4.Beard3e, null),
        Beard4: React.createElement(Body4.Beard4e, null),
        Beard5: React.createElement(Body4.Beard5e, null),
        Beard6: React.createElement(Body4.Beard6e, null),
    },
};
export const beardConfigBody5 = {
    '#862618': {
        Beard1: React.createElement(Body5.Beard1a, null),
        Beard2: React.createElement(Body5.Beard2a, null),
        Beard3: React.createElement(Body5.Beard3a, null),
        Beard4: React.createElement(Body5.Beard4a, null),
        Beard5: React.createElement(Body5.Beard5a, null),
        Beard6: React.createElement(Body5.Beard6a, null),
    },
    '#85898B': {
        Beard1: React.createElement(Body5.Beard1b, null),
        Beard2: React.createElement(Body5.Beard2b, null),
        Beard3: React.createElement(Body5.Beard3b, null),
        Beard4: React.createElement(Body5.Beard4b, null),
        Beard5: React.createElement(Body5.Beard5b, null),
        Beard6: React.createElement(Body5.Beard6b, null),
    },
    '#DEA65F': {
        Beard1: React.createElement(Body5.Beard1c, null),
        Beard2: React.createElement(Body5.Beard2c, null),
        Beard3: React.createElement(Body5.Beard3c, null),
        Beard4: React.createElement(Body5.Beard4c, null),
        Beard5: React.createElement(Body5.Beard5c, null),
        Beard6: React.createElement(Body5.Beard6c, null),
    },
    '#654023': {
        Beard1: React.createElement(Body5.Beard1d, null),
        Beard2: React.createElement(Body5.Beard2d, null),
        Beard3: React.createElement(Body5.Beard3d, null),
        Beard4: React.createElement(Body5.Beard4d, null),
        Beard5: React.createElement(Body5.Beard5d, null),
        Beard6: React.createElement(Body5.Beard6d, null),
    },
    '#000000': {
        Beard1: React.createElement(Body5.Beard1e, null),
        Beard2: React.createElement(Body5.Beard2e, null),
        Beard3: React.createElement(Body5.Beard3e, null),
        Beard4: React.createElement(Body5.Beard4e, null),
        Beard5: React.createElement(Body5.Beard5e, null),
        Beard6: React.createElement(Body5.Beard6e, null),
    },
};
// must match the body order
export const beardConfigsOrdered = [
    beardConfigBody1,
    beardConfigBody2,
    beardConfigBody3,
    beardConfigBody4,
    beardConfigBody5,
];
