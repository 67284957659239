var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AvatarByConfig } from '@components/basic-components/avatar/avatar-by-config';
import { Toggle } from '@components/basic-components/toggle';
import { beardColorsOrdered, beardShapesOrdered, } from '@components/basic-components/avatar/layers/beard/config-beard';
import { bodyColorsOrdered, bodyShapesOrdered, } from '@components/basic-components/avatar/layers/config-body';
import { classNames } from '@utils/class-names';
import { eyeColorsOrdered, eyeShapesOrdered, } from '@components/basic-components/avatar/layers/config-eye';
import { eyebrowColorsOrdered, eyebrowShapesOrdered, } from '@components/basic-components/avatar/layers/config-eyebrow';
import { hairColorsOrdered, hairShapesOrdered, } from '@components/basic-components/avatar/layers/hair/config-hair';
import { headGearColorsOrdered, headGearShapesOrdered, } from '@components/basic-components/avatar/layers/head-gear/config-head-gear';
import { jewelryColorsOrdered, jewelryShapesOrdered, } from '@components/basic-components/avatar/layers/config-jewelry';
import { shirtColorsOrdered, shirtShapesOrdered, } from '@components/basic-components/avatar/layers/config-shirts';
import React, { useEffect, useState } from 'react';
const layers = [
    {
        icon: 'icon-body',
        labels: {
            tab: 'Kopf',
            shape: 'Kopfform',
            color: 'Hautfarbe',
        },
        poolConfig: {
            orderedColors: bodyColorsOrdered,
            orderedShapes: bodyShapesOrdered,
        },
        avatarConfigKey: 'body',
    },
    {
        icon: 'icon-hair',
        labels: {
            tab: 'Frisur',
            shape: 'Frisur',
            color: 'Haarfarbe',
        },
        poolConfig: {
            orderedColors: hairColorsOrdered,
            orderedShapes: hairShapesOrdered,
        },
        avatarConfigKey: 'hair',
    },
    {
        icon: 'icon-eye',
        labels: {
            tab: 'Augen',
            shape: 'Form',
            color: 'Farbe',
        },
        poolConfig: {
            orderedColors: eyeColorsOrdered,
            orderedShapes: eyeShapesOrdered,
        },
        avatarConfigKey: 'eye',
    },
    {
        icon: 'icon-eyebrow',
        labels: {
            tab: 'Augenbrauen',
            shape: 'Form',
            color: 'Farbe',
        },
        poolConfig: {
            orderedColors: eyebrowColorsOrdered,
            orderedShapes: eyebrowShapesOrdered,
        },
        avatarConfigKey: 'eyebrow',
    },
    {
        icon: 'icon-beard',
        labels: {
            tab: 'Bart',
            shape: 'Bart',
            color: 'Bartfarbe',
        },
        poolConfig: {
            orderedColors: beardColorsOrdered,
            orderedShapes: beardShapesOrdered,
        },
        avatarConfigKey: 'beard',
    },
    {
        icon: 'icon-headgear',
        labels: {
            tab: 'Kopfbedeckung',
            shape: 'Kopfbedeckung',
            color: 'Farbe',
        },
        poolConfig: {
            orderedColors: headGearColorsOrdered,
            orderedShapes: headGearShapesOrdered,
        },
        avatarConfigKey: 'headGear',
    },
    {
        icon: 'icon-jewelry',
        labels: {
            tab: 'Schmuck',
            shape: 'Schmuck',
            color: 'Farbe',
        },
        poolConfig: {
            orderedColors: jewelryColorsOrdered,
            orderedShapes: jewelryShapesOrdered,
        },
        avatarConfigKey: 'jewelry',
    },
    {
        icon: 'icon-shirt',
        labels: {
            tab: 'T-Shirt',
            shape: 'T-Shirt',
            color: 'Farbe',
        },
        poolConfig: {
            orderedColors: shirtColorsOrdered,
            orderedShapes: shirtShapesOrdered,
        },
        avatarConfigKey: 'shirt',
    },
];
const flex = 'flex-1 flex flex-col';
export const AvatarConfigComponent = (avatarConfigComponentProps) => {
    const [activeTab, setActiveTab] = useState(0);
    return (React.createElement("div", { "data-testid": "AvatarConfig", className: flex },
        React.createElement(AvatarConfigTabsComponent, { activeTab: activeTab, setActiveTab: setActiveTab }),
        React.createElement(AvatarConfigBodyComponent, Object.assign({}, avatarConfigComponentProps, { activeTab: activeTab }))));
};
const AvatarConfigTabsComponent = ({ activeTab, setActiveTab }) => {
    function handleOnClick(index) {
        return function _() {
            if (index !== activeTab) {
                setActiveTab(index);
            }
        };
    }
    return (React.createElement("div", { className: "overflow-x-auto pt-3", "data-testid": "AvatarConfigTabsComponent" },
        React.createElement("div", { className: "inline-flex gap-x-2 mx-3" }, layers.map((layer, index) => (React.createElement("button", { key: layer.labels.tab, className: classNames('flex justify-center items-center gap-x-3 min-w-16 h-10 px-3', 'bg-white font-condensed text-darkBlue whitespace-nowrap tracking-widest'), "data-testid": `AvatarConfigTabsButton-${index}`, onClick: handleOnClick(index) },
            React.createElement("div", { className: classNames(layer.icon, index === activeTab
                    ? 'text-green'
                    : 'text-darkBlue/70') }),
            index === activeTab && layer.labels.tab))))));
};
const AvatarConfigBodyComponent = ({ activeTab, avatarConfig, setAvatarConfig }) => {
    const initialEditMode = layers[activeTab].poolConfig.orderedShapes.length < 2
        ? 'color'
        : 'shape';
    const [editMode, setEditMode] = useState(initialEditMode);
    useEffect(() => {
        setEditMode(initialEditMode);
    }, [activeTab, initialEditMode]);
    function handleOnChangeToggle(checked) {
        setEditMode(checked ? 'color' : 'shape');
    }
    function handleSwitchEditMode(_editMode) {
        return function _() {
            setEditMode(_editMode);
        };
    }
    const activeLayer = layers[activeTab];
    const activeAvatarConfig = avatarConfig[activeLayer.avatarConfigKey];
    return (React.createElement("div", { className: classNames(flex, 'pt-3 px-3'), "data-testid": `AvatarConfigBodyComponent-${activeTab}` },
        layers[activeTab].poolConfig.orderedColors.length > 1 &&
            layers[activeTab].poolConfig.orderedShapes.length > 1 && (React.createElement("div", { className: "grid grid-cols-[1fr_auto_1fr] gap-x-3 items-center pb-3 px-3 text-darkBlue/50 font-medium" },
            React.createElement("button", { className: classNames('text-right', editMode === 'shape' && 'text-darkBlue'), onClick: handleSwitchEditMode('shape') }, layers[activeTab].labels.shape),
            React.createElement(Toggle, { id: "switchEditMode", onChange: handleOnChangeToggle, checked: editMode !== 'shape', dataTestId: "shape-color-toggle" }),
            React.createElement("button", { className: classNames('text-left', editMode === 'color' && 'text-darkBlue'), onClick: handleSwitchEditMode('color') }, layers[activeTab].labels.color))),
        React.createElement(AvatarConfigBodyShapeComponent, { avatarConfig: avatarConfig, setAvatarConfig: setAvatarConfig, activeLayer: activeLayer, activeShape: activeAvatarConfig.shape, activeColor: activeAvatarConfig.color, editMode: editMode })));
};
const AvatarConfigBodyShapeComponent = (_a) => {
    var { activeColor, activeShape, editMode, activeLayer } = _a, avatarConfigComponentProps = __rest(_a, ["activeColor", "activeShape", "editMode", "activeLayer"]);
    const { avatarConfigKey, poolConfig: { orderedShapes, orderedColors }, } = activeLayer;
    const shapes = editMode === 'shape'
        ? orderedShapes.map((shape) => ({
            shape,
            color: activeColor,
        }))
        : orderedColors.map((color) => ({
            shape: activeShape,
            color,
        }));
    return (React.createElement("div", { className: "relative flex-1", "data-testid": `AvatarConfigBodyShapeComponent-${editMode}` },
        React.createElement("div", { className: "absolute inset-0 overflow-auto force-scrollbar pb-3" },
            React.createElement("div", { className: classNames('relative grid gap-3 mx-auto', shapes.length === 2 && 'grid-cols-2 w-2/3', (shapes.length > 2 || shapes.length === 1) &&
                    'grid-cols-3 w-full') },
                shapes.length === 1 && React.createElement("div", null),
                shapes.map(({ shape, color }) => (React.createElement(AvatarPreviewTile, Object.assign({ key: `${shape}-${color}` }, avatarConfigComponentProps, { activeShape: shape, activeColor: color, avatarConfigKey: avatarConfigKey, editMode: editMode })))),
                shapes.length === 1 && React.createElement("div", null)))));
};
const AvatarPreviewTile = ({ avatarConfig, avatarConfigKey, activeShape, activeColor, setAvatarConfig, editMode, }) => {
    const config = JSON.parse(JSON.stringify(avatarConfig));
    config[avatarConfigKey].shape = activeShape;
    config[avatarConfigKey].color = activeColor;
    function handleClick() {
        setAvatarConfig(config);
    }
    const isActive = (editMode === 'shape' &&
        avatarConfig[avatarConfigKey].shape === activeShape) ||
        (editMode === 'color' &&
            avatarConfig[avatarConfigKey].color === activeColor);
    return (React.createElement("button", { className: classNames('relative p-4 overflow-hidden', isActive ? 'bg-[#D0E09B]' : 'bg-white'), onClick: handleClick },
        editMode === 'color' && (React.createElement("span", { className: "absolute top-1 right-1 w-4 h-4 border border-solid border-darkBlue/10 rounded-sm", style: { backgroundColor: activeColor } })),
        React.createElement(AvatarByConfig, { config: config })));
};
