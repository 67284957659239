import { SingleAnswerStateDto, } from '@mika-bibb/shared/challenge-status-dto';
import { Popup } from '@components/basic-components/popup';
import { useBackPath } from '@components/basic-components/back-button';
import { useNestClientContext } from '../../context/nest-client-context';
import PopupFooterButton from '@components/question/popup-footer-button';
import React from 'react';
export default function QuestionViewRoundscorePopup({ roundScorePopupOpen, challengeRound, challenge, }) {
    var _a;
    const { nestClient } = useNestClientContext();
    const myRoundAnswers = challenge.me.answersPerRound[(_a = challenge.currentRound) !== null && _a !== void 0 ? _a : 0].answers;
    const myRoundScore = myRoundAnswers.filter((a) => a === SingleAnswerStateDto.CORRECT).length;
    // eslint-disable-next-line no-prototype-builtins
    const isKiosk = nestClient.hasOwnProperty('kiosk');
    const backToChallengePath = useBackPath(isKiosk ? 1 : 2);
    return (React.createElement(Popup, { popupOpen: roundScorePopupOpen, withoutFooter: true, mika: "normal", centerScrollPanel: {
            header: (React.createElement("div", { className: "text-darkBlue text-xl font-bold mb-4" },
                "Du hast ",
                myRoundScore,
                "/",
                challenge.questionsPerRound,
                ' ',
                "Fragen richtig beantwortet")),
            footer: (React.createElement("div", { className: "mb-4" },
                React.createElement(PopupFooterButton, { challengeRound: challengeRound, to: backToChallengePath, overrideLabelText: "Zur\u00FCck zur \u00DCbersicht" }),
                React.createElement("div", { className: "h-4" }))),
        } }));
}
