import { QuestionType } from '@mika-bibb/shared/enums';
import { SingleChoiceAnswer } from '@components/challenge/question/single-choice-answer';
import { getQuestionPrompt } from '@mika-bibb/shared/util/get-question-prompt';
import { shuffleArray } from '@utils/mixed';
import { useNestClientContext } from '../../../context/nest-client-context';
import React, { useCallback, useEffect, useState } from 'react';
import useChallengeContext from '../../../context/challenge-context';
export function SingleChoiceAnswersPanel({ question, setTimeoutFunction, setFinishFunction, setQuestionFinished, }) {
    const { challengeId, challenge, challengeRound, roundIndex, questionIndex, selectedAnswerIndex, setSelectedAnswerIndex, refreshChallengeData, } = useChallengeContext();
    const answerState = challenge && roundIndex !== undefined && questionIndex !== undefined
        ? challenge.me.answersPerRound[roundIndex].answers[questionIndex]
        : undefined;
    const { nestClient } = useNestClientContext();
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const selectAnswer = useCallback((index) => {
        if (!challengeId ||
            roundIndex === undefined ||
            questionIndex === undefined) {
            console.error('Invalid view state for answer selection!');
            return;
        }
        setSelectedAnswerIndex(index !== null && index !== void 0 ? index : -1);
        void nestClient
            .answerQuestion(challengeId, roundIndex, questionIndex, index)
            .then(refreshChallengeData, () => { });
    }, [
        challengeId,
        nestClient,
        questionIndex,
        refreshChallengeData,
        roundIndex,
        setSelectedAnswerIndex,
    ]);
    useEffect(() => {
        setQuestionFinished(selectedAnswerIndex !== -1);
    }, [setQuestionFinished, selectedAnswerIndex]);
    useEffect(() => {
        setTimeoutFunction(() => () => selectAnswer(null));
        setFinishFunction(undefined);
    }, [setTimeoutFunction, selectAnswer, setFinishFunction]);
    useEffect(() => {
        setShuffledAnswers(shuffleArray(question.answers.map((answer, originalIndex) => ({
            answer,
            originalIndex,
        }))));
    }, [question.answers]);
    const hasImageAnswers = question.answers.some((a) => a.imageUrl);
    const hasImageQuestion = !!question.imageUrl;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-darkBlue mb-2 text-sm", "data-testid": "QuestionPrompt" }, getQuestionPrompt(question.type)),
        React.createElement("div", { className: hasImageAnswers || question.type === QuestionType.TRUE_FALSE
                ? 'grid grid-cols-2 gap-4 desktop:px-80'
                : 'flex flex-col ' + hasImageQuestion
                    ? 'space-y-2'
                    : 'space-y-4' }, shuffledAnswers.map(({ answer, originalIndex }) => (React.createElement(SingleChoiceAnswer, { key: originalIndex, originalIndex: originalIndex, selectedAnswerIndex: selectedAnswerIndex, answerState: answerState, selectAnswer: selectAnswer, disabled: challengeRound.nextQuestion !== questionIndex ||
                selectedAnswerIndex !== -1, hasImageAnswers: hasImageAnswers, hasImageQuestion: hasImageQuestion, answer: answer, isTrueFalse: question.type === QuestionType.TRUE_FALSE }))))));
}
