import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { InTeamStatisticsInfo } from '@components/statistics/in-team-statistics-info';
import { ViewsType } from '@typesSrc/views';
import { useNestClientContext } from '../context/nest-client-context';
import { useParams } from 'react-router-dom';
import { usePolling } from '../hooks/usePolling';
import BaseView from '@views/base-view';
import React, { useState } from 'react';
import dayjs from '@mika-bibb/shared/util/dayjs';
export default function TeamMemberStatisticsView() {
    const teamId = useParams().teamId;
    const memberId = useParams().memberId;
    const { nestClient } = useNestClientContext();
    const [allTime, setAllTime] = useState(true);
    const [inTeamStatistics, setInTeamStatistics] = useState();
    usePolling(() => {
        if (!teamId || !memberId) {
            return;
        }
        void nestClient
            .getInTeamStatistics(teamId, memberId, allTime
            ? undefined
            : dayjs().subtract(1, 'week').toISOString())
            .then((res) => setInTeamStatistics(res));
    }, 30000, [teamId, memberId, allTime]);
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGES_OVERVIEW, dataTestId: "ChallengesHistoryView", header: {
            title: `Statistik von ${inTeamStatistics === null || inTeamStatistics === void 0 ? void 0 : inTeamStatistics.memberName} im Team ${inTeamStatistics === null || inTeamStatistics === void 0 ? void 0 : inTeamStatistics.teamName}`,
            showHelp: true,
            showBack: true,
            backLevels: 2,
        }, showFooter: false },
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement(CenterScrollPanel, { className: "flex-1 bg-bgGray overflow-y-hidden", padding: "p-0", greyOnDesktop: false },
                React.createElement("div", { className: "flex flex-col min-h-full p-4" }, inTeamStatistics && (React.createElement(InTeamStatisticsInfo, { inTeamStatistics: inTeamStatistics, allTime: allTime, setAllTime: setAllTime })))))));
}
