import { Button } from '@components/basic-components/button';
import { Popup } from '@components/basic-components/popup';
import React from 'react';
export function ChallengeHelpPopup({ popupOpen, setPopupOpen, withFooter, }) {
    return (React.createElement(Popup, { popupOpen: popupOpen, setPopupOpen: setPopupOpen, withoutFooter: !withFooter, centerScrollPanel: {
            header: (React.createElement("span", { className: "font-bold text-xl text-darkBlue" }, "Wie funktioniert\u2018s?")),
            footer: (React.createElement(Button, { className: "w-full", onClick: () => setPopupOpen(false) }, "Verstanden")),
        } },
        React.createElement("p", { className: "text-green font-sm leading-[17px] my-1" },
            React.createElement("b", null, "Home:"),
            " Schon oben auf der Bestenliste? Deine Punkte und die Bestenliste findest du auf der Home- Seite. \u00DCbrigens: Alle erspielten Punkte flie\u00DFen in Dein pers\u00F6nliches Punktekonto.",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("b", null, "Quiz:"),
            " W\u00E4hle \u00FCber \u201ENeues Spiel\u201C einen von vier Spielmodi aus: Einzelspiel, 1vs1, Zufallsgegner/-in 1vs1 oder Klassenzimmer-Quiz. Du spielst bis zu sechs Runden mit je 3-10 Fragen zu den wichtigsten Themen rund um Medien- und IT-Kompetenz. F\u00FCr die Beantwortung der Fragen kannst du zwei Geschwindigkeitsstufen ausw\u00E4hlen \u2013 20 oder 40 Sekunden. Bitte beachte, dass Du mit derselben Person immer nur ein Spiel gleichzeitig haben kannst.",
            React.createElement("br", null),
            React.createElement("br", null),
            "F\u00FCr jede richtig beantwortete Frage erh\u00E4ltst Du einen Punkt. Wenn Du ein Quiz mit sechs Runden und je zehn Fragen gewinnst, erh\u00E4ltst du 15 Bonus-Punkte. Bei Gleichstand erhalten beide Spieler/-innen je 10 Bonus-Punkte.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Du willst ohne festes Team das Wissen Deiner Klasse testen? Dann nutze das Klassenzimmer-Quiz. Generiere einen Code und teile diesen mit deinen Mitspieler/-innen. Nun habt ihr 90 Minuten Zeit Euer Wissen gegeneinander zu testen. Du kannst jederzeit sehen, wie gut Du im Vergleich zum Klassenzimmer-Team bist.",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("b", null, "Teams:"),
            " Ihr seid ein Team und wollt Euch mit einem anderen messen? Lege im Bereich Teams ein neues Team an und fordere ein anderes zum Duell heraus. Jede Antwort eines Teammitglieds flie\u00DFt in Eure Teamwertung ein. Zus\u00E4tzlich k\u00F6nnt ihr auch ein Spiel innerhalb des Teams starten. Bitte beachte, dass Du immer nur ein Spiel gleichzeitig gegen dasselbe Team haben kannst.",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("b", null, "Profil:"),
            " Im Profil kannst Du u.a. Deinen Avatar einstellen, einen Nickname vergeben und Benachrichtigungen aktivieren.",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("b", null, "Eigene Fragen:"),
            " Du willst eigene Fragen in die Quiz-Kategorien einbinden? Das gelingt Dir unter Meine Fragen. Du kannst deine Fragen als Entwurf speichern oder direkt ver\u00F6ffentlichen \u2013 bediene dazu nach dem Erstellen der Frage den orangefarbigen Schieberegler. Bereits ver\u00F6ffentlichte Fragen kannst Du direkt l\u00F6schen oder inaktiv schalten, indem Du sie wieder als Entwurf speicherst. In der App findest Du Deine eigenen Fragen unter \u201EMeine Fragen\u201C in Deinem Profil.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Leg los und teste Dein Wissen!")));
}
