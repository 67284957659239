import { AndroidSettings, IOSSettings, NativeSettings, } from 'capacitor-native-settings';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PUSH_TOKEN_KEY, } from '@components/notification-handlers/notification-handler';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications } from '@capacitor/push-notifications';
import { urlToRelative } from '@mika-bibb/shared/util/url';
import ManualPromise from '@mika-bibb/shared/util/manual-promise';
export class MobileNotificationHandler {
    async init() {
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === 'prompt' ||
            permStatus.receive === 'prompt-with-rationale') {
            permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions! Status: ' + permStatus.receive);
        }
        await PushNotifications.register();
        const tokenPromise = new ManualPromise();
        void Preferences.get({ key: PUSH_TOKEN_KEY }).then((token) => {
            if (token.value) {
                tokenPromise.resolve(token.value);
            }
        });
        await PushNotifications.addListener('registration', (token) => {
            if (token.value) {
                void Preferences.set({
                    key: PUSH_TOKEN_KEY,
                    value: token.value,
                });
            }
            tokenPromise.resolve(token.value);
        });
        await PushNotifications.addListener('registrationError', (error) => {
            console.error('Error on registration: ' + JSON.stringify(error));
            tokenPromise.reject(error);
        });
        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            // On android, push notification clicks do not work when the app is already open,
            //  so we need to show a local notification instead.
            //  see also: https://github.com/ionic-team/capacitor-plugins/pull/1478
            // On ios, this is not needed, and might actually break things.
            if (Capacitor.getPlatform() === 'android') {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                setTimeout(async () => {
                    var _a, _b;
                    await PushNotifications.removeAllDeliveredNotifications();
                    await LocalNotifications.schedule({
                        notifications: [
                            {
                                id: 42,
                                title: (_a = notification.title) !== null && _a !== void 0 ? _a : 'Notification',
                                body: (_b = notification.body) !== null && _b !== void 0 ? _b : '',
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                extra: notification.data,
                            },
                        ],
                    });
                }, 0);
            }
        });
        function onNotificationClick(payload) {
            const relativeUrl = urlToRelative(payload.url);
            if (relativeUrl) {
                window.location.href = relativeUrl;
            }
        }
        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => onNotificationClick(notification.notification.data));
        await LocalNotifications.addListener('localNotificationActionPerformed', (notification) => onNotificationClick(notification.notification.extra));
        if (Capacitor.getPlatform() === 'android') {
            // immediately create a channel, so that the user is prompted for permissions (at least for android <= 12)
            // see https://firebase.google.com/docs/cloud-messaging/android/client#request-permission13
            // and https://firebase.google.com/docs/cloud-messaging/android/client#notification_permissions_for_apps_targeting_android_12l_api_level_32_or_lower
            await PushNotifications.createChannel({
                id: 'mika_personal_notifications',
                name: 'Personal Notifications',
            });
        }
        return tokenPromise;
    }
    async finalize() {
        await Promise.all([
            PushNotifications.removeAllDeliveredNotifications(),
            PushNotifications.removeAllListeners(),
            LocalNotifications.removeAllDeliveredNotifications(),
            LocalNotifications.removeAllListeners(),
        ]);
    }
    async handleMissingPermissions() {
        await NativeSettings.open({
            optionIOS: IOSSettings.Notifications,
            optionAndroid: AndroidSettings.AppNotification,
        });
    }
}
const mobileNotificationHandler = new MobileNotificationHandler();
export default mobileNotificationHandler;
