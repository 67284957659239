import { useNestClientContext } from './nest-client-context';
import React, { useContext, useEffect, useState, } from 'react';
const DefaultImageContext = React.createContext(undefined);
export function DefaultImageContextProvider({ children }) {
    const [state, setState] = useState({
        defaultTeamImage: undefined,
    });
    const { nestClient } = useNestClientContext();
    useEffect(() => {
        void nestClient
            .getDefaultTeamPicture()
            .then((teamImage) => setState({ defaultTeamImage: teamImage }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(DefaultImageContext.Provider, { value: state }, children));
}
export default function useDefaultImageContext() {
    const state = useContext(DefaultImageContext);
    if (!state) {
        throw new Error('useDefaultImageContext must be used within a DefaultImageContext');
    }
    return state;
}
