import { DragDropContext } from 'react-beautiful-dnd';
import { ReactComponent as DragHandle } from '@public/icons/drag-handle.svg';
import { SingleAnswerStateDto } from '@mika-bibb/shared/challenge-status-dto';
import { classNames } from '@utils/class-names';
import { getQuestionPrompt } from '@mika-bibb/shared/util/get-question-prompt';
import { shuffleArray } from '@utils/mixed';
import { useNestClientContext } from '../../../context/nest-client-context';
import DraggableList from '@components/basic-components/draggable-list';
import React, { useCallback, useEffect, useState } from 'react';
import useChallengeContext from '../../../context/challenge-context';
function isAllCorrect(shuffledAnswers, question) {
    return !shuffledAnswers.some((shuffledAnswer, index) => question.answers.findIndex((answer) => answer.id === shuffledAnswer.answer.id) !== index);
}
export function OrderingAnswersPanel({ question, setTimeoutFunction, setFinishFunction, setQuestionFinished, }) {
    const { challengeId, roundIndex, questionIndex, challenge, refreshChallengeData, } = useChallengeContext();
    const { nestClient } = useNestClientContext();
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const answerState = challenge && roundIndex !== undefined && questionIndex !== undefined
        ? challenge.me.answersPerRound[roundIndex].answers[questionIndex]
        : undefined;
    useEffect(() => {
        setQuestionFinished(false);
    }, [setQuestionFinished]);
    const finishQuestion = useCallback(() => {
        const allCorrect = isAllCorrect(shuffledAnswers, question);
        setQuestionFinished(true);
        if (challengeId === undefined ||
            roundIndex === undefined ||
            questionIndex === undefined) {
            return;
        }
        void nestClient
            .answerQuestion(challengeId, roundIndex, questionIndex, allCorrect ? 0 : 1)
            .then(() => {
            refreshChallengeData();
        }, () => { });
    }, [
        shuffledAnswers,
        question,
        setQuestionFinished,
        challengeId,
        roundIndex,
        questionIndex,
        nestClient,
        refreshChallengeData,
    ]);
    useEffect(() => {
        setTimeoutFunction(() => finishQuestion);
        setFinishFunction(() => finishQuestion);
    }, [
        setQuestionFinished,
        setFinishFunction,
        finishQuestion,
        setTimeoutFunction,
    ]);
    useEffect(() => {
        setShuffledAnswers(shuffleArray(question.answers.map((answer, originalIndex) => ({
            answer,
            originalIndex,
        }))));
    }, [question.answers]);
    const reorderAnswers = useCallback((result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(shuffledAnswers);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setShuffledAnswers(items);
    }, [shuffledAnswers]);
    return (React.createElement("div", { className: classNames('h-max flex flex-col justify-between') },
        React.createElement("div", { className: "text-darkBlue mb-2 text-sm" }, getQuestionPrompt(question.type)),
        React.createElement(DragDropContext, { onDragEnd: (result) => reorderAnswers(result) },
            React.createElement(DraggableList, { items: shuffledAnswers.map(({ answer }, index) => {
                    return (React.createElement("div", { className: classNames('w-full p-4 border-2 text-left text-darkBlue', answerState &&
                            answerState !==
                                SingleAnswerStateDto.NOT_ANSWERED &&
                            (question.answers.findIndex((a) => a.id === answer.id) === index
                                ? 'bg-green/30'
                                : 'bg-red'), 'flex flex-row items-center justify-between w-full h-full space-x-2'), "data-testid": `answer-${answer.index}`, key: answer.id },
                        answer.text,
                        React.createElement(DragHandle, { className: "shrink-0" })));
                }) }))));
}
