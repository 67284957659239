var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Popup } from '@components/basic-components/popup';
import { useLocalUserState } from '../../context/use-local-user-state';
import React, { useEffect, useMemo, useState } from 'react';
const minimumShowTime = 500;
export const OneTimePopup = (_a) => {
    var { id } = _a, popupProps = __rest(_a, ["id"]);
    const { loaded, popupAlreadyShown, addShownOneTimePopup } = useLocalUserState();
    const [ready, setReady] = useState(false);
    const [reachedMinimumShowTime, setReachedMinimumShowTime] = useState(false);
    const { setPopupOpen, popupOpen } = popupProps;
    const popWasUpAlreadyShown = useMemo(() => {
        return popupAlreadyShown(id);
    }, [id, popupAlreadyShown]);
    useEffect(() => {
        if (!loaded) {
            return;
        }
        let timer;
        if (popWasUpAlreadyShown) {
            setPopupOpen === null || setPopupOpen === void 0 ? void 0 : setPopupOpen(false);
        }
        else if (popupOpen) {
            timer = setTimeout(() => {
                setReachedMinimumShowTime(true);
            }, minimumShowTime);
        }
        setReady(true);
        return () => {
            clearTimeout(timer);
            if (reachedMinimumShowTime) {
                addShownOneTimePopup(id);
            }
        };
    }, [
        popupOpen,
        loaded,
        id,
        addShownOneTimePopup,
        setPopupOpen,
        popWasUpAlreadyShown,
        reachedMinimumShowTime,
    ]);
    if (!ready) {
        return null;
    }
    return React.createElement(Popup, Object.assign({}, popupProps));
};
