import { Avatar } from '@components/basic-components/avatar/avatar';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeType, } from '@mika-bibb/shared/challenge-status-dto';
import { Loading } from '@components/basic-components/loading';
import { Popup } from '@components/basic-components/popup';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { formatTimeLeft, formatTimePassed, overflowEllipsis, } from '@utils/mixed';
import { isSoloChallenge } from '@mika-bibb/shared/util/challenge';
import { nestClient, useNestClientContext, } from '../context/nest-client-context';
import { useNavigate } from 'react-router';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import { useSearchParams } from 'react-router-dom';
import BaseView from '@views/base-view';
import ListEntry from '@components/basic-components/list-entry';
import React, { useCallback, useEffect, useState } from 'react';
import useWatchCallback, { dismantleWatchableCallback, } from '@utils/promise-watch';
function NewGameText({ text }) {
    return (React.createElement("p", { className: "pt-0.5 pb-5 text-darkBlue/70 text-sm text-center" }, text));
}
function MyTurnFilter({ myTurnFilter, setMyTurnFilter, }) {
    return (React.createElement(TabsToggle, { leftSelected: myTurnFilter, setLeftSelected: setMyTurnFilter, labelLeft: "Mein Zug", labelRight: "Warten" }));
}
function ChallengeAcceptOrDeclineButtons(props) {
    const [onAcceptBare, acceptBusy] = dismantleWatchableCallback(props.onAccept);
    const onAcceptClick = useCallback((e) => {
        e.preventDefault();
        onAcceptBare();
    }, [onAcceptBare]);
    const [onDeclineBare, declineBusy] = dismantleWatchableCallback(props.onDecline);
    const onDeclineClick = useCallback((e) => {
        e.preventDefault();
        onDeclineBare();
    }, [onDeclineBare]);
    const busy = acceptBusy || declineBusy;
    return (React.createElement("div", { className: "flex justify-center" },
        props.canAccept && (React.createElement("button", { onClick: onAcceptClick, className: classNames('text-white text-2xl icon-check-pur bg-green w-8 h-8', busy && 'opacity-50'), disabled: busy })),
        React.createElement("button", { onClick: onDeclineClick, className: classNames('ml-1 text-white text-2xl icon-close bg-red/100 w-8 h-8', busy && 'opacity-50'), disabled: busy })));
}
export function ChallengeOverviewItem({ profile, challenge, statusText, refreshOverview, }) {
    const navigate = useNavigate();
    const timeLeftText = challenge.gameOver
        ? formatTimePassed(challenge.validUntil)
        : formatTimeLeft(challenge.validUntil);
    let roundText = challenge.currentRound !== undefined
        ? `Runde ${challenge.currentRound + 1}/${challenge.numRounds}`
        : 'Warte auf Gegner/-in';
    if (challenge.gameOver) {
        roundText = 'Quiz beendet';
    }
    if (!challenge.started && challenge.gameOver) {
        if (challenge.type === ChallengeType.CODE) {
            roundText = 'Nicht rechtzeitig gestartet';
        }
        else {
            roundText = 'Einladung abgelehnt';
        }
    }
    const onDecline = useWatchCallback(() => {
        return nestClient.declineChallenge(challenge.id).then(refreshOverview);
    }, []);
    const onAccept = useWatchCallback(() => {
        return nestClient.acceptChallenge(challenge.id).then(() => {
            navigate(`/challenges/${challenge.id}`);
        });
    }, []);
    return (React.createElement(ListEntry, { to: challenge.id, avatar: isSoloChallenge(challenge.type) ? (React.createElement(Avatar, { image: profile.avatarURL, bannerType: profile.bannerType, sizes: "42px" })) : (React.createElement(Avatar, { sizes: "42px", image: challenge.opponentInfo.imageUrl, bannerType: challenge.opponentInfo.bannerType })), middle: React.createElement("div", { className: "flex-1 flex flex-col min-w-0" },
            React.createElement("p", { className: `font-bold text-darkBlue ${overflowEllipsis}` }, challenge.name),
            React.createElement("p", { className: "text-sm text-orange" }, roundText)), right: challenge.pendingAccept ? (React.createElement(ChallengeAcceptOrDeclineButtons, { canAccept: challenge.isUsersTurn, onAccept: onAccept, onDecline: onDecline })) : ((challenge.type !== ChallengeType.SOLO ||
            challenge.gameOver) && (React.createElement("div", { className: "flex-1 flex flex-col items-end text-sm" },
            React.createElement("p", { className: "text-darkBlue/50" }, timeLeftText),
            React.createElement("p", { className: "text-green" }, statusText)))) }));
}
function NewGamePopup(props) {
    const navigate = useNavigate();
    const create1v1RandomChallenge = useWatchCallback(() => {
        return nestClient
            .create1v1RandomChallenge()
            .then((res) => navigate(`/challenges/${res.id}`));
    }, []);
    return (React.createElement(Popup, { popupOpen: props.popupOpen, setPopupOpen: props.setPopupOpen, centerScrollPanel: { padding: 'pt-6 px-6' }, hideCloseButton: true },
        React.createElement("div", { className: "flex flex-col gap-y-3 desktop:bg-bgGray desktop:p-8 desktop:max-w-[24rem] desktop:mx-auto" },
            React.createElement("div", { className: "desktop:bg-white flex flex-col gap-y-1.5" },
                React.createElement(Button, { to: `/challenges/new?type=${ChallengeType.SOLO}` }, "Einzelspiel"),
                React.createElement(NewGameText, { text: "Spiele alleine und teste Dein Wissen" })),
            React.createElement("div", { className: "desktop:bg-white flex flex-col gap-y-1.5" },
                React.createElement(Button, { to: 'searchOpponent', priority: "secondary" }, "1vs1"),
                React.createElement(NewGameText, { text: "Spiele gegen Spieler/-innen Deiner Wahl" })),
            React.createElement("div", { className: "desktop:bg-white flex flex-col gap-y-1.5" },
                React.createElement(Button, { onClick: create1v1RandomChallenge, priority: "secondary" }, "Zufallsgegner 1vs1"),
                React.createElement(NewGameText, { text: "Spiele gegen zuf\u00E4llige Spieler/-innen" })),
            React.createElement("div", { className: "desktop:bg-white flex flex-col gap-y-1.5" },
                React.createElement(Button, { to: 'classroomChallenge', className: "border-green text-green font-semibold", priority: "tertiary" }, "Klassenzimmer-Quiz"),
                React.createElement(NewGameText, { text: "Klassenzimmer-Quiz beitreten oder erstellen" })))));
}
const HISTORY_VIEW_POLLING_TIME = 15000;
export default function ChallengesOverviewView() {
    const { state: { profile }, } = useProfile();
    const { nestClient } = useNestClientContext();
    const [popupOpen, setPopupOpen] = useState(false);
    const openPopup = useCallback(() => {
        setPopupOpen(true);
    }, []);
    const [myTurnFilter, setMyTurnFilter] = useState(true);
    const [params] = useSearchParams();
    useEffect(() => {
        if (params.get('waiting')) {
            setMyTurnFilter(false);
        }
    }, [params]);
    const [challengeOverviewDTO, setChallengeOverviewDTO] = useState();
    const refreshHistory = () => {
        void nestClient.getMyChallenges().then((res) => {
            setChallengeOverviewDTO(res);
        });
    };
    usePolling(refreshHistory, HISTORY_VIEW_POLLING_TIME);
    const shownChallenges = challengeOverviewDTO === null || challengeOverviewDTO === void 0 ? void 0 : challengeOverviewDTO.filter((challenge) => challenge.isUsersTurn === myTurnFilter);
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGES_OVERVIEW, dataTestId: "ChallengesOverviewView", header: {
            title: 'Quiz-Übersicht',
            showHelp: true,
        } },
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement(CenterScrollPanel, { className: "flex-1 bg-bgGray", padding: "p-6", header: React.createElement(React.Fragment, null,
                    React.createElement(MyTurnFilter, { myTurnFilter: myTurnFilter, setMyTurnFilter: setMyTurnFilter })), footer: profile && (React.createElement("div", { className: "space-y-2" },
                    React.createElement(Button, { onClick: openPopup, className: "w-full desktop:max-w-[20rem] desktop:mx-auto" }, "Neues Spiel"),
                    React.createElement(Button, { to: "/challenges/history", className: "w-full desktop:max-w-[20rem] desktop:mx-auto", priority: "tertiary" }, "Historie"))) },
                React.createElement("div", { className: "flex flex-col min-h-full" },
                    (shownChallenges === null || shownChallenges === void 0 ? void 0 : shownChallenges.length) === 0 && (React.createElement("p", { className: "text-darkBlue text-xl text-center font-bold" }, "Keine Quizze")),
                    profile ? (shownChallenges === null || shownChallenges === void 0 ? void 0 : shownChallenges.map((challenge) => (React.createElement(ChallengeOverviewItem, { refreshOverview: refreshHistory, key: challenge.id, profile: profile, challenge: challenge, statusText: myTurnFilter ? 'Mein Zug' : 'Warten' })))) : (React.createElement(Loading, { className: "mt-10" }))))),
        React.createElement(NewGamePopup, { popupOpen: popupOpen, setPopupOpen: setPopupOpen })));
}
