import { ReactComponent as AddMember } from '@public/icons/add_member.svg';
import { ApiError } from '@api/errors';
import { Avatar, DefaultAvatar, } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { Link, useParams } from 'react-router-dom';
import { Loading } from '@components/basic-components/loading';
import { MIN_TEAM_MEMBERS_TO_START_CHALLENGE } from '@mika-bibb/shared/constants';
import { Popup } from '@components/basic-components/popup';
import { ServerErrorCode } from '@mika-bibb/shared/server-error-codes';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { formatStandingsString } from '@utils/formatting';
import { overflowEllipsis } from '@utils/mixed';
import { useErrorContext } from '../context/error-context';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import ListEntry from '@components/basic-components/list-entry';
import React, { useCallback } from 'react';
import useWatchCallback, { dismantleWatchableCallback, } from '@utils/promise-watch';
function TeamMemberSwipeElement({ member, onAdmin, onRemove, }) {
    const [onAdminBare, adminBusy] = dismantleWatchableCallback(onAdmin);
    const onAdminClick = (e) => {
        e.preventDefault();
        onAdminBare();
    };
    const [onRemoveBare, removeBusy] = dismantleWatchableCallback(onRemove);
    const onRemoveClick = (e) => {
        e.preventDefault();
        onRemoveBare();
    };
    return (React.createElement("span", { className: "flex flex-row h-full pl-4 bg-white" },
        React.createElement("button", { className: classNames('w-12 bg-green/100 text-white', adminBusy && 'opacity-50', member.admin
                ? 'bg-orange icon-demote'
                : 'bg-green icon-promote'), onClick: onAdminClick, "data-testid": "toggle-admin" }),
        React.createElement("button", { className: classNames('w-12 bg-red/100 text-white icon-delete', removeBusy && 'opacity-50'), onClick: onRemoveClick, "data-testid": "remove-member" })));
}
export default function TeamDetailsView() {
    var _a;
    const { addError } = useErrorContext();
    const { nestClient } = useNestClientContext();
    const teamId = useParams().teamId;
    const [team, setTeam] = React.useState();
    const [swipeResetCounter, setSwipeResetCounter] = React.useState(0);
    const { state: { profile }, } = useProfile();
    usePolling(() => {
        if (teamId) {
            void nestClient.getTeam(teamId).then(setTeam);
        }
    }, 15000, [teamId]);
    const navigate = useNavigate();
    const [leavePopupOpen, setLeavePopupOpen] = React.useState(false);
    const openLeavePopup = useCallback(() => setLeavePopupOpen(true), []);
    const closeLeavePopup = useCallback(() => setLeavePopupOpen(false), []);
    const onLeaveTeam = useWatchCallback(async () => {
        if (!teamId) {
            return;
        }
        closeLeavePopup();
        return nestClient.leaveTeam(teamId).then(() => {
            navigate('/teams');
        });
    }, [navigate, teamId]);
    const startTeamChallenge = useCallback(() => {
        if (!teamId || !team) {
            return;
        }
        if (team.currentChallenge) {
            navigate(`/challenges/${team.currentChallenge}`);
            return;
        }
        navigate(`/challenges/new?type=${ChallengeType.TEAM}&team=${teamId}`);
    }, [navigate, teamId, team]);
    const toggleAdmin = useWatchCallback(async (uuid) => {
        setSwipeResetCounter((c) => c + 1);
        return nestClient.toggleAdmin(teamId, uuid).then((updatedTeam) => {
            setTeam(updatedTeam);
        });
    }, []);
    const removeMember = useWatchCallback(async (uuid) => nestClient.removeMember(teamId, uuid).then((updatedTeam) => {
        setTeam(updatedTeam);
    }), []);
    if (!teamId) {
        navigate('/teams');
    }
    const teamMembers = ((_a = team === null || team === void 0 ? void 0 : team.members) !== null && _a !== void 0 ? _a : []).sort((a, b) => a.admin === b.admin
        ? a.profile.nickname.localeCompare(b.profile.nickname)
        : a.admin
            ? -1
            : 1);
    const membership = teamMembers.find((membership) => membership.profile.uuid === (profile === null || profile === void 0 ? void 0 : profile.uuid));
    if (team && !membership) {
        navigate('/teams');
        return null;
    }
    const isAdmin = !!(membership === null || membership === void 0 ? void 0 : membership.admin);
    const isLastAdmin = isAdmin && teamMembers.filter((m) => m.admin).length === 1;
    const challengeDisabled = !(team === null || team === void 0 ? void 0 : team.currentChallenge) &&
        teamMembers.length < MIN_TEAM_MEMBERS_TO_START_CHALLENGE;
    function handleLastAdminLeave() {
        addError(new ApiError(403, ServerErrorCode.LAST_ADMIN_LEAVE));
    }
    function footer(team) {
        return (React.createElement(React.Fragment, null,
            isAdmin && (React.createElement(React.Fragment, null,
                React.createElement(Button, { priority: "tertiary", className: "w-full mt-3 desktop:h-9", to: `/teams/${teamId}/searchOpponent` }, "Anderes Team herausfordern"),
                React.createElement(Button, { priority: "tertiary", className: "w-full mt-3 desktop:h-9", to: `/teams/${teamId}/results` }, "Auswertung aller Spiele"))),
            React.createElement(Button, { className: classNames('w-full mt-3', isAdmin && 'desktop:h-9'), onClick: startTeamChallenge, disabled: challengeDisabled, dataTestId: "start-team-challenge" }, team.currentChallenge
                ? 'Zum Team-Quiz'
                : 'Team-Quiz starten'),
            challengeDisabled && (React.createElement("div", { className: "text-sm text-darkBlue/50 text-center" }, "Team-Quiz ben\u00F6tigt mindestens 2 Mitglieder."))));
    }
    function header(team) {
        return (React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement(Avatar, { defaultAvatar: DefaultAvatar.TEAM, bannerType: BannerType.NONE, image: team.imageUrl, className: "w-24 pb-2" }),
            React.createElement("span", { className: "text-2xl font-bold text-darkBlue leading-9" }, team.name),
            React.createElement("span", { className: "text-md text-green pb-2" },
                teamMembers.length,
                ' ',
                teamMembers.length === 1 ? 'Mitglied' : 'Mitglieder'),
            React.createElement("span", { className: "text-sm text-darkBlue/50 pb-2" },
                "Punktestand:",
                ' ',
                formatStandingsString(team.totalAnswers, team.correctAnswers))));
    }
    return (React.createElement(BaseView, { view: ViewsType.TEAMS, dataTestId: "TeamDetailsView", showFooter: false, header: {
            title: 'Team Profil',
            customHeaderAction: (React.createElement("div", { className: "flex flex-row space-x-8 items-center" },
                isAdmin && (React.createElement(Link, { className: "fill-green", to: `/teams/${teamId}/addMember` },
                    React.createElement(AddMember, { className: "w-6 h-6" }))),
                isAdmin && (React.createElement(Link, { className: "text-2xl text-green icon-settings", to: `/teams/${teamId}/edit` })),
                React.createElement("button", { onClick: isLastAdmin
                        ? handleLastAdminLeave
                        : openLeavePopup, className: classNames(isLastAdmin
                        ? 'text-backgroundGray'
                        : 'text-orange', 'text-2xl icon-exit'), "data-testid": "leave-team-icon" }))),
            showBack: true,
        } },
        (!profile || !team) && React.createElement(Loading, null),
        profile && team && (React.createElement("div", { className: "flex flex-col flex-1 px-2 gap-y-2" },
            React.createElement(CenterScrollPanel, { className: "flex-1", rounded: "top", header: header(team), footer: footer(team), greyOnDesktop: false }, teamMembers.map((member) => (React.createElement(ListEntry, { key: member.profile.uuid, to: '/profile/' + member.profile.uuid, avatar: React.createElement(Avatar, { image: member.profile.avatarURL, bannerType: member.profile.bannerType, sizes: "42px" }), middle: React.createElement("span", { className: classNames('font-bold text-darkBlue min-w-0', overflowEllipsis, isAdmin && 'pr-14') }, member.profile.nickname), right: member.admin && (React.createElement("span", { className: "text-sm text-darkBlue/50" }, "Admin")), swipeElement: isAdmin &&
                    profile.uuid !== member.profile.uuid && (React.createElement(TeamMemberSwipeElement, { member: member, onAdmin: toggleAdmin.with(member.profile.uuid), onRemove: removeMember.with(member.profile.uuid) })), swipeResetCounter: swipeResetCounter })))))),
        profile && team && (React.createElement(Popup, { popupOpen: leavePopupOpen, setPopupOpen: setLeavePopupOpen, withoutFooter: true },
            React.createElement("div", { className: "flex flex-col gap-y-1.5" },
                React.createElement("span", { className: "text-xl text-darkBlue font-bold pb-6" }, "Team wirklich verlassen?"),
                React.createElement(Button, { onClick: onLeaveTeam, priority: "tertiary", className: "mb-2.5", dataTestId: "leave-team" }, "Team verlassen"),
                React.createElement(Button, { onClick: closeLeavePopup }, "Zur\u00FCck"))))));
}
