import { ACCESS_TOKEN_HEADER_NAME, REFRESH_TOKEN_HEADER_NAME, SET_ACCESS_TOKEN_HEADER_NAME, SET_REFRESH_TOKEN_HEADER_NAME, } from '@mika-bibb/shared/constants';
import { Preferences } from '@capacitor/preferences';
export class TokenHandler {
    async onBeforeRequest(_) {
        return {
            headers: Object.assign({}, (await this.getTokenHeaders())),
        };
    }
    async onSuccessfulResponse(response) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!response.headers) {
            return;
        }
        const renewedAccessToken = response.headers[SET_ACCESS_TOKEN_HEADER_NAME];
        const renewedRefreshToken = response.headers[SET_REFRESH_TOKEN_HEADER_NAME];
        if (renewedAccessToken) {
            void this.storeToken(ACCESS_TOKEN_HEADER_NAME, renewedAccessToken);
        }
        if (renewedRefreshToken) {
            await this.storeToken(REFRESH_TOKEN_HEADER_NAME, renewedRefreshToken);
        }
    }
    async clearTokens() {
        await Preferences.remove({ key: ACCESS_TOKEN_HEADER_NAME });
        await Preferences.remove({ key: REFRESH_TOKEN_HEADER_NAME });
    }
    async storeToken(key, token) {
        await Preferences.set({ key: key, value: token });
    }
    async getTokenHeaders() {
        var _a, _b;
        const tokens = await Promise.all([
            Preferences.get({
                key: ACCESS_TOKEN_HEADER_NAME,
            }),
            Preferences.get({
                key: REFRESH_TOKEN_HEADER_NAME,
            }),
        ]);
        return {
            [ACCESS_TOKEN_HEADER_NAME]: (_a = tokens[0].value) !== null && _a !== void 0 ? _a : undefined,
            [REFRESH_TOKEN_HEADER_NAME]: (_b = tokens[1].value) !== null && _b !== void 0 ? _b : undefined,
        };
    }
    static async hasTokens() {
        const token = await Preferences.get({ key: ACCESS_TOKEN_HEADER_NAME });
        return !!token.value;
    }
}
