import { Avatar } from '@components/basic-components/avatar/avatar';
import { Loading } from '@components/basic-components/loading';
import React from 'react';
export default function ProfileDisplayFullscreen({ children, profile, }) {
    if (!profile) {
        return React.createElement(Loading, null);
    }
    return (React.createElement("div", { className: "flex flex-col items-center" },
        React.createElement("div", { className: "w-36 mt-4 mb-4" },
            React.createElement(Avatar, { image: profile.avatarURL, bannerType: profile.bannerType, sizes: "144px" })),
        React.createElement("div", { className: "font-bold text-2xl text-darkBlue mb-4 text-center break-words" }, profile.nickname),
        profile.statusMessage && (React.createElement("div", { className: "text-green mb-4 text-center" }, profile.statusMessage)),
        children));
}
