import { Capacitor } from '@capacitor/core';
import { Loading } from '@components/basic-components/loading';
import { useNavigate } from 'react-router';
import { useProfile } from '../context/profile-context';
import React, { useEffect } from 'react';
export default function StartupFlow() {
    const navigate = useNavigate();
    const { state: { profile }, } = useProfile();
    useEffect(() => {
        if (profile) {
            navigate('/home');
        }
        else if (Capacitor.getPlatform() === 'web') {
            navigate('/landing');
        }
        else {
            navigate('/welcome');
        }
    });
    return React.createElement(Loading, { fullSize: true });
}
