import { ApiError } from '@api/errors';
import { Capacitor } from '@capacitor/core';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeOverviewItem } from '@views/challenges-overview-view';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { Loading } from '@components/basic-components/loading';
import { RankingsList, SinglePlayerInTeamRankingEntry, } from '@components/rankings/rankings';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { useErrorContext } from '../context/error-context';
import { useNestClientContext } from '../context/nest-client-context';
import { useParams } from 'react-router-dom';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import { winnerText } from '@utils/formatting';
import BaseView from '@views/base-view';
import React, { useCallback, useState } from 'react';
import dayjs from '@mika-bibb/shared/util/dayjs';
export default function TeamResultsView() {
    const { addError } = useErrorContext();
    const { nestClient } = useNestClientContext();
    const teamId = useParams().teamId;
    const { state: { profile }, } = useProfile();
    const [showRanking, setShowRanking] = useState(true);
    const [teamChallenges, setTeamChallenges] = useState();
    const [team, setTeam] = React.useState();
    const getRankingData = useCallback(() => {
        return teamId ? nestClient.getTeamRanking(teamId) : Promise.resolve([]);
    }, [teamId, nestClient]);
    const refreshOverview = () => {
        if (!teamId) {
            return;
        }
        void nestClient
            .getTeamChallenges(teamId)
            .then(setTeamChallenges)
            .catch((e) => addError(e, true, true));
        void nestClient.getTeam(teamId, false).then(setTeam);
    };
    usePolling(refreshOverview, 20000, [teamId]);
    const membership = team === null || team === void 0 ? void 0 : team.members.find((membership) => membership.profile.uuid === (profile === null || profile === void 0 ? void 0 : profile.uuid));
    const isAdmin = !!(membership === null || membership === void 0 ? void 0 : membership.admin);
    const onDownloadCSV = useCallback(() => {
        if (!teamId) {
            return;
        }
        void nestClient.getTeamCSV(teamId).then((csv) => {
            const downloadName = `${team === null || team === void 0 ? void 0 : team.name}-${dayjs().format('DD.MM.YY')}-teamexport.csv`;
            if (Capacitor.getPlatform() === 'web') {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(csv);
                link.download = downloadName;
                link.click();
            }
            else {
                void csv.text().then(async (text) => {
                    const result = await Filesystem.writeFile({
                        path: downloadName,
                        data: text,
                        directory: Directory.Documents,
                        encoding: Encoding.UTF8,
                    });
                    await FileOpener.openFile({
                        path: result.uri,
                        mimeType: 'text/csv',
                    });
                });
            }
        });
    }, [nestClient, team === null || team === void 0 ? void 0 : team.name, teamId]);
    if (!membership) {
        return null;
    }
    if (!isAdmin) {
        addError(new ApiError(403, 'Du bist nicht Admin des Teams'), true, true);
        return null;
    }
    return (React.createElement(BaseView, { view: ViewsType.TEAMS, dataTestId: "TeamDetailsView", showFooter: false, header: {
            title: 'Auswertung aller Spiele von ' + (team === null || team === void 0 ? void 0 : team.name),
            showBack: true,
            customHeaderAction: (React.createElement("div", { className: "flex flex-row items-center" },
                React.createElement("button", { className: "text-xl text-orange tracking-tighter", onClick: onDownloadCSV, "data-testid": "download-csv" }, "CSV"))),
        } },
        React.createElement(CenterScrollPanel, { className: "flex-1 bg-bgGray overflow-y-hidden", padding: "p-0", greyOnDesktop: false },
            React.createElement(TabsToggle, { labelLeft: "Ranking", labelRight: "Historie", leftSelected: showRanking, setLeftSelected: setShowRanking, className: "desktop:hidden mx-2" }),
            React.createElement("div", { className: "grid grid-cols-1 desktop:grid-cols-2" },
                React.createElement("div", { className: classNames(!showRanking && 'hidden desktop:block', 'py-4') },
                    React.createElement("div", { className: "text-xl font-bold text-darkBlue mb-4 hidden desktop:block px-4" }, "Ranking"),
                    React.createElement(RankingsList, { getData: getRankingData, Entry: SinglePlayerInTeamRankingEntry, emptyMessage: "Keine Spieler/-innen gefunden", showEllipsis: false, showWinner: false, showDesktopHeader: false }),
                    React.createElement("p", { className: "text-darkBlue/50 text-sm p-2" }, "Rangliste innerhalb des Teams \u00FCber alle Spiele die im Team intern oder gegen ein anderes Team erspielt wurden.")),
                React.createElement("div", { className: classNames(showRanking && 'hidden desktop:flex', 'flex flex-col min-h-full p-4') },
                    React.createElement("div", { className: "text-xl font-bold text-darkBlue mb-4 hidden desktop:block" }, "Historie"),
                    !(teamChallenges === null || teamChallenges === void 0 ? void 0 : teamChallenges.length) && (React.createElement("p", { className: "text-darkBlue text-xl text-center font-bold" }, "Keine Quizze")),
                    profile ? (teamChallenges === null || teamChallenges === void 0 ? void 0 : teamChallenges.map((challenge) => (React.createElement(ChallengeOverviewItem, { refreshOverview: refreshOverview, key: challenge.id, profile: profile, challenge: challenge, statusText: winnerText(challenge.winner) })))) : (React.createElement(Loading, { className: "mt-10" })))))));
}
