import { Helmet, HelmetProvider } from 'react-helmet-async';
import { isProd } from '../environment.global';
import { useConsent } from '../context/consent-context';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
/* istanbul ignore next */
export const matomo = (trackingData) => { var _a; return (_a = window._paq) === null || _a === void 0 ? void 0 : _a.push(trackingData); };
export function MatomoTracker({ title }) {
    const { consent } = useConsent();
    const location = useLocation();
    function removeScripts() {
        const matomoScript = document.querySelector(`script[id="matomo"]`);
        if (matomoScript) {
            matomoScript.remove();
        }
        const matomoInternalScript = document.querySelector(`script[id="matomo-internal"]`);
        if (matomoInternalScript) {
            matomoInternalScript.remove();
        }
    }
    useEffect(() => {
        if (!consent) {
            removeScripts();
            return;
        }
        /* istanbul ignore next */
        return () => {
            // Remove the script added below and the script added by this script in order to prevent duplicate scripts
            // and to remove the scripts if the consent is revoked.
            removeScripts();
        };
    }, [consent]);
    useEffect(() => {
        if (!consent || consent === 'loading') {
            return;
        }
        /* istanbul ignore next */
        matomo(['setCustomUrl', '/' + location.pathname.substring(1)]);
        /* istanbul ignore next */
        matomo(['setDocumentTitle', title !== null && title !== void 0 ? title : 'Unbennante Seite']);
        /* istanbul ignore next */
        matomo(['trackPageView']);
    }, [location, consent, title]);
    return (React.createElement(HelmetProvider, null,
        React.createElement(Helmet, null, consent === true && isProd && (React.createElement("script", { id: "matomo", type: "text/javascript" }, `var _paq = window._paq = window._paq || [];  
                        _paq.push(['enableHeartBeatTimer']);
                        _paq.push(['enableLinkTracking']);
                        (function() {
                            var u="https://webstats.bibb.de/";
                            _paq.push(['setTrackerUrl', u+'piwik.php']);
                            _paq.push(['setSiteId', '107']);
                            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                            g.type='text/javascript'; g.id="matomo-internal"; g.async=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);}
                        )();`)))));
}
