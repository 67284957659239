import { ReactComponent as Eyebrows1a } from '@public/images/avatar-layers/eyebrows/eyebrows_1a.svg';
import { ReactComponent as Eyebrows1b } from '@public/images/avatar-layers/eyebrows/eyebrows_1b.svg';
import { ReactComponent as Eyebrows1c } from '@public/images/avatar-layers/eyebrows/eyebrows_1c.svg';
import { ReactComponent as Eyebrows1d } from '@public/images/avatar-layers/eyebrows/eyebrows_1d.svg';
import { ReactComponent as Eyebrows1e } from '@public/images/avatar-layers/eyebrows/eyebrows_1e.svg';
import { ReactComponent as Eyebrows2a } from '@public/images/avatar-layers/eyebrows/eyebrows_2a.svg';
import { ReactComponent as Eyebrows2b } from '@public/images/avatar-layers/eyebrows/eyebrows_2b.svg';
import { ReactComponent as Eyebrows2c } from '@public/images/avatar-layers/eyebrows/eyebrows_2c.svg';
import { ReactComponent as Eyebrows2d } from '@public/images/avatar-layers/eyebrows/eyebrows_2d.svg';
import { ReactComponent as Eyebrows2e } from '@public/images/avatar-layers/eyebrows/eyebrows_2e.svg';
import { ReactComponent as Eyebrows3a } from '@public/images/avatar-layers/eyebrows/eyebrows_3a.svg';
import { ReactComponent as Eyebrows3b } from '@public/images/avatar-layers/eyebrows/eyebrows_3b.svg';
import { ReactComponent as Eyebrows3c } from '@public/images/avatar-layers/eyebrows/eyebrows_3c.svg';
import { ReactComponent as Eyebrows3d } from '@public/images/avatar-layers/eyebrows/eyebrows_3d.svg';
import { ReactComponent as Eyebrows3e } from '@public/images/avatar-layers/eyebrows/eyebrows_3e.svg';
import { ReactComponent as Eyebrows4a } from '@public/images/avatar-layers/eyebrows/eyebrows_4a.svg';
import { ReactComponent as Eyebrows4b } from '@public/images/avatar-layers/eyebrows/eyebrows_4b.svg';
import { ReactComponent as Eyebrows4c } from '@public/images/avatar-layers/eyebrows/eyebrows_4c.svg';
import { ReactComponent as Eyebrows4d } from '@public/images/avatar-layers/eyebrows/eyebrows_4d.svg';
import { ReactComponent as Eyebrows4e } from '@public/images/avatar-layers/eyebrows/eyebrows_4e.svg';
import { ReactComponent as Eyebrows5a } from '@public/images/avatar-layers/eyebrows/eyebrows_5a.svg';
import { ReactComponent as Eyebrows5b } from '@public/images/avatar-layers/eyebrows/eyebrows_5b.svg';
import { ReactComponent as Eyebrows5c } from '@public/images/avatar-layers/eyebrows/eyebrows_5c.svg';
import { ReactComponent as Eyebrows5d } from '@public/images/avatar-layers/eyebrows/eyebrows_5d.svg';
import { ReactComponent as Eyebrows5e } from '@public/images/avatar-layers/eyebrows/eyebrows_5e.svg';
import { ReactComponent as Eyebrows6a } from '@public/images/avatar-layers/eyebrows/eyebrows_6a.svg';
import { ReactComponent as Eyebrows6b } from '@public/images/avatar-layers/eyebrows/eyebrows_6b.svg';
import { ReactComponent as Eyebrows6c } from '@public/images/avatar-layers/eyebrows/eyebrows_6c.svg';
import { ReactComponent as Eyebrows6d } from '@public/images/avatar-layers/eyebrows/eyebrows_6d.svg';
import { ReactComponent as Eyebrows6e } from '@public/images/avatar-layers/eyebrows/eyebrows_6e.svg';
import React from 'react';
export const eyebrowColorsOrdered = [
    '#862618',
    '#85898B',
    '#DEA65F',
    '#654023',
    '#000000',
];
export const eyebrowShapesOrdered = [
    undefined,
    'Eyebrow1',
    'Eyebrow2',
    'Eyebrow3',
    'Eyebrow4',
    'Eyebrow5',
    'Eyebrow6',
];
export const eyebrowConfig = {
    '#862618': {
        Eyebrow1: React.createElement(Eyebrows1a, null),
        Eyebrow2: React.createElement(Eyebrows2a, null),
        Eyebrow3: React.createElement(Eyebrows3a, null),
        Eyebrow4: React.createElement(Eyebrows4a, null),
        Eyebrow5: React.createElement(Eyebrows5a, null),
        Eyebrow6: React.createElement(Eyebrows6a, null),
    },
    '#85898B': {
        Eyebrow1: React.createElement(Eyebrows1b, null),
        Eyebrow2: React.createElement(Eyebrows2b, null),
        Eyebrow3: React.createElement(Eyebrows3b, null),
        Eyebrow4: React.createElement(Eyebrows4b, null),
        Eyebrow5: React.createElement(Eyebrows5b, null),
        Eyebrow6: React.createElement(Eyebrows6b, null),
    },
    '#DEA65F': {
        Eyebrow1: React.createElement(Eyebrows1c, null),
        Eyebrow2: React.createElement(Eyebrows2c, null),
        Eyebrow3: React.createElement(Eyebrows3c, null),
        Eyebrow4: React.createElement(Eyebrows4c, null),
        Eyebrow5: React.createElement(Eyebrows5c, null),
        Eyebrow6: React.createElement(Eyebrows6c, null),
    },
    '#654023': {
        Eyebrow1: React.createElement(Eyebrows1d, null),
        Eyebrow2: React.createElement(Eyebrows2d, null),
        Eyebrow3: React.createElement(Eyebrows3d, null),
        Eyebrow4: React.createElement(Eyebrows4d, null),
        Eyebrow5: React.createElement(Eyebrows5d, null),
        Eyebrow6: React.createElement(Eyebrows6d, null),
    },
    '#000000': {
        Eyebrow1: React.createElement(Eyebrows1e, null),
        Eyebrow2: React.createElement(Eyebrows2e, null),
        Eyebrow3: React.createElement(Eyebrows3e, null),
        Eyebrow4: React.createElement(Eyebrows4e, null),
        Eyebrow5: React.createElement(Eyebrows5e, null),
        Eyebrow6: React.createElement(Eyebrows6e, null),
    },
};
