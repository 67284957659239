import { Capacitor } from '@capacitor/core';
import { isTest } from '../environment.global';
import { useNestClientContext } from './nest-client-context';
import { usePreferencesStateBool } from '@utils/use-preferences-state';
import { useProfile } from './profile-context';
import NotificationConsentPopUp from '@components/notification-handlers/notification-consent-pop-up';
import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import desktopNotificationHandler from '@components/notification-handlers/desktop-notification-handler';
import mobileNotificationHandler from '@components/notification-handlers/mobile-notification-handler';
const PUSH_NOTIFICATIONS_ENABLED_KEY = 'push-notifications-enabled';
function getHandler() {
    if (Capacitor.getPlatform() === 'web') {
        return desktopNotificationHandler;
    }
    else {
        return mobileNotificationHandler;
    }
}
async function initPushNotifications() {
    return getHandler().init();
}
const PushNotificationContext = React.createContext(undefined);
export function PushNotificationProvider({ children }) {
    const { nestClient } = useNestClientContext();
    const { state: { profile }, } = useProfile();
    const [enabledByUser, setEnabledByUser] = usePreferencesStateBool(PUSH_NOTIFICATIONS_ENABLED_KEY);
    const [pushRegistered, setPushRegistered] = useState(false);
    const [busy, setBusy] = useState(false);
    const [showSettingsInfo, setShowSettingsInfo] = useState(false);
    const [showConsentPopup, setShowConsentPopup] = useState(false);
    const disableNotifications = enabledByUser === false || !profile;
    const trySetup = useCallback(async (startTimestamp) => {
        try {
            const token = await initPushNotifications();
            setPushRegistered(true);
            await nestClient.updateNotificationToken(token);
        }
        catch (e) {
            const endTimestamp = Date.now();
            const wasManuallyEnabled = enabledByUser === true;
            setEnabledByUser(false);
            setPushRegistered(false);
            setBusy(false);
            if (Capacitor.getPlatform() !== 'web' &&
                endTimestamp - startTimestamp < 100 &&
                window.location.pathname.match(/profile/) &&
                wasManuallyEnabled) {
                // If this error came nearly instant, it is probably because the user denied the permissions.
                // In this case, we want to direct the user to the settings page.
                setShowSettingsInfo(true);
            }
            else {
                console.error('Error while registering push notifications: ', e);
            }
        }
        finally {
            setBusy(false);
        }
    }, [enabledByUser, nestClient, setEnabledByUser]);
    const handleConsent = useCallback(async (consentGiven) => {
        setShowConsentPopup(false);
        if (consentGiven) {
            await trySetup(Date.now());
        }
        else {
            setEnabledByUser(false);
            setPushRegistered(false);
        }
    }, [setEnabledByUser, trySetup]);
    // Register the push notification system
    useEffect(() => {
        if (disableNotifications || pushRegistered || busy || isTest) {
            return;
        }
        setBusy(true);
        const startTimestamp = Date.now();
        void (async function () {
            if (Capacitor.getPlatform() === 'web' &&
                'Notification' in window &&
                Notification.permission !== 'granted') {
                setShowConsentPopup(true);
            }
            else {
                await trySetup(startTimestamp);
            }
        })();
        return () => {
            setPushRegistered(false);
            void getHandler().finalize();
        };
    }, [
        disableNotifications,
        pushRegistered,
        busy,
        nestClient,
        enabledByUser,
        setEnabledByUser,
        trySetup,
    ]);
    useEffect(() => {
        if (!showSettingsInfo) {
            return;
        }
        setShowSettingsInfo(false);
        alert(Capacitor.getPlatform() === 'web'
            ? 'Bitte erlaube Benachrichtigungen in deinem Browser und im Betriebssystem'
            : 'Bitte aktiviere Push-Benachrichtigungen in den App Einstellungen des Systems');
        setTimeout(() => {
            void getHandler().handleMissingPermissions();
        }, 0);
    }, [showSettingsInfo]);
    // Disable notifications again if wanted by the user
    useEffect(() => {
        if (enabledByUser === false && pushRegistered && !busy) {
            setBusy(true);
            void nestClient.updateNotificationToken(undefined).finally(() => {
                setPushRegistered(false);
                setBusy(false);
            });
        }
    }, [enabledByUser, busy, pushRegistered, nestClient]);
    const state = useMemo(() => ({ enabledByUser, setEnabledByUser, busy }), [enabledByUser, setEnabledByUser, busy]);
    return (React.createElement(PushNotificationContext.Provider, { value: state },
        children,
        showConsentPopup && (React.createElement(NotificationConsentPopUp, { handleConsent: handleConsent }))));
}
export function usePushNotifications() {
    const state = useContext(PushNotificationContext);
    if (!state) {
        throw new Error('usePushNotifications must be used within a PushNotificationProvider');
    }
    return state;
}
