import { Avatar } from '@components/basic-components/avatar/avatar';
import { AvatarEditorExplanation } from '@views/my-avatar-view';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Loading } from '@components/basic-components/loading';
import { OneTimePopup } from '@components/basic-components/one-time-popup';
import { Toggle } from '@components/basic-components/toggle';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { useLocalUserState } from '../context/use-local-user-state';
import { useNavigate } from 'react-router';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useCallback, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
export function AvatarBadgeSettings({ profile, initialState, }) {
    const { updateProfile } = useProfile();
    const navigate = useNavigate();
    const [showBadge, setShowBadge] = useState(initialState !== BannerType.NONE);
    const [roleTrainer, setRoleTrainer] = useState(initialState !== BannerType.TRAINEE);
    const setRoleTrainerTrue = useCallback(() => showBadge && setRoleTrainer(true), [showBadge]);
    const setRoleTrainerFalse = useCallback(() => showBadge && setRoleTrainer(false), [showBadge]);
    const { popupAlreadyShown } = useLocalUserState();
    const [youLookGreatPopupOpen, setYouLookGreatPopupOpen] = useState(false);
    const bannerType = showBadge
        ? roleTrainer
            ? BannerType.TRAINER
            : BannerType.TRAINEE
        : BannerType.NONE;
    const saveBadgeSettings = useWatchCallback(() => {
        return updateProfile({
            bannerType: bannerType,
        }).then(() => {
            if (popupAlreadyShown('you-look-great')) {
                navigate('/profile');
            }
            else {
                setYouLookGreatPopupOpen(true);
            }
        });
    }, [bannerType, profile, updateProfile]);
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, header: {
            showBack: true,
            title: 'Dein Avatar',
        }, showFooter: false },
        React.createElement("div", { className: "flex flex-col flex-1 px-2" },
            React.createElement(CenterScrollPanel, { className: "flex-1 mt-2", rounded: "top", header: React.createElement(AvatarEditorExplanation, { title: "Deine Rolle", text: "Verrate der MIKA-Community, welche Rolle Du hast!" }), footer: React.createElement(Button, { priority: "primary", onClick: saveBadgeSettings, className: "w-full mt-4", dataTestId: "save-badge-settings-button" }, "Speichern") },
                React.createElement("div", { className: "flex flex-col items-center mt-4" },
                    React.createElement("div", { className: "w-36 mt-4 mb-4" },
                        React.createElement(Avatar, { image: profile.avatarURL, bannerType: bannerType, sizes: "144px" }))),
                React.createElement("div", { className: "flex flex-row items-center justify-between font-semibold" },
                    React.createElement("span", { className: classNames(showBadge && 'text-green') }, "Rolle anzeigen"),
                    React.createElement(Toggle, { id: "switchEnabled", greyIfUnchecked: true, onChange: setShowBadge, checked: showBadge, dataTestId: "show-badge-toggle" })),
                React.createElement("hr", { className: "my-4 border-1 border-t border-green" }),
                React.createElement("div", { className: "flex flex-row items-center justify-between font-semibold" },
                    React.createElement("span", { className: classNames(!roleTrainer && showBadge
                            ? 'text-darkBlue'
                            : 'text-darkBlue/50'), onClick: setRoleTrainerFalse }, "Auszubildende/-r"),
                    React.createElement("div", { className: "flex-0" },
                        React.createElement(Toggle, { id: "switchRole", onChange: setRoleTrainer, checked: roleTrainer, disabled: !showBadge, dataTestId: "role-toggle" })),
                    React.createElement("span", { className: classNames(roleTrainer && showBadge
                            ? 'text-darkBlue'
                            : 'text-darkBlue/50'), onClick: setRoleTrainerTrue }, "Ausbilder/-in")))),
        React.createElement(OneTimePopup, { id: "you-look-great", "data-testid": "you-look-great-popup", mika: "normal", popupOpen: youLookGreatPopupOpen, setPopupOpen: setYouLookGreatPopupOpen, centerScrollPanel: {
                header: 'Wow, Du siehst toll aus!',
                footer: (React.createElement(Button, { priority: "primary", to: "/home", className: "w-full", dataTestId: "you-look-great-popup-button" }, "Abschlie\u00DFen")),
            }, withoutFooter: true }, "Super gemacht! Mit dem Avatar ist Dein Profil vollst\u00E4ndig. Du kannst anfangen zu spielen. Viel Gl\u00FCck!")));
}
export const AvatarBadgeSettingsView = () => {
    const { state: { profile }, } = useProfile();
    if (!profile) {
        return React.createElement(Loading, { fullSize: true });
    }
    return (React.createElement(AvatarBadgeSettings, { profile: profile, initialState: profile.bannerType }));
};
