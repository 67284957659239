export var ViewsType;
(function (ViewsType) {
    ViewsType["HOME"] = "HOME";
    ViewsType["CHALLENGES_OVERVIEW"] = "CHALLENGES_OVERVIEW";
    ViewsType["TEAMS"] = "TEAMS";
    ViewsType["PROFILE"] = "PROFILE";
    ViewsType["ERROR"] = "ERROR";
    ViewsType["CHALLENGE_DETAILS"] = "CHALLENGE_DETAILS";
    ViewsType["CHALLENGE_ROUND"] = "CHALLENGE_ROUND";
    ViewsType["QUESTION"] = "QUESTION";
    ViewsType["CHALLENGE_SEARCH_PLAYER"] = "CHALLENGE_SEARCH_PLAYER";
    ViewsType["CHALLENGE_SEARCH_TEAM"] = "CHALLENGE_SEARCH_TEAM";
    ViewsType["CHALLENGE_CLASSROOM"] = "CHALLENGE_CLASSROOM";
    ViewsType["WELCOME"] = "WELCOME";
    ViewsType["IMPRINT"] = "IMPRINT";
    ViewsType["PRIVACY_POLICY"] = "PRIVACY_POLICY";
    ViewsType["CUSTOM_QUESTIONS"] = "CUSTOM_QUESTIONS";
})(ViewsType || (ViewsType = {}));
