import { ReactComponent as Body1a } from '@public/images/avatar-layers/body/body_1a.svg';
import { ReactComponent as Body1b } from '@public/images/avatar-layers/body/body_1b.svg';
import { ReactComponent as Body1c } from '@public/images/avatar-layers/body/body_1c.svg';
import { ReactComponent as Body1d } from '@public/images/avatar-layers/body/body_1d.svg';
import { ReactComponent as Body1e } from '@public/images/avatar-layers/body/body_1e.svg';
import { ReactComponent as Body2a } from '@public/images/avatar-layers/body/body_2a.svg';
import { ReactComponent as Body2b } from '@public/images/avatar-layers/body/body_2b.svg';
import { ReactComponent as Body2c } from '@public/images/avatar-layers/body/body_2c.svg';
import { ReactComponent as Body2d } from '@public/images/avatar-layers/body/body_2d.svg';
import { ReactComponent as Body2e } from '@public/images/avatar-layers/body/body_2e.svg';
import { ReactComponent as Body3a } from '@public/images/avatar-layers/body/body_3a.svg';
import { ReactComponent as Body3b } from '@public/images/avatar-layers/body/body_3b.svg';
import { ReactComponent as Body3c } from '@public/images/avatar-layers/body/body_3c.svg';
import { ReactComponent as Body3d } from '@public/images/avatar-layers/body/body_3d.svg';
import { ReactComponent as Body3e } from '@public/images/avatar-layers/body/body_3e.svg';
import { ReactComponent as Body4a } from '@public/images/avatar-layers/body/body_4a.svg';
import { ReactComponent as Body4b } from '@public/images/avatar-layers/body/body_4b.svg';
import { ReactComponent as Body4c } from '@public/images/avatar-layers/body/body_4c.svg';
import { ReactComponent as Body4d } from '@public/images/avatar-layers/body/body_4d.svg';
import { ReactComponent as Body4e } from '@public/images/avatar-layers/body/body_4e.svg';
import { ReactComponent as Body5a } from '@public/images/avatar-layers/body/body_5a.svg';
import { ReactComponent as Body5b } from '@public/images/avatar-layers/body/body_5b.svg';
import { ReactComponent as Body5c } from '@public/images/avatar-layers/body/body_5c.svg';
import { ReactComponent as Body5d } from '@public/images/avatar-layers/body/body_5d.svg';
import { ReactComponent as Body5e } from '@public/images/avatar-layers/body/body_5e.svg';
import React from 'react';
export const bodyColorsOrdered = [
    '#EEC183',
    '#F9B187',
    '#DB8049',
    '#784331',
    '#E6E8F3',
];
export const bodyShapesOrdered = [
    'body_1',
    'body_2',
    'body_3',
    'body_4',
    'body_5',
];
export const bodyConfig = {
    '#EEC183': {
        body_1: React.createElement(Body1a, null),
        body_2: React.createElement(Body2a, null),
        body_3: React.createElement(Body3a, null),
        body_4: React.createElement(Body4a, null),
        body_5: React.createElement(Body5a, null),
    },
    '#F9B187': {
        body_1: React.createElement(Body1b, null),
        body_2: React.createElement(Body2b, null),
        body_3: React.createElement(Body3b, null),
        body_4: React.createElement(Body4b, null),
        body_5: React.createElement(Body5b, null),
    },
    '#DB8049': {
        body_1: React.createElement(Body1c, null),
        body_2: React.createElement(Body2c, null),
        body_3: React.createElement(Body3c, null),
        body_4: React.createElement(Body4c, null),
        body_5: React.createElement(Body5c, null),
    },
    '#784331': {
        body_1: React.createElement(Body1d, null),
        body_2: React.createElement(Body2d, null),
        body_3: React.createElement(Body3d, null),
        body_4: React.createElement(Body4d, null),
        body_5: React.createElement(Body5d, null),
    },
    '#E6E8F3': {
        body_1: React.createElement(Body1e, null),
        body_2: React.createElement(Body2e, null),
        body_3: React.createElement(Body3e, null),
        body_4: React.createElement(Body4e, null),
        body_5: React.createElement(Body5e, null),
    },
};
