import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeType, } from '@mika-bibb/shared/challenge-status-dto';
import { CreateClassroom } from '@components/challenge/create-classroom';
import { Input } from '@components/basic-components/input';
import { Link } from 'react-router-dom';
import { Role } from '@mika-bibb/shared/enums';
import { Toggle } from '@components/basic-components/toggle';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { isTwoPlayerChallenge } from '@mika-bibb/shared/util/challenge';
import { nestClient } from '../context/nest-client-context';
import { useNavigate } from 'react-router';
import { useProfile } from '../context/profile-context';
import { useQuery } from '../hooks/useQuery';
import BaseView from './base-view';
import React, { useEffect, useState } from 'react';
import useCategoriesInfoContext from '../context/categories-info-context';
import useWatchCallback from '@utils/promise-watch';
async function createChallenge(type, settings, opponent, team, classRoomId) {
    switch (type) {
        case ChallengeType.SOLO:
            return nestClient.createSinglePlayerChallenge(settings);
        case ChallengeType.ONE_VS_ONE_RANDOM:
            return nestClient.create1v1RandomChallenge();
        case ChallengeType.ONE_VS_ONE: {
            const challenge = await nestClient.create1v1Challenge(opponent, settings);
            return { id: challenge.uuid };
        }
        case ChallengeType.CODE:
            await nestClient.startClassroomChallenge(classRoomId, settings);
            return { id: classRoomId };
        case ChallengeType.TEAM:
            return nestClient.startTeamChallenge(team, settings);
        case ChallengeType.TEAM_VS_TEAM:
            return nestClient.startTeamVsTeamChallenge(team, opponent, settings);
        default:
            throw new Error(`Unknown challenge type ${type}`);
    }
}
async function getRedirect(queryParameters) {
    const findChallenge = async (condition) => {
        return await nestClient.getMyChallenges().then((challenges) => {
            for (const challenge of challenges) {
                if (condition(challenge) && !challenge.gameOver) {
                    return challenge.id;
                }
            }
            return undefined;
        });
    };
    const type = queryParameters.get('type');
    switch (type) {
        case ChallengeType.TEAM:
            return await nestClient
                .getTeam(queryParameters.get('team'))
                .then((team) => {
                return team.currentChallenge;
            });
        case ChallengeType.ONE_VS_ONE:
            return await findChallenge((challenge) => challenge.type === ChallengeType.ONE_VS_ONE &&
                challenge.opponentInfo.profileId ===
                    queryParameters.get('opponent'));
        case ChallengeType.SOLO:
            return await findChallenge((challenge) => challenge.type === ChallengeType.SOLO);
        case ChallengeType.ONE_VS_ONE_RANDOM:
            return await nestClient
                .create1v1RandomChallenge()
                .then((challenge) => challenge.id);
    }
}
function getQuizName(type) {
    switch (type) {
        case ChallengeType.ONE_VS_ONE:
            return '1vs1';
        case ChallengeType.ONE_VS_ONE_RANDOM:
            return 'Zufallsgegner 1vs1';
        case ChallengeType.CODE:
            return 'Klassenzimmer';
        case ChallengeType.TEAM:
            return 'Team';
        case ChallengeType.SOLO:
            return 'Einzelspiel';
        case ChallengeType.TEAM_VS_TEAM:
            return 'Team vs Team';
    }
}
export default function NewChallengeView() {
    var _a;
    const queryParameters = useQuery();
    const { categories } = useCategoriesInfoContext();
    const [settings, setSettings] = useState({
        useCustomQuestions: false,
        questionsPerRound: 10,
        categories: [...categories.values()].map((category) => category.id),
        slowSpeed: false,
    });
    useEffect(() => {
        setSettings((settings) => (Object.assign(Object.assign({}, settings), { categories: [...categories.values()].map((category) => category.id) })));
    }, [categories]);
    const { state } = useProfile();
    const [classRoomChallenge, setClassRoomChallenge] = useState();
    const navigate = useNavigate();
    const challengeType = queryParameters.get('type');
    const createChallengeCallback = useWatchCallback(async () => {
        const createdChallenge = await createChallenge(challengeType, settings, queryParameters.get('opponent'), queryParameters.get('team'), queryParameters.get('classroom'));
        return navigate(`/challenges/${createdChallenge.id}`, {
            replace: true,
        });
    }, [settings, queryParameters]);
    useEffect(() => {
        if (challengeType === ChallengeType.CODE) {
            void nestClient
                .getChallengeState(queryParameters.get('classroom'))
                .then((challenge) => setClassRoomChallenge(challenge));
        }
    }, [challengeType, queryParameters]);
    useEffect(() => {
        void getRedirect(queryParameters).then((challengeID) => {
            if (challengeID) {
                navigate(`/challenges/${challengeID}`, {
                    replace: true,
                });
            }
        });
    }, [queryParameters, navigate]);
    if (!challengeType) {
        throw new Error('No challenge type provided');
    }
    const quizName = getQuizName(challengeType);
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_DETAILS, dataTestId: "NewChallengeView", header: {
            title: `Neues ${quizName} Quiz`,
            showBack: true,
        }, showFooter: false },
        React.createElement(CenterScrollPanel, { className: "flex-1", footer: React.createElement(Button, { onClick: createChallengeCallback, dataTestId: 'create-quiz-button', className: "w-full desktop:max-w-[20rem] desktop:mx-auto" },
                quizName,
                " Quiz starten"), greyOnDesktop: false },
            React.createElement("div", { className: "flex flex-col space-y-4 p-4" },
                classRoomChallenge && (React.createElement(CreateClassroom, { createdChallenge: classRoomChallenge })),
                React.createElement("div", null,
                    React.createElement("label", { className: "font-semibold" }, "Kategorien"),
                    [...categories.values()]
                        .sort((c1, c2) => c1.sortId - c2.sortId)
                        .map((category) => {
                        var _a, _b;
                        return (React.createElement("div", { key: category.id, className: "flex flex-row space-x-2 mb-2" },
                            React.createElement(Toggle, { id: category.id, checked: (_a = settings.categories) === null || _a === void 0 ? void 0 : _a.includes(category.id), dataTestId: `category-toggle-${category.id}`, greyIfUnchecked: true, onChange: (selected) => {
                                    var _a, _b;
                                    if (selected) {
                                        setSettings(Object.assign(Object.assign({}, settings), { categories: [
                                                ...((_a = settings.categories) !== null && _a !== void 0 ? _a : []),
                                                category.id,
                                            ] }));
                                    }
                                    else {
                                        setSettings(Object.assign(Object.assign({}, settings), { categories: (_b = settings.categories) === null || _b === void 0 ? void 0 : _b.filter((id) => id !==
                                                category.id) }));
                                    }
                                }, disabled: ((_b = settings.categories) === null || _b === void 0 ? void 0 : _b.length) === 1 &&
                                    settings.categories.includes(category.id) }),
                            React.createElement("label", { htmlFor: category.id }, category.text)));
                    })),
                React.createElement("label", { className: "font-semibold" },
                    "Fragen pro Runde",
                    React.createElement(Input, { type: "number", value: settings.questionsPerRound, onChange: (value) => setSettings(Object.assign(Object.assign({}, settings), { questionsPerRound: value })), dataTestId: 'questionsPerRound-input', min: '3', max: '10' })),
                React.createElement("div", null,
                    React.createElement("label", { className: "font-semibold" }, "Zeit pro Frage"),
                    React.createElement("div", { className: "flex flex-row space-x-2" },
                        React.createElement("label", { htmlFor: 'speed', className: classNames(!settings.slowSpeed && 'font-bold') }, "20s"),
                        React.createElement(Toggle, { id: 'speed', checked: settings.slowSpeed, dataTestId: `speed-toggle`, onChange: (selected) => {
                                setSettings(Object.assign(Object.assign({}, settings), { slowSpeed: selected }));
                            } }),
                        React.createElement("label", { htmlFor: 'speed', className: classNames(settings.slowSpeed && 'font-bold') }, "40s"))),
                !isTwoPlayerChallenge(challengeType) &&
                    ((_a = state.profile) === null || _a === void 0 ? void 0 : _a.role) === Role.REGISTERED && (React.createElement("div", null,
                    React.createElement("div", { className: "flex flex-row items-center justify-between font-semibold" },
                        React.createElement("div", null,
                            React.createElement(Link, { to: "/myQuestions", className: "underline" }, "Eigene Fragen"),
                            ' ',
                            "einbeziehen"),
                        React.createElement(Toggle, { id: "useCustomQuestions", checked: settings.useCustomQuestions, onChange: (checked) => setSettings(Object.assign(Object.assign({}, settings), { useCustomQuestions: checked })), greyIfUnchecked: true })),
                    React.createElement("p", { className: "text-green leading-5 font-normal" }, "F\u00FCr die von Dir erstellten Fragen erh\u00E4ltst Du keine Punkte.")))))));
}
