import { ReactComponent as ArrowLeft } from '@public/icons/arrow-left.svg';
import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '@utils/class-names';
import React, { Fragment } from 'react';
export const Select = ({ value, displayValue, onChange, title, classNameContainer, options, disabled = false, }) => {
    return (React.createElement("div", { className: classNameContainer },
        React.createElement(Listbox, { value: value, onChange: onChange, disabled: disabled }, ({ open }) => (React.createElement(React.Fragment, null,
            React.createElement(Listbox.Label, { className: classNames('w-full text-sm font-medium inline-block leading-tight', disabled
                    ? 'text-n500DarkGrey'
                    : 'text-n600DarkBlue') }, title),
            React.createElement("div", { className: "relative" },
                React.createElement(Listbox.Button, { className: classNames('px-3.5 py-2.5 rounded-md border bg-white border-checkboxBorder w-full', 'shadow-input focus:shadow-none focus:border-green focus:ring-4 focus:ring-[#F1FDF7] text-left', disabled
                        ? '!bg-bgGray text-lightGray'
                        : // eslint-disable-next-line sonarjs/no-duplicate-string
                            'text-darkBlue'), "data-testid": "selectBtn" },
                    React.createElement("span", { className: "block truncate leading-snug" },
                        displayValue(value),
                        "\u200B"),
                    !disabled && (React.createElement("span", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" },
                        React.createElement(ArrowLeft, { className: "h-5 w-5 text-gray-400 -rotate-90", "aria-hidden": "true" })))),
                React.createElement(Transition, { show: open, as: Fragment, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                    React.createElement(Listbox.Options, { className: "absolute z-10 mt-1 max-h-60 w-full overflow-auto force-scrollbar rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, options.map((option, index) => (React.createElement(Listbox.Option, { key: `${displayValue(option)}-${index}`, "data-testid": `selectOption_${displayValue(option)}`, className: ({ active }) => classNames(active
                            ? 'bg-bgGray text-green'
                            : 'text-darkBlue', 'relative cursor-default select-none py-2 pl-3 pr-9'), value: option }, ({ selected, active }) => (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: classNames(selected
                                ? 'font-semibold'
                                : 'font-normal', 'block truncate') }, displayValue(option)),
                        selected && (React.createElement("span", { className: classNames(active
                                ? 'text-green'
                                : 'text-darkBlue', 'absolute inset-y-0 right-0 flex items-center pr-4') },
                            React.createElement(CheckIcon, { className: "h-5 w-5", "aria-hidden": "true" }))))))))))))))));
};
