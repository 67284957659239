import { GenerateCode } from '@components/challenge/generate-code';
import { JoinByCode } from '@components/challenge/join-by-code';
import { ViewsType } from '@typesSrc/views';
import BaseView from '@views/base-view';
import React from 'react';
export default function ClassroomChallengeView() {
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_CLASSROOM, header: { showBack: true, title: 'Klassenzimmer-Quiz' }, showFooter: false },
        React.createElement("p", { className: "text-green text-center leading-[19px] my-4 mx-6" }, "Mit einem Code kannst Du schnell mit anderen in einer Gruppe spielen."),
        React.createElement(JoinByCode, null),
        React.createElement(GenerateCode, null)));
}
