var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ReactComponent as Search } from '@public/icons/search.svg';
import { classNames } from '@utils/class-names';
import React, { useCallback, useEffect, useState, } from 'react';
function onBlurNumberInput(rest, setNewValue) {
    return (e) => {
        if (rest.min && parseInt(e.currentTarget.value) < parseInt(rest.min)) {
            setNewValue === null || setNewValue === void 0 ? void 0 : setNewValue(rest.min);
        }
        if (rest.max && parseInt(e.currentTarget.value) > parseInt(rest.max)) {
            setNewValue === null || setNewValue === void 0 ? void 0 : setNewValue(rest.max);
        }
    };
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function Input(_a) {
    var _b;
    var { type = 'text', disabled = false, readOnly = false, required = false, value = '', className, dataTestId, placeHolder, onChange, onBlur, trimSpaces = false } = _a, rest = __rest(_a, ["type", "disabled", "readOnly", "required", "value", "className", "dataTestId", "placeHolder", "onChange", "onBlur", "trimSpaces"]);
    const [currentValue, setCurrentValue] = useState(value);
    useEffect(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(currentValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);
    const onValueChange = useCallback((e) => {
        let newValue = e.target.value;
        if (trimSpaces) {
            while (newValue.startsWith(' ')) {
                newValue = newValue.slice(1);
            }
            while (newValue.endsWith('  ')) {
                // check for two spaces, so that we preserve one trailing space
                newValue = newValue.slice(0, -1);
            }
        }
        setCurrentValue(newValue);
    }, [trimSpaces]);
    const showSearchIcon = type === 'search';
    const baseInputProps = {
        className: classNames('relative w-full px-4 border-0 bg-transparent outline-0 outline-none', 'text-input text-darkBlue font-medium', type !== 'text-area' && !!currentValue && 'pt-4', showSearchIcon && 'pl-14', type === 'text-area' && (currentValue ? 'pt-7' : 'pt-5'), type === 'text-area' ? 'block resize-none mb-4' : 'h-14'),
        onChange: onValueChange,
        onBlur: () => onBlur === null || onBlur === void 0 ? void 0 : onBlur(currentValue),
        readOnly,
        disabled,
        required,
        value: currentValue,
    };
    return (React.createElement("div", { "data-testid": dataTestId, className: classNames('relative rounded-sm text-input border border-solid border-darkBlue/10', className) },
        React.createElement("div", { className: classNames('absolute flex items-center w-full px-4 text-darkBlue/50', currentValue
                ? 'top-1.5 text-[0.765em]'
                : 'top-5 text-[1em] font-medium', showSearchIcon && 'pl-14') }, placeHolder),
        showSearchIcon && (React.createElement("div", { className: "absolute h-full w-full flex items-center pl-4" },
            React.createElement(Search, { className: "w-6 h-6" }))),
        type === 'text-area' ? (React.createElement("textarea", Object.assign({ "data-testid": dataTestId ? `${dataTestId}-input` : `input-${type}`, rows: (_b = rest.rows) !== null && _b !== void 0 ? _b : 5 }, baseInputProps, rest))) : (React.createElement("input", Object.assign({ type: type, "data-testid": dataTestId ? `${dataTestId}-input` : `input-${type}` }, baseInputProps, rest, { onWheel: (e) => e.currentTarget.blur(), onBlur: type === 'number'
                ? onBlurNumberInput(rest, setCurrentValue)
                : undefined })))));
}
