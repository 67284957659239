export function formatScore(score) {
    return score.toLocaleString('de-DE', {
        maximumFractionDigits: 1,
    });
}
export function formatStandingsString(total, correct) {
    if (total === 0) {
        return '(0/0)';
    }
    return `${Math.round((correct / total) * 100)}% (${correct}/${total})`;
}
export function winnerText(winnerInfo) {
    switch (winnerInfo) {
        case 'me':
            return 'Gewonnen';
        case 'others':
            return 'Verloren';
        case 'draw':
            return 'Unentschieden';
        default:
            return '';
    }
}
