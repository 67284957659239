import { Button } from '@components/basic-components/button';
import { KioskMockNestClient } from '@api/kiosk-mock-nest-client';
import { useNestClientContext } from '../context/nest-client-context';
import React, { useEffect } from 'react';
import useCategoriesInfoContext from '../context/categories-info-context';
export default function KioskEntryView() {
    const { setNestClient, nestClient } = useNestClientContext();
    const { categories } = useCategoriesInfoContext();
    useEffect(() => {
        void nestClient.getQuestions().then((questions) => {
            setNestClient(new KioskMockNestClient([...categories.values()]
                .sort((a, b) => a.sortId - b.sortId)
                .map((c) => c.id), [...questions.values()]));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return React.createElement(Button, { to: "/challenges/1/1" }, "Start");
}
