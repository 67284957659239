import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { RankingsList } from '@components/rankings/rankings';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import React, { useState } from 'react';
function MobileTeamWonIcon() {
    return (React.createElement("div", { className: "icon-trophy inline mr-1 align-middle text-md leading-5" }));
}
export default function TeamVsTeamRankings({ getData, Entry, challenge, }) {
    var _a, _b, _c;
    const [showOwnTeamRanking, setShowOwnTeamRanking] = useState(true);
    const myTeamName = `${(_b = (_a = challenge.ownTeamInfo) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Mein Team'}`;
    const opponentTeamName = `${(_c = challenge.opponentInfo.name) !== null && _c !== void 0 ? _c : 'Gegner'}`;
    const ownTeamWon = challenge.gameOver &&
        challenge.me.teamTotalScore > challenge.others.totalScore;
    const ownTeamRanking = (React.createElement(RankingsList, { getData: () => getData().then((data) => data.filter((d) => { var _a; return d.teamId === ((_a = challenge.ownTeamInfo) === null || _a === void 0 ? void 0 : _a.teamId); })), Entry: Entry, showEllipsis: false, showWinner: challenge.gameOver, emptyMessage: 'Keine Teilnehmenden gefunden', desktopHeader: myTeamName, desktopHeaderRight: `∅${challenge.me.teamTotalScore} Punkte`, markListAsWinner: ownTeamWon }));
    const opponentWon = challenge.gameOver &&
        challenge.me.teamTotalScore < challenge.others.totalScore;
    const opponentTeamRanking = (React.createElement(RankingsList, { getData: () => getData().then((data) => data.filter((d) => d.teamId === challenge.opponentInfo.teamId)), Entry: Entry, showEllipsis: false, showWinner: challenge.gameOver, emptyMessage: 'Keine Teilnehmenden gefunden', desktopHeader: opponentTeamName, desktopHeaderRight: `∅${challenge.others.totalScore} Punkte`, markListAsWinner: opponentWon }));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "desktop:hidden" },
            React.createElement(TabsToggle, { labelLeft: `${myTeamName} ∅${challenge.me.teamTotalScore}`, labelRight: `${opponentTeamName} ∅${challenge.others.totalScore}`, leftSelected: showOwnTeamRanking, setLeftSelected: setShowOwnTeamRanking, iconLeft: ownTeamWon ? React.createElement(MobileTeamWonIcon, null) : undefined, iconRight: opponentWon ? React.createElement(MobileTeamWonIcon, null) : undefined })),
        React.createElement("div", { className: "desktop:hidden h-full bg-white" },
            React.createElement(CenterScrollPanel, { padding: "p-0", className: "flex-grow h-full" }, showOwnTeamRanking ? ownTeamRanking : opponentTeamRanking)),
        React.createElement("div", { className: "hidden h-full desktop:grid grid-cols-2 gap-6" },
            React.createElement(React.Fragment, null,
                ownTeamRanking,
                opponentTeamRanking))));
}
