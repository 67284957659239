import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ViewsType } from '@typesSrc/views';
import { useNavigate } from 'react-router';
import BaseView from '@views/base-view';
import React from 'react';
export default function ImprintView() {
    const navigate = useNavigate();
    return (React.createElement(BaseView, { view: ViewsType.IMPRINT, header: {
            showBack: true,
            onBack: () => navigate(-1),
            title: 'Impressum',
        }, showFooter: false },
        React.createElement(CenterScrollPanel, { className: "flex-1 text-darkBlue standalone-page-style" },
            React.createElement("h1", null, "Impressum"),
            React.createElement("p", null, "Die nachstehenden Informationen enthalten die gesetzlich vorgesehenen Pflichtangaben zur Anbieterkennzeichnung sowie Hinweise zu Ansprechpartner/-innen, Haftung und Urheberrecht."),
            React.createElement("h2", null, "Herausgeber:"),
            React.createElement("p", null,
                "Bundesinstitut f\u00FCr Berufsbildung (BIBB)",
                React.createElement("br", null),
                "Friedrich-Ebert-Allee 114-116",
                React.createElement("br", null),
                " 53175 Bonn"),
            React.createElement("p", null,
                "Telefon:",
                ' ',
                React.createElement("a", { href: "tel:+492281070" },
                    "0228 / 107 - 0",
                    React.createElement("br", null)),
                ' ',
                "Fax: 0228 / 107 - 2977",
                React.createElement("br", null),
                " Internet:",
                ' ',
                React.createElement("a", { href: "https://www.bibb.de/" }, "https://www.bibb.de/"),
                ' ',
                React.createElement("br", null),
                "E-Mail:",
                ' ',
                React.createElement("a", { href: "mailto:zentrale@bibb.de" }, "zentrale@bibb.de")),
            React.createElement("p", null, "Das Bundesinstitut f\u00FCr Berufsbildung ist eine bundesunmittelbare, rechtsf\u00E4hige Anstalt des \u00F6ffentlichen Rechts."),
            React.createElement("h2", null, "Gesetzlicher Vertreter:"),
            React.createElement("p", null,
                React.createElement("a", { href: "https://www.bibb.de/de/464.php" }, "Prof. Dr. Friedrich Hubert Esser"),
                React.createElement("br", null),
                "Adresse siehe oben"),
            React.createElement("p", null, "USt-IdNr. des BIBB: DE205571125"),
            React.createElement("h2", null, "Das MIKA-Game wird gef\u00F6rdert vom Bundesministerium f\u00FCr Bildung und Forschung (BMBF)."),
            React.createElement("h2", null, "Inhaltlich Verantwortliche"),
            React.createElement("p", null,
                "Natalie Deininger;",
                React.createElement("br", null),
                " Adresse siehe oben"),
            React.createElement("p", null,
                "Telefon: ",
                React.createElement("a", { href: "tel:+4902281071310" }, "0228 / 107 -1310"),
                ' ',
                React.createElement("br", null),
                "E-Mail: ",
                React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de")),
            React.createElement("h2", null, "Redaktion und Weiterentwicklung:"),
            React.createElement("p", null,
                React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de"),
                ' ',
                "(Ansprechpartnerinnen: Annina Hittmeyer & Constanze Eick",
                ' ',
                React.createElement("a", { href: "tel:+492281072806" }, "0228 / 107 - 2906"),
                ")"),
            React.createElement("h2", null, "Realisierung"),
            React.createElement("h2", null, "Webdesign"),
            React.createElement("p", null,
                "Sprylab Technologies GmbH, Keithstr. 2-4, 10787 Berlin",
                React.createElement("br", null),
                "nvii-media GmbH, Leipziger Stra\u00DFe 100, 06108 Halle",
                React.createElement("br", null),
                "Kr\u00F6pelin Projekt GmbH, Jahnstra\u00DFe 37-39, 12347 Berlin"),
            React.createElement("h2", null, "Idee & didaktisches Konzept:"),
            React.createElement("p", null, "BIBB: MIKA-Team"),
            React.createElement("h2", null, "Technische Entwicklung und Support:"),
            React.createElement("p", null,
                "Kr\u00F6pelin Projekt GmbH, Jahnstra\u00DFe 37-39, 12347 Berlin",
                React.createElement("br", null),
                "Sprylab Technologies GmbH, Keithstr. 2-4, 10787 Berlin"),
            React.createElement("h2", null, "Inhalte:"),
            React.createElement("p", null, "BIBB - Bundesinstitut f\u00FCr Berufsbildung"),
            React.createElement("p", null,
                "DLGI - Dienstleistungsgesellschaft f\u00FCr Informatik mbH, Rabinstra\u00DFe 1, 53111 Bonn",
                React.createElement("br", null),
                "Kr\u00F6pelin Projekt GmbH, Jahnstr. 37-39, 12347 Berlin"),
            React.createElement("h2", null, "Bildnachweis:"),
            React.createElement("p", null, "Adobe Stock"),
            React.createElement("h2", null, "Webhosting:"),
            React.createElement("p", null, "BRINGE Informationstechnik GmbH, Zur Seeplatte 12, 76228 Karlsruhe"),
            React.createElement("h2", null, "Haftungsausschluss und Copyright"),
            React.createElement("h2", null, "Inhalt des Internetangebotes:"),
            React.createElement("p", null, "Das Bundesinstitut f\u00FCr Berufsbildung (BIBB) \u00FCbernimmt als Herausgeber dieses Internetangebotes keine Gew\u00E4hr f\u00FCr die Richtigkeit, Vollst\u00E4ndigkeit und Aktualit\u00E4t der aufbereiteten Informationen. F\u00FCr Sch\u00E4den, insbesondere finanzieller und ideeller Art, die durch die Nutzung der dargestellten Informationen entstehen, ist eine Haftung seitens des Herausgebers oder von Mitarbeitenden des BIBB grunds\u00E4tzlich ausgeschlossen. Alle Angebote sind unverbindlich und nicht verpflichtend. Es bleibt dem Herausgeber vorbehalten, das komplette Angebot oder auch nur Ausz\u00FCge ohne ausdr\u00FCckliche Ank\u00FCndigung auch zeitlich beschr\u00E4nkt zu l\u00F6schen, zu erweitern oder zu \u00E4ndern."),
            React.createElement("h2", null, "Verweise und Links:"),
            React.createElement("p", null, "Bei direkten oder indirekten Verweisen auf fremde Internetseiten, die au\u00DFerhalb des Verantwortungsbereiches des Herausgebers liegen, w\u00FCrde eine Haftungsverpflichtung ausschlie\u00DFlich in dem Fall in Kraft treten, in dem der Herausgeber von den Inhalten Kenntnis hat und es ihm technisch m\u00F6glich und zumutbar w\u00E4re, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Herausgeber distanziert sich ausdr\u00FCcklich von illegalen Inhalten auf extern verlinkten Seiten. Der Herausgeber hat keinerlei Einfluss auf die aktuelle und zuk\u00FCnftige Gestaltung und auf die Inhalte der gelinkten/ verkn\u00FCpften Seiten. Deshalb distanziert sich der Herausgeber hiermit ausdr\u00FCcklich von allen Inhalten aller gelinkten/ verkn\u00FCpften Seiten, die nach der Linksetzung ver\u00E4ndert wurden. Diese Feststellung gilt f\u00FCr alle innerhalb des eigenen Internet-Angebotes gesetzten Links und Verweise sowie f\u00FCr Fremdeintr\u00E4ge in vom Herausgeber eingerichteten G\u00E4steb\u00FCchern, Diskussionsforen und Mailinglisten. F\u00FCr illegale, fehlerhafte oder unvollst\u00E4ndige Inhalte und insbesondere f\u00FCr Sch\u00E4den, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haften allein Anbietende der Seite, auf welche verwiesen wurde, nicht diejenigen, die \u00FCber Links auf die jeweilige Ver\u00F6ffentlichung lediglich verweisen."),
            React.createElement("h2", null, "Urheber- und Kennzeichenrecht"),
            React.createElement("p", null,
                "Der Herausgeber ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Fotos, Tondokumente, Videosequenzen und Texte etc. zu beachten, von ihm selbst erstellte Grafiken, Fotos, Tondokumente, Videosequenzen und Texte etc. zu nutzen oder auf lizenzfreie Grafiken, Fotos, Tondokumente, Videosequenzen und Texte etc. zur\u00FCckzugreifen.",
                React.createElement("br", null),
                "Das Copyright f\u00FCr ver\u00F6ffentlichte, vom Herausgeber selbst erstellte Objekte, Inhalte und Strukturen bleibt allein beim Herausgeber der Seiten. Eine Ver\u00F6ffentlichung im World Wide Web oder in sonstigen Diensten des Internets bedeutet keine Einverst\u00E4ndniserkl\u00E4rung f\u00FCr eine anderweitige Nutzung durch Dritte. Eine Vervielf\u00E4ltigung oder Verwendung solcher Grafiken, Fotos, Ton-, Video- oder Animationsdateien, Texte etc., auch nur Teile davon, einschlie\u00DFlich deren Anordnung auf den Webseiten in anderen elektronischen oder gedruckten Publikationen und deren Ver\u00F6ffentlichungen (auch im Internet) ist ohne ausdr\u00FCckliche vorherige Zustimmung des Herausgebers nicht gestattet. Auf Seiten, auf denen die Kennzeichnung der Creative Commons-Lizenz sichtbar ist, gelten die Bedingungen dieser Lizenz.",
                React.createElement("br", null),
                "Alle innerhalb des Internetangebotes des Herausgebers genannten und ggf. durch Dritte gesch\u00FCtzten Marken- und Warenzeichen unterliegen uneingeschr\u00E4nkt den Bestimmungen des jeweils g\u00FCltigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigent\u00FCmer/-innen. Allein aufgrund der blo\u00DFen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter gesch\u00FCtzt sind!"),
            React.createElement("p", null, "Der Herausgeber weist alle externen Einrichtungen und Personen, die zum Hochladen und zur Ver\u00F6ffentlichung von Informationen auf diesen Web-Auftritt berechtigt sind, darauf hin, dass die Urheberrechte nicht nur von Texten, sondern insbesondere auch von Grafiken, Fotos, Ton-, Video- oder Animationsdateien etc. zu beachten sind. Eine Verwendung dieses Materials ist nur zul\u00E4ssig, wenn die entsprechenden Rechte erworben wurden; oder es ist auf lizenzfreie Angebote zur\u00FCckzugreifen. Eine Vervielf\u00E4ltigung oder Verwendung durch Rechte Dritter gesch\u00FCtzte Grafiken, Fotos, Ton-, Video- oder Animationsdateien, und Texte, auch nur Teile davon, einschlie\u00DFlich deren Anordnung auf den Webseiten etc. in anderen elektronischen oder gedruckten Publikationen und deren Ver\u00F6ffentlichungen (auch im Internet) ist ohne deren ausdr\u00FCckliche Zustimmung nicht gestattet. Der Herausgeber \u00FCbernimmt bei solchen Objekten, die durch vorgenannte Einrichtungen und Personen auf den auf diesem Web-Auftritt des Herausgebers ver\u00F6ffentlicht oder in den E-Mail-Versand des Herausgebers eingebracht wurden, keinerlei Verantwortung im Falle von Urheberrechts- oder Besitzrechtsverletzungen."),
            React.createElement("h2", null, "Rechtswirksamkeit"),
            React.createElement("p", null, "Die Hinweise zu Haftung und Urheberrecht sind als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde."),
            React.createElement("h2", null,
                "Haben Sie Fragen oder Anmerkungen? Dann schreiben Sie uns an",
                ' ',
                React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de")),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null))));
}
