import { ReactComponent as CustomQuestions } from '@public/icons/customquestions.svg';
import { ReactComponent as Home } from '@public/icons/home.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Profile } from '@public/icons/profile.svg';
import { ReactComponent as Quiz } from '@public/icons/quiz.svg';
import { ReactComponent as Teams } from '@public/icons/teams.svg';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import React, { useCallback } from 'react';
export const FooterItemTypeConfiguration = {
    [ViewsType.HOME]: {
        icon: Home,
        label: 'Home',
        path: '/home',
    },
    [ViewsType.CHALLENGES_OVERVIEW]: {
        icon: Quiz,
        label: 'Quiz',
        path: '/challenges',
    },
    [ViewsType.TEAMS]: {
        icon: Teams,
        label: 'Teams',
        path: '/teams',
    },
    [ViewsType.PROFILE]: {
        icon: Profile,
        label: 'Profil',
        path: '/profile',
    },
    [ViewsType.CUSTOM_QUESTIONS]: {
        icon: CustomQuestions,
        label: 'Meine Fragen',
        path: '/myQuestions',
    },
};
function FooterItem({ item, isActive, onRedirect, onBackToTopClicked, featured, className, }) {
    const onClick = useCallback(
    // Todo: write a cypress test for that https://jira.sprylab.com/browse/MIKA-328
    /* istanbul ignore next */
    (event) => {
        if (isActive && onBackToTopClicked) {
            onBackToTopClicked();
            event.preventDefault();
        }
        else if (onRedirect) {
            onRedirect();
        }
    }, [isActive, onBackToTopClicked, onRedirect]);
    const config = FooterItemTypeConfiguration[item];
    return (React.createElement(Link, { className: classNames('relative flex flex-col items-center gap-1', className), to: config.path, onClick: onClick },
        React.createElement(config.icon, { className: `h-6 w-auto ${isActive ? 'fill-green' : 'fill-darkBlue/70'}` }),
        React.createElement("div", { className: `text-xs leading-6 text-darkBlue ${isActive ? 'font-bold' : ''}` }, config.label),
        featured && (React.createElement("div", { className: "absolute rounded-full bg-green/10 w-18 h-18 p-1 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" },
            React.createElement("div", { className: "rounded-full bg-green/10 w-full h-full" })))));
}
export default FooterItem;
