import { Button } from '@components/basic-components/button';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Popup } from '@components/basic-components/popup';
import { useProfile } from '../../context/profile-context';
import React from 'react';
export const RegisteredOnlyPopup = (popupProps) => {
    const { state: { profile }, } = useProfile();
    const registeredOnlyPopupProps = Object.assign(Object.assign({}, popupProps), { mika: 'sad', centerScrollPanel: {
            header: FEATURE_FLAG_DIDACTA_VERSION
                ? 'Wir arbeiten dran!'
                : 'Du bist nicht registriert',
            footer: profile && (React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement(Button, { priority: "primary", onClick: () => { var _a; return (_a = popupProps.setPopupOpen) === null || _a === void 0 ? void 0 : _a.call(popupProps, false); }, className: "w-full" }, "Ok"))),
        } });
    const content = FEATURE_FLAG_DIDACTA_VERSION
        ? 'Dieses Feature wird bald für registrierte Nutzer/-innen verfügbar sein. Halte Deine App stets aktuell, um dieses Feature nutzen zu können, sobald es verfügbar ist.'
        : 'Bitte logge Dich ein oder erstelle einen Account, um dieses Feature nutzen zu können';
    return React.createElement(Popup, Object.assign({}, registeredOnlyPopupProps), content);
};
