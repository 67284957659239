import { ReactComponent as Background } from '@public/images/avatar-layers/background/_back_circle.svg';
import { ReactComponent as Face } from '@public/images/avatar-layers/face/_face.svg';
import { beardColorsOrdered, beardConfigsOrdered, beardShapesOrdered, } from '@components/basic-components/avatar/layers/beard/config-beard';
import { bodyColorsOrdered, bodyConfig, bodyShapesOrdered, } from '@components/basic-components/avatar/layers/config-body';
import { eyeColorsOrdered, eyeConfig, eyeShapesOrdered, } from '@components/basic-components/avatar/layers/config-eye';
import { eyebrowColorsOrdered, eyebrowConfig, eyebrowShapesOrdered, } from '@components/basic-components/avatar/layers/config-eyebrow';
import { hairColorsOrdered, hairConfigsOrdered, hairShapesOrdered, } from '@components/basic-components/avatar/layers/hair/config-hair';
import { headGearColorsOrdered, headGearConfigsOrdered, headGearShapesOrdered, } from '@components/basic-components/avatar/layers/head-gear/config-head-gear';
import { jewelryColorsOrdered, jewelryConfigsOrdered, jewelryShapesOrdered, } from '@components/basic-components/avatar/layers/config-jewelry';
import { shirtColorsOrdered, shirtConfig, shirtShapesOrdered, } from '@components/basic-components/avatar/layers/config-shirts';
import React, { useCallback } from 'react';
export const defaultAvatarConfig = {
    beard: {
        shape: beardShapesOrdered[0],
        color: beardColorsOrdered[0],
    },
    body: {
        shape: bodyShapesOrdered[0],
        color: bodyColorsOrdered[0],
    },
    eye: {
        shape: eyeShapesOrdered[0],
        color: eyeColorsOrdered[0],
    },
    eyebrow: {
        shape: eyebrowShapesOrdered[0],
        color: eyebrowColorsOrdered[0],
    },
    hair: {
        shape: hairShapesOrdered[0],
        color: hairColorsOrdered[0],
    },
    headGear: {
        shape: headGearShapesOrdered[0],
        color: headGearColorsOrdered[0],
    },
    jewelry: {
        shape: jewelryShapesOrdered[0],
        color: jewelryColorsOrdered[0],
    },
    shirt: {
        shape: shirtShapesOrdered[0],
        color: shirtColorsOrdered[0],
    },
    __version: 1,
};
export const AvatarByConfig = React.forwardRef(({ config, noBackground, className }, svgContainerRef) => {
    const activeBodyIndex = config.body.shape
        ? bodyShapesOrdered.indexOf(config.body.shape)
        : 0;
    const activeHairIndex = config.hair.shape
        ? hairShapesOrdered.indexOf(config.hair.shape)
        : 0;
    const getLayer = useCallback((layer, layerConfig) => {
        const mergedConfig = Object.assign(Object.assign({}, defaultAvatarConfig), config);
        const shape = mergedConfig[layer].shape;
        return shape && layerConfig[mergedConfig[layer].color][shape];
    }, [config]);
    return (React.createElement("div", { className: className, ref: svgContainerRef },
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", version: "1.1", xmlSpace: "preserve", viewBox: "0 0 720 720" },
            !noBackground && React.createElement(Background, null),
            getLayer('body', bodyConfig),
            getLayer('eye', eyeConfig),
            getLayer('eyebrow', eyebrowConfig),
            getLayer('shirt', shirtConfig),
            getLayer('jewelry', jewelryConfigsOrdered[activeBodyIndex]),
            getLayer('hair', hairConfigsOrdered[activeBodyIndex]),
            getLayer('beard', beardConfigsOrdered[activeBodyIndex]),
            getLayer('headGear', headGearConfigsOrdered[activeHairIndex]),
            React.createElement(Face, null))));
});
