const convertV0ToV1 = {
    body: {
        FemaleBody: 'body_1',
        MaleBody: 'body_2',
    },
    jewelry: {
        ManJewelry1: 'Jewelry1',
        ManJewelry2: 'Jewelry2',
        WomanJewelry1: 'Jewelry3',
        WomanJewelry2: 'Jewelry4',
    },
    headGear: {
        ManHeadGear1: 'HeadGear4',
        ManHeadGear2: 'HeadGear3',
        ManHeadGear3: 'HeadGear2',
        WomanHeadGear1: 'HeadGear1',
        WomanHeadGear2: undefined,
    },
};
export function avatarConfigV0ToV1(avatarConfigV0) {
    const avatarConfigV1 = Object.assign(Object.assign({}, avatarConfigV0), { body: Object.assign(Object.assign({}, avatarConfigV0.body), { shape: convertV0ToV1.body[avatarConfigV0.body.shape] }), jewelry: Object.assign(Object.assign({}, avatarConfigV0.jewelry), { shape: avatarConfigV0.jewelry.shape
                ? convertV0ToV1.jewelry[avatarConfigV0.jewelry.shape]
                : undefined }), headGear: Object.assign(Object.assign({}, avatarConfigV0.headGear), { shape: avatarConfigV0.headGear.shape
                ? convertV0ToV1.headGear[avatarConfigV0.headGear.shape]
                : undefined }), __version: 1 });
    return avatarConfigV1;
}
