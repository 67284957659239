import { ReactComponent as Shirt1 } from '@public/images/avatar-layers/shirts/t-shirt_1.svg';
import { ReactComponent as Shirt2 } from '@public/images/avatar-layers/shirts/t-shirt_2.svg';
import { ReactComponent as Shirt3 } from '@public/images/avatar-layers/shirts/t-shirt_3.svg';
import { ReactComponent as Shirt4 } from '@public/images/avatar-layers/shirts/t-shirt_4.svg';
import { ReactComponent as Shirt5 } from '@public/images/avatar-layers/shirts/t-shirt_5.svg';
import React from 'react';
export const shirtColorsOrdered = [
    '#46905F',
    '#727EEA',
    '#D14D75',
    '#F86136',
    '#2B3356',
];
export const shirtShapesOrdered = ['Shirt1'];
export const shirtConfig = {
    '#46905F': {
        Shirt1: React.createElement(Shirt1, null),
    },
    '#727EEA': {
        Shirt1: React.createElement(Shirt2, null),
    },
    '#D14D75': {
        Shirt1: React.createElement(Shirt3, null),
    },
    '#F86136': {
        Shirt1: React.createElement(Shirt4, null),
    },
    '#2B3356': {
        Shirt1: React.createElement(Shirt5, null),
    },
};
