import { Network } from '@capacitor/network';
import { Popup } from '@components/basic-components/popup';
import { isTest } from '../environment.global';
import { useNestClientContext } from './nest-client-context';
import React, { createContext, useContext, useEffect, useState, } from 'react';
const BACKEND_CHECK_INTERVAL = isTest ? 500 : 5000;
const OFFLINE_TITLE = 'Du bist Offline!';
const OFFLINE_MESSAGE = 'Du bist offline und kannst die App nicht verwenden. Bitte aktiviere Deine mobilen Daten oder Dein WLAN.';
const BACKEND_OFFLINE_TITLE = 'Server ist nicht erreichbar!';
const BACKEND_OFFLINE_MESSAGE = 'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte überprüfe Deine Internetverbindung und versuche es später erneut.';
/**
 * Since the capacitor offline detection does not always work,
 * we additionally also periodically check if we can reach our own backend.
 * @returns [isOffline, isBackendOffline]
 */
function useOfflineDetection() {
    const { nestClient } = useNestClientContext();
    const [capDisconnected, setCapDisconnected] = useState(false);
    const [nestDisconnected, setNestDisconnected] = useState(false);
    useEffect(() => {
        void Network.addListener('networkStatusChange', (status) => {
            setCapDisconnected(!status.connected);
        });
        // There is no way to remove a specific listener,
        // so we just assume to be the only one using this plugin and clear everything
        return () => void Network.removeAllListeners();
    }, []);
    useEffect(() => {
        const updateNest = () => void nestClient
            .checkOnline()
            .then(() => setNestDisconnected(false))
            .catch(() => setNestDisconnected(true));
        updateNest();
        const intervalId = setInterval(updateNest, BACKEND_CHECK_INTERVAL);
        return () => clearInterval(intervalId);
    }, [nestClient]);
    return [
        capDisconnected || nestDisconnected,
        nestDisconnected && !capDisconnected,
    ];
}
export const OfflineContext = createContext(undefined);
export const OfflineProvider = ({ children }) => {
    const [showAsOffline, setShowAsOffline] = useState(false);
    const [showAsOfflineDelayed, setShowAsOfflineDelayed] = useState(false);
    const [isOffline, isBackendOffline] = useOfflineDetection();
    useEffect(() => {
        if (!isOffline && showAsOffline) {
            // we just got back online!
            window.location.reload();
        }
        if (isOffline) {
            setShowAsOffline(true);
            setTimeout(() => setShowAsOfflineDelayed(true), 100);
        }
    }, [isOffline, showAsOffline]);
    return (React.createElement(OfflineContext.Provider, { value: isOffline },
        children,
        showAsOffline && (React.createElement(Popup, { dataTestId: "offline-popup", popupOpen: showAsOfflineDelayed, withoutFooter: true, mika: "sad", className: "z-20", centerScrollPanel: {
                header: isBackendOffline
                    ? BACKEND_OFFLINE_TITLE
                    : OFFLINE_TITLE,
            } }, isBackendOffline
            ? BACKEND_OFFLINE_MESSAGE
            : OFFLINE_MESSAGE))));
};
export function useOfflineContext() {
    const state = useContext(OfflineContext);
    if (state === undefined) {
        throw new Error('useOfflineContext must be used within a OfflineProvider');
    }
    return state;
}
