import { useNestClientContext } from './nest-client-context';
import { useProfile } from './profile-context';
import MapWithDefaultValue from '@mika-bibb/shared/util/map-with-default-value';
import React, { useContext, useEffect, useState, } from 'react';
const DEFAULT_CATEGORY = Object.freeze({
    id: 'unknown',
    sortId: 0,
    text: 'Unknown',
    imageURL: undefined,
});
const DEFAULT_CATEGORIES = new MapWithDefaultValue(DEFAULT_CATEGORY);
async function loadCategoryInfo(nestClient) {
    const rawCategories = await nestClient.getCategories();
    const categories = new MapWithDefaultValue(DEFAULT_CATEGORY);
    for (const category of rawCategories) {
        categories.set(category.id, category);
    }
    return { categories };
}
const CategoriesInfoContext = React.createContext(undefined);
export function CategoriesInfoContextProvider({ children }) {
    const [state, setState] = useState({
        categories: DEFAULT_CATEGORIES,
    });
    const { nestClient } = useNestClientContext();
    const { state: { profile }, } = useProfile();
    useEffect(() => {
        if (!profile) {
            return;
        }
        loadCategoryInfo(nestClient).then(setState).catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nestClient, !!profile]);
    return (React.createElement(CategoriesInfoContext.Provider, { value: state }, children));
}
export default function useCategoriesInfoContext() {
    const state = useContext(CategoriesInfoContext);
    if (!state) {
        throw new Error('useCategoriesInfoContext must be used within a CategoriesInfoContextProvider');
    }
    return state;
}
