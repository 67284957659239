import './styles/globals.scss';
import 'react-toggle/style.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { MikaContext, routes } from './routes';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppInitializer from '@utils/app-initialization';
import React from 'react';
import ReactDOM from 'react-dom/client';
const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById('root'));
// For testing  purposes, I created an AppInitializer utility-file.
// For now, it only enables the back-button-behaviour.
// Other initialization-task can be moved here too, to enable easier testing of those.
void AppInitializer.init(router);
root.render(React.createElement(MikaContext, null,
    React.createElement(RouterProvider, { router: router })));
// If you don't want to use the service worker, you can change
// register() to unregister() below.
serviceWorkerRegistration.register();
