import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { SearchTeam } from '@components/challenge/search-team';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
export default function SearchOpponentTeam({ searchingTeam, }) {
    const navigate = useNavigate();
    const startGame = useCallback(async (teamIDs) => {
        const selectedTeam = teamIDs[0];
        if (!selectedTeam) {
            return;
        }
        navigate(`/challenges/new?type=${ChallengeType.TEAM_VS_TEAM}&team=${searchingTeam}&opponent=${selectedTeam}`);
        return await Promise.resolve();
    }, [navigate, searchingTeam]);
    return (React.createElement(SearchTeam, { singleChoice: true, button: { text: 'Spiel starten', onClick: startGame }, searchingTeam: searchingTeam }));
}
