import { BannerType } from '@mika-bibb/shared/profile.dto';
import { ChallengeType, SingleAnswerStateDto, } from '@mika-bibb/shared/challenge-status-dto';
import { DEFAULT_QUESTIONS_PER_ROUND } from '@mika-bibb/shared/constants';
import { sampleSeeded } from '@mika-bibb/shared/util/seeded-sample';
const SHOW_ALL_CATEGORIES = true;
export class KioskMockNestClient {
    constructor(allCategoryIDs, allQuestions) {
        this.allCategoryIDs = allCategoryIDs;
        this.allQuestions = allQuestions;
        this.kiosk = true;
        this.challengeRound = {
            id: 'r1',
            categoryId: '',
            questionIds: [],
            opponentDone: false,
        };
        this.seedExtra = `${new Date().getTime()}`;
        this.answers = new Array(DEFAULT_QUESTIONS_PER_ROUND).fill(SingleAnswerStateDto.NOT_ANSWERED);
        this.challengeState = {
            id: 'kiosk',
            type: ChallengeType.SOLO,
            name: 'Kiosk Challenge',
            gameOver: false,
            creatorId: undefined,
            validUntil: '',
            waitingForOpponent: false,
            rounds: new Proxy([], {
                get: () => {
                    return this.challengeRound;
                },
            }),
            me: {
                answersPerRound: new Proxy([], {
                    get: () => {
                        return {
                            answers: this.answers,
                        };
                    },
                }),
                totalScore: 0,
                scoresPerRound: [],
            },
            others: { totalScore: 0, scoresPerRound: [] },
            winner: 'none',
            opponentInfo: { amount: 0, bannerType: BannerType.NONE },
            currentRound: 1,
            isUsersTurn: true,
            started: true,
            questionsPerRound: DEFAULT_QUESTIONS_PER_ROUND,
            usesSlowSpeed: false,
        };
        this.roundState = {
            categoryOptions: this._getCategoryOptions(),
            chosenCategory: undefined,
            nextQuestion: 0,
        };
    }
    getChallengeState(challengeId) {
        console.log('getChallengeState', challengeId);
        return Promise.resolve(this.challengeState);
    }
    getChallengeRoundState(challengeId, roundId) {
        console.log('getChallengeRoundState', challengeId, roundId);
        return Promise.resolve(this.roundState);
    }
    checkOnline() {
        return Promise.resolve(true);
    }
    chooseCategory(challengeId, roundIndex, categoryId) {
        this.roundState.chosenCategory = categoryId;
        this.challengeRound.categoryId = categoryId;
        this.challengeRound.questionIds = sampleSeeded(this.allQuestions.filter((q) => q.categoryId === categoryId), DEFAULT_QUESTIONS_PER_ROUND, `${this.challengeState.currentRound}${this.seedExtra}`).map((q) => q.id);
        this.roundState.nextQuestion = 0;
        this.answers.fill(SingleAnswerStateDto.NOT_ANSWERED);
        return Promise.resolve();
    }
    getRoundQuestion(roundID, index) {
        const questionID = this.challengeRound.questionIds[index];
        return Promise.resolve(this.allQuestions.find((q) => q.id === questionID));
    }
    answerQuestion(challengeId, roundIndex, questionIndex, answerIndex) {
        const question = this.allQuestions.find((q) => q.id ===
            this.challengeRound.questionIds[this.roundState.nextQuestion]);
        const correct = (question === null || question === void 0 ? void 0 : question.answers.findIndex((a) => a.isCorrect)) === answerIndex;
        this.answers[this.roundState.nextQuestion] = correct
            ? SingleAnswerStateDto.CORRECT
            : SingleAnswerStateDto.WRONG;
        this.roundState.nextQuestion =
            this.roundState.nextQuestion !== undefined
                ? this.roundState.nextQuestion + 1
                : undefined;
        if (this.roundState.nextQuestion === DEFAULT_QUESTIONS_PER_ROUND) {
            this.roundState.chosenCategory = undefined;
            this.challengeState.currentRound =
                this.challengeState.currentRound + 1;
            this.roundState.categoryOptions = this._getCategoryOptions();
            this.roundState.nextQuestion = 0;
        }
        return Promise.resolve();
    }
    _getCategoryOptions() {
        if (SHOW_ALL_CATEGORIES) {
            return [...this.allCategoryIDs];
        }
        return sampleSeeded(this.allCategoryIDs, 3, `${this.challengeState.currentRound}${this.seedExtra}`);
    }
}
