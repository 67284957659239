export var ServerErrorCode;
(function (ServerErrorCode) {
    ServerErrorCode["POST_ROUTE_FAILED"] = "POST_ROUTE_FAILED";
    ServerErrorCode["GET_ROUTE_FAILED"] = "GET_ROUTE_FAILED";
    ServerErrorCode["DELETE_ROUTE_FAILED"] = "DELETE_ROUTE_FAILED";
    ServerErrorCode["VALIDATION_FAILED"] = "VALIDATION_FAILED";
    ServerErrorCode["NICKNAME_TAKEN"] = "NICKNAME_TAKEN";
    ServerErrorCode["NICKNAME_INVALID_LENGTH"] = "NICKNAME_INVALID_LENGTH";
    ServerErrorCode["TEAMNAME_TAKEN"] = "TEAMNAME_TAKEN";
    ServerErrorCode["ANSWER_NOT_POSSIBLE"] = "ANSWER_NOT_POSSIBLE";
    ServerErrorCode["INVALID_CODE"] = "INVALID_CODE";
    ServerErrorCode["LAST_ADMIN_LEAVE"] = "LAST_ADMIN_LEAVE";
    ServerErrorCode["LAST_ADMIN_DELETE_PROFILE"] = "LAST_ADMIN_DELETE_PROFILE";
    ServerErrorCode["UPLOAD_FAILED"] = "UPLOAD_FAILED";
    ServerErrorCode["SSO_AUTH_FAILED"] = "SSO_AUTH_FAILED";
    ServerErrorCode["SSO_CONVERSION_FAILED"] = "SSO_CONVERSION_FAILED";
})(ServerErrorCode || (ServerErrorCode = {}));
