import { Button } from '@components/basic-components/button';
import { Link } from 'react-router-dom';
import { ReactComponent as MikaGameLogo } from '@public/icons/mikaGameLogo.svg';
import { Popup } from '@components/basic-components/popup';
import { RegisteredOnlyPopup } from '@components/basic-components/registeredOnlyPopup';
import { Role } from '@mika-bibb/shared/enums';
import { SitebarItem } from '@components/sidebar/sitebar-item';
import { ViewsType } from '@typesSrc/views';
import { useProfile } from '../../context/profile-context';
import React, { useState } from 'react';
export const Sitebar = ({ activeItem, onRedirect, onBackToTopClicked, featuredIndex, isGuest, children, showSidebar = true, }) => {
    const { state: { profile }, logout, } = useProfile();
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    function handleShowLogout() {
        setShowLogoutPopup(true);
    }
    const [showRegisteredOnlyPopup, setShowRegisteredOnlyPopup] = useState(false);
    function handleShowRegisteredOnlyPopup() {
        setShowRegisteredOnlyPopup(true);
    }
    if (!showSidebar) {
        return (React.createElement("main", { className: "h-full bg-bgGray w-full" },
            React.createElement("div", { className: "flex justify-center bg-wireframeGray h-full" },
                React.createElement("div", { className: "w-full grow max-w-[1200px] bg-white" },
                    React.createElement("div", { className: "h-full" }, children)))));
    }
    return (React.createElement("div", { className: "flex justify-center bg-wireframeGray h-full" },
        React.createElement("div", { className: "grow max-w-[1200px] bg-white" },
            React.createElement("div", { className: "hidden desktop:fixed desktop:inset-y-0 desktop:z-5 desktop:flex desktop:w-80 desktop:flex-col bg-white" },
                React.createElement("div", { className: "flex grow flex-col gap-y-5 overflow-y-auto pb-4" },
                    React.createElement("div", { className: "flex items-center p-16 border-darkBlue/10 border-b" },
                        React.createElement(Link, { to: "/" },
                            React.createElement(MikaGameLogo, null))),
                    React.createElement("nav", { className: "flex flex-1 flex-col" },
                        React.createElement(SitebarItem, { item: ViewsType.HOME, isActive: activeItem === ViewsType.HOME, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 0 }),
                        React.createElement(SitebarItem, { item: ViewsType.CHALLENGES_OVERVIEW, isActive: activeItem ===
                                ViewsType.CHALLENGES_OVERVIEW ||
                                activeItem ===
                                    ViewsType.CHALLENGE_DETAILS ||
                                activeItem === ViewsType.CHALLENGE_ROUND, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 1 }),
                        React.createElement("div", Object.assign({}, (isGuest
                            ? {
                                onClick: handleShowRegisteredOnlyPopup,
                            }
                            : {})),
                            React.createElement(SitebarItem, { item: ViewsType.TEAMS, isActive: activeItem === ViewsType.TEAMS, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 2, className: isGuest
                                    ? 'pointer-events-none opacity-50'
                                    : '' })),
                        React.createElement(SitebarItem, { item: ViewsType.PROFILE, isActive: activeItem === ViewsType.PROFILE, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 3 }),
                        React.createElement("div", Object.assign({}, (isGuest
                            ? {
                                onClick: handleShowRegisteredOnlyPopup,
                            }
                            : {})),
                            React.createElement(SitebarItem, { item: ViewsType.CUSTOM_QUESTIONS, isActive: activeItem ===
                                    ViewsType.CUSTOM_QUESTIONS, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 4, className: isGuest
                                    ? 'pointer-events-none opacity-50'
                                    : '' }))),
                    React.createElement("div", { className: "grow" }),
                    (profile === null || profile === void 0 ? void 0 : profile.role) === Role.REGISTERED && (React.createElement("button", { className: "relative flex flex-row items-center my-4 ml-16 ", onClick: handleShowLogout },
                        React.createElement("div", { className: "icon-exit text-darkBlue mr-4 text-2xl" }),
                        React.createElement("div", { className: "text-darkBlue text-lg" }, "Ausloggen"))))),
            React.createElement("div", { className: "desktop:pl-80 h-full" },
                React.createElement("div", { className: "h-full bg-bgGray" },
                    React.createElement("div", { className: "h-full" }, children))),
            React.createElement(RegisteredOnlyPopup, { popupOpen: showRegisteredOnlyPopup, setPopupOpen: setShowRegisteredOnlyPopup, withoutFooter: false }),
            React.createElement(Popup, { popupOpen: showLogoutPopup, setPopupOpen: setShowLogoutPopup, hideCloseButton: true, centerScrollPanel: {
                    header: (React.createElement("h3", { className: "font-bold text-xl text-darkBlue" }, "Wirklich ausloggen?")),
                    footer: (React.createElement("div", { className: "flex flex-col space-y-4" },
                        React.createElement(Button, { priority: "tertiary", onClick: logout }, "Ausloggen"),
                        React.createElement(Button, { priority: "primary", onClick: () => setShowLogoutPopup(false) }, "Zur\u00FCck"))),
                } }))));
};
