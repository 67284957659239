import dayjs from '@mika-bibb/shared/util/dayjs';
export function getAvatarFileName(userId) {
    return `avatar-${userId}.svg`;
}
export function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
}
// use minutes, hours, days, years
export function formatTimePassed(timestamp) {
    const now = dayjs();
    const diffTime = dayjs(timestamp);
    const minutesPassed = Math.max(now.diff(diffTime, 'minute'), 0);
    if (minutesPassed < 60) {
        return `Vor ${minutesPassed} Minuten`;
    }
    const hoursPassed = Math.max(now.diff(diffTime, 'hour'), 0);
    if (hoursPassed < 24) {
        return `Vor ${hoursPassed} Stunden`;
    }
    const daysPassed = Math.max(now.diff(diffTime, 'day'), 0);
    if (daysPassed < 365) {
        return `Vor ${daysPassed} Tagen`;
    }
    const yearsPassed = Math.max(now.diff(diffTime, 'year'), 0);
    return `Vor ${yearsPassed} Jahren`;
}
export function formatTimeLeft(timestamp) {
    const minutesLeft = Math.max(dayjs(timestamp).diff(dayjs(), 'minute'), 0);
    if (minutesLeft >= 100) {
        return `${Math.floor(minutesLeft / 60)}h übrig`;
    }
    return `${minutesLeft} min übrig`;
}
export const overflowEllipsis = 'text-ellipsis overflow-hidden whitespace-nowrap';
