import { ApiError, NewTeamImageUploadError } from '@api/errors';
import { Avatar, DefaultAvatar, } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Input } from '@components/basic-components/input';
import { MAX_TEAMNAME_LENGTH, MIN_TEAMNAME_LENGTH, } from '@mika-bibb/shared/constants';
import { Popup } from '@components/basic-components/popup';
import { ServerErrorCode } from '@mika-bibb/shared/server-error-codes';
import { ViewsType } from '@typesSrc/views';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { useParams } from 'react-router-dom';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useDefaultImageContext from '../context/default-image-context';
import useWatchCallback from '@utils/promise-watch';
export default function TeamsCreateView() {
    const { teamId } = useParams();
    const isNewTeam = !teamId;
    const { state } = useProfile();
    const { nestClient } = useNestClientContext();
    const navigate = useNavigate();
    const [teamName, setTeamName] = useState(undefined);
    const [teamPicture, setTeamPicture] = useState();
    const [teamPictureAsset, setTeamPictureAsset] = useState();
    const defaultTeamPictureAsset = useDefaultImageContext().defaultTeamImage;
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const openPopup = useCallback(() => {
        setDeletePopupOpen(true);
    }, []);
    const closePopup = useCallback(() => {
        setDeletePopupOpen(false);
    }, []);
    useEffect(() => {
        if (!isNewTeam && state.profile) {
            void nestClient.getTeam(teamId).then((team) => {
                setTeamName(team.name);
                setTeamPictureAsset(team.imageUrl);
                if (!team.members.find((member) => {
                    var _a;
                    return member.profile.uuid === ((_a = state.profile) === null || _a === void 0 ? void 0 : _a.uuid) &&
                        member.admin;
                })) {
                    // non admin gets redirected back to the teams page
                    navigate('/teams/' + teamId);
                }
            });
        }
    }, [teamId, state.profile, isNewTeam, nestClient, navigate]);
    useEffect(() => {
        setDisabled(false);
    }, [teamName]);
    const [disabled, setDisabled] = useState(false);
    const onCreate = useWatchCallback(() => {
        return (isNewTeam
            ? nestClient.createTeam((teamName !== null && teamName !== void 0 ? teamName : '').trim(), teamPicture)
            : nestClient.updateTeam(teamId, (teamName !== null && teamName !== void 0 ? teamName : '').trim(), teamPicture)).then((team) => {
            navigate(`/teams/${team.uuid}`);
        }, (e) => {
            if (e instanceof NewTeamImageUploadError) {
                navigate(`/teams/${e.team}/edit`);
            }
            else if (e instanceof ApiError &&
                e.message ===
                    ServerErrorCode.TEAMNAME_TAKEN) {
                setDisabled(true);
            }
        });
    }, [teamName, navigate, teamPicture]);
    const onDelete = useWatchCallback(() => {
        return nestClient.deleteTeam(teamId).then(() => {
            navigate('/teams');
        });
    }, [teamId, navigate]);
    const hiddenFileInput = useRef(null);
    const handleClick = () => {
        var _a;
        (_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement(BaseView, { view: ViewsType.TEAMS, dataTestId: "TeamsView", header: {
            title: isNewTeam ? 'Neues Team' : 'Team bearbeiten',
            showBack: true,
        } },
        React.createElement(CenterScrollPanel, { className: "flex-1", padding: "p-6", footer: React.createElement(React.Fragment, null,
                React.createElement("input", { id: "profile-picture", className: "hidden", type: "file", ref: hiddenFileInput, onChange: (e) => {
                        var _a;
                        setTeamPicture((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]);
                    }, accept: "image/*" }),
                React.createElement(Button, { className: "w-full mb-4", priority: "tertiary", onClick: handleClick }, "Teambild hochladen"),
                !isNewTeam && (React.createElement(Button, { className: "w-full mb-4", onClick: openPopup, priority: "tertiary" }, "Team l\u00F6schen")),
                React.createElement(Button, { onClick: onCreate, className: "w-full", disabled: disabled ||
                        (teamName !== null && teamName !== void 0 ? teamName : '').trim().length <
                            MIN_TEAMNAME_LENGTH ||
                        (teamName !== null && teamName !== void 0 ? teamName : '').trim().length >
                            MAX_TEAMNAME_LENGTH, dataTestId: "team-create-button" }, isNewTeam ? 'Team erstellen' : 'Team speichern')) },
            React.createElement("div", { className: "flex flex-col items-center" },
                React.createElement(Avatar, { className: "w-40 pb-8", preview: teamPicture, image: teamPictureAsset !== null && teamPictureAsset !== void 0 ? teamPictureAsset : defaultTeamPictureAsset, bannerType: BannerType.NONE, defaultAvatar: DefaultAvatar.TEAM, sizes: "160px" }),
                (isNewTeam || teamName !== undefined) && (React.createElement(Input, { minLength: MIN_TEAMNAME_LENGTH, maxLength: MAX_TEAMNAME_LENGTH, placeHolder: "Teamname", className: "w-full", onChange: setTeamName, "data-testid": "team-name", value: teamName, trimSpaces: true })))),
        React.createElement(Popup, { popupOpen: deletePopupOpen, setPopupOpen: setDeletePopupOpen },
            React.createElement("div", { className: "flex flex-col gap-y-1.5" },
                React.createElement("span", { className: "text-xl text-darkBlue font-bold pb-6" }, "Team wirklich l\u00F6schen?"),
                React.createElement(Button, { onClick: onDelete, priority: "tertiary", className: "mb-2.5" }, "Team l\u00F6schen"),
                React.createElement(Button, { onClick: closePopup }, "Zur\u00FCck")))));
}
