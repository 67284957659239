import { Avatar } from '@components/basic-components/avatar/avatar';
import { Link } from 'react-router-dom';
import { SingleCategoryStatistics } from '@components/statistics/single-category-statistics';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import { formatStandingsString } from '@utils/formatting';
import { nestClient } from '../../context/nest-client-context';
import { usePolling } from '../../hooks/usePolling';
import Image from '@components/image';
import React, { useCallback, useState } from 'react';
import dayjs from '@mika-bibb/shared/util/dayjs';
import game from '@public/images/game.png';
import maxBy from 'lodash.maxby';
import minBy from 'lodash.minby';
import sortBy from 'lodash.sortby';
import useCategoriesInfoContext from '../../context/categories-info-context';
function getMinMaxCategories(statisticsInfo) {
    if (!statisticsInfo) {
        return undefined;
    }
    const categories = statisticsInfo.categories;
    const correctness = (category) => {
        return category.numTotalAnswers === 0
            ? 0
            : category.numCorrectAnswers / category.numTotalAnswers;
    };
    return {
        best: maxBy(categories, correctness),
        worst: minBy(categories, correctness),
        mostPicked: maxBy(categories, (category) => {
            return category.numPicked;
        }),
        leastPicked: minBy(categories, (category) => {
            return category.numPicked;
        }),
    };
}
function FavoriteEnemy({ favoriteEnemy }) {
    return (React.createElement("div", { className: "text-darkBlue text-lg font-bold py-4" },
        "Lieblingsgegner/-in:",
        ' ',
        React.createElement(Link, { className: "flex flex-row items-center h-16 text-orange", to: `/profile/${favoriteEnemy.uuid}` },
            React.createElement(Avatar, { image: favoriteEnemy.avatarURL, bannerType: favoriteEnemy.bannerType, sizes: "42px", className: "h-full w-16 mr-4" }),
            favoriteEnemy.nickname)));
}
function MinMaxCategory({ categoryKey, category, categoryImageURL, }) {
    return (React.createElement("div", { className: "h-16 flex flex-row justify-between max-w-[360px] " },
        React.createElement("div", { className: "overflow-hidden" },
            React.createElement("div", { className: "text-darkBlue desktop:text-lg text-md font-bold text-ellipsis overflow-hidden whitespace-nowrap" }, minMaxCategoryNames[categoryKey]),
            React.createElement("div", { className: "text-sm text-orange text-ellipsis overflow-hidden whitespace-nowrap" }, category.name)),
        categoryImageURL ? (React.createElement(Image, { asset: categoryImageURL, alt: "topics", className: "h-full", sizes: "200px" })) : (React.createElement("img", { src: game, alt: "topics", className: "h-full" }))));
}
export function PersonalStatisticsInfo({ challengeHistoryDTO, }) {
    const [statisticsInfo, setStatisticsInfo] = useState();
    const [allTime, setAllTime] = useState(true);
    const categories = useCategoriesInfoContext();
    const minMaxCategories = getMinMaxCategories(statisticsInfo);
    const since = allTime
        ? undefined
        : dayjs().subtract(1, 'week').toISOString();
    const refreshOverview = useCallback(() => {
        void nestClient.getIndividualStatistics(since).then((res) => {
            setStatisticsInfo(res);
        });
    }, [since]);
    usePolling(refreshOverview, 30000, [allTime]);
    if (!statisticsInfo) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TabsToggle, { labelLeft: "Gesamte Zeit", labelRight: "Letzte Woche", leftSelected: allTime, setLeftSelected: setAllTime }),
        React.createElement("div", { className: "pb-8 grid grid-cols-1 desktop:grid-cols-2 gap-8" },
            React.createElement("div", { className: "space-y-4" },
                React.createElement("div", { className: "text-xl font-bold text-darkBlue" }, "Statistiken:"),
                React.createElement("div", null,
                    React.createElement("div", { className: "text-darkBlue text-lg font-bold" }, "Gesamt:"),
                    React.createElement("div", { className: "text-lg text-orange" },
                        formatStandingsString(statisticsInfo.numTotalAnswers, statisticsInfo.numCorrectAnswers),
                        ' ',
                        "richtig"),
                    challengeHistoryDTO && (React.createElement("div", { className: "text-lg text-orange" },
                        challengeHistoryDTO.filter((c) => c.winner === 'me' &&
                            (!since ||
                                new Date(c.validUntil).getTime() >
                                    new Date(since).getTime())).length,
                        ' ',
                        "Quizzes gewonnen"))),
                statisticsInfo.favoriteEnemy && (React.createElement(FavoriteEnemy, { favoriteEnemy: statisticsInfo.favoriteEnemy })),
                minMaxCategories &&
                    Object.entries(minMaxCategories).map(([key, category]) => {
                        const categoryImageURL = categories.categories.get(category.id).imageURL;
                        return (React.createElement(MinMaxCategory, { key: key, categoryKey: key, category: category, categoryImageURL: categoryImageURL }));
                    })),
            React.createElement("div", { className: "space-y-4" },
                React.createElement("div", { className: "text-darkBlue text-xl font-bold " }, "Kategorien:"),
                sortBy(statisticsInfo.categories, (category) => {
                    return (-category.numCorrectAnswers /
                        category.numTotalAnswers);
                }).map((category) => {
                    const categoryImageURL = categories.categories.get(category.id).imageURL;
                    return (React.createElement(SingleCategoryStatistics, { key: category.id, category: category, categoryImageURL: categoryImageURL }));
                })))));
}
const minMaxCategoryNames = {
    best: 'Beste Kategorie',
    worst: 'Da geht noch mehr',
    mostPicked: 'Am meisten gewählt',
    leastPicked: 'Am wenigsten gewählt',
};
