import { isTest } from '../../../environment.global';
import React, { useCallback, useEffect, useRef, useState } from 'react';
export default function QuestionTimer({ totalTimeSeconds, onTimeout, }) {
    const timeOverMessage = 'Zeit abgelaufen!';
    const startTime = useRef(Date.now());
    const text1 = useRef(null);
    const text2 = useRef(null);
    const [timeOver, setTimeOver] = useState(false);
    const animationRef = useRef(0);
    const totalTimeMS = totalTimeSeconds * 1000;
    const setProgressState = (text, widthPercentage) => {
        if (text1.current) {
            text1.current.innerText = text;
        }
        if (text2.current) {
            text2.current.innerText = text;
            text2.current.style.setProperty('--maskWidth', `${widthPercentage}%`);
        }
    };
    const updateProgressBar = useCallback(() => {
        const timePassed = Date.now() - startTime.current;
        const timeLeft = totalTimeMS - timePassed;
        if (timeLeft <= 0) {
            setTimeOver(true);
            setProgressState(timeOverMessage, 0);
            onTimeout();
            return;
        }
        const secondsRounded = Math.ceil(timeLeft / 1000);
        const text = `${secondsRounded}s`;
        const percentage = (timePassed / totalTimeMS) * 100;
        setProgressState(text, 100 - percentage);
        animationRef.current = requestAnimationFrame(updateProgressBar);
    }, [onTimeout, timeOverMessage, totalTimeMS]);
    useEffect(() => {
        if (isTest) {
            return; // to prevent flakiness with the progress bar width, we simply do not animate the progress bar in the test environment at all
        }
        if (timeOver) {
            return;
        }
        updateProgressBar();
        return () => window.cancelAnimationFrame(animationRef.current);
    }, [totalTimeSeconds, timeOver, updateProgressBar]);
    return (React.createElement("div", { className: "relative h-6" },
        React.createElement("div", { ref: text1, className: "absolute inset-0 bg-darkBlue/10 text-orange text-center" }),
        React.createElement("div", { ref: text2, className: "question-time-mask absolute inset-0 bg-orange text-white text-center" })));
}
