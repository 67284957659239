import { AUTHORIZATION_FAILED } from '@utils/constants';
import { TokenHandler } from '@api/token-handler';
import { tokenHandler, useNestClientContext } from './nest-client-context';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import debounce from 'lodash.debounce';
const ProfileStateContext = createContext(undefined);
export const ProfileProvider = ({ children, profile }) => {
    const [actionState, setActionState] = useState(profile
        ? {
            action: 'fetch',
            pending: false,
            fulfilled: true,
            profile: profile,
            initialized: true,
        }
        : {
            action: 'fetch',
            pending: false,
            fulfilled: false,
            initialized: false,
        });
    const { nestClient } = useNestClientContext();
    const resetToggle = useRef(false);
    const wrapApiCall = useCallback(async (call, action, shouldReset = false) => {
        const newActionState = {
            action,
            pending: true,
            fulfilled: false,
            profile: actionState.profile,
            initialized: actionState.initialized,
        };
        setActionState(newActionState);
        try {
            const profile = await call();
            setActionState(Object.assign(Object.assign({}, newActionState), { pending: false, fulfilled: true, profile }));
            resetToggle.current = shouldReset;
        }
        catch (error) {
            setActionState(Object.assign(Object.assign({}, newActionState), { pending: false, fulfilled: false, error: error, initialized: true }));
        }
    }, [actionState.initialized, actionState.profile]);
    const fetchProfile = useCallback(() => {
        void wrapApiCall(async () => {
            if (!(await TokenHandler.hasTokens())) {
                throw new Error(); // reject the promise of a profile immediately
            }
            return nestClient.getProfile();
        }, 'fetch');
    }, [nestClient, wrapApiCall]);
    const updateProfile = useCallback((profile) => {
        return wrapApiCall(() => nestClient.updateProfile(profile), 'update', true);
    }, [nestClient, wrapApiCall]);
    const createGuestProfile = useCallback(() => {
        return wrapApiCall(() => nestClient.createGuestProfile(), 'createGuest', true);
    }, [nestClient, wrapApiCall]);
    const deleteProfile = useCallback(() => {
        if (!actionState.profile) {
            console.warn('No profile to delete');
            return;
        }
        void nestClient
            .deleteProfile(actionState.profile)
            .then(() => tokenHandler.clearTokens())
            .then(() => navigate('/'))
            .catch((e) => {
            console.error('Failed to delete profile', e);
        });
    }, [actionState.profile, nestClient]);
    const login = useCallback((code) => {
        void wrapApiCall(() => nestClient.login(code), 'login', true);
    }, [nestClient, wrapApiCall]);
    const logout = useCallback(() => {
        nestClient
            .logout()
            .catch((e) => {
            console.error('Failed to logout', e);
        })
            .finally(() => {
            void tokenHandler.clearTokens().finally(() => {
                navigate('/');
            });
        });
    }, [nestClient]);
    function reset() {
        setActionState(Object.assign(Object.assign({}, actionState), { fulfilled: true, action: 'fetch', pending: false }));
    }
    function navigate(to) {
        const path = window.location.pathname;
        if (path !== to && path !== `/${to}` && !to.startsWith(`${path}`)) {
            window.location.assign(to);
        }
    }
    const state = useMemo(() => {
        return {
            state: actionState,
            login,
            logout,
            deleteProfile,
            fetchProfile,
            updateProfile,
            createGuestProfile,
        };
    }, [
        actionState,
        createGuestProfile,
        deleteProfile,
        fetchProfile,
        login,
        logout,
        updateProfile,
    ]);
    useEffect(() => {
        if (resetToggle.current) {
            reset();
            resetToggle.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetToggle.current]);
    useEffect(() => {
        const { profile, pending, error } = actionState;
        if (!profile && !pending && !error) {
            fetchProfile();
        }
        function navigateToStart() {
            debounce(() => navigate('/'), 500);
        }
        window.addEventListener(AUTHORIZATION_FAILED, navigateToStart);
        return () => {
            window.removeEventListener(AUTHORIZATION_FAILED, navigateToStart);
        };
    });
    return (React.createElement(ProfileStateContext.Provider, { value: state }, children));
};
export function useProfile() {
    const state = useContext(ProfileStateContext);
    if (!state) {
        throw new Error('useProfile must be used within a ProfileProvider');
    }
    return state;
}
