import { useNestClientContext } from './nest-client-context';
import { useParams } from 'react-router-dom';
import { usePolling } from '../hooks/usePolling';
import React, { useContext, useEffect, useState, } from 'react';
const ChallengeContext = React.createContext(undefined);
/**
 * This context, together with the {@link ChallengeContextParamsUpdate} component,
 * provides challenge-state handling across different views.
 * These two separate parts are needed, so that the state can be stored outside of the router,
 * while the URL params can only be accessed from inside the router.
 *
 * @example
 * <ChallengeContextProvider>
 *   <Router>
 *     <Route>
 *       <ChallengeContextParamsUpdate>
 *         <Component />
 *       </ChallengeContextParamsUpdate>
 *     </Route>
 *   </Router>
 * </ChallengeContextProvider>
 */
export function ChallengeContextProvider({ children }) {
    const [state, setState] = useState({
        _refreshDataCounter: 0,
        refreshChallengeData: () => {
            setState((previousState) => (Object.assign(Object.assign({}, previousState), { _refreshDataCounter: previousState._refreshDataCounter + 1 })));
        },
        setSelectedAnswerIndex: (index) => {
            setState((previousState) => (Object.assign(Object.assign({}, previousState), { selectedAnswerIndex: index })));
        },
        selectedAnswerIndex: -1,
        _setState: (s) => setState(s),
    });
    usePolling(state.refreshChallengeData, 10000, [], true);
    return (React.createElement(ChallengeContext.Provider, { value: state }, children));
}
/**
 * This component should always be within the router, so that the context (outside the router) can be updated.
 */
export function ChallengeContextParamsUpdate(props) {
    const contextState = useContext(ChallengeContext);
    const { nestClient } = useNestClientContext();
    if (!contextState) {
        throw new Error('ChallengeContextParamsUpdate must be used within a ChallengeContextProvider');
    }
    const setState = contextState._setState;
    const { challengeId, roundIndex: rawRoundIndex, questionIndex: rawQuestionIndex, } = useParams();
    const roundIndex = rawRoundIndex ? parseInt(rawRoundIndex, 10) : undefined;
    const questionIndex = rawQuestionIndex
        ? parseInt(rawQuestionIndex, 10)
        : undefined;
    useEffect(() => {
        setState((previousState) => (Object.assign(Object.assign({}, previousState), { challenge: undefined, challengeId })));
        if (!challengeId) {
            return;
        }
        void nestClient.getChallengeState(challengeId).then((challenge) => setState((previousState) => (Object.assign(Object.assign({}, previousState), { challenge }))));
    }, [challengeId, nestClient, setState]);
    useEffect(() => {
        setState((s) => (Object.assign(Object.assign({}, s), { challengeRound: undefined, roundIndex })));
        if (!challengeId || roundIndex === undefined) {
            return;
        }
        void nestClient
            .getChallengeRoundState(challengeId, roundIndex)
            .then((challengeRound) => setState((s) => (Object.assign(Object.assign({}, s), { challengeRound }))));
    }, [roundIndex, nestClient, setState, challengeId]);
    useEffect(() => {
        setState((s) => (Object.assign(Object.assign({}, s), { questionIndex, selectedAnswerIndex: -1 })));
    }, [questionIndex, nestClient, setState]);
    useEffect(() => {
        if (!challengeId || contextState._refreshDataCounter === 0) {
            return;
        }
        const promises = [
            nestClient.getChallengeState(challengeId),
            roundIndex !== undefined &&
                nestClient.getChallengeRoundState(challengeId, roundIndex),
        ].filter((p) => p);
        void Promise.all(promises).then(([challengeState, roundState]) => {
            if (roundState) {
                setState((previousState) => (Object.assign(Object.assign({}, previousState), { challenge: challengeState, challengeRound: roundState })));
            }
            else {
                setState((previousState) => (Object.assign(Object.assign({}, previousState), { challenge: challengeState })));
            }
        });
    }, [
        challengeId,
        contextState._refreshDataCounter,
        nestClient,
        roundIndex,
        setState,
    ]);
    // eslint-disable-next-line no-prototype-builtins
    const isKiosk = nestClient.hasOwnProperty('kiosk');
    useEffect(() => {
        if (!isKiosk && challengeId === '1') {
            window.location.assign('/kiosk');
        }
    }, [challengeId, isKiosk]);
    return React.createElement(React.Fragment, null, props.children);
}
export default function useChallengeContext() {
    const state = useContext(ChallengeContext);
    if (!state) {
        throw new Error('useChallengeContext must be used within a ChallengeContextProvider');
    }
    return state;
}
