import { ApiError } from '@api/errors';
import { Button } from '@components/basic-components/button';
import { MAX_USERNAME_LENGTH, MIN_USERNAME_LENGTH, } from '@mika-bibb/shared/constants';
import { Popup } from '@components/basic-components/popup';
import { ServerErrorCode } from '@mika-bibb/shared/server-error-codes';
import { useNestClientContext } from './nest-client-context';
import { useOfflineContext } from './offline-context';
import React, { createContext, useContext, useEffect, useMemo, useState, } from 'react';
const NETWORK_ERROR = 'Netzwerk-Fehler';
export const errorMessages = {
    [ServerErrorCode.GET_ROUTE_FAILED]: {
        title: NETWORK_ERROR,
        message: 'Ein Netzwerk-Fehler ist aufgetreten.\nEs konnten keine Daten gelesen werden.',
        unDismissable: true,
        refresh: 'Nochmal probieren',
    },
    [ServerErrorCode.DELETE_ROUTE_FAILED]: {
        title: NETWORK_ERROR,
        message: 'Ein Netzwerk-Fehler ist aufgetreten.\nEs konnten keine Daten übertragen werden.',
        unDismissable: true,
        refresh: 'Nochmal probieren',
    },
    [ServerErrorCode.POST_ROUTE_FAILED]: {
        title: NETWORK_ERROR,
        message: 'Ein Netzwerk-Fehler ist aufgetreten.\nEs konnten keine Daten gespeichert werden.',
    },
    [ServerErrorCode.NICKNAME_INVALID_LENGTH]: {
        message: `Dein Name muss zwischen ${MIN_USERNAME_LENGTH} und ${MAX_USERNAME_LENGTH} Zeichen lang sein. \n Suche Dir bitte einen anderen Namen aus.`,
    },
    [ServerErrorCode.NICKNAME_TAKEN]: {
        message: 'Leider ist dieser Name schon vergeben. \n Suche Dir bitte einen anderen Namen aus.',
    },
    [ServerErrorCode.LAST_ADMIN_LEAVE]: {
        title: 'Du bist der einzige Admin',
        message: 'Als einziger Admin kannst Du das Team nicht verlassen.\n Ernenne einen anderen Admin oder lösche das Team.',
    },
    [ServerErrorCode.LAST_ADMIN_DELETE_PROFILE]: {
        title: 'Profil kann nicht gelöscht werden - Du bist der einzige Admin',
        message: 'In manchen deiner Teams bist du der einzige Admin.\n Ernenne einen anderen Admin oder lösche das Team um deinen Account löschen zu können.',
    },
    [ServerErrorCode.TEAMNAME_TAKEN]: {
        message: 'Leider ist dieser Teamname bereits vergeben. \n Suche Dir bitte einen anderen Namen aus.',
    },
    [ServerErrorCode.ANSWER_NOT_POSSIBLE]: {
        message: 'Das Antworten ist nicht möglich. Das Quiz wurde beendet oder es ist nicht Dein Zug.',
        unDismissable: true,
        backTo: {
            text: 'Zurück zur Quizübersicht',
            url: '/challenges',
        },
    },
    [ServerErrorCode.VALIDATION_FAILED]: {
        message: 'Validierung fehlgeschlagen.',
    },
    [ServerErrorCode.UPLOAD_FAILED]: {
        message: 'Der Bildupload ist fehlgeschlagen.',
    },
    [ServerErrorCode.INVALID_CODE]: {
        message: 'Es existiert kein gültiges Quiz mit diesem Code.',
    },
    [ServerErrorCode.SSO_AUTH_FAILED]: {
        message: 'Die Anmeldung konnte nicht durchgeführt werden. \n Bitte versuche es erneut oder wende dich an den Support.',
    },
    [ServerErrorCode.SSO_CONVERSION_FAILED]: {
        title: 'Dein Gastprofil konnte nicht konvertiert werden',
        message: 'Dein Gastprofil kann nicht konvertiert werden, da der Account bereits mit einem anderen Profil verknüpft ist. Versuche es mit einem anderen Account, oder lösche dieses Gastprofil.',
    },
    'GENERIC_SAVE_ERROR': {
        message: 'Ein Fehler ist beim Speichern aufgetreten. Versuche es später noch einmal.',
    },
    'GENERIC_ERROR': {
        message: 'Ein Fehler ist aufgetreten.',
    },
    '400': {
        title: 'Fehler 400',
        message: 'Die Anfrage wurde abgelehnt',
    },
    '403': {
        title: 'Fehler 403',
        message: 'Zugriff verweigert.',
    },
    '403_OUTDATED': {
        title: 'Fehler 403',
        message: 'Zugriff verweigert. Möglichweise ist die App veraltet. Stelle sicher, dass Du die neuste Version installiert hast.',
    },
    '404': {
        title: 'Fehler 404',
        message: 'Die Ressource wurde nicht gefunden',
    },
    '500': {
        title: 'Fehler 500',
        message: 'Es gab ein Problem mit der Verbindung zum Backend. Bitte versuch es später noch einmal',
    },
};
export const ErrorContext = createContext(undefined);
export const ErrorProvider = ({ children }) => {
    const isOffline = useOfflineContext();
    const [errorState, setErrorState] = useState({
        errorMessages: [],
    });
    const { nestClient } = useNestClientContext();
    const addError = (error, withHomeButton = false, undismissable = false) => {
        let message;
        if (error instanceof ApiError) {
            message = (Object.keys(ServerErrorCode).includes(error.message)
                ? error.message
                : `${error.status}`);
        }
        setErrorState((errorState) => {
            var _a;
            const errorMessage = 
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (_a = errorMessages[message]) !== null && _a !== void 0 ? _a : errorMessages['GENERIC_ERROR'];
            return Object.assign(Object.assign({}, errorState), { errorMessages: [
                    ...errorState.errorMessages.filter((errorMessage) => errorMessage.errorKey !== message),
                    Object.assign(Object.assign({ errorKey: message, withHomeButton }, errorMessage), { unDismissable: undismissable || errorMessage.unDismissable }),
                ] });
        });
        console.error(error);
    };
    useEffect(() => {
        nestClient.setErrorHandler(addError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const removeError = (errorKey) => {
        setErrorState((errorState) => {
            return Object.assign(Object.assign({}, errorState), { errorMessages: errorState.errorMessages.filter((errorMessage) => errorMessage.errorKey !== errorKey) });
        });
    };
    const handleClosePopup = (errorKey) => () => {
        removeError(errorKey);
    };
    const handleRefresh = () => {
        window.location.reload();
    };
    const state = useMemo(() => ({
        state: errorState,
        addError,
        removeError,
    }), [errorState]);
    return (React.createElement(ErrorContext.Provider, { value: state },
        children,
        !isOffline && (React.createElement(React.Fragment, null, errorState.errorMessages.map((errorMessage) => {
            var _a, _b, _c, _d;
            return (React.createElement(Popup, { key: errorMessage.errorKey, dataTestId: `${errorMessage.errorKey}`, popupOpen: true, setPopupOpen: errorMessage.unDismissable
                    ? undefined
                    : handleClosePopup(errorMessage.errorKey), withoutFooter: true, mika: "sad", className: "z-20", centerScrollPanel: {
                    header: (_a = errorMessage.title) !== null && _a !== void 0 ? _a : 'Fehler',
                    footer: (React.createElement("div", null,
                        !errorMessage.unDismissable && (React.createElement(Button, { className: "w-full", onClick: handleClosePopup(errorMessage.errorKey) }, "OK")),
                        errorMessage.refresh && (React.createElement(Button, { className: "w-full", onClick: handleRefresh }, errorMessage.refresh)),
                        ((_b = errorMessage.backTo) !== null && _b !== void 0 ? _b : errorMessage.withHomeButton) && (React.createElement(Button, { className: "w-full mt-2", onClick: () => {
                                var _a, _b;
                                return window.location.assign((_b = (_a = errorMessage.backTo) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : '/welcome');
                            } /* no router available at this point */ }, (_d = (_c = errorMessage.backTo) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : 'Zurück zur Startseite')))),
                } }, errorMessage.message));
        })))));
};
export function useErrorContext() {
    const state = useContext(ErrorContext);
    if (!state) {
        throw new Error('useErrorContext must be used within a ErrorProvider');
    }
    return state;
}
