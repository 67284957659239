import { Button } from '@components/basic-components/button';
import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { SingleAnswerStateDto } from '@mika-bibb/shared/challenge-status-dto';
import { ReactComponent as WrongIcon } from '@public/icons/wrong.svg';
import { classNames } from '@utils/class-names';
import Image from '@components/image';
import React from 'react';
export function SingleChoiceAnswer({ originalIndex, selectedAnswerIndex, answerState, selectAnswer, disabled, hasImageAnswers, hasImageQuestion, answer, isTrueFalse = false, }) {
    return (React.createElement(Button, { customStyles: true, className: classNames('w-full px-4 border-2 text-left text-darkBlue', originalIndex === selectedAnswerIndex
            ? 'font-bold border-darkBlue/30'
            : 'border-darkBlue/10', originalIndex === selectedAnswerIndex &&
            answerState === SingleAnswerStateDto.CORRECT &&
            'bg-green/30', originalIndex === selectedAnswerIndex &&
            answerState === SingleAnswerStateDto.WRONG &&
            'bg-red', hasImageQuestion ? 'py-2' : 'py-4'), onClick: () => selectAnswer(originalIndex), disabled: disabled },
        React.createElement("div", { className: classNames('flex flex-col items-start justify-start w-full h-full', isTrueFalse && '!flex-row'), "data-testid": `answer-${originalIndex}` },
            isTrueFalse && answer.text === 'Richtig' && (React.createElement(CheckIcon, { className: "w-6 h-6 mr-4 fill-darkBlue" })),
            isTrueFalse && answer.text === 'Falsch' && (React.createElement(WrongIcon, { className: "w-6 h-6 mr-4 fill-darkBlue" })),
            hasImageAnswers && answer.imageUrl && (React.createElement(Image, { className: classNames('w-full', answer.text && 'mb-2'), asset: answer.imageUrl, sizes: "50vw" })),
            answer.text)));
}
