import { useCallback, useState } from 'react';
function isWatchableCallback(c) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return c && typeof c.call === 'function' && typeof c.running === 'boolean';
}
/**
 * deconstruct a potential watchable callback (or a simple callback) into a simple callback and a boolean indicating whether the callback is currently running.
 */
export function dismantleWatchableCallback(x) {
    if (isWatchableCallback(x)) {
        return [x.call, x.running];
    }
    return [x, false];
}
/**
 * Wrap a promise callback, and additionally return a boolean indicating whether the callback is currently running.
 */
export default function useWatchCallback(c, deps) {
    const [running, setRunning] = useState(false);
    const call = useCallback((arg) => {
        setRunning(true);
        return c(arg).finally(() => setRunning(false));
    }, 
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react-hooks/exhaustive-deps
    [...deps, c]);
    return {
        call,
        running,
        with: (arg) => {
            const base = {
                call: () => call(arg),
                running,
            };
            base.with = () => base;
            return base;
        },
    };
}
