import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeOverviewItem } from '@views/challenges-overview-view';
import { Loading } from '@components/basic-components/loading';
import { PersonalStatisticsInfo } from '@components/statistics/personal-statistics-info';
import { ViewsType } from '@typesSrc/views';
import { useNestClientContext } from '../context/nest-client-context';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import { winnerText } from '@utils/formatting';
import BaseView from '@views/base-view';
import React, { useState } from 'react';
export default function ChallengesHistoryView() {
    const { state: { profile }, } = useProfile();
    const { nestClient } = useNestClientContext();
    const [challengeHistoryDTO, setChallengeHistoryDTO] = useState();
    const refreshOverview = () => {
        void nestClient.getMyChallengeHistory().then((res) => {
            setChallengeHistoryDTO(res);
        });
    };
    usePolling(refreshOverview, 30000);
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGES_OVERVIEW, dataTestId: "ChallengesHistoryView", header: {
            title: 'Quiz-Historie',
            showHelp: true,
            showBack: true,
        }, showFooter: false },
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement(CenterScrollPanel, { className: "flex-1 bg-bgGray overflow-y-hidden", padding: "p-0", greyOnDesktop: false },
                React.createElement("div", { className: "flex flex-col min-h-full p-2 desktop:p-4" },
                    React.createElement(PersonalStatisticsInfo, { challengeHistoryDTO: challengeHistoryDTO }),
                    React.createElement("div", { className: "text-xl font-bold text-darkBlue mb-4" }, "Historie"),
                    !(challengeHistoryDTO === null || challengeHistoryDTO === void 0 ? void 0 : challengeHistoryDTO.length) && (React.createElement("p", { className: "text-darkBlue text-xl text-center font-bold" }, "Keine Quizze")),
                    profile ? (challengeHistoryDTO === null || challengeHistoryDTO === void 0 ? void 0 : challengeHistoryDTO.map((challenge) => (React.createElement(ChallengeOverviewItem, { refreshOverview: refreshOverview, key: challenge.id, profile: profile, challenge: challenge, statusText: winnerText(challenge.winner) })))) : (React.createElement(Loading, { className: "mt-10" })))))));
}
