import { Capacitor } from '@capacitor/core';
import { Environment } from '../environment.global';
import { InAppBrowser, } from '@awesome-cordova-plugins/in-app-browser';
let ref = null;
let eventSubscriptions = [];
/**
 * See also: https://gitlab.sprylab.com/berliner-dom/berliner-dom-app/-/blob/develop/app/src/utils/in-app-browser.ts
 */
export function openInAppBrowser(url) {
    if (Capacitor.getPlatform() !== 'web') {
        ref = InAppBrowser.create(url, '_blank', {
            usewkwebview: 'yes',
            location: 'no',
            toolbar: 'no',
            hidenavigationbuttons: 'yes',
            hardwareback: 'no',
            footer: 'no',
            // closebuttoncaption: 'Zurück',
            // closebuttoncolor: '#F59C00',
        });
        eventSubscriptions.push(...[
            ref.on('loadstart').subscribe(checkDeepLink),
            ref.on('loadstop').subscribe(checkDeepLink),
            ref.on('loaderror').subscribe(checkDeepLink),
            ref.on('exit').subscribe(() => (ref = null)),
        ]);
    }
    else {
        window.open(url, '_self');
    }
}
export function closeInAppBrowser() {
    ref === null || ref === void 0 ? void 0 : ref.close();
    ref = null;
    eventSubscriptions.forEach((s) => s.unsubscribe());
    eventSubscriptions = [];
}
function checkDeepLink(browserEvent) {
    const url = browserEvent.url;
    if (url.startsWith(Environment.PUBLIC_URL)) {
        const event = new Event('appUrlOpen');
        event.url = url;
        window.dispatchEvent(event);
    }
}
