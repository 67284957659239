import { Button } from '@components/basic-components/button';
import { Device } from '@capacitor/device';
import { FOOTER_HEIGHT_IN_PX, HEADER_HEIGHT_IN_PX } from '@utils/constants';
import { MatomoTracker } from '@components/matomo-tracker';
import { Popup } from '@components/basic-components/popup';
import { Role } from '@mika-bibb/shared/enums';
import { Sitebar } from '@components/sidebar/sitebar';
import { classNames } from '@utils/class-names';
import { isProd } from '../environment.global';
import { useConsent } from '../context/consent-context';
import { useNestClientContext } from '../context/nest-client-context';
import { useProfile } from '../context/profile-context';
import Footer from '@components/footer/footer';
import Header from '@components/header/header';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import useSafeSpaceContext from '../context/safe-space-context';
function CookieConsentPopup({ setConsent, }) {
    return (React.createElement(Popup, { popupOpen: true, dataTestId: 'cookie-consent-popup', hideCloseButton: true, withoutFooter: true, className: "z-20", centerScrollPanel: {
            header: (React.createElement("h2", { className: "font-bold text-darkBlue" }, "Zustimmung zu Analyse- und Statistikzwecken dieser Anwendung")),
            footer: (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: "w-full mb-2", priority: "primary", onClick: () => setConsent(true), dataTestId: 'cookie-consent-popup-accept-button' }, "Ich akzeptiere"),
                React.createElement(Button, { className: "w-full ", priority: "decline", onClick: () => setConsent(false), dataTestId: 'cookie-consent-popup-decline-button', dataCy: 'cookie-consent-decline-button' }, "Ich lehne ab"))),
        } },
        React.createElement("p", { className: "leading-5 text-darkBlue text-sm" },
            "Um das Nutzungserlebnis von MIKA-Game zu verbessern, werten wir anonymisierte Nutzungsdaten zu Analyse- und Statistikzwecken mit Matomo aus. ",
            React.createElement("br", null),
            React.createElement("br", null),
            " Die hieraus gewonnenen Daten k\u00F6nnen wir keiner einzelnen Person zuordnen. Wir verwenden die gewonnen Daten ausschlie\u00DFlich zur Verbesserung und Weiterentwicklung des MIKA-Games. Eine Analyse zu statistischen Zwecken erfolgt nur mit Ihrer ausdr\u00FCcklichen Einwilligung.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Die Datenschutzbestimmungen des MIKA-Game kannst Du jederzeit in den Einstellungen unter Datenschutz einsehen. Auch Deine Einwilligung kannst Du jederzeit in den Einstellungen \u00E4ndern oder widerrufen.")));
}
export default function BaseView({ view, showHeader = true, children, enableScrollToTopBehavior = false, showFooter = true, dataTestId = 'BaseView', header = {}, footer = {}, className, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const scrollContainer = useRef(null);
    const insets = useSafeSpaceContext();
    const [blankPage, setBlankPage] = useState(false);
    // Todo: write a cypress test for that https://jira.sprylab.com/browse/MIKA-328
    /* istanbul ignore next */
    const scrollToTop = useCallback(() => {
        if (scrollContainer.current && enableScrollToTopBehavior) {
            scrollContainer.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [scrollContainer, enableScrollToTopBehavior]);
    const { state: { profile }, } = useProfile();
    const { consent, setConsent } = useConsent();
    const { nestClient } = useNestClientContext();
    // eslint-disable-next-line no-prototype-builtins
    const isKiosk = nestClient.hasOwnProperty('kiosk');
    useEffect(() => {
        if (isKiosk) {
            return;
        }
        Device.getInfo()
            .then((deviceInfo) => {
            const runningOnMobilePhone = deviceInfo.operatingSystem === 'ios' ||
                deviceInfo.operatingSystem === 'android';
            const runningInBrowser = deviceInfo.platform === 'web';
            if (runningOnMobilePhone && runningInBrowser && isProd) {
                alert('Es ist ein Problem mit der App aufgetreten. Location: ' +
                    window.location.href);
                setBlankPage(true);
            }
        })
            .catch(console.error);
    }, [isKiosk]);
    if (blankPage) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: classNames('font-base bg-bgGray flex flex-col justify-items-stretch max-h-[100dvh] h-screen w-screen', className), "data-testd": dataTestId, style: {
            '--headerHeightInPx': `${HEADER_HEIGHT_IN_PX}px`,
            '--insetsTop': `${insets.insets.top}px`,
            '--footerHeight': `${FOOTER_HEIGHT_IN_PX}px`,
        } },
        React.createElement(MatomoTracker, { title: typeof header.title === 'string' ? header.title : undefined }),
        React.createElement(Sitebar, Object.assign({}, footer, { isGuest: ((_a = profile === null || profile === void 0 ? void 0 : profile.role) !== null && _a !== void 0 ? _a : Role.GUEST) === Role.GUEST, activeItem: (_b = footer.activeItem) !== null && _b !== void 0 ? _b : view, onBackToTopClicked: (_c = footer.onBackToTopClicked) !== null && _c !== void 0 ? _c : scrollToTop, showSidebar: showFooter }),
            React.createElement("div", { className: classNames('flex flex-col flex-1 overflow-y-auto h-full force-scrollbar'), "data-cy": "main-scroll-container", ref: scrollContainer },
                React.createElement("div", { className: classNames('sticky top-0 flex-[0_0_var(--insetsTop)]', ((_d = header.isSubpage) !== null && _d !== void 0 ? _d : header.showBack)
                        ? 'bg-bgGray'
                        : 'bg-white') }),
                showHeader && React.createElement(Header, Object.assign({}, header)),
                children)),
        showFooter && (React.createElement(Footer, Object.assign({}, footer, { isGuest: ((_e = profile === null || profile === void 0 ? void 0 : profile.role) !== null && _e !== void 0 ? _e : Role.GUEST) === Role.GUEST, activeItem: (_f = footer.activeItem) !== null && _f !== void 0 ? _f : view, onBackToTopClicked: (_g = footer.onBackToTopClicked) !== null && _g !== void 0 ? _g : scrollToTop }))),
        consent === undefined && (React.createElement(CookieConsentPopup, { setConsent: setConsent }))));
}
