import { Button } from '@components/basic-components/button';
import { ChallengeHelpPopup } from '@components/challenge/challenge-help-popup';
import { classNames } from '@utils/class-names';
import BackButton from '@components/basic-components/back-button';
import React, { useCallback } from 'react';
function Header({ title = '', showBack = false, isSubpage = showBack, onBack, customHeaderAction, backLevels, sticky, forceBackgroundColor, showHelp, }) {
    const backButtonProps = {
        className: 'relative flex justify-center items-center h-10 w-10 icon-arrow-left text-lg text-darkBlue',
        dataTestId: 'header-back-button',
        dataCy: 'header-back-button',
        customStyles: true,
    };
    const [helpPopupOpen, setHelpPopupOpen] = React.useState(false);
    const openHelpPopup = useCallback(() => setHelpPopupOpen(true), []);
    let backgroundColor = isSubpage ? 'bg-bgGray' : 'bg-white';
    if (forceBackgroundColor) {
        backgroundColor = forceBackgroundColor;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: classNames('grow-0 shrink-0 desktop:min-h-[84px] desktop:border-darkBlue/10 desktop:border-l desktop:border-b-0 desktop:py-2', sticky && 'sticky top-[var(--insetsTop)]', backgroundColor) },
            React.createElement("div", { className: classNames(`grid gap-x-4 items-center w-full z-20`, `h-[var(--headerHeightInPx)] desktop:h-full`, isSubpage
                    ? 'grid-cols-[1fr_auto_1fr]'
                    : 'grid-cols-[auto_1fr] px-6') },
                showBack &&
                    (onBack ? (React.createElement(Button, Object.assign({}, backButtonProps, { onClick: onBack, customStyles: true }))) : (React.createElement(BackButton, Object.assign({ levels: backLevels }, backButtonProps)))),
                isSubpage && !showBack && React.createElement("div", null),
                React.createElement("div", { className: classNames('font-condensed text-darkBlue leading-8 font-bold tracking-widest', 'text-ellipsis overflow-hidden whitespace-nowrap', isSubpage ? 'text-base' : 'text-xl'), "data-testid": "header-title" }, title),
                React.createElement("div", { className: classNames('justify-self-end', isSubpage && 'pr-4') }, showHelp ? (React.createElement("div", { className: "flex space-x-4" },
                    React.createElement("button", { onClick: openHelpPopup, className: "text-2xl text-green icon-help" }),
                    customHeaderAction)) : (customHeaderAction))),
            React.createElement(ChallengeHelpPopup, { popupOpen: helpPopupOpen, setPopupOpen: setHelpPopupOpen, withFooter: true }))));
}
export default Header;
