export const isProd = process.env.DEPLOYMENT_ENVIRONMENT === 'prod';
export const isDev = process.env.DEPLOYMENT_ENVIRONMENT === 'dev';
export const isTest = process.env.NODE_ENV === 'test';
export const isDebug = !!process.env.DEBUG;
export const isCI = !!process.env.CI;
export function definedOrThrow(variableName, value) {
    /* istanbul ignore next */
    if (!value) {
        throw new Error(`Missing environment variable process.env.${variableName}, you need to provide it for the application to run.`);
    }
    return value;
}
export function definedOrThrowUnlessTest(variableName, value) {
    if (isTest) {
        return value;
    }
    return definedOrThrow(variableName, value);
}
// We need to access the environment variables statically with process.env.__NAME__
// since the value is replaced at build-time and needs to appear in the script verbatim.
// See https://create-react-app.dev/docs/adding-custom-environment-variables/.
export const Environment = {
    PUBLIC_URL: definedOrThrow('REACT_APP_PUBLIC_URL', process.env.REACT_APP_PUBLIC_URL),
    NEST_API_URL: definedOrThrow('REACT_APP_NEST_API_URL', process.env.REACT_APP_NEST_API_URL),
    FIREBASE_API_KEY: definedOrThrowUnlessTest('REACT_APP_FIREBASE_API_KEY', process.env.REACT_APP_FIREBASE_API_KEY),
    FIREBASE_AUTH_DOMAIN: definedOrThrowUnlessTest('REACT_APP_FIREBASE_AUTH_DOMAIN', process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
    FIREBASE_PROJECT_ID: definedOrThrowUnlessTest('REACT_APP_FIREBASE_PROJECT_ID', process.env.REACT_APP_FIREBASE_PROJECT_ID),
    FIREBASE_STORAGE_BUCKET: definedOrThrowUnlessTest('REACT_APP_FIREBASE_STORAGE_BUCKET', process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    FIREBASE_MESSAGING_SENDER_ID: definedOrThrowUnlessTest('REACT_APP_FIREBASE_MESSAGING_SENDER_ID', process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID),
    FIREBASE_APP_ID: definedOrThrowUnlessTest('REACT_APP_FIREBASE_APP_ID', process.env.REACT_APP_FIREBASE_APP_ID),
    FIREBASE_MEASUREMENT_ID: definedOrThrowUnlessTest('REACT_APP_FIREBASE_MEASUREMENT_ID', process.env.REACT_APP_FIREBASE_MEASUREMENT_ID),
};
