import { ANSWERS_PER_QUESTION } from '@mika-bibb/shared/constants';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Input } from '@components/basic-components/input';
import { Loading } from '@components/basic-components/loading';
import { QuestionState } from '@mika-bibb/shared/enums';
import { SectionTitle } from '@components/basic-components/section-title';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { nestClient } from '../context/nest-client-context';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import BaseView from '@views/base-view';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useCategoriesInfoContext from '../context/categories-info-context';
export default function CustomQuestionDetailView() {
    const checkComplete = (questionToCheck) => {
        if (!questionToCheck) {
            return false;
        }
        if (!questionToCheck.text || !questionToCheck.explanation) {
            return false;
        }
        return questionToCheck.answers.every((answer) => answer.text);
    };
    const { id } = useParams();
    const [question, setQuestion] = useState();
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [isComplete, setIsComplete] = useState(checkComplete(question));
    const navigate = useNavigate();
    const { categories } = useCategoriesInfoContext();
    const handleIncompleteChanges = useCallback((potentialIncompleteQuestion) => {
        const questionIsComplete = checkComplete(potentialIncompleteQuestion);
        setIsComplete(questionIsComplete);
        if (!questionIsComplete) {
            potentialIncompleteQuestion.state = QuestionState.DRAFT;
        }
    }, []);
    useEffect(() => {
        const queryQuestion = async () => {
            setQuestion(id ? await nestClient.getCustomQuestion(id) : undefined);
        };
        void queryQuestion();
    }, [id]);
    const onChangeQuestion = ({ text, explanation, state, }) => {
        if (question) {
            const updatedQuestion = Object.assign({}, question);
            if (text !== undefined) {
                updatedQuestion.text = text;
            }
            if (explanation !== undefined) {
                updatedQuestion.explanation = explanation;
            }
            if (state !== undefined) {
                updatedQuestion.state = state;
            }
            handleIncompleteChanges(updatedQuestion);
            setQuestion(updatedQuestion);
        }
    };
    const onChangeAnswer = useCallback(({ text }, index) => {
        if (question === null || question === void 0 ? void 0 : question.answers[index]) {
            const answers = [...question.answers];
            answers[index] = Object.assign(Object.assign({}, answers[index]), { text });
            question.answers = answers;
            const updatedQuestion = Object.assign({}, question);
            handleIncompleteChanges(updatedQuestion);
            setQuestion(updatedQuestion);
        }
    }, [handleIncompleteChanges, question]);
    const updateQuestion = async () => {
        if (question) {
            setUpdateInProgress(true);
            await nestClient.updateQuestion(question);
            setUpdateInProgress(false);
            navigate('/myQuestions');
        }
    };
    const deleteQuestion = async () => {
        if (question) {
            setUpdateInProgress(true);
            await nestClient.deleteQuestion(question);
            setUpdateInProgress(false);
            navigate('/myQuestions');
        }
    };
    const answers = useMemo(() => {
        var _a;
        const inputs = [];
        if (question) {
            for (let i = 0; i < ANSWERS_PER_QUESTION; i++) {
                inputs.push(React.createElement(Input, { className: classNames('mb-4 w-full', i === 0 ? 'bg-green/30' : 'bg-red'), value: (_a = question.answers[i]) === null || _a === void 0 ? void 0 : _a.text, placeHolder: i === 0
                        ? 'Richtige Antwort'
                        : `Falsche Antwort ${i}`, onChange: (text) => onChangeAnswer({ text }, i), dataTestId: i === 0
                        ? 'input-correct-answer'
                        : `input-incorrect-answer-${i}` }));
            }
        }
        return inputs;
    }, [onChangeAnswer, question]);
    return (React.createElement(BaseView, { view: ViewsType.CUSTOM_QUESTIONS, dataTestId: "CustomQuestionDetailView", header: {
            title: 'Meine Frage bearbeiten',
            showBack: true,
        } }, question ? (React.createElement(CenterScrollPanel, { padding: "p-0", className: "desktop:m-4 h-full grow" },
        React.createElement("div", { className: "flex justify-center bg-white p-4" },
            React.createElement("div", { className: "w-full" },
                React.createElement(SectionTitle, { className: "px-4 my-4" },
                    "Kategorie:",
                    ' ',
                    categories.get(question.categoryId).text),
                React.createElement(Input, { className: "mb-4 w-full", value: question.text === 'Deine Frage'
                        ? ''
                        : question.text, placeHolder: "Deine Frage", onChange: (text) => onChangeQuestion({ text }), dataTestId: "input-question-text" }),
                ...answers,
                React.createElement(Input, { type: "text-area", className: "mb-4 w-full mt-8", value: question.explanation === 'Erläuterung'
                        ? ''
                        : question.explanation, placeHolder: "Erl\u00E4uterung", onChange: (explanation) => onChangeQuestion({ explanation }), dataTestId: "input-explanation" }),
                React.createElement("div", { className: "flex w-full justify-center space-x-4" },
                    React.createElement(TabsToggle, { labelLeft: 'Entwurf', labelRight: 'Veröffentlicht', leftSelected: question.state === QuestionState.DRAFT, className: "w-full", setLeftSelected: (isDraft) => {
                            if (isDraft) {
                                onChangeQuestion({
                                    state: QuestionState.DRAFT,
                                });
                            }
                            else {
                                onChangeQuestion({
                                    state: QuestionState.ACTIVE,
                                });
                            }
                        }, disabled: !isComplete })),
                React.createElement("div", { className: "flex w-full justify-between" },
                    React.createElement(Button, { disabled: updateInProgress, priority: "delete", onClick: () => void deleteQuestion(), dataTestId: "delete-question-button" }, "L\u00F6schen"),
                    React.createElement(Button, { onClick: () => void updateQuestion(), dataTestId: "save-question-button" }, "Speichern")))))) : (React.createElement(Loading, null))));
}
