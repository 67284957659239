import { FooterItemTypeConfiguration } from '@components/footer/footer-item';
import { Link } from 'react-router-dom';
import { classNames } from '@utils/class-names';
import React, { useCallback } from 'react';
export function SitebarItem({ item, isActive, onRedirect, onBackToTopClicked, featured, className, }) {
    const onClick = useCallback(
    // Todo: write a cypress test for that https://jira.sprylab.com/browse/MIKA-328
    /* istanbul ignore next */
    (event) => {
        if (isActive && onBackToTopClicked) {
            onBackToTopClicked();
            event.preventDefault();
        }
        else if (onRedirect) {
            onRedirect();
        }
    }, [isActive, onBackToTopClicked, onRedirect]);
    const config = FooterItemTypeConfiguration[item];
    return (React.createElement(Link, { className: classNames('relative flex flex-row items-center my-4 ml-16', className), to: config.path, onClick: onClick },
        React.createElement(config.icon, { className: `h-6 w-auto mr-4 justify-self-center self-center ${isActive ? 'fill-green' : 'fill-darkBlue/70'}` }),
        React.createElement("div", { className: `text-darkBlue ${isActive ? 'font-bold' : ''}` }, config.label),
        featured && (React.createElement("div", { className: "absolute rounded-full bg-green/10 w-28 h-10 p-1 top-1/2 -translate-y-1/2 -translate-x-3" },
            React.createElement("div", { className: "rounded-full bg-green/10 w-full h-full" })))));
}
