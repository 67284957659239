import { ReactComponent as Points } from '@public/icons/points.svg';
import React from 'react';
export default function DesktopHomeChallengeDisplay({ score, }) {
    return (React.createElement("div", { className: "hidden text-darkBlue desktop:flex flex-row items-center justify-center px-4 col-span-4 bg-white text-xl min-w-[190px] min-h-[84px]" },
        React.createElement(Points, { className: "w-8 h-8 mr-2 fill-green" }),
        React.createElement("p", null, score !== null && score !== void 0 ? score : '--',
            " "),
        React.createElement("p", { className: "text-xl font-bold" },
            React.createElement("b", { className: "ml-1" }, "Punkte"))));
}
