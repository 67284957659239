import { Avatar, DefaultAvatar, } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Link } from 'react-router-dom';
import { ViewsType } from '@typesSrc/views';
import { useNestClientContext } from '../context/nest-client-context';
import { usePolling } from '../hooks/usePolling';
import BaseView from '@views/base-view';
import React, { useState } from 'react';
export default function TeamsView() {
    const { nestClient } = useNestClientContext();
    const [teamOverviewDTO, setTeamOverviewDTO] = useState();
    usePolling(() => {
        void nestClient.getMyTeams().then((res) => {
            setTeamOverviewDTO(res);
        });
    }, 20000);
    return (React.createElement(BaseView, { view: ViewsType.TEAMS, dataTestId: "TeamsView", header: {
            title: 'Teams',
            showHelp: true,
        } },
        React.createElement(CenterScrollPanel, { className: "flex-1", padding: "p-4 desktop:p-6", footer: React.createElement(Button, { to: '/teams/create', className: "w-full desktop:max-w-[20rem] desktop:mx-auto" }, "Team erstellen") },
            React.createElement("div", { className: "flex flex-col" },
                (teamOverviewDTO === null || teamOverviewDTO === void 0 ? void 0 : teamOverviewDTO.length) === 0 && (React.createElement("span", { className: "text-darkBlue text-xl text-center font-bold" }, "Keine Teams")), teamOverviewDTO === null || teamOverviewDTO === void 0 ? void 0 :
                teamOverviewDTO.map((team) => {
                    return (React.createElement(Link, { key: team.uuid, to: `/teams/${team.uuid}`, className: "block border-b border-solid border-darkBlue/10" },
                        React.createElement("div", { className: "grid grid-cols-[42px_1fr_auto] gap-x-2 items-center py-2" },
                            React.createElement(Avatar, { sizes: "42px", image: team.imageUrl, bannerType: BannerType.NONE, defaultAvatar: DefaultAvatar.TEAM }),
                            React.createElement("div", { className: "flex-1 flex flex-col" },
                                React.createElement("span", { className: "font-bold text-darkBlue" }, team.name),
                                React.createElement("span", { className: "text-sm text-orange" },
                                    team.numMembers,
                                    ' ',
                                    team.numMembers === 1
                                        ? 'Mitglied'
                                        : 'Mitglieder')))));
                }),
                React.createElement("p", { className: "text-darkBlue/50 text-sm pt-2" }, "Administratoren k\u00F6nnen alle Deine Antworten innerhalb eines Teams, sowie Statistiken zu Deinem Lernfortschritt im Team einsehen.")))));
}
