import { ReactComponent as Eye1a } from '@public/images/avatar-layers/eye-shapes/eyeshape_1a.svg';
import { ReactComponent as Eye1b } from '@public/images/avatar-layers/eye-shapes/eyeshape_1b.svg';
import { ReactComponent as Eye1c } from '@public/images/avatar-layers/eye-shapes/eyeshape_1c.svg';
import { ReactComponent as Eye2a } from '@public/images/avatar-layers/eye-shapes/eyeshape_2a.svg';
import { ReactComponent as Eye2b } from '@public/images/avatar-layers/eye-shapes/eyeshape_2b.svg';
import { ReactComponent as Eye2c } from '@public/images/avatar-layers/eye-shapes/eyeshape_2c.svg';
import { ReactComponent as Eye3a } from '@public/images/avatar-layers/eye-shapes/eyeshape_3a.svg';
import { ReactComponent as Eye3b } from '@public/images/avatar-layers/eye-shapes/eyeshape_3b.svg';
import { ReactComponent as Eye3c } from '@public/images/avatar-layers/eye-shapes/eyeshape_3c.svg';
import { ReactComponent as Eye4a } from '@public/images/avatar-layers/eye-shapes/eyeshape_4a.svg';
import { ReactComponent as Eye4b } from '@public/images/avatar-layers/eye-shapes/eyeshape_4b.svg';
import { ReactComponent as Eye4c } from '@public/images/avatar-layers/eye-shapes/eyeshape_4c.svg';
import { ReactComponent as Eye5a } from '@public/images/avatar-layers/eye-shapes/eyeshape_5a.svg';
import { ReactComponent as Eye5b } from '@public/images/avatar-layers/eye-shapes/eyeshape_5b.svg';
import { ReactComponent as Eye5c } from '@public/images/avatar-layers/eye-shapes/eyeshape_5c.svg';
import { ReactComponent as Eye6a } from '@public/images/avatar-layers/eye-shapes/eyeshape_6a.svg';
import { ReactComponent as Eye6b } from '@public/images/avatar-layers/eye-shapes/eyeshape_6b.svg';
import { ReactComponent as Eye6c } from '@public/images/avatar-layers/eye-shapes/eyeshape_6c.svg';
import { ReactComponent as Eye7a } from '@public/images/avatar-layers/eye-shapes/eyeshape_7a.svg';
import { ReactComponent as Eye7b } from '@public/images/avatar-layers/eye-shapes/eyeshape_7b.svg';
import { ReactComponent as Eye7c } from '@public/images/avatar-layers/eye-shapes/eyeshape_7c.svg';
import { ReactComponent as Eye8a } from '@public/images/avatar-layers/eye-shapes/eyeshape_8a.svg';
import { ReactComponent as Eye8b } from '@public/images/avatar-layers/eye-shapes/eyeshape_8b.svg';
import { ReactComponent as Eye8c } from '@public/images/avatar-layers/eye-shapes/eyeshape_8c.svg';
import React from 'react';
export const eyeColorsOrdered = ['#7192BC', '#654023', '#9DA17B'];
export const eyeShapesOrdered = [
    'Eye1',
    'Eye2',
    'Eye3',
    'Eye4',
    'Eye5',
    'Eye6',
    'Eye7',
    'Eye8',
];
export const eyeConfig = {
    '#7192BC': {
        Eye1: React.createElement(Eye1a, null),
        Eye2: React.createElement(Eye2a, null),
        Eye3: React.createElement(Eye3a, null),
        Eye4: React.createElement(Eye4a, null),
        Eye5: React.createElement(Eye5a, null),
        Eye6: React.createElement(Eye6a, null),
        Eye7: React.createElement(Eye7a, null),
        Eye8: React.createElement(Eye8a, null),
    },
    '#654023': {
        Eye1: React.createElement(Eye1b, null),
        Eye2: React.createElement(Eye2b, null),
        Eye3: React.createElement(Eye3b, null),
        Eye4: React.createElement(Eye4b, null),
        Eye5: React.createElement(Eye5b, null),
        Eye6: React.createElement(Eye6b, null),
        Eye7: React.createElement(Eye7b, null),
        Eye8: React.createElement(Eye8b, null),
    },
    '#9DA17B': {
        Eye1: React.createElement(Eye1c, null),
        Eye2: React.createElement(Eye2c, null),
        Eye3: React.createElement(Eye3c, null),
        Eye4: React.createElement(Eye4c, null),
        Eye5: React.createElement(Eye5c, null),
        Eye6: React.createElement(Eye6c, null),
        Eye7: React.createElement(Eye7c, null),
        Eye8: React.createElement(Eye8c, null),
    },
};
