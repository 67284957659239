export const MAX_ROUNDS_PER_CHALLENGE = 6;
export const DEFAULT_QUESTIONS_PER_ROUND = 10;
export const ANSWERS_PER_QUESTION = 4;
export const SCORE_FOR_WINNING_1V1_GAME_PER_QUESTION = 0.25;
export const SCORE_FOR_DRAWING_1V1_GAME_PER_QUESTION = 1 / 6; // when both players have exactly the same score
export const FAR_IN_THE_FUTURE = new Date('3000-01-31T23:00:00.000Z');
export const BACK_THEN = new Date('2000-01-31T23:00:00.000Z');
const ONE_MINUTE = 1000 * 60;
export const ONE_DAY = ONE_MINUTE * 60 * 24;
export const VALID_TIME_FOR_1V1_GAME = ONE_MINUTE * 60;
export const VALID_TIME_FOR_CLASSROOM_GAME = ONE_MINUTE * 90;
export const VALID_TIME_FOR_TEAM_GAME = ONE_DAY;
export const MIN_USERNAME_LENGTH = 3;
export const MAX_USERNAME_LENGTH = 20;
export const MIN_TEAMNAME_LENGTH = 3;
export const MAX_TEAMNAME_LENGTH = 20;
export const MAX_STATUS_MESSAGE_LENGTH = 255;
export const MAX_JOB_TITLE_LENGTH = 255;
export const MIN_CHALLENGE_NAME_LENGTH = 3;
export const MAX_CHALLENGE_NAME_LENGTH = 20;
export const TOP_RESULTS_VISIBLE_IN_RANKINGS = 5;
export const SURROUNDING_RESULTS_VISIBLE_IN_RANKINGS = 2;
export const TEAM_MIN_ANSWERS_FOR_RANKING = 10;
export const MIN_TEAM_MEMBERS_TO_START_CHALLENGE = 2;
/**
 * Name of the access token request header field.
 */
export const ACCESS_TOKEN_HEADER_NAME = 'x-access-token';
/**
 * Name of the refresh token request header field.
 */
export const REFRESH_TOKEN_HEADER_NAME = 'x-refresh-token';
/**
 * Name of the response header field used to expose a renewed access token.
 */
export const SET_ACCESS_TOKEN_HEADER_NAME = 'x-set-access-token';
/**
 * Name of the response header field used to expose a renewed refresh token.
 */
export const SET_REFRESH_TOKEN_HEADER_NAME = 'x-set-refresh-token';
export const IMAGE_QUAILTY = 75;
export const IMAGE_SIZES = [
    16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048,
    3840,
];
export const DEFAULT_CHALLENGE_SETTINGS = {
    questionsPerRound: DEFAULT_QUESTIONS_PER_ROUND,
    useCustomQuestions: false,
    categories: undefined,
    slowSpeed: false,
};
export const FILL_IN_THE_BLANK_SEPARATOR = '___';
