import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { FILL_IN_THE_BLANK_SEPARATOR } from '@mika-bibb/shared/constants';
import { Loading } from '@components/basic-components/loading';
import { OrderingAnswersPanel } from '@components/challenge/question/ordering-answers-panel';
import { ParticipantsCount } from '@components/challenge/participants-count';
import { QuestionType } from '@mika-bibb/shared/enums';
import { SingleAnswerStateDto } from '@mika-bibb/shared/challenge-status-dto';
import { SingleChoiceAnswersPanel } from '@components/challenge/question/single-choice-answers-panel';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { useNestClientContext } from '../context/nest-client-context';
import BaseView from '@views/base-view';
import Image from '@components/image';
import QuestionProgressBar from '@components/challenge/question/question-progress-bar';
import QuestionTimer from '@components/challenge/question/question-timer';
import QuestionViewExplanationPopup from '@components/question/question-view-explanation-popup';
import QuestionViewRoundscorePopup from '@components/question/question-view-roundscore-popup';
import React, { useEffect, useState } from 'react';
import useCategoriesInfoContext from '../context/categories-info-context';
import useChallengeContext from '../context/challenge-context';
function getAnswerPanel(type) {
    switch (type) {
        case QuestionType.ORDERING:
            return OrderingAnswersPanel;
        case QuestionType.SINGLE_CHOICE:
        default:
            return SingleChoiceAnswersPanel;
    }
}
function QuestionText({ question, answerState, }) {
    const { selectedAnswerIndex } = useChallengeContext();
    const text = question.text;
    if (question.type === QuestionType.FILL_IN_THE_BLANK) {
        const [part1, part2] = text.split(FILL_IN_THE_BLANK_SEPARATOR);
        const isAnswered = answerState && answerState !== SingleAnswerStateDto.NOT_ANSWERED;
        return (React.createElement("div", { className: "text-darkBlue text-xl font-bold mt-4", "data-testid": "question-text" },
            part1,
            React.createElement("div", { className: classNames('border-0 border-b-2 border-darkBlue w-24 p-1 min-w-max text-center inline-block', answerState === SingleAnswerStateDto.CORRECT &&
                    'bg-green/30', selectedAnswerIndex > 0 &&
                    answerState === SingleAnswerStateDto.WRONG &&
                    'bg-red') }, selectedAnswerIndex < 0
                ? isAnswered
                    ? question.answers[0].text
                    : '\xa0' // non-breaking space
                : question.answers[selectedAnswerIndex].text),
            part2));
    }
    return (React.createElement("div", { className: "text-darkBlue text-xl font-bold mt-4", "data-testid": "question-text" }, question.text));
}
export default function QuestionView() {
    const { challenge, challengeRound, roundIndex, questionIndex } = useChallengeContext();
    const { categories } = useCategoriesInfoContext();
    const { nestClient } = useNestClientContext();
    const [explanationPopupOpen, setExplanationPopupOpen] = useState(false);
    const [roundScorePopupOpen, setRoundScorePopupOpen] = useState(false);
    const [question, setQuestion] = useState();
    const [timeoutFunction, setTimeoutFunction] = useState(() => { });
    const [finishFunction, setFinishFunction] = useState();
    const [questionFinished, setQuestionFinished] = useState(true);
    const answerState = challenge && roundIndex !== undefined && questionIndex !== undefined
        ? challenge.me.answersPerRound[roundIndex].answers[questionIndex]
        : undefined;
    const currentRound = roundIndex != null ? challenge === null || challenge === void 0 ? void 0 : challenge.rounds[roundIndex] : undefined;
    useEffect(() => {
        if (!currentRound || questionIndex === undefined || question) {
            return;
        }
        void nestClient
            .getRoundQuestion(currentRound.id, questionIndex)
            .then(setQuestion);
    }, [currentRound, questionIndex, nestClient, question]);
    useEffect(() => {
        if (!explanationPopupOpen &&
            answerState &&
            answerState !== SingleAnswerStateDto.NOT_ANSWERED) {
            if ((question === null || question === void 0 ? void 0 : question.type) === QuestionType.ORDERING) {
                setTimeout(() => {
                    setExplanationPopupOpen(true);
                }, 1000);
            }
            else {
                setExplanationPopupOpen(true);
            }
        }
    }, [answerState, explanationPopupOpen, question]);
    if (roundIndex === undefined || questionIndex === undefined) {
        // TODO: use error context? https://jira.sprylab.com/browse/MIKA-326
        return React.createElement("div", { className: "text-error" }, "Invalid URL!");
    }
    // TODO: return <Loading /> within BaseView https://jira.sprylab.com/browse/MIKA-327
    if (!challenge || !challengeRound || !currentRound || !question) {
        return React.createElement(Loading, { fullSize: true });
    }
    const AnswerPanelComponent = getAnswerPanel(question.type);
    const timePerQuestion = challenge.usesSlowSpeed ? 40 : 20;
    return (React.createElement(BaseView, { view: ViewsType.QUESTION, showFooter: false, dataTestId: "QuestionView", header: {
            title: (React.createElement(React.Fragment, null,
                categories.get(currentRound.categoryId).text,
                React.createElement(QuestionProgressBar, { value: questionIndex + 1, maxValue: currentRound.questionIds.length + 1 }))),
            showBack: false,
            isSubpage: true,
            customHeaderAction: React.createElement(ParticipantsCount, null),
        } },
        React.createElement("div", { className: "flex flex-col flex-1 px-2" },
            React.createElement(CenterScrollPanel, { className: "flex-1 mt-2", rounded: "top", header: React.createElement(React.Fragment, null,
                    question.imageUrl && (React.createElement(Image, { asset: question.imageUrl, className: "w-full h-40 desktop:h-48 object-contain rounded-sm pb-2 desktop:pb-8", sizes: "100vw" })),
                    React.createElement("div", { className: "text-green mb-2 text-sm" }, `Frage ${questionIndex + 1} von ${currentRound.questionIds.length}`),
                    question.creatorName && (React.createElement("div", { className: "text-orange font-bold" },
                        "Eigene Frage von ",
                        question.creatorName)),
                    React.createElement(QuestionText, { question: question, answerState: answerState })), footer: React.createElement("div", { className: "mb-6 flex flex-col" },
                    !questionFinished && finishFunction && (React.createElement(Button, { small: true, onClick: finishFunction, className: "self-end mb-6", dataTestId: "QuestionViewFinishButton" },
                        React.createElement(CheckIcon, { className: "w-6 h-6 mr-4 fill-white" }),
                        "Fertig")),
                    !questionFinished && (React.createElement(QuestionTimer, { totalTimeSeconds: challengeRound.nextQuestion ===
                            questionIndex
                            ? timePerQuestion
                            : 0, onTimeout: () => challengeRound.nextQuestion ===
                            questionIndex && timeoutFunction() }))), greyOnDesktop: false },
                React.createElement(AnswerPanelComponent, { question: question, setTimeoutFunction: setTimeoutFunction, setFinishFunction: setFinishFunction, setQuestionFinished: setQuestionFinished }))),
        React.createElement(QuestionViewExplanationPopup, { explanationPopupOpen: explanationPopupOpen, answerState: answerState, challengeRound: challengeRound, question: question, setRoundScorePopupOpen: setRoundScorePopupOpen, setExplanationPopupOpen: setExplanationPopupOpen }),
        React.createElement(QuestionViewRoundscorePopup, { roundScorePopupOpen: roundScorePopupOpen, challengeRound: challengeRound, challenge: challenge })));
}
