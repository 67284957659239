import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { QuestionState } from '@mika-bibb/shared/enums';
import { Select } from '@components/basic-components/Select';
import { ViewsType } from '@typesSrc/views';
import { nestClient } from '../context/nest-client-context';
import BaseView from '@views/base-view';
import Checkbox from '@components/forms/checkbox';
import CustomQuestionsList from '@components/customQuestions/custom-questions-list';
import React, { useEffect, useMemo, useState } from 'react';
import useCategoriesInfoContext from '../context/categories-info-context';
const allCategory = {
    id: 'all',
    sortId: -1,
    text: 'Alle Kategorien',
    imageURL: undefined,
};
export default function CustomQuestionsOverview() {
    const [customQuestions, setCustomQuestions] = useState([]);
    const { categories } = useCategoriesInfoContext();
    const [currentCategory, setCurrentCategory] = useState(allCategory);
    const [draftOnly, setDraftOnly] = useState(false);
    useEffect(() => {
        void nestClient.getCustomQuestions().then(setCustomQuestions);
    }, []);
    const categoryDtos = useMemo(() => {
        return categories
            .keyValuePairs()
            .filter(([key, _value]) => !!key)
            .map(([_key, value]) => {
            return value;
        });
    }, [categories]);
    const questionsByCategory = useMemo(() => {
        const lists = [];
        categoryDtos.forEach((categoryDto) => {
            if (currentCategory.id === allCategory.id ||
                categoryDto.id === currentCategory.id) {
                const questionsForCategory = customQuestions.filter((question) => question.categoryId === categoryDto.id &&
                    (!draftOnly || question.state === QuestionState.DRAFT));
                lists.push(React.createElement(CustomQuestionsList, { key: categoryDto.id, category: categoryDto, questions: questionsForCategory }));
            }
        });
        return lists;
    }, [customQuestions, categoryDtos, currentCategory, draftOnly]);
    return (React.createElement(BaseView, { view: ViewsType.CUSTOM_QUESTIONS, dataTestId: "CustomQuestionsListView", header: {
            title: 'Meine Fragen',
            showHelp: true,
        } },
        React.createElement(CenterScrollPanel, { padding: "p-0", className: "flex-1 overflow-y-hidden" },
            React.createElement("div", { className: "flex justify-center" },
                React.createElement("div", { className: "max-w-[48rem] grow" },
                    React.createElement("div", { className: "m-4 desktop:flex desktop:space-x-4 desktop:items-end" },
                        React.createElement(Select, { options: [allCategory, ...categoryDtos], onChange: setCurrentCategory, displayValue: (category) => category.text, value: currentCategory, title: "Kategoriefilter", classNameContainer: "grow" }),
                        React.createElement(Checkbox, { id: "onlyDraft", checked: draftOnly, label: "Nur Entw\u00FCrfe anzeigen", onChange: setDraftOnly })),
                    questionsByCategory)))));
}
