import { Button } from '@components/basic-components/button';
import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { Panel } from '@components/basic-components/panel';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../../context/nest-client-context';
import React from 'react';
import createImage from '@public/images/game.png';
import useWatchCallback from '@utils/promise-watch';
export function GenerateCode() {
    const { nestClient } = useNestClientContext();
    const navigate = useNavigate();
    const startCodeChallenge = useWatchCallback(async () => {
        return nestClient.createClassroomChallenge().then((res) => {
            navigate(`/challenges/new?type=${ChallengeType.CODE}&classroom=${res.id}`, {
                replace: true,
            });
        });
    }, []);
    return (React.createElement(Panel, { className: "flex flex-col items-center mx-2" },
        React.createElement("div", { className: "flex justify-center pb-4" },
            React.createElement("img", { src: createImage, alt: "Illustration", className: "w-24" })),
        React.createElement("p", { className: "text-center text-darkBlue/70 leading-[19px] mb-4" },
            "Um ein Klassenzimmer-Quiz zu erstellen, ",
            React.createElement("br", null),
            " generiere einen Code."),
        React.createElement(Button, { className: "w-full", priority: "tertiary", onClick: startCodeChallenge, dataTestId: 'createChallenge' }, "Code generieren")));
}
