import { Loading } from '@components/basic-components/loading';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../../context/nest-client-context';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import SearchPlayer from '@components/search-player';
export default function SearchTeamMember() {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const [existingMembers, setExistingMembers] = useState();
    const { nestClient } = useNestClientContext();
    const addToTeam = useCallback(async (selectedPlayers) => {
        for (const selectedPlayer of selectedPlayers) {
            await nestClient
                .addTeamMember(teamId, selectedPlayer)
                .then(() => navigate(`/teams/${teamId}`));
        }
    }, [navigate, nestClient, teamId]);
    useEffect(() => {
        if (teamId) {
            void nestClient.getTeam(teamId).then((team) => {
                setExistingMembers(team.members.map((member) => member.profile.uuid));
            });
        }
    }, [nestClient, teamId]);
    if (!teamId || !existingMembers) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(SearchPlayer, { singleChoice: true, registeredOnly: true, button: { text: 'Spieler/-in hinzufügen', onClick: addToTeam }, omittedPlayers: existingMembers }));
}
