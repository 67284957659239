export var Role;
(function (Role) {
    Role["GUEST"] = "GUEST";
    Role["REGISTERED"] = "REGISTERED";
})(Role || (Role = {}));
export var QuestionState;
(function (QuestionState) {
    QuestionState["DRAFT"] = "DRAFT";
    QuestionState["ACTIVE"] = "ACTIVE";
    QuestionState["DISABLED"] = "DISABLED";
})(QuestionState || (QuestionState = {}));
export var QuestionType;
(function (QuestionType) {
    QuestionType["SINGLE_CHOICE"] = "SINGLE_CHOICE";
    QuestionType["TRUE_FALSE"] = "TRUE_FALSE";
    QuestionType["FILL_IN_THE_BLANK"] = "FILL_IN_THE_BLANK";
    QuestionType["ORDERING"] = "ORDERING";
})(QuestionType || (QuestionType = {}));
