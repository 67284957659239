import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Loading } from '@components/basic-components/loading';
import { Role } from '@mika-bibb/shared/enums';
import { SinglePlayerRankingsList, TeamsRankingsList, } from '@components/rankings/rankings';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import React, { useState } from 'react';
export default function SinglePlayerAndTeamsRankings({ profile, }) {
    const [showSinglePlayerRanking, setShowSinglePlayerRanking] = useState(true);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "px-6 desktop:px-0" },
            React.createElement("p", { className: "text-xl font-bold text-darkBlue mb-4" }, "Bestenliste"),
            (profile === null || profile === void 0 ? void 0 : profile.role) === Role.GUEST &&
                !FEATURE_FLAG_DIDACTA_VERSION && (React.createElement("p", { className: "text-darkBlue" }, "Nur registrierte Nutzer werden in der Bestenliste angezeigt.")),
            React.createElement("div", { className: "desktop:hidden" },
                React.createElement(TabsToggle, { labelLeft: "Spieler/-in", labelRight: "Teams", leftSelected: showSinglePlayerRanking, setLeftSelected: setShowSinglePlayerRanking }))),
        React.createElement("div", { className: "desktop:hidden h-full bg-white" }, profile ? (React.createElement(CenterScrollPanel, { padding: "p-0", className: "flex-grow h-full" }, showSinglePlayerRanking ? (React.createElement(SinglePlayerRankingsList, null)) : (React.createElement(TeamsRankingsList, null)))) : (React.createElement(Loading, { className: "mt-10" }))),
        React.createElement("div", { className: "hidden h-full desktop:grid grid-cols-2 gap-6" }, profile ? (React.createElement(React.Fragment, null,
            React.createElement(SinglePlayerRankingsList, null),
            React.createElement(TeamsRankingsList, null))) : (React.createElement(Loading, { className: "mt-10" })))));
}
