import { classNames } from '@utils/class-names';
import { formatStandingsString } from '@utils/formatting';
import Image from '@components/image';
import React from 'react';
import game from '@public/images/game.png';
export function SingleCategoryStatistics({ category, categoryImageURL, className, }) {
    return (React.createElement("div", { className: classNames(className, 'h-16 flex flex-row justify-between max-w-[360px]') },
        React.createElement("div", { className: "text-darkBlue font-bold flex flex-col justify-start overflow-hidden" },
            React.createElement("div", { className: "text-ellipsis overflow-hidden whitespace-nowrap text-md desktop:text-lg" },
                category.name,
                ":"),
            React.createElement("div", { className: "text-sm text-orange font-normal" },
                formatStandingsString(category.numTotalAnswers, category.numCorrectAnswers),
                ' ',
                "richtig"),
            !!category.numPicked && (React.createElement("div", { className: "text-sm text-orange font-normal" },
                category.numPicked,
                " Mal gew\u00E4hlt"))),
        categoryImageURL ? (React.createElement(Image, { asset: categoryImageURL, alt: "topics", className: "h-full", sizes: "200px" })) : (React.createElement("img", { src: game, alt: "topics", className: "h-full" }))));
}
