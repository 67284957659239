import { App as CapacitorApp } from '@capacitor/app';
import { useEffect } from 'react';
import { useOfflineContext } from '../context/offline-context';
import debounce from 'lodash.debounce';
export function usePolling(fetcher, interval = 5000, dependencies = [], skipInit = false) {
    const isOffline = useOfflineContext();
    const debouncedFetcher = debounce(() => !isOffline && fetcher(), 50);
    useEffect(() => {
        // One initial fetch immediately
        !skipInit && fetcher();
        const handler = CapacitorApp.addListener('resume', () => {
            debouncedFetcher();
        });
        const intervalId = setInterval(() => {
            debouncedFetcher();
        }, interval);
        return () => {
            clearInterval(intervalId);
            void Promise.resolve(handler).then((handler) => handler.remove());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, isOffline]);
}
