import { ViewsType } from '@typesSrc/views';
import BaseView from '@views/base-view';
import React from 'react';
import SearchTeamMember from '@components/team/search-team-member';
export default function TeamAddMemberView() {
    return (React.createElement(BaseView, { view: ViewsType.TEAMS, header: {
            title: 'Spieler zum Team hinzufügen',
            showBack: true,
        }, showFooter: false },
        React.createElement(SearchTeamMember, null)));
}
