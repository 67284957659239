export var ChallengeType;
(function (ChallengeType) {
    ChallengeType["ONE_VS_ONE"] = "ONE_VS_ONE";
    ChallengeType["ONE_VS_ONE_RANDOM"] = "ONE_VS_ONE_RANDOM";
    ChallengeType["SOLO"] = "SOLO";
    ChallengeType["TEAM"] = "TEAM";
    ChallengeType["TEAM_VS_TEAM"] = "TEAM_VS_TEAM";
    ChallengeType["CODE"] = "CODE";
})(ChallengeType || (ChallengeType = {}));
export var SingleAnswerStateDto;
(function (SingleAnswerStateDto) {
    SingleAnswerStateDto["CORRECT"] = "CORRECT";
    SingleAnswerStateDto["WRONG"] = "WRONG";
    SingleAnswerStateDto["NOT_ANSWERED"] = "NOT_ANSWERED";
})(SingleAnswerStateDto || (SingleAnswerStateDto = {}));
