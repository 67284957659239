import { CenterScrollPanel, } from '@components/basic-components/center-scroll-panel';
import { classNames } from '@utils/class-names';
import React, { useCallback } from 'react';
import mikaNormal from '@public/images/mika/mika.svg';
import mikaSad from '@public/images/mika/mika-sad.svg';
import useSafeSpaceContext from '../../context/safe-space-context';
const mikaPool = {
    normal: mikaNormal,
    sad: mikaSad,
};
export function Popup({ children, className, popupOpen, setPopupOpen, withoutFooter = false, centerScrollPanel, dataTestId, hideCloseButton, mika, withSideBar, }) {
    const closePopup = useCallback(() => {
        setPopupOpen === null || setPopupOpen === void 0 ? void 0 : setPopupOpen(false);
    }, [setPopupOpen]);
    const insets = useSafeSpaceContext();
    return (React.createElement("div", { className: classNames('fixed top-0 right-0 left-0 z-50 flex w-full justify-center transition-[visibility] pointer-events-none', popupOpen ? 'delay-0' : 'invisible delay-300', className, withoutFooter
            ? 'bottom-0'
            : 'bottom-[var(--footerHeight)] desktop:bottom-0') },
        React.createElement("div", { className: classNames('relative flex flex-col justify-end desktop:max-w-[1200px] grow'), "data-testid": dataTestId !== null && dataTestId !== void 0 ? dataTestId : 'Popup' },
            React.createElement("div", { className: classNames('absolute inset-0 pointer-events-auto', 'transition ease-in-out duration-300', popupOpen ? 'bg-darkBlue/50' : 'bg-darkBlue/0', withSideBar ? 'desktop:ml-80' : ''), onClick: closePopup }),
            React.createElement("div", { className: classNames('relative pointer-events-auto desktop:bg-bgGray desktop:flex desktop:flex-col desktop:items-center desktop:justify-center', 'ease-in-out transition-all duration-300', mika && 'pt-40 desktop:pt-4 px-6 pb-6', popupOpen
                    ? 'translate-y-0 opacity-100'
                    : 'translate-y-full opacity-0', centerScrollPanel === null || centerScrollPanel === void 0 ? void 0 : centerScrollPanel.className), style: {
                    maxHeight: `calc(100% - ${insets.insets.top}px)`,
                } },
                mika && (React.createElement("div", { className: "absolute left-6 top-0 desktop:position-unset desktop:flex desktop:justify-center h-40 pt-6 pb-2 desktop:p-0 pointer-events-auto" },
                    React.createElement("img", { src: mikaPool[mika], alt: "defaultMika", className: "h-full" }))),
                React.createElement(CenterScrollPanel, Object.assign({}, centerScrollPanel, { header: getHeader(centerScrollPanel === null || centerScrollPanel === void 0 ? void 0 : centerScrollPanel.header, mika, hideCloseButton),
                    className: classNames('relative h-full pointer-events-auto desktop:bg-bgGray desktop:w-full desktop:max-w-[500px]', centerScrollPanel === null || centerScrollPanel === void 0 ? void 0 : centerScrollPanel.className), fixedHeight: false }), mika && typeof children === 'string' ? (React.createElement("div", { className: "mb-2 desktop:px-8 desktop:text-center text-sm text-darkBlue/70 italic whitespace-pre-line" }, children)) : (children)),
                setPopupOpen && !hideCloseButton && !mika && (React.createElement("button", { className: "icon-close absolute flex justify-center items-center top-1 right-1 w-10 h-10 text-2xl text-darkBlue/70 pointer-events-auto", onClick: closePopup }))))));
}
function getHeader(header, isMikaPopup, hideCloseButton) {
    if (header) {
        if (isMikaPopup && typeof header === 'string') {
            return (React.createElement("div", { className: "text-xl desktop:px-8 desktop:text-center text-darkBlue font-bold" }, header));
        }
        else if (!hideCloseButton && !isMikaPopup) {
            return React.createElement("div", { className: "relative mr-8" }, header);
        }
    }
    return header;
}
