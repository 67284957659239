import { Link } from 'react-router-dom';
import { classNames } from '@utils/class-names';
import React, { useEffect } from 'react';
export default function ListEntry({ to, avatar, middle, right, swipeElement, swipeResetCounter, }) {
    const swipeContainer = React.useRef(null);
    useEffect(() => {
        if (swipeContainer.current) {
            swipeContainer.current.scrollLeft = 0;
        }
    }, [swipeResetCounter]);
    //The swipeElement can not be used within any type of overflow behaviour, that is not visible. Both x and y direction
    const entry = (React.createElement("div", { className: classNames('relative grid gap-x-2 items-center py-2', avatar ? 'grid-cols-[42px_1fr_auto]' : 'grid-cols-2') },
        avatar,
        middle,
        !swipeElement && right,
        swipeElement && (React.createElement("div", { className: "absolute w-full snap-x overflow-x-auto flex top-0 bottom-0 desktop:justify-end", ref: swipeContainer },
            React.createElement("div", { className: "flex items-center justify-end shrink-0 w-full h-full snap-end desktop:w-auto" },
                React.createElement("div", { className: "w-4 h-full bg-gradient-to-r from-transparent to-white" }),
                right && (React.createElement("div", { className: "flex items-center h-full pl-4 bg-white" }, right))),
            React.createElement("div", { className: "snap-end shrink-0" }, swipeElement)))));
    return to ? (React.createElement(Link, { to: to, className: "block border-b border-solid border-darkBlue/10" }, entry)) : (React.createElement("div", { className: "block border-b border-solid border-darkBlue/10" }, entry));
}
