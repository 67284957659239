import { Avatar } from '@components/basic-components/avatar/avatar';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Input } from '@components/basic-components/input';
import { classNames } from '@utils/class-names';
import { overflowEllipsis } from '@utils/mixed';
import { useNestClientContext } from '../context/nest-client-context';
import React, { useCallback, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
function ProfilePreview({ profile, onClick, selected, }) {
    const onClickCallback = useCallback(() => {
        onClick(profile.uuid);
    }, [onClick, profile.uuid]);
    return (React.createElement("button", { onClick: onClickCallback, className: classNames('w-full flex flex-row py-3 px-4 items-center space-x-2 border-b border-darkBlue/10', selected ? 'bg-darkBlue/10' : '') },
        React.createElement(Avatar, { image: profile.avatarURL, bannerType: profile.bannerType, className: "w-10", sizes: "40px" }),
        React.createElement("div", { className: classNames('font-bold text-darkBlue', overflowEllipsis) }, profile.nickname)));
}
export default function SearchPlayer({ singleChoice = true, button, omittedPlayers = [], registeredOnly = false, }) {
    const [selectedPlayers, setSelectedPlayers] = useState();
    const { nestClient } = useNestClientContext();
    const selectPlayer = useCallback((playerID) => {
        if (singleChoice) {
            setSelectedPlayers([playerID]);
            return;
        }
        if (selectedPlayers === null || selectedPlayers === void 0 ? void 0 : selectedPlayers.includes(playerID)) {
            setSelectedPlayers(selectedPlayers.filter((id) => id !== playerID));
        }
        else {
            setSelectedPlayers([...(selectedPlayers !== null && selectedPlayers !== void 0 ? selectedPlayers : []), playerID]);
        }
    }, [selectedPlayers, singleChoice]);
    const onClick = useWatchCallback(() => {
        return button.onClick(selectedPlayers !== null && selectedPlayers !== void 0 ? selectedPlayers : []);
    }, [selectedPlayers, button]);
    const [foundPlayers, setFoundPlayers] = useState();
    const updateRecommendations = useCallback((searchTerm) => {
        setSelectedPlayers(undefined);
        if (searchTerm && searchTerm.length > 2) {
            //Debounce?
            void nestClient
                .searchPlayer(searchTerm, registeredOnly)
                .then((result) => {
                setFoundPlayers(result.filter((p) => !omittedPlayers.includes(p.uuid)));
            });
        }
        else {
            setFoundPlayers(undefined);
        }
    }, [nestClient, omittedPlayers, registeredOnly]);
    return (React.createElement("div", { className: "flex flex-col space-y-2 h-full" },
        React.createElement(Input, { type: "search", placeHolder: "Spieler/-in suchen", onChange: updateRecommendations, dataTestId: "search-player-input", showSearchIcon: true, className: "m-2 bg-white rounded-sm" }),
        React.createElement(CenterScrollPanel, { className: "m-2 flex flex-col flex-1", rounded: "top", header: React.createElement("div", { className: classNames(foundPlayers ? 'text-left' : 'text-center', 'text-darkBlue/70 text-sm font-medium') }, foundPlayers
                ? foundPlayers.length > 0
                    ? 'Ergebnisse'
                    : 'Keine Spieler mit diesem Namen gefunden'
                : 'Spieler/-in suchen'), footer: React.createElement(Button, { onClick: onClick, disabled: !selectedPlayers, dataTestId: "confirm-search-button", className: "w-full mb-4" }, button.text) },
            React.createElement("div", { className: "flex flex-col flex-1" }, foundPlayers === null || foundPlayers === void 0 ? void 0 : foundPlayers.map((player) => {
                var _a;
                return (React.createElement(ProfilePreview, { key: player.uuid, profile: player, onClick: selectPlayer, selected: (_a = selectedPlayers === null || selectedPlayers === void 0 ? void 0 : selectedPlayers.includes(player.uuid)) !== null && _a !== void 0 ? _a : false }));
            })))));
}
