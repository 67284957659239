export class ApiError extends Error {
    constructor(status, message) {
        super(message);
        this.name = ApiError.name;
        this.status = status;
    }
}
export class NewTeamImageUploadError extends ApiError {
    constructor(status, team, message) {
        super(status, message);
        this.team = team;
    }
}
