import { Avatar } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { ChallengeHelpPopup } from '@components/challenge/challenge-help-popup';
import { Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import WelcomeHeaderPart from '@components/home/welcome-header-part';
export default function DesktopHomeHeader({ profile, }) {
    var _a;
    const [helpPopupOpen, setHelpPopupOpen] = React.useState(false);
    const openHelpPopup = useCallback(() => setHelpPopupOpen(true), []);
    return (React.createElement("div", { className: "hidden desktop:flex w-full px-6 py-2 bg-white border-darkBlue/10 border-l flex-row justify-between" },
        React.createElement("div", { className: "py-2 flex" },
            React.createElement(Link, { to: "/profile" },
                React.createElement(Avatar, { className: "w-[52px] h-[52px]", sizes: "52px", image: profile === null || profile === void 0 ? void 0 : profile.avatarURL, bannerType: (_a = profile === null || profile === void 0 ? void 0 : profile.bannerType) !== null && _a !== void 0 ? _a : BannerType.NONE })),
            React.createElement(WelcomeHeaderPart, { profile: profile })),
        React.createElement("button", { onClick: openHelpPopup, className: "text-2xl text-green icon-help" }),
        React.createElement(ChallengeHelpPopup, { popupOpen: helpPopupOpen, setPopupOpen: setHelpPopupOpen, withFooter: true })));
}
