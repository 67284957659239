import { Avatar, DefaultAvatar, } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Input } from '@components/basic-components/input';
import { classNames } from '@utils/class-names';
import { overflowEllipsis } from '@utils/mixed';
import { useNestClientContext } from '../../context/nest-client-context';
import React, { useCallback, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
export function SearchTeam({ singleChoice = true, button, searchingTeam, }) {
    const [selectedTeams, setSelectedTeams] = useState();
    const { nestClient } = useNestClientContext();
    const selectTeam = useCallback((teamID) => {
        if (singleChoice) {
            setSelectedTeams([teamID]);
            return;
        }
        if (selectedTeams === null || selectedTeams === void 0 ? void 0 : selectedTeams.includes(teamID)) {
            setSelectedTeams(selectedTeams.filter((id) => id !== teamID));
        }
        else {
            setSelectedTeams([...(selectedTeams !== null && selectedTeams !== void 0 ? selectedTeams : []), teamID]);
        }
    }, [selectedTeams, singleChoice]);
    const onClick = useWatchCallback(() => {
        return button.onClick(selectedTeams !== null && selectedTeams !== void 0 ? selectedTeams : []);
    }, [selectedTeams, button]);
    const [foundTeams, setFoundTeams] = useState();
    const updateRecommendations = useCallback((searchTerm) => {
        setSelectedTeams(undefined);
        if (searchTerm && searchTerm.length > 1) {
            //Debounce?
            void nestClient.searchTeam(searchTerm).then((result) => {
                setFoundTeams(result.filter((team) => team.uuid !== searchingTeam)); //FILTER
            });
        }
        else {
            setFoundTeams(undefined);
        }
    }, [nestClient, searchingTeam]);
    return (React.createElement("div", { className: "flex flex-col space-y-2 h-full" },
        React.createElement(Input, { type: "search", placeHolder: "Team suchen", onChange: updateRecommendations, dataTestId: "search-team-input", showSearchIcon: true, className: "m-2 bg-white rounded-sm" }),
        React.createElement(CenterScrollPanel, { className: "m-2 flex flex-col flex-1", rounded: "top", header: React.createElement("div", { className: classNames(foundTeams ? 'text-left' : 'text-center', 'text-darkBlue/70 text-sm font-medium') }, foundTeams
                ? foundTeams.length > 0
                    ? 'Ergebnisse'
                    : 'Keine Teams mit diesem Namen gefunden'
                : 'Team suchen'), footer: React.createElement(Button, { onClick: onClick, disabled: !selectedTeams, dataTestId: "confirm-search-button", className: "w-full mb-4" }, button.text) },
            React.createElement("div", { className: "flex flex-col flex-1" }, foundTeams === null || foundTeams === void 0 ? void 0 : foundTeams.map((team) => (React.createElement(TeamPreview, { key: team.uuid, team: team, onClick: selectTeam, selected: selectedTeams === null || selectedTeams === void 0 ? void 0 : selectedTeams.includes(team.uuid) })))))));
}
function TeamPreview({ team, onClick, selected, }) {
    const onClickCallback = useCallback(() => {
        onClick(team.uuid);
    }, [onClick, team.uuid]);
    return (React.createElement("button", { onClick: onClickCallback, className: classNames('w-full flex flex-row py-3 px-4 items-center space-x-2 border-b border-darkBlue/10', selected ? 'bg-darkBlue/10' : '') },
        React.createElement(Avatar, { image: team.imageUrl, bannerType: BannerType.NONE, defaultAvatar: DefaultAvatar.TEAM, className: "w-10", sizes: "40px" }),
        React.createElement("div", { className: classNames('font-bold text-darkBlue', overflowEllipsis) }, team.name)));
}
