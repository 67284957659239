import { ViewsType } from '@typesSrc/views';
import { useRouteError } from 'react-router-dom';
import BaseView from '@views/base-view';
import React from 'react';
export default function ErrorView() {
    const error = useRouteError();
    return (React.createElement(BaseView, { view: ViewsType.ERROR, showHeader: false, dataTestId: "ErrorView" },
        React.createElement("div", { className: "flex flex-col items-center justify-center h-full" },
            React.createElement("div", { className: "text-xl font-bold mb-5" }, "Hoppla!"),
            (error === null || error === void 0 ? void 0 : error.status) === 404 ? (React.createElement("p", { className: "mb-5" }, "Diese Seite existiert nicht.")) : (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "mb-5" }, "Ein unerwarteter Fehler ist aufgetreten."),
                error && (React.createElement("p", null,
                    React.createElement("i", null, error.statusText || error.message))))))));
}
