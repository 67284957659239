var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@components/basic-components/button';
import { useLocation } from 'react-router-dom';
import React from 'react';
export function useBackPath(levels = 1) {
    const path = useLocation().pathname;
    const trimmedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const parts = trimmedPath.split('/');
    return parts.slice(0, parts.length - levels).join('/');
}
/**
 * A button that navigates up 1 (or more) levels, effectively going back to the previous overview page
 */
const BackButton = (_a) => {
    var { levels = 1, children, dataTestId } = _a, rest = __rest(_a, ["levels", "children", "dataTestId"]);
    const newPath = useBackPath(levels);
    return (React.createElement(Button, Object.assign({ to: newPath }, rest, { dataTestId: dataTestId !== null && dataTestId !== void 0 ? dataTestId : 'back-button' }), children));
};
export default BackButton;
