var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Panel } from '@components/basic-components/panel';
import { classNames } from '@utils/class-names';
import React from 'react';
export const CenterScrollPanel = (_a) => {
    var _b;
    var { children, header, footer, className, padding = 'p-4', fixedHeight = true } = _a, panelProps = __rest(_a, ["children", "header", "footer", "className", "padding", "fixedHeight"]);
    return (
    // we should not use 'overflow-hidden' here, as this breaks the SwipeElement interactions
    React.createElement(Panel, Object.assign({}, panelProps, { className: classNames('relative basis-0', className), dataTestId: "CenterScrollPanel", padding: padding, greyOnDesktop: (_b = panelProps.greyOnDesktop) !== null && _b !== void 0 ? _b : true }),
        React.createElement("div", { className: classNames('inline-grid grid-cols-1 w-full', fixedHeight ? 'h-full' : 'max-h-full', header && footer && 'grid-rows-[auto_1fr_auto]', !header && !footer && 'grid-rows-[1fr]', !header && footer && 'grid-rows-[1fr_auto]', header && !footer && 'grid-rows-[auto_1fr]') },
            !!header && React.createElement("div", { className: "pb-4" }, header),
            React.createElement("div", { className: "relative min-h-0 overflow-y-auto force-scrollbar" }, fixedHeight ? (React.createElement("div", { className: "absolute inset-0" }, children)) : (children)),
            !!footer && (React.createElement("div", { className: "pt-4 z-40 desktop:p-4" }, footer)))));
};
