import { ManHat1 } from './import-man-hat1';
import { ManHat2 } from './import-man-hat2';
import { ManHat3 } from './import-man-hat3';
import { WomanHat1 } from './import-woman-hat1';
import React from 'react';
export const headGearColorsOrdered = ['#000000'];
export const headGearShapesOrdered = [
    undefined,
    'HeadGear1',
    'HeadGear2',
    'HeadGear3',
    'HeadGear4',
];
export const headGearConfigNoHair = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair1, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair1, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair1, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair1, null),
    },
};
export const headGearConfigWomanHair1 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair1, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair1, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair1, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair1, null),
    },
};
export const headGearConfigWomanHair2 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair2, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair2, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair2, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair2, null),
    },
};
export const headGearConfigWomanHair3 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair3, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair3, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair3, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair3, null),
    },
};
export const headGearConfigWomanHair4 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair4, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair4, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair4, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair4, null),
    },
};
export const headGearConfigWomanHair5 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair5, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair5, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair5, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair5, null),
    },
};
export const headGearConfigWomanHair6 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair6, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair6, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair6, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair6, null),
    },
};
export const headGearConfigWomanHair7 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair7, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair7, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair7, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair7, null),
    },
};
export const headGearConfigWomanHair8 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair8, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair8, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair8, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair8, null),
    },
};
export const headGearConfigWomanHair9 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_WomanHair9, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_WomanHair9, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_WomanHair9, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_WomanHair9, null),
    },
};
export const headGearConfigManHair1 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair1, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair1, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair1, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair1, null),
    },
};
export const headGearConfigManHair2 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair2, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair2, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair2, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair2, null),
    },
};
export const headGearConfigManHair3 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair3, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair3, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair3, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair3, null),
    },
};
export const headGearConfigManHair4 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair4, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair4, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair4, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair4, null),
    },
};
export const headGearConfigManHair5 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair5, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair5, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair5, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair5, null),
    },
};
export const headGearConfigManHair6 = {
    '#000000': {
        HeadGear1: React.createElement(WomanHat1.HeadGear_ManHair6, null),
        HeadGear2: React.createElement(ManHat1.HeadGear_ManHair6, null),
        HeadGear3: React.createElement(ManHat2.HeadGear_ManHair6, null),
        HeadGear4: React.createElement(ManHat3.HeadGear_ManHair6, null),
    },
};
// must match the order of hairShapesOrdered
export const headGearConfigsOrdered = [
    headGearConfigNoHair,
    headGearConfigWomanHair1,
    headGearConfigWomanHair2,
    headGearConfigWomanHair3,
    headGearConfigWomanHair4,
    headGearConfigWomanHair5,
    headGearConfigManHair1,
    headGearConfigManHair2,
    headGearConfigManHair3,
    headGearConfigManHair4,
    headGearConfigManHair5,
    headGearConfigWomanHair6,
    headGearConfigWomanHair7,
    headGearConfigWomanHair8,
    headGearConfigWomanHair9,
    headGearConfigManHair6,
];
