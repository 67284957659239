import { Avatar } from '@components/basic-components/avatar/avatar';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Loading } from '@components/basic-components/loading';
import { OneTimePopup } from '@components/basic-components/one-time-popup';
import { ViewsType } from '@typesSrc/views';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useCallback, useRef, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
export function AvatarEditorExplanation({ title, text, }) {
    return (React.createElement("div", null,
        React.createElement("h2", { className: "mb-6 text-darkBlue font-bold text-xl" }, title),
        React.createElement("div", { className: "text-green mt-2 leading-5" }, text)));
}
export const MyAvatarView = () => {
    const { state: { profile }, fetchProfile, } = useProfile();
    const [oneTimePopupOpen, setOneTimePopupOpen] = useState(true);
    const [file, setFile] = useState(undefined);
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const { nestClient } = useNestClientContext();
    const handleClickOk = useCallback(() => {
        setOneTimePopupOpen(false);
    }, []);
    const handleClickUploadButton = useCallback(() => {
        var _a;
        (_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.click();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenFileInput.current]);
    const handleOnImageUploadChange = useCallback((e) => {
        setFile(e.target.files[0]);
    }, []);
    const clearFile = useCallback(() => {
        setFile(undefined);
    }, []);
    const saveUploadedImage = useWatchCallback(async () => {
        if (!profile || !file) {
            return;
        }
        return nestClient
            .updateProfilePicture(file)
            .then(() => fetchProfile())
            .then(() => nestClient.updateProfile({
            avatarConfig: undefined,
        }))
            .then(() => {
            navigate('/profile/avatar-badge-settings');
        });
    }, [profile, file]);
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, dataTestId: "MyProfileEditor", header: {
            title: file ? 'Dein Avatar (Vorschau)' : 'Dein Avatar',
            showBack: true,
        }, showFooter: false },
        profile ? (React.createElement(CenterScrollPanel, { className: "flex-1 mx-2", rounded: "top", header: React.createElement(AvatarEditorExplanation, { title: "Avatar-Editor", text: "Gib Deinem Profil ein Gesicht und kreiere Deinen eigenen Avatar. W\u00E4hle aus verschiedenen Kopfformen, Haarfrisuren, Accessoires und vielem mehr. Lass uns anfangen!" }), footer: React.createElement("div", { className: "flex flex-col gap-y-4 mb-4" }, file ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { dataTestId: "saveUploadedImage", priority: "primary", onClick: saveUploadedImage }, "Bild verwenden"),
                React.createElement(Button, { priority: "tertiary", onClick: clearFile }, "Abbrechen"))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "relative" },
                    React.createElement("input", { type: "file", onChange: handleOnImageUploadChange, accept: "image/*", className: "absolute hidden", ref: hiddenFileInput, "data-testid": "imageUpload" }),
                    !FEATURE_FLAG_DIDACTA_VERSION && (React.createElement(Button, { priority: "tertiary", className: "w-full", onClick: handleClickUploadButton }, "Aus Galerie hochladen"))),
                React.createElement(Button, { priority: "primary", to: "/profile/create-avatar" }, "Pers\u00F6nlichen Avatar erstellen")))) },
            React.createElement(Avatar, { preview: file, image: profile.avatarURL, bannerType: profile.bannerType, className: "w-50 m-auto mt-8", sizes: "200px" }))) : (React.createElement(Loading, { className: "mt-10" })),
        React.createElement(OneTimePopup, { id: "MyAvatarView-whats-a-avatar", "data-testid": "MyAvatarView-whats-a-avatar", mika: "normal", popupOpen: oneTimePopupOpen, setPopupOpen: setOneTimePopupOpen, centerScrollPanel: {
                header: 'Erstelle deinen eigenen Avatar',
                footer: (React.createElement(Button, { priority: "primary", onClick: handleClickOk, className: "w-full" }, "OK")),
            }, withoutFooter: true }, "Gib Deinem Profil ein Gesicht und kreiere Deinen eigenen Avatar. W\u00E4hle aus verschiedenen Kopfformen, Haarfrisuren, Accessoires und vielem mehr. Lass uns anfangen!")));
};
