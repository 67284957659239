/** A Promise where you can call resolve and reject from the outside */
export default class ManualPromise extends Promise {
    constructor(executor) {
        let resolve;
        let reject;
        super((res, rej) => {
            resolve = res;
            reject = rej;
            executor === null || executor === void 0 ? void 0 : executor(res, rej);
        });
        this.resolve = resolve;
        this.reject = reject;
    }
}
