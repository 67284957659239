var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Environment } from '../environment.global';
import { generateSourceSet } from '@utils/loader';
import React from 'react';
const Image = (initialProps) => {
    var _a, _b, _c;
    const { aspectRatio, dataTestId } = initialProps, props = __rest(initialProps, ["aspectRatio", "dataTestId"]);
    let imageProps = props;
    let src = undefined;
    if ('src' in props) {
        const { src: _src } = props, _imageProps = __rest(props, ["src"]);
        src = _src;
        imageProps = _imageProps;
    }
    if ('asset' in props && props.asset) {
        const { asset } = props, _imageProps = __rest(props, ["asset"]);
        src = `${Environment.NEST_API_URL}/image/${asset}`;
        imageProps = _imageProps;
    }
    if (src === undefined) {
        console.error('Image component received invalid props', props);
        throw new Error('Invalid image props');
    }
    if (typeof src === 'object' && JSON.stringify(src) === '{}') {
        src = '[Failed to import image source]';
    }
    return (React.createElement("img", Object.assign({}, imageProps, { src: src, alt: (_b = (_a = imageProps.alt) !== null && _a !== void 0 ? _a : dataTestId) !== null && _b !== void 0 ? _b : 'Image', sizes: (_c = imageProps.sizes) !== null && _c !== void 0 ? _c : '100vw', srcSet: 'asset' in props
            ? generateSourceSet(src, undefined, aspectRatio)
            : undefined, "data-testid": dataTestId !== null && dataTestId !== void 0 ? dataTestId : 'Image' })));
};
export default Image;
