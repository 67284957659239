import { Button } from '@components/basic-components/button';
import { Capacitor } from '@capacitor/core';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Loading } from '@components/basic-components/loading';
import { Popup } from '@components/basic-components/popup';
import { ProfileLink } from '@components/profile/profile-link';
import { RegisteredOnlyPopup } from '@components/basic-components/registeredOnlyPopup';
import { Role } from '@mika-bibb/shared/enums';
import { ViewsType } from '@typesSrc/views';
import { nestClient } from '../context/nest-client-context';
import { openInAppBrowser } from '@utils/in-app-browser';
import { useConsent } from '../context/consent-context';
import { useProfile } from '../context/profile-context';
import { usePushNotifications } from '../context/push-notification-context';
import BaseView from '@views/base-view';
import ProfileDisplayFullscreen from '@components/profile/profile-display-fullscreen';
import React, { useState } from 'react';
export default function MyProfileView() {
    const { state: { profile }, logout, deleteProfile, } = useProfile();
    const [showRegisteredOnlyPopup, setShowRegisteredOnlyPopup] = useState(false);
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const { consent, setConsent } = useConsent();
    const { setEnabledByUser: setPushEnabled, enabledByUser: pushEnabled, busy: pushSettingBusy, } = usePushNotifications();
    function handleShowLogout() {
        setShowLogoutPopup(true);
    }
    const handleShowDelete = () => {
        setShowDeletePopup(true);
    };
    const startSsoFlow = async (action) => {
        const { url } = await nestClient.initializeSsoFlow(action);
        openInAppBrowser(url);
    };
    const loggedInText = FEATURE_FLAG_DIDACTA_VERSION || (profile === null || profile === void 0 ? void 0 : profile.role) === Role.REGISTERED
        ? ''
        : ' (Du musst eingeloggt sein)';
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, dataTestId: "MyProfileView", showHeader: false, showFooter: true },
        profile ? (React.createElement(CenterScrollPanel, { className: "flex-1 overflow-y-hidden", padding: "p-0" },
            React.createElement(ProfileDisplayFullscreen, { profile: profile }),
            React.createElement("div", { className: "flex flex-col mt-2 p-4" },
                React.createElement(ProfileLink, { title: "Profil anpassen", subTitle: "Nickname oder Statusnachricht \u00E4ndern", to: "/profile/edit", arrowRight: true }),
                profile.role === Role.GUEST &&
                    !FEATURE_FLAG_DIDACTA_VERSION && (React.createElement(ProfileLink, { title: "Gastprofil persistieren", subTitle: "Das Gastprofil in einen Mika-Lernwelt-Account konvertieren", onClick: () => void startSsoFlow('login'), arrowRight: true, buttonTestId: "ConvertToRegisteredButton" })),
                React.createElement(ProfileLink, { title: "Avatar anpassen", subTitle: `Avatar erstellen oder ändern${loggedInText}`, registeredOnly: profile, setShowRegisteredOnlyPopup: setShowRegisteredOnlyPopup, to: "/profile/my-avatar", arrowRight: true }),
                React.createElement(ProfileLink, { title: "Badge anpassen", subTitle: "Rolle anzeigen", registeredOnly: profile, setShowRegisteredOnlyPopup: setShowRegisteredOnlyPopup, to: "/profile/avatar-badge-settings", arrowRight: true }),
                React.createElement(ProfileLink, { title: "Meine Fragen", subTitle: "Eigene Fragen bearbeiten", registeredOnly: profile, setShowRegisteredOnlyPopup: setShowRegisteredOnlyPopup, to: "/myQuestions", arrowRight: true }),
                React.createElement(ProfileLink, { title: "Push-Benachrichtigungen", toggle: {
                        onToggle: setPushEnabled,
                        checked: pushEnabled !== null && pushEnabled !== void 0 ? pushEnabled : true,
                        dataTestId: 'PushEnabled',
                    }, subTitle: Capacitor.getPlatform() === 'web'
                        ? 'Benötigt Zustimmung für Benachrichtigungen auch in den Betriebsystemeinstellungen'
                        : undefined, disabled: pushSettingBusy }),
                profile.role === Role.REGISTERED && (React.createElement(ProfileLink, { title: "Ausloggen", subTitle: "Vom Konto abmelden", onClick: handleShowLogout, arrowRight: true })),
                React.createElement(ProfileLink, { title: "L\u00F6schen", subTitle: "Profil und Spieldaten l\u00F6schen", onClick: handleShowDelete, arrowRight: true, buttonTestId: "DeleteProfileButton" }),
                React.createElement(ProfileLink, { title: "Datenschutz", to: "/privacy-policy", arrowRight: true }),
                React.createElement(ProfileLink, { title: "Impressum", to: "/imprint", arrowRight: true }),
                React.createElement(ProfileLink, { title: "Analyse App-Nutzung", subTitle: 'Zustimmung zur Datenanalyse', toggle: {
                        onToggle: setConsent,
                        checked: consent === true,
                        dataTestId: 'Consent',
                    } })))) : (React.createElement(Loading, { fullSize: true })),
        React.createElement(RegisteredOnlyPopup, { popupOpen: showRegisteredOnlyPopup, setPopupOpen: setShowRegisteredOnlyPopup }),
        React.createElement(Popup, { popupOpen: showDeletePopup, setPopupOpen: setShowDeletePopup, hideCloseButton: true, centerScrollPanel: {
                header: (React.createElement("h3", { className: "font-bold text-xl text-darkBlue desktop:text-center" }, "Bist Du Dir sicher, dass Du Dein Profil l\u00F6schen m\u00F6chtest?")),
                footer: (React.createElement("div", { className: "flex flex-col space-y-4" },
                    React.createElement(Button, { priority: "tertiary", onClick: deleteProfile, dataTestId: "confirmDelete" }, "Profil l\u00F6schen"),
                    React.createElement(Button, { priority: "primary", onClick: () => setShowDeletePopup(false) }, "Zur\u00FCck"))),
            } }),
        React.createElement(Popup, { popupOpen: showLogoutPopup, setPopupOpen: setShowLogoutPopup, hideCloseButton: true, centerScrollPanel: {
                header: (React.createElement("h3", { className: "font-bold text-xl text-darkBlue" }, "Wirklich ausloggen?")),
                footer: (React.createElement("div", { className: "flex flex-col space-y-4" },
                    React.createElement(Button, { priority: "tertiary", onClick: logout }, "Ausloggen"),
                    React.createElement(Button, { priority: "primary", onClick: () => setShowLogoutPopup(false) }, "Zur\u00FCck"))),
            } })));
}
