import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { isMultiplayerChallenge } from '@mika-bibb/shared/util/challenge';
import React, { useMemo } from 'react';
import useChallengeContext from '../../context/challenge-context';
export function ParticipantsCount() {
    const { challenge } = useChallengeContext();
    const participantsCount = useMemo(() => {
        var _a, _b;
        if (!challenge || !isMultiplayerChallenge(challenge.type)) {
            return undefined;
        }
        if (challenge.type === ChallengeType.TEAM_VS_TEAM) {
            return (challenge.opponentInfo.amount +
                ((_b = (_a = challenge.ownTeamInfo) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0));
        }
        return challenge.opponentInfo.amount + 1;
    }, [challenge]);
    if (participantsCount === undefined) {
        return null;
    }
    return (React.createElement("div", { className: "flex items-center space-x-1" },
        React.createElement("div", { className: "icon-most-played text-green" }),
        React.createElement("div", { className: "text-sm text-darkBlue" }, participantsCount)));
}
