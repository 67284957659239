import { Button } from '@components/basic-components/button';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Role } from '@mika-bibb/shared/enums';
import { Toggle } from '@components/basic-components/toggle';
import { classNames } from '@utils/class-names';
import React from 'react';
export const ProfileLink = ({ title, subTitle, to, onClick, rightElement, disabled, arrowRight, registeredOnly, setShowRegisteredOnlyPopup, toggle, buttonTestId, }) => {
    var _a;
    const content = (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", { className: "font-bold text-darkBlue text-left" }, title),
            subTitle && (React.createElement("div", { className: "text-sm text-darkBlue/50 text-left" }, subTitle))),
        rightElement && React.createElement("div", null, rightElement),
        !rightElement && arrowRight && (React.createElement("div", { className: "icon-arrow-right text-darkBlue" }))));
    const forbidden = (registeredOnly === null || registeredOnly === void 0 ? void 0 : registeredOnly.role) === Role.GUEST && !FEATURE_FLAG_DIDACTA_VERSION;
    const className = classNames('flex justify-between items-center gap-x-4 min-h-16 py-3 border-b border-solid border-darkBlue/10', (!!disabled || forbidden) && 'opacity-50');
    function handleShowRegisteredOnlyPopup() {
        setShowRegisteredOnlyPopup === null || setShowRegisteredOnlyPopup === void 0 ? void 0 : setShowRegisteredOnlyPopup(true);
    }
    if (to !== null && to !== void 0 ? to : onClick) {
        const action = forbidden
            ? {
                onClick: handleShowRegisteredOnlyPopup,
            }
            : { to, onClick };
        return (React.createElement(Button, Object.assign({}, action, { className: className, customStyles: true, disabled: disabled, dataTestId: buttonTestId }), content));
    }
    if (toggle) {
        return (React.createElement("div", { className: className },
            content,
            React.createElement(Toggle, { id: (_a = toggle.dataTestId) !== null && _a !== void 0 ? _a : 'Toggle', onChange: toggle.onToggle, disabled: toggle.disabled, checked: toggle.checked, greyIfUnchecked: true })));
    }
    return React.createElement("div", { className: className }, content);
};
