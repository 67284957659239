import { Avatar } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeParticipantRankingEntry, RankingsList, } from '@components/rankings/rankings';
import { ChallengeType, } from '@mika-bibb/shared/challenge-status-dto';
import { Loading } from '@components/basic-components/loading';
import { ParticipantsCount } from '@components/challenge/participants-count';
import { SectionTitle } from '@components/basic-components/section-title';
import { ViewsType } from '@typesSrc/views';
import { nestClient, useNestClientContext, } from '../context/nest-client-context';
import { useNavigate } from 'react-router';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useCallback } from 'react';
import TeamVsTeamRankings from '@components/rankings/team-vs-team-rankings';
import useChallengeContext from '../context/challenge-context';
export function ChallengeParticipantsRankingsList({ challenge, }) {
    const { nestClient } = useNestClientContext();
    const getData = useCallback(() => nestClient.getChallengeRankingInfo(challenge.id), [challenge.id, nestClient]);
    return challenge.type === ChallengeType.TEAM_VS_TEAM ? (React.createElement(TeamVsTeamRankings, { getData: getData, Entry: ChallengeParticipantRankingEntry, challenge: challenge })) : (React.createElement(RankingsList, { getData: getData, Entry: ChallengeParticipantRankingEntry, emptyMessage: "Keine Spieler/-innen gefunden", showEllipsis: false, showWinner: challenge.gameOver, desktopHeader: "Spieler/-in" }));
}
export default function ChallengeParticipantsView() {
    var _a, _b;
    const { challenge, challengeId } = useChallengeContext();
    const { state: { profile }, } = useProfile();
    const [team, setTeam] = React.useState();
    const navigate = useNavigate();
    const teamId = (_b = (_a = challenge === null || challenge === void 0 ? void 0 : challenge.ownTeamInfo) === null || _a === void 0 ? void 0 : _a.teamId) !== null && _b !== void 0 ? _b : challenge === null || challenge === void 0 ? void 0 : challenge.opponentInfo.teamId;
    const refreshOverview = () => {
        if (!teamId) {
            return;
        }
        void nestClient.getTeam(teamId).then(setTeam);
    };
    usePolling(refreshOverview, 15000, [teamId]);
    if (!challengeId) {
        return React.createElement("h1", { className: "text-error" }, "Quiz nicht gefunden");
    }
    if (!challenge || !profile) {
        return React.createElement(Loading, { fullSize: true });
    }
    const membership = team === null || team === void 0 ? void 0 : team.members.find((membership) => membership.profile.uuid === profile.uuid);
    const isAdmin = !!(membership === null || membership === void 0 ? void 0 : membership.admin);
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_DETAILS, dataTestId: "ChallengeParticipantsView", showFooter: false, header: {
            title: challenge.gameOver
                ? 'Quiz-Ergebnis'
                : 'Quiz-Teilnehmende',
            customHeaderAction: React.createElement(ParticipantsCount, null),
            showBack: true,
            onBack: () => navigate(-1),
            showHelp: true,
        } },
        React.createElement(CenterScrollPanel, { className: "flex-1", rounded: "top", header: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex flex-col items-center border-b border-solid border-darkBlue/10 mb-2 pb-2" },
                    React.createElement(Avatar, { className: "w-24 h-24 mb-2", sizes: "96px", image: challenge.opponentInfo.imageUrl, bannerType: BannerType.NONE }),
                    React.createElement("span", { className: "font-bold text-2xl" }, challenge.name),
                    challenge.type === ChallengeType.CODE && (React.createElement("div", null,
                        React.createElement("span", { className: "font-medium" }, "Code: "),
                        React.createElement("span", { className: "" }, challenge.joinCode)))),
                React.createElement("div", { className: "flex flex-row justify-between" },
                    React.createElement(SectionTitle, null, "Teilnehmende"))), footer: React.createElement(React.Fragment, null, isAdmin && (React.createElement(Button, { priority: "tertiary", className: "w-full mt-3 desktop:h-9", to: `/teams/${teamId}/results` }, "Auswertung aller Spiele"))) },
            React.createElement(ChallengeParticipantsRankingsList, { challenge: challenge }))));
}
