import { ApiError } from '@api/errors';
import { Button } from '@components/basic-components/button';
import { Input } from '@components/basic-components/input';
import { Panel } from '@components/basic-components/panel';
import { ServerErrorCode } from '@mika-bibb/shared/server-error-codes';
import { useErrorContext } from '../../context/error-context';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../../context/nest-client-context';
import React, { useState } from 'react';
import joinImage from '@public/images/team.png';
import useWatchCallback from '@utils/promise-watch';
export function JoinByCode() {
    const navigate = useNavigate();
    const { nestClient } = useNestClientContext();
    const { addError } = useErrorContext();
    const [joinCode, setJoinCode] = useState('');
    const joinChallenge = useWatchCallback(() => {
        return nestClient.joinClassroom(joinCode).then((res) => {
            if (res.success) {
                navigate(`/challenges/${res.challengeId}`);
            }
            else {
                addError(new ApiError(404, ServerErrorCode.INVALID_CODE));
            }
        });
    }, [joinCode]);
    return (React.createElement(Panel, { className: "flex flex-col items-center mb-4 mx-2" },
        React.createElement("div", { className: "flex justify-center pb-4" },
            React.createElement("img", { src: joinImage, alt: "Illustration", className: "w-24" })),
        React.createElement("p", { className: "text-center text-darkBlue/70 leading-[19px] mb-4" }, "Gib einen Code ein, um einem Klassenzimmer-Quiz beizutreten."),
        React.createElement(Input, { className: "mb-4 w-full", placeHolder: "Code eingeben", onChange: setJoinCode, dataTestId: 'enterCode' }),
        React.createElement(Button, { className: "w-full", priority: "primary", onClick: joinChallenge, dataTestId: 'joinChallenge', disabled: !joinCode }, "Spiel beitreten")));
}
