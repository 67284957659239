import { ReactComponent as ArrowRight } from '@public/icons/arrow-right.svg';
import { ChallengeType, } from '@mika-bibb/shared/challenge-status-dto';
import { Link } from 'react-router-dom';
import { OneTimePopup } from '@components/basic-components/one-time-popup';
import { ViewsType } from '@typesSrc/views';
import { formatTimeLeft } from '@utils/mixed';
import { useNestClientContext } from '../context/nest-client-context';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import DesktopHomeChallengeDisplay from '@components/home/desktop-home-challenge-display';
import DesktopHomeHeader from '@components/home/desktop-home-header';
import MobileHomeHeader from '@components/home/mobile-home-header';
import React, { useState } from 'react';
import SinglePlayerAndTeamsRankings from '@components/rankings/single-player-and-teams-rankings';
import useCategoriesInfoContext from '../context/categories-info-context';
export default function HomeView() {
    var _a, _b, _c;
    const { state: { profile }, } = useProfile();
    const { nestClient } = useNestClientContext();
    const [score, setScore] = useState();
    const [getStartedPopupOpen, setGetStartedPopupOpen] = useState(true);
    const [currentChallenge, setCurrentChallenge] = useState(undefined);
    const { categories } = useCategoriesInfoContext();
    const categoryId = (_b = currentChallenge === null || currentChallenge === void 0 ? void 0 : currentChallenge.rounds[(_a = currentChallenge.currentRound) !== null && _a !== void 0 ? _a : -1]) === null || _b === void 0 ? void 0 : _b.categoryId;
    const currentCategory = categoryId ? categories.get(categoryId) : undefined;
    usePolling(() => {
        void nestClient.getMyRecentChallenge().then(setCurrentChallenge);
        void nestClient.getMyScore().then(setScore);
    }, 10000);
    return (React.createElement(BaseView, { view: ViewsType.HOME, dataTestId: "HomeView", showHeader: false, footer: { featuredIndex: getStartedPopupOpen ? 1 : undefined } },
        React.createElement(DesktopHomeHeader, { profile: profile }),
        React.createElement(MobileHomeHeader, { profile: profile, score: score }),
        React.createElement("div", { className: "flex desktop:m-8" },
            React.createElement(DesktopHomeChallengeDisplay, { score: score }),
            currentChallenge && (React.createElement(Link, { className: "hidden bg-green p-4 text-white desktop:flex flex-row items-center justify-between text-left ml-6", to: `/challenges/${currentChallenge.id}` },
                React.createElement("div", null,
                    React.createElement("p", { className: "inline font-condensed tracking-[0.08em] text-sm font-bold" }, "Aktuelles Quiz"),
                    currentChallenge.type !== ChallengeType.SOLO && (React.createElement("p", { className: "text-darkBlue/50 inline ml-10" }, formatTimeLeft(currentChallenge.validUntil))),
                    React.createElement("p", { className: "text-xl font-bold mt-2" }, (_c = currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.text) !== null && _c !== void 0 ? _c : 'Kategorie wählen')),
                React.createElement(ArrowRight, { className: "w-3 h-3 fill-white ml-4" })))),
        React.createElement("div", { className: "desktop:mx-8 pt-6 bg-white desktop:bg-bgGray h-full flex flex-col" },
            React.createElement(SinglePlayerAndTeamsRankings, { profile: profile })),
        React.createElement(OneTimePopup, { id: "HomeViewGetStarted", mika: "normal", popupOpen: getStartedPopupOpen, setPopupOpen: setGetStartedPopupOpen, centerScrollPanel: {
                header: 'Willkommen',
            }, withSideBar: true }, "Um ein Quiz zu starten, klicke auf den gr\u00FCn markierten Reiter.")));
}
