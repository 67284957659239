import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { Loading } from '@components/basic-components/loading';
import { ViewsType } from '@typesSrc/views';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { useParams } from 'react-router-dom';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import ProfileDisplayFullscreen from '@components/profile/profile-display-fullscreen';
import React, { useCallback, useState } from 'react';
export default function OtherProfileView() {
    var _a;
    const { profileId } = useParams();
    const navigate = useNavigate();
    const { nestClient } = useNestClientContext();
    const [profile, setProfile] = useState();
    const myself = useProfile();
    if (!profileId) {
        throw new Error('profileId is undefined!');
    }
    usePolling(() => {
        void nestClient.getOtherProfile(profileId).then(setProfile);
    }, 30000, [profileId]);
    const startGame = useCallback(() => {
        navigate(`/challenges/new?type=${ChallengeType.ONE_VS_ONE}&opponent=${profileId}`);
    }, [navigate, profileId]);
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, dataTestId: "OtherProfileView", header: {
            title: 'Spieler/-in Profil',
            showBack: true,
            onBack: () => navigate(-1),
        }, showFooter: false }, profile ? (React.createElement("div", { className: "flex flex-col flex-1 px-2" },
        React.createElement(CenterScrollPanel, { className: "flex-1", footer: React.createElement("div", { className: "flex flex-col justify-center" }, profile.uuid !==
                ((_a = myself.state.profile) === null || _a === void 0 ? void 0 : _a.uuid) && (React.createElement(Button, { dataTestId: "createChallengeButton", onClick: startGame, priority: "primary" }, "1vs1"))) },
            React.createElement(ProfileDisplayFullscreen, { profile: profile })))) : (React.createElement(Loading, { className: "mt-10" }))));
}
