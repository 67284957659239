import { ChallengeType } from '../challenge-status-dto';
import { SCORE_FOR_DRAWING_1V1_GAME_PER_QUESTION, SCORE_FOR_WINNING_1V1_GAME_PER_QUESTION, } from '../constants';
export function isMultiplayerChallenge(challengeType) {
    return (challengeType === ChallengeType.TEAM ||
        challengeType === ChallengeType.TEAM_VS_TEAM ||
        challengeType === ChallengeType.CODE);
}
export function isTwoPlayerChallenge(challengeType) {
    return (challengeType === ChallengeType.ONE_VS_ONE ||
        challengeType === ChallengeType.ONE_VS_ONE_RANDOM);
}
export function isSoloChallenge(challengeType) {
    return challengeType === ChallengeType.SOLO;
}
export function isManyVsManyChallenge(challengeType) {
    return challengeType === ChallengeType.TEAM_VS_TEAM;
}
export function getBonusPoints(rounds, questionsPerRound, isDraw) {
    return Math.round(rounds *
        questionsPerRound *
        (isDraw
            ? SCORE_FOR_DRAWING_1V1_GAME_PER_QUESTION
            : SCORE_FOR_WINNING_1V1_GAME_PER_QUESTION));
}
