import { NestClient } from '@api/nest-client';
import { TokenHandler } from '@api/token-handler';
import React, { createContext, useContext, useMemo, } from 'react';
export const nestClient = new NestClient();
export const tokenHandler = new TokenHandler();
nestClient.addResponseHandler(tokenHandler);
nestClient.beforeRequestHandler = tokenHandler;
export const NestClientContext = createContext(undefined);
export const NestClientProvider = ({ children, }) => {
    const [client, setClient] = React.useState(nestClient);
    const state = useMemo(() => {
        return {
            nestClient: client,
            setNestClient: (newClient) => {
                setClient(newClient);
            },
        };
    }, [client]);
    return (React.createElement(NestClientContext.Provider, { value: state }, children));
};
export function useNestClientContext() {
    const state = useContext(NestClientContext);
    if (!state) {
        throw new Error('useNestClientContext must be used within a NestClientProvider');
    }
    return state;
}
