import { ChallengeType, SingleAnswerStateDto, } from '@mika-bibb/shared/challenge-status-dto';
import { Check } from '@components/basic-components/check';
import { Counter } from '@components/basic-components/counter';
import { classNames } from '@utils/class-names';
import { formatScore } from '@utils/formatting';
import { isManyVsManyChallenge, isSoloChallenge, isTwoPlayerChallenge, } from '@mika-bibb/shared/util/challenge';
import { overflowEllipsis } from '@utils/mixed';
import { useProfile } from '../../context/profile-context';
import React, { useCallback } from 'react';
import useCategoriesInfoContext from '../../context/categories-info-context';
export default function ChallengeRoundOverview({ challenge, round, index, }) {
    var _a, _b;
    const { state: { profile }, } = useProfile();
    const { categories } = useCategoriesInfoContext();
    const meAnswers = challenge.me.answersPerRound[index];
    const meScores = challenge.me.scoresPerRound[index];
    const myTeamScores = (_a = challenge.me.teamScoresPerRound) === null || _a === void 0 ? void 0 : _a[index];
    const othersScores = challenge.others.scoresPerRound[index];
    const roundFinishedForMe = !!round.questionIds.length &&
        round.questionIds.length ===
            meAnswers.answers.filter((answer) => answer !== SingleAnswerStateDto.NOT_ANSWERED).length;
    const roundFinished = roundFinishedForMe && round.opponentDone;
    const getOthersPrefix = useCallback(() => {
        switch (challenge.type) {
            case ChallengeType.TEAM_VS_TEAM:
            case ChallengeType.ONE_VS_ONE:
            case ChallengeType.ONE_VS_ONE_RANDOM:
                return challenge.opponentInfo.name;
            case ChallengeType.TEAM:
                return 'Team ∅';
            case ChallengeType.CODE:
                return 'Klassenzimmer ∅';
            default:
                return undefined;
        }
    }, [challenge]);
    const getWinner = useCallback((player) => {
        if (!roundFinished ||
            isSoloChallenge(challenge.type) ||
            isManyVsManyChallenge(challenge.type)) {
            return false;
        }
        if (player === 'me') {
            return meScores > othersScores;
        }
        if (!isTwoPlayerChallenge(challenge.type)) {
            return false;
        }
        return othersScores > meScores;
    }, [challenge.type, meScores, othersScores, roundFinished]);
    return (React.createElement("div", { className: classNames('text-sm border-b border-solid border-darkBlue/10 pt-3 pb-2', round.categoryId
            ? 'text-darkBlue font-medium'
            : 'text-darkBlue/50 font-base') },
        React.createElement("div", { className: "grid grid-cols-[auto_1fr_auto] items-center gap-x-2" },
            React.createElement(Counter, null, index + 1),
            React.createElement("div", null, round.categoryId
                ? categories.get(round.categoryId).text
                : 'Runde noch nicht gespielt'),
            React.createElement(Check, { checked: roundFinishedForMe })),
        round.categoryId && (React.createElement("div", { className: classNames('grid items-start pt-2', isSoloChallenge(challenge.type)
                ? 'grid-cols-1'
                : 'grid-cols-[auto_auto] gap-x-4') },
            React.createElement("div", { className: "min-w-0" },
                React.createElement(Score, { questions: round.questionIds.length, score: meScores, teamScore: myTeamScores, teamName: (_b = challenge.ownTeamInfo) === null || _b === void 0 ? void 0 : _b.name, prefix: profile === null || profile === void 0 ? void 0 : profile.nickname, winner: getWinner('me') })),
            challenge.type !== ChallengeType.SOLO && (React.createElement("div", { className: "justify-self-end min-w-0" },
                React.createElement(Score, { questions: round.questionIds.length, score: othersScores, prefix: getOthersPrefix(), winner: getWinner('others') })))))));
}
const Score = ({ questions, score, winner, prefix, teamScore, teamName }) => {
    return (React.createElement("div", null,
        teamScore !== undefined && (React.createElement("div", { className: classNames('inline-grid grid-cols-[1fr_auto_auto] items-center gap-px ml-auto px-2 font-medium mb-1 mr-1', winner
                ? 'bg-green/10 text-green'
                : 'bg-darkBlue/10 text-darkBlue') },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "justify-self-end" }, teamName),
                React.createElement("div", null, ":"),
                React.createElement("div", { className: "pl-1" },
                    formatScore(teamScore),
                    "/",
                    questions)))),
        React.createElement("div", { className: classNames('inline-grid grid-cols-[1fr_auto_auto] items-center gap-px ml-auto px-2 font-medium', winner
                ? 'bg-green/10 text-green'
                : 'bg-darkBlue/10 text-darkBlue') },
            React.createElement("div", { className: classNames('relative overflow-ellipsis whitespace-nowrap overflow-hidden justify-self-end', winner && 'pl-5') },
                winner && (React.createElement("div", { className: "icon-trophy absolute top-0 bottom-0 left-0 text-base leading-5" })),
                React.createElement("div", { className: overflowEllipsis }, prefix)),
            ":",
            React.createElement("div", { className: "pl-1" },
                formatScore(score),
                "/",
                questions))));
};
