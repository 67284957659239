import { Avatar } from '@components/basic-components/avatar/avatar';
import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Link } from 'react-router-dom';
import { ReactComponent as Points } from '@public/icons/points.svg';
import React from 'react';
import WelcomeHeaderPart from '@components/home/welcome-header-part';
export default function MobileHomeHeader({ profile, score, }) {
    var _a;
    return (React.createElement("div", { className: "desktop:hidden w-full border-green border-b px-6 py-2 bg-white " },
        React.createElement("div", { className: "grid grid-cols-5 py-2" },
            React.createElement(WelcomeHeaderPart, { profile: profile }),
            React.createElement(Link, { to: "/profile", className: "justify-self-end" },
                React.createElement(Avatar, { className: "w-[52px] h-[52px]", sizes: "52px", image: profile === null || profile === void 0 ? void 0 : profile.avatarURL, bannerType: (_a = profile === null || profile === void 0 ? void 0 : profile.bannerType) !== null && _a !== void 0 ? _a : BannerType.NONE })),
            React.createElement("div", { className: "desktop:hidden mt-4 text-darkBlue flex flex-row items-center col-span-4" },
                React.createElement(Points, { className: "w-4 h-4 mr-2 fill-green" }), score !== null && score !== void 0 ? score : '--',
                " ",
                React.createElement("b", { className: "ml-1" }, "Punkte")))));
}
