import { ChallengeType } from '@mika-bibb/shared/challenge-status-dto';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import SearchPlayer from '@components/search-player';
export default function Search1v1Opponent() {
    const navigate = useNavigate();
    const startGame = useCallback(async (selectedPlayers) => {
        const selectedPlayer = selectedPlayers[0];
        if (!selectedPlayer) {
            return;
        }
        navigate(`/challenges/new?type=${ChallengeType.ONE_VS_ONE}&opponent=${selectedPlayer}`);
        return await Promise.resolve();
    }, [navigate]);
    return (React.createElement(SearchPlayer, { singleChoice: true, button: { text: 'Spiel starten', onClick: startGame } }));
}
