import { Button } from '@components/basic-components/button';
import { Check } from '@components/basic-components/check';
import { classNames } from '@utils/class-names';
import React from 'react';
export function CategoryCard({ name, chosen, onClick, isDisabled = false, }) {
    return (React.createElement(Button, { customStyles: true, className: classNames('flex justify-start items-center gap-x-4 w-full p-4 border-2 text-darkBlue font-medium text-sm', chosen
            ? 'font-bold border-darkBlue/30 bg-green/30'
            : 'border-darkBlue/10'), disabled: chosen || isDisabled, onClick: onClick, dataTestId: `category-card-${name}` },
        name,
        React.createElement(Check, { className: "ml-auto", checked: chosen })));
}
