import { ReactComponent as Jewelry1 } from '@public/images/avatar-layers/jewelry/jewelry_1.svg';
import { ReactComponent as Jewelry2 } from '@public/images/avatar-layers/jewelry/jewelry_2.svg';
import { ReactComponent as Jewelry3 } from '@public/images/avatar-layers/jewelry/jewelry_3.svg';
import { ReactComponent as Jewelry4 } from '@public/images/avatar-layers/jewelry/jewelry_4.svg';
import { ReactComponent as Jewelry5 } from '@public/images/avatar-layers/jewelry/new_jewelry_2.svg';
import { ReactComponent as Jewelry6 } from '@public/images/avatar-layers/jewelry/new_jewelry_3.svg';
import { ReactComponent as Jewelry7 } from '@public/images/avatar-layers/jewelry/new_jewelry_4.svg';
import { ReactComponent as Jewelry8 } from '@public/images/avatar-layers/jewelry/new_jewelry_6.svg';
import { ReactComponent as Jewelry9a } from '@public/images/avatar-layers/jewelry/Creolen/headtype1-new_jewelry_1_upd.svg';
import { ReactComponent as Jewelry9b } from '@public/images/avatar-layers/jewelry/Creolen/headtype2-new_jewelry_1_upd.svg';
import { ReactComponent as Jewelry9c } from '@public/images/avatar-layers/jewelry/Creolen/headtype3-new_jewelry_1_upd.svg';
import { ReactComponent as Jewelry9d } from '@public/images/avatar-layers/jewelry/Creolen/headtype4-new_jewelry_1_upd.svg';
import { ReactComponent as Jewelry9e } from '@public/images/avatar-layers/jewelry/Creolen/headtype5-new_jewelry_1_upd.svg';
import React from 'react';
export const jewelryColorsOrdered = ['#000000'];
export const jewelryShapesOrdered = [
    undefined,
    'Jewelry1',
    'Jewelry2',
    'Jewelry3',
    'Jewelry4',
    'Jewelry5',
    'Jewelry6',
    'Jewelry7',
    'Jewelry8',
    'Jewelry9',
];
export const jewelryConfig = {
    '#000000': {
        Jewelry1: React.createElement(Jewelry1, null),
        Jewelry2: React.createElement(Jewelry2, null),
        Jewelry3: React.createElement(Jewelry3, null),
        Jewelry4: React.createElement(Jewelry4, null),
        Jewelry5: React.createElement(Jewelry5, null),
        Jewelry6: React.createElement(Jewelry6, null),
        Jewelry7: React.createElement(Jewelry7, null),
        Jewelry8: React.createElement(Jewelry8, null),
        Jewelry9: React.createElement(Jewelry9a, null),
    },
};
export const jewelryConfigBody1 = Object.assign(Object.assign({}, jewelryConfig), { '#000000': Object.assign(Object.assign({}, jewelryConfig['#000000']), { Jewelry9: React.createElement(Jewelry9a, null) }) });
export const jewelryConfigBody2 = Object.assign(Object.assign({}, jewelryConfig), { '#000000': Object.assign(Object.assign({}, jewelryConfig['#000000']), { Jewelry9: React.createElement(Jewelry9b, null) }) });
export const jewelryConfigBody3 = Object.assign(Object.assign({}, jewelryConfig), { '#000000': Object.assign(Object.assign({}, jewelryConfig['#000000']), { Jewelry9: React.createElement(Jewelry9c, null) }) });
export const jewelryConfigBody4 = Object.assign(Object.assign({}, jewelryConfig), { '#000000': Object.assign(Object.assign({}, jewelryConfig['#000000']), { Jewelry9: React.createElement(Jewelry9d, null) }) });
export const jewelryConfigBody5 = Object.assign(Object.assign({}, jewelryConfig), { '#000000': Object.assign(Object.assign({}, jewelryConfig['#000000']), { Jewelry9: React.createElement(Jewelry9e, null) }) });
// must match the body order
export const jewelryConfigsOrdered = [
    jewelryConfigBody1,
    jewelryConfigBody2,
    jewelryConfigBody3,
    jewelryConfigBody4,
    jewelryConfigBody5,
];
