export default class MapWithDefaultValue {
    constructor(defaultValue) {
        this.defaultValue = defaultValue;
        this.m = new Map();
    }
    get(key) {
        if (this.has(key)) {
            return this.m.get(key);
        }
        return this.defaultValue;
    }
    has(key) {
        return this.m.has(key);
    }
    set(key, value) {
        this.m.set(key, value);
        return this;
    }
    values() {
        return this.m.values();
    }
    keyValuePairs() {
        const entries = [];
        for (const entry of this.m) {
            entries.push(entry);
        }
        return entries;
    }
}
