import { classNames } from '@utils/class-names';
import React from 'react';
function ToggleButton({ icon, label, isLeftButton, leftSelected, setLeftSelected, disabled, }) {
    const active = isLeftButton === leftSelected;
    const getButtonStyle = (active, disabled) => {
        if (active) {
            if (disabled) {
                return 'bg-orange/20 text-white font-bold';
            }
            else {
                return 'bg-orange text-white font-bold';
            }
        }
        else if (disabled) {
            return 'text-darkBlue/20';
        }
        else {
            return 'text-darkBlue/70';
        }
    };
    return (React.createElement("button", { className: classNames('m-1 px-2 rounded-sm grid grid-cols-[auto_auto] items-center justify-center leading-4 text-sm', getButtonStyle(active, disabled)), onClick: () => setLeftSelected(isLeftButton), "data-testid": isLeftButton ? 'left-tab-toggle' : 'right-tab-toggle', disabled: disabled, "data-testactive": active },
        icon,
        React.createElement("div", { className: "overflow-ellipsis whitespace-nowrap overflow-hidden" }, label)));
}
export function TabsToggle({ labelLeft, labelRight, iconLeft, iconRight, leftSelected, setLeftSelected, className, disabled, }) {
    return (React.createElement("div", { className: classNames('bg-darkBlue/10 h-11 rounded-sm my-4 grid grid-cols-2 flex-shrink-0', className) },
        React.createElement(ToggleButton, { isLeftButton: true, leftSelected: leftSelected, setLeftSelected: setLeftSelected, label: labelLeft, icon: iconLeft, disabled: disabled }),
        React.createElement(ToggleButton, { isLeftButton: false, leftSelected: leftSelected, setLeftSelected: setLeftSelected, label: labelRight, icon: iconRight, disabled: disabled })));
}
