import { AvatarByConfig, defaultAvatarConfig, } from '@components/basic-components/avatar/avatar-by-config';
import { AvatarConfigComponent } from '@components/basic-components/avatar/avatar-config';
import { Button } from '@components/basic-components/button';
import { Panel } from '@components/basic-components/panel';
import { ViewsType } from '@typesSrc/views';
import { getAvatarFileName } from '@utils/mixed';
import { migrate } from '@utils/migrations/migrate';
import { svgToBlob } from '@components/basic-components/avatar/svgToBlob';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
export const CreateAvatarView = () => {
    const { state: { profile }, updateProfile, fetchProfile, } = useProfile();
    const { nestClient } = useNestClientContext();
    const [avatarConfig, setAvatarConfig] = useState(defaultAvatarConfig);
    const svgContainerRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (profile === null || profile === void 0 ? void 0 : profile.avatarConfig) {
            setAvatarConfig(migrate(profile.avatarConfig));
        }
    }, [profile]);
    const handleDone = useWatchCallback(async () => {
        await Promise.all([
            updateProfile({
                avatarConfig,
                avatarURL: undefined,
            }),
            handleAvatarUpload(saveAvatarSvg()),
        ]);
        fetchProfile();
        navigate('/profile/avatar-badge-settings');
    }, [avatarConfig, svgContainerRef, profile]);
    function handleAvatarUpload(blob) {
        if (!blob || !profile) {
            return;
        }
        return nestClient.updateProfilePicture(blob, getAvatarFileName(profile.uuid));
    }
    const saveAvatarSvg = useCallback(() => {
        return svgToBlob(svgContainerRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [svgContainerRef, avatarConfig]);
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, dataTestId: "CreateAvatarView", showFooter: false, header: {
            showBack: true,
            onBack: () => navigate(-1),
            forceBackgroundColor: 'bg-white',
            customHeaderAction: (React.createElement(Button, { customStyles: true, className: "relative text-green font-bold font-condensed tracking-widest", onClick: handleDone, busySpinnerSize: 6, dataTestId: "saveAvatarButton" }, "Speichern")),
            title: 'Avatar erstellen',
        }, footer: { activeItem: ViewsType.PROFILE } },
        React.createElement("div", { className: "flex-1 flex flex-col" },
            React.createElement(Panel, { className: "flex justify-center items-center" },
                React.createElement(AvatarByConfig, { config: avatarConfig, className: "w-50", ref: svgContainerRef })),
            React.createElement(AvatarConfigComponent, { avatarConfig: avatarConfig, setAvatarConfig: setAvatarConfig }))));
};
