import { ReactComponent as Copy } from '@public/icons/copy.svg';
import { Input } from '@components/basic-components/input';
import { MAX_CHALLENGE_NAME_LENGTH, MIN_CHALLENGE_NAME_LENGTH, } from '@mika-bibb/shared/constants';
import { copyTextToClipboard } from '@utils/clipboard';
import { useNestClientContext } from '../../context/nest-client-context';
import React, { useCallback, useState } from 'react';
import createImage from '@public/images/game.png';
import debounce from 'lodash.debounce';
export function CreateClassroom({ createdChallenge, }) {
    const [copied, setCopied] = useState(false);
    const [showNameError, setShowNameError] = useState(false);
    const { nestClient } = useNestClientContext();
    const onNameChange = async (newName) => {
        if (!newName) {
            return;
        }
        const name = newName.trim();
        if (name.length < MIN_CHALLENGE_NAME_LENGTH ||
            name.length > MAX_CHALLENGE_NAME_LENGTH) {
            setShowNameError(true);
            return;
        }
        setShowNameError(false);
        await nestClient.renameChallenge({
            challengeId: createdChallenge.id,
            name,
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDebouncedNameChange = useCallback(debounce(onNameChange, 500), [
        createdChallenge,
    ]);
    const onCopy = useCallback(() => {
        copyTextToClipboard(createdChallenge.joinCode);
        setCopied(true);
    }, [createdChallenge]);
    return (React.createElement("div", { className: "flex flex-col items-center" },
        React.createElement("div", { className: "flex justify-center pb-4" },
            React.createElement("img", { src: createImage, alt: "Illustration", className: "w-32" })),
        React.createElement("button", { className: "rounded-sm border border-solid border-darkBlue/10 w-full mb-4 h-16 text-darkBlue flex flex-row justify-between items-center p-4 font-semibold", onClick: onCopy },
            React.createElement("div", null,
                createdChallenge.joinCode,
                copied && (React.createElement("p", { className: "font-normal inline" }, ' (kopiert)'))),
            React.createElement(Copy, { className: "w-6 h-6 fill-green" })),
        React.createElement("div", { className: "text-center text-darkBlue/70 mb-6" }, "Teile diesen Code mit Deinen Mitspielenden, damit sie sich Deinem Klassenzimmer-Quiz anschlie\u00DFen k\u00F6nnen."),
        React.createElement(Input, { className: "w-full mb-4", placeHolder: "Klassenzimmername (optional)", onChange: onDebouncedNameChange, dataTestId: 'challengeName', trimSpaces: true }),
        showNameError && (React.createElement("p", { className: "text-red/100 text-center mb-4", "data-testid": "challengeNameError" }, "Der Name muss 3 bis 20 Zeichen lang sein."))));
}
