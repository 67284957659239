// man old
import { ReactComponent as ManHair1a } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_1a.svg';
import { ReactComponent as ManHair1b } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_1b.svg';
import { ReactComponent as ManHair1c } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_1c.svg';
import { ReactComponent as ManHair1d } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_1d.svg';
import { ReactComponent as ManHair1e } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_1e.svg';
import { ReactComponent as ManHair2a } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_2a.svg';
import { ReactComponent as ManHair2b } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_2b.svg';
import { ReactComponent as ManHair2c } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_2c.svg';
import { ReactComponent as ManHair2d } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_2d.svg';
import { ReactComponent as ManHair2e } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_2e.svg';
import { ReactComponent as ManHair3a } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_3a.svg';
import { ReactComponent as ManHair3b } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_3b.svg';
import { ReactComponent as ManHair3c } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_3c.svg';
import { ReactComponent as ManHair3d } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_3d.svg';
import { ReactComponent as ManHair3e } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_3e.svg';
import { ReactComponent as ManHair4a } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_4a.svg';
import { ReactComponent as ManHair4b } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_4b.svg';
import { ReactComponent as ManHair4c } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_4c.svg';
import { ReactComponent as ManHair4d } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_4d.svg';
import { ReactComponent as ManHair4e } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_4e.svg';
import { ReactComponent as ManHair5a } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_5a.svg';
import { ReactComponent as ManHair5b } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_5b.svg';
import { ReactComponent as ManHair5c } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_5c.svg';
import { ReactComponent as ManHair5d } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_5d.svg';
import { ReactComponent as ManHair5e } from '@public/images/avatar-layers/hair/body_2/man/man_hairstyle_5e.svg';
// man new
import { ReactComponent as ManHair6a } from '@public/images/avatar-layers/hair/body_2/man/new_hairstyle_3a.svg';
import { ReactComponent as ManHair6b } from '@public/images/avatar-layers/hair/body_2/man/new_hairstyle_3b.svg';
import { ReactComponent as ManHair6c } from '@public/images/avatar-layers/hair/body_2/man/new_hairstyle_3c.svg';
import { ReactComponent as ManHair6d } from '@public/images/avatar-layers/hair/body_2/man/new_hairstyle_3d.svg';
import { ReactComponent as ManHair6e } from '@public/images/avatar-layers/hair/body_2/man/new_hairstyle_3e.svg';
// woman old
import { ReactComponent as WomanHair1a } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_1a.svg';
import { ReactComponent as WomanHair1b } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_1b.svg';
import { ReactComponent as WomanHair1c } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_1c.svg';
import { ReactComponent as WomanHair1d } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_1d.svg';
import { ReactComponent as WomanHair1e } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_1e.svg';
import { ReactComponent as WomanHair2a } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_2a.svg';
import { ReactComponent as WomanHair2b } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_2b.svg';
import { ReactComponent as WomanHair2c } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_2c.svg';
import { ReactComponent as WomanHair2d } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_2d.svg';
import { ReactComponent as WomanHair2e } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_2e.svg';
import { ReactComponent as WomanHair3a } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_3a.svg';
import { ReactComponent as WomanHair3b } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_3b.svg';
import { ReactComponent as WomanHair3c } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_3c.svg';
import { ReactComponent as WomanHair3d } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_3d.svg';
import { ReactComponent as WomanHair3e } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_3e.svg';
import { ReactComponent as WomanHair4a } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_4a.svg';
import { ReactComponent as WomanHair4b } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_4b.svg';
import { ReactComponent as WomanHair4c } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_4c.svg';
import { ReactComponent as WomanHair4d } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_4d.svg';
import { ReactComponent as WomanHair4e } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_4e.svg';
import { ReactComponent as WomanHair5a } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_5a.svg';
import { ReactComponent as WomanHair5b } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_5b.svg';
import { ReactComponent as WomanHair5c } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_5c.svg';
import { ReactComponent as WomanHair5d } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_5d.svg';
import { ReactComponent as WomanHair5e } from '@public/images/avatar-layers/hair/body_2/woman/woman_hairstyle_5e.svg';
// woman new
import { ReactComponent as WomanHair6a } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_1a.svg';
import { ReactComponent as WomanHair6b } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_1b.svg';
import { ReactComponent as WomanHair6c } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_1c.svg';
import { ReactComponent as WomanHair6d } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_1d.svg';
import { ReactComponent as WomanHair6e } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_1e.svg';
import { ReactComponent as WomanHair7a } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_2a.svg';
import { ReactComponent as WomanHair7b } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_2b.svg';
import { ReactComponent as WomanHair7c } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_2c.svg';
import { ReactComponent as WomanHair7d } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_2d.svg';
import { ReactComponent as WomanHair7e } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_2e.svg';
import { ReactComponent as WomanHair8a } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_4a.svg';
import { ReactComponent as WomanHair8b } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_4b.svg';
import { ReactComponent as WomanHair8c } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_4c.svg';
import { ReactComponent as WomanHair8d } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_4d.svg';
import { ReactComponent as WomanHair8e } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_4e.svg';
import { ReactComponent as WomanHair9a } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_5a.svg';
import { ReactComponent as WomanHair9b } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_5b.svg';
import { ReactComponent as WomanHair9c } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_5c.svg';
import { ReactComponent as WomanHair9d } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_5d.svg';
import { ReactComponent as WomanHair9e } from '@public/images/avatar-layers/hair/body_2/woman/new_hairstyle_5e.svg';
export const Body2 = {
    WomanHair1a,
    WomanHair1b,
    WomanHair1c,
    WomanHair1d,
    WomanHair1e,
    WomanHair2a,
    WomanHair2b,
    WomanHair2c,
    WomanHair2d,
    WomanHair2e,
    WomanHair3a,
    WomanHair3b,
    WomanHair3c,
    WomanHair3d,
    WomanHair3e,
    WomanHair4a,
    WomanHair4b,
    WomanHair4c,
    WomanHair4d,
    WomanHair4e,
    WomanHair5a,
    WomanHair5b,
    WomanHair5c,
    WomanHair5d,
    WomanHair5e,
    WomanHair6a,
    WomanHair6b,
    WomanHair6c,
    WomanHair6d,
    WomanHair6e,
    WomanHair7a,
    WomanHair7b,
    WomanHair7c,
    WomanHair7d,
    WomanHair7e,
    WomanHair8a,
    WomanHair8b,
    WomanHair8c,
    WomanHair8d,
    WomanHair8e,
    WomanHair9a,
    WomanHair9b,
    WomanHair9c,
    WomanHair9d,
    WomanHair9e,
    ManHair1a,
    ManHair1b,
    ManHair1c,
    ManHair1d,
    ManHair1e,
    ManHair2a,
    ManHair2b,
    ManHair2c,
    ManHair2d,
    ManHair2e,
    ManHair3a,
    ManHair3b,
    ManHair3c,
    ManHair3d,
    ManHair3e,
    ManHair4a,
    ManHair4b,
    ManHair4c,
    ManHair4d,
    ManHair4e,
    ManHair5a,
    ManHair5b,
    ManHair5c,
    ManHair5d,
    ManHair5e,
    ManHair6a,
    ManHair6b,
    ManHair6c,
    ManHair6d,
    ManHair6e,
};
