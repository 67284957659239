import { Environment } from '../../environment.global';
import { PUSH_TOKEN_KEY, } from '@components/notification-handlers/notification-handler';
import { Preferences } from '@capacitor/preferences';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import ManualPromise from '@mika-bibb/shared/util/manual-promise';
export class DesktopNotificationHandler {
    async init() {
        const tokenPromise = new ManualPromise();
        if (!('Notification' in window)) {
            tokenPromise.reject('This browser does not support desktop notification');
        }
        else {
            let userResult = Notification.permission;
            if (Notification.permission !== 'granted') {
                userResult = await Notification.requestPermission();
            }
            if (userResult === 'granted') {
                const firebaseConfig = {
                    apiKey: Environment.FIREBASE_API_KEY,
                    authDomain: Environment.FIREBASE_AUTH_DOMAIN,
                    projectId: Environment.FIREBASE_PROJECT_ID,
                    storageBucket: Environment.FIREBASE_STORAGE_BUCKET,
                    messagingSenderId: Environment.FIREBASE_MESSAGING_SENDER_ID,
                    appId: Environment.FIREBASE_APP_ID,
                    measurementId: Environment.FIREBASE_MEASUREMENT_ID,
                };
                const firebaseApp = initializeApp(firebaseConfig);
                const messaging = getMessaging(firebaseApp);
                const registeredServiceWorker = await navigator.serviceWorker.ready;
                const token = await getToken(messaging, {
                    serviceWorkerRegistration: registeredServiceWorker,
                });
                void Preferences.set({
                    key: PUSH_TOKEN_KEY,
                    value: token,
                });
                tokenPromise.resolve(token);
            }
        }
        return tokenPromise;
    }
    async finalize() {
        var _a;
        (_a = this.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(this);
        return Promise.resolve();
    }
    async handleMissingPermissions() {
        return Promise.resolve();
    }
}
const desktopNotificationHandler = new DesktopNotificationHandler();
export default desktopNotificationHandler;
