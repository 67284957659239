import { BannerType } from '@mika-bibb/shared/profile.dto';
import { Loading } from '@components/basic-components/loading';
import { ReactComponent as OverlayTrainee } from '@public/images/banner/trainee.svg';
import { ReactComponent as OverlayTrainer } from '@public/images/banner/trainer.svg';
import { classNames } from '@utils/class-names';
import Image from '@components/image';
import React, { useState } from 'react';
import defaultAvatarTeam from '@public/images/default-avatar-registered.svg';
import fallbackAvatarSrc from '@public/images/mika/mika.svg';
export var DefaultAvatar;
(function (DefaultAvatar) {
    DefaultAvatar["GUEST"] = "guest";
    DefaultAvatar["TEAM"] = "team";
})(DefaultAvatar || (DefaultAvatar = {}));
const fallbackAvatar = (React.createElement(Image, { src: fallbackAvatarSrc, alt: "fallback avatar", className: "w-full h-full object-contain" }));
const imageClasses = 'w-full h-full object-cover object-center';
export const Avatar = ({ className, image, bannerType, sizes, defaultAvatar = DefaultAvatar.GUEST, preview, }) => {
    let avatar;
    const previewSrc = preview ? URL.createObjectURL(preview) : undefined;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);
    function handleImageLoad() {
        setImageLoaded(true);
    }
    function handleImageError() {
        setImageError(true);
    }
    if (previewSrc) {
        avatar = (React.createElement(Image, { src: previewSrc, alt: "avatar", className: imageClasses, onLoad: handleImageLoad, onError: handleImageError }));
    }
    else if (image) {
        avatar = (React.createElement(Image, { asset: image, alt: "avatar", className: classNames(imageClasses, imageLoaded ? 'visible' : 'invisible'), sizes: sizes !== null && sizes !== void 0 ? sizes : '96px', onLoad: handleImageLoad, onError: handleImageError }));
    }
    else if (defaultAvatar === DefaultAvatar.TEAM) {
        avatar = (React.createElement("img", { src: defaultAvatarTeam, className: imageClasses, alt: "defaultAvatarTeam", onLoad: handleImageLoad, onError: handleImageError }));
    }
    return (React.createElement("div", { "data-testid": "Avatar", className: classNames(className) },
        React.createElement("div", { className: classNames('relative w-full pt-full') },
            React.createElement("div", { className: classNames('absolute inset-0 rounded-full', !!avatar && 'overflow-hidden') },
                avatar && !imageError ? avatar : fallbackAvatar,
                avatar && !imageError && (React.createElement("div", { className: "absolute inset-0 rounded-full border border-solid border-darkBlue/10" })),
                bannerType === BannerType.TRAINER && (React.createElement(OverlayTrainer, { className: "absolute w-full h-full bottom-0 rounded-full" })),
                bannerType === BannerType.TRAINEE && (React.createElement(OverlayTrainee, { className: "absolute w-full h-full bottom-0 rounded-full" })),
                avatar && !imageLoaded && !imageError && (React.createElement(Loading, { size: 4, className: "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" }))))));
};
