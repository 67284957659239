import { useEffect, useState } from 'react';
export var Orientation;
(function (Orientation) {
    Orientation[Orientation["Landscape"] = 0] = "Landscape";
    Orientation[Orientation["Portrait"] = 1] = "Portrait";
})(Orientation || (Orientation = {}));
export function useOrientation() {
    const parseOrientation = (screenOrientation) => {
        if (!screenOrientation) {
            return Orientation.Portrait;
        }
        switch (screenOrientation.type) {
            case 'portrait-secondary':
            case 'portrait-primary':
                return Orientation.Portrait;
            case 'landscape-secondary':
            case 'landscape-primary':
                return Orientation.Landscape;
            default:
                return Orientation.Portrait;
        }
    };
    const [orientation, setOrientation] = useState(Orientation.Portrait);
    useEffect(() => {
        const handleOrientationChange = () => {
            setOrientation(parseOrientation(window.screen.orientation));
        };
        window.addEventListener('orientationchange', handleOrientationChange);
        setOrientation(parseOrientation(window.screen.orientation));
        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, []);
    return {
        orientation,
    };
}
