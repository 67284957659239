import { Preferences } from '@capacitor/preferences';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
export const ConsentContext = createContext(undefined);
async function getSystemConsent() {
    const { value } = await Preferences.get({ key: 'consent' });
    if (value) {
        return value === 'true';
    }
    return undefined;
}
async function setSystemConsent(value) {
    await Preferences.set({ key: 'consent', value: value.toString() });
    return value;
}
export const ConsentProvider = ({ children }) => {
    const [consent, _setConsent] = useState('loading');
    useEffect(() => {
        void getSystemConsent().then(_setConsent);
    }, []);
    const setConsent = useCallback((value) => {
        void setSystemConsent(value).then(_setConsent);
    }, []);
    const context = useMemo(() => ({
        consent,
        setConsent,
    }), [consent, setConsent]);
    return (React.createElement(ConsentContext.Provider, { value: context }, children));
};
/*
 * This hook is used to get and set the user's consent to the use of cookies.
 * The consent is stored in the Capacitor Preferences plugin.
 * The hook returns the consent  value (undefined, if not yet set) and a function to set the consent value.
 */
export function useConsent() {
    const state = useContext(ConsentContext);
    if (!state) {
        throw new Error('useErrorContext must be used within a ErrorProvider');
    }
    return state;
}
