var _a;
import { closeInAppBrowser } from '@utils/in-app-browser';
import { App as CapacitorApp } from '@capacitor/app';
import { Environment } from '../environment.global';
class AppInitializer {
    static async initBackButton(router) {
        await CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            // is question view, ie url is of form challenges/UUID/INDEX/INDEX
            if (window.location.pathname.match(/challenges\/[\w-]+\/\d+\/\d+/)) {
                return;
            }
            // is challenge detail view, ie url is of form challenges/UUID
            if (window.location.pathname.match(/challenges\/[\w-]+/)) {
                void router.navigate('/challenges', { replace: true });
                return;
            }
            if (window.location.pathname.match(/home/)) {
                void CapacitorApp.exitApp();
            }
            // is top level tab - go back to home
            if (window.location.pathname.match(/[\w-]/)) {
                void router.navigate('/home', { replace: true });
                return;
            }
            if (document.fullscreenElement) {
                void document.exitFullscreen();
            }
            else if (canGoBack) {
                void router.navigate(-1);
            }
            else {
                void CapacitorApp.exitApp();
            }
        });
    }
    /**
     * See also https://gitlab.sprylab.com/berliner-dom/berliner-dom-app/-/blob/develop/app/src/index.tsx#L122-L144
     */
    static async initDeepLinking(router) {
        // Note that this might be called multiple for a single link. The implementation must be robust enough.
        const onDeepLinkOpened = (url) => {
            // Deep links typically originate from the in-app browser. Make sure to close it.
            closeInAppBrowser();
            // Navigate to the linked URL.
            // Example url: https://app.mikabibb.de/tabs/tab2
            // slug = /tabs/tab2
            const slug = (url !== null && url !== void 0 ? url : '').split(Environment.PUBLIC_URL).pop();
            if (slug) {
                void router.navigate(slug);
            }
        };
        await CapacitorApp.addListener('appUrlOpen', (event) => {
            // A deep link was opened (https://developer.apple.com/ios/universal-links/, https://developer.android.com/training/app-links/verify-android-applinks)
            onDeepLinkOpened(event.url);
        });
        /**
         * See also https://gitlab.sprylab.com/berliner-dom/berliner-dom-app/-/blob/develop/app/src/index.tsx#L164
         */
        window.addEventListener('appUrlOpen', (event) => {
            // We triggered a deep link internally. This can happen if the in-app browser encountered a link that should be opened in the app.
            onDeepLinkOpened(event.url);
        });
    }
}
_a = AppInitializer;
AppInitializer.init = async (router) => {
    await _a.initBackButton(router);
    await _a.initDeepLinking(router);
};
export default AppInitializer;
