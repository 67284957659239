var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { classNames } from '@utils/class-names';
import React, { useCallback } from 'react';
export const Toggle = (_a) => {
    var { id, checked, onChange, greyIfUnchecked = false, dataTestId = 'Toggle', disabled } = _a, inputProps = __rest(_a, ["id", "checked", "onChange", "greyIfUnchecked", "dataTestId", "disabled"]);
    const handleOnChange = useCallback(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(!checked);
    }, [checked, onChange]);
    return (React.createElement("label", { htmlFor: id, "data-testid": dataTestId },
        React.createElement("input", Object.assign({ type: "checkbox", id: id, checked: checked, disabled: disabled, onChange: handleOnChange }, inputProps, { className: "absolute invisible" })),
        React.createElement("div", { className: classNames('flex w-14 p-0.5 rounded-full transition-colors', disabled
                ? 'bg-lightGray'
                : greyIfUnchecked && !checked
                    ? 'bg-backgroundGray'
                    : 'bg-green') },
            React.createElement("div", { className: classNames('w-6 h-6 bg-white shadow-sm rounded-full transition-all', checked ? 'ml-7' : 'ml-0') }))));
};
