import { SingleCategoryStatistics } from '@components/statistics/single-category-statistics';
import { TabsToggle } from '@components/basic-components/tabs-toggle';
import { classNames } from '@utils/class-names';
import { formatStandingsString } from '@utils/formatting';
import React, { useState } from 'react';
import sortBy from 'lodash.sortby';
import useCategoriesInfoContext from '../../context/categories-info-context';
export function InTeamStatisticsInfo({ inTeamStatistics, allTime, setAllTime, }) {
    const [memberTab, setMemberTab] = useState(true);
    const categories = useCategoriesInfoContext();
    const sortedCategories = sortBy(inTeamStatistics.categories, (category) => {
        return -category.numCorrectAnswers / category.numTotalAnswers;
    });
    const memberTabVisible = classNames(!memberTab && 'hidden desktop:flex');
    const teamTabVisible = classNames(memberTab && 'hidden desktop:flex');
    return (React.createElement(React.Fragment, null,
        React.createElement(TabsToggle, { labelLeft: "Gesamte Zeit", labelRight: "Letzte Woche", leftSelected: allTime, setLeftSelected: setAllTime }),
        React.createElement(TabsToggle, { labelLeft: `Teilnehmer/-in: ${inTeamStatistics.memberName}`, labelRight: `Team-Durchschnitt: ${inTeamStatistics.teamName}`, leftSelected: memberTab, setLeftSelected: setMemberTab, className: "desktop:hidden" }),
        React.createElement("div", { className: "grid grid-cols-1 desktop:grid-cols-2" },
            React.createElement("div", { className: classNames(memberTabVisible, 'text-darkBlue text-xl font-bold') },
                "Teilnehmer/-in: ",
                inTeamStatistics.memberName),
            React.createElement("div", { className: classNames(teamTabVisible, 'text-darkBlue text-xl font-bold') },
                "Team-Durchschnitt: ",
                inTeamStatistics.teamName),
            React.createElement("div", { className: classNames(memberTabVisible, 'text-lg text-orange pb-4') },
                formatStandingsString(inTeamStatistics.numTotalAnswers, inTeamStatistics.numCorrectAnswers),
                ' ',
                "richtig"),
            React.createElement("div", { className: classNames(teamTabVisible, 'text-lg text-orange pb-4') },
                formatStandingsString(inTeamStatistics.teamStatistics.numTotalAnswers, inTeamStatistics.teamStatistics.numCorrectAnswers),
                ' ',
                "richtig"),
            sortedCategories.map((category) => {
                const categoryImageURL = categories.categories.get(category.id).imageURL;
                const teamCategory = inTeamStatistics.teamStatistics.categories.find((teamStatisticsCategory) => teamStatisticsCategory.id === category.id);
                return (React.createElement(React.Fragment, { key: `${category.id}-container` },
                    React.createElement(SingleCategoryStatistics, { key: category.id, category: category, categoryImageURL: categoryImageURL, className: memberTabVisible }),
                    React.createElement(SingleCategoryStatistics, { key: `${category.id}-team`, category: teamCategory, categoryImageURL: categoryImageURL, className: teamTabVisible })));
            })),
        React.createElement("p", { className: "text-darkBlue/50 text-sm pt-2" }, "Die Punkte beziehen sich nur auf die Fragen aus Quizzen innerhalb des Teams oder aus Quizzen, die dieses Team gegen andere Teams gespielt hat. Die pers\u00F6nlichen Punkte des Mitglieds k\u00F6nnen variieren und diese Auswertung spiegelt nicht zwingend den echten Lernfortschritt des Mitglieds wieder.")));
}
