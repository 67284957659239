import { Button } from '@components/basic-components/button';
import React from 'react';
export default function PopupFooterButton({ to, challengeRound, onClick, overrideLabelText, }) {
    const buttonText = challengeRound.nextQuestion === undefined
        ? 'Zurück zum Quiz'
        : 'Quiz pausieren';
    return (React.createElement(Button, { to: to, onClick: onClick, className: "mb-4 w-full", priority: challengeRound.nextQuestion === undefined
            ? 'primary'
            : 'tertiary', replace: true }, overrideLabelText !== null && overrideLabelText !== void 0 ? overrideLabelText : buttonText));
}
