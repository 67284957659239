import { AvatarBadgeSettingsView } from '@views/avatar-badge-settings-view';
import { CategoriesInfoContextProvider } from './context/categories-info-context';
import { ChallengeContextParamsUpdate, ChallengeContextProvider, } from './context/challenge-context';
import { ChallengeRoundView } from '@views/challenge-round-view';
import { ConsentProvider } from './context/consent-context';
import { CreateAvatarView } from '@views/create-avatar-view';
import { DefaultImageContextProvider } from './context/default-image-context';
import { ErrorProvider } from './context/error-context';
import { LocalUserProvider } from './context/use-local-user-state';
import { MyAvatarView } from '@views/my-avatar-view';
import { NestClientProvider } from './context/nest-client-context';
import { OfflineProvider } from './context/offline-context';
import { ProfileProvider } from './context/profile-context';
import { PushNotificationProvider } from './context/push-notification-context';
import { useLocation } from 'react-router-dom';
import { SafeSpaceContextProvider } from './context/safe-space-context';
import { WelcomeView } from '@views/welcome-view';
import ChallengeDetailsView from '@views/challenge-details-view';
import ChallengeParticipantsView from '@views/challenge-participants-view';
import ChallengesHistoryView from '@views/challenges-history-view';
import ChallengesOverviewView from '@views/challenges-overview-view';
import ClassroomChallengeView from '@views/classroom-challenge-view';
import CustomQuestionDetailView from '@views/custom-question-detail-view';
import CustomQuestionsOverview from '@views/custom-questions-overview';
import ErrorView from '@views/error';
import HomeView from '@views/home-view';
import ImprintView from '@views/imprint-view';
import KioskBeforeView from '@views/kiosk-before';
import KioskEntryView from '@views/kiosk-entry';
import LandingPageView from '@views/landing-page-view';
import MyProfileEditorView from '@views/my-profile-editor-view';
import MyProfileView from '@views/my-profile-view';
import NewChallengeView from '@views/new-challenge-view';
import OtherProfileView from '@views/other-profile-view';
import PrivacyPolicyView from '@views/privacy-policy-view';
import QuestionView from '@views/question-view';
import React, { useEffect, useState } from 'react';
import SearchOpponentTeamView from '@views/search-opponent-team-view';
import SearchPlayerView from '@views/search-player-view';
import StartupFlow from '@views/startup-flow';
import TeamAddMemberView from '@views/team-add-member-view';
import TeamDetailsView from '@views/team-details-view';
import TeamMemberStatisticsView from '@views/team-member-statistics-view';
import TeamResultsView from '@views/team-results-view';
import TeamsCreateView from '@views/team-edit-view';
import TeamsView from '@views/teams-view';
/**
 * Wrap a component into this function, to make it automatically reset all internal state when the URL changes.
 */
function ClearStateOnNavigate({ component: Component, }) {
    const { pathname } = useLocation();
    const [key, setKey] = useState(`${Date.now()}`);
    useEffect(() => {
        setKey(`${Date.now()}`);
    }, [pathname]);
    return React.createElement(Component, { key: key });
}
const rawRoutes = [
    {
        path: '/',
        element: React.createElement(StartupFlow, null),
    },
    {
        path: '/landing',
        element: React.createElement(LandingPageView, null),
    },
    {
        path: '/welcome',
        element: React.createElement(WelcomeView, null),
    },
    {
        path: '/home',
        element: React.createElement(HomeView, null),
    },
    {
        path: '/challenges',
        element: React.createElement(ChallengesOverviewView, null),
    },
    {
        path: '/teams',
        element: React.createElement(TeamsView, null),
    },
    {
        path: '/teams/:teamId/edit',
        element: React.createElement(TeamsCreateView, null),
    },
    {
        path: '/teams/create',
        element: React.createElement(TeamsCreateView, null),
    },
    {
        path: '/teams/:teamId',
        element: React.createElement(TeamDetailsView, null),
    },
    {
        path: '/teams/:teamId/results',
        element: React.createElement(TeamResultsView, null),
    },
    {
        path: '/teams/:teamId/results/:challengeId',
        element: React.createElement(ChallengeDetailsView, null),
    },
    {
        path: '/teams/:teamId/statistics/:memberId',
        element: React.createElement(TeamMemberStatisticsView, null),
    },
    {
        path: '/teams/:teamId/addMember',
        element: React.createElement(TeamAddMemberView, null),
    },
    {
        path: '/teams/:teamId/searchOpponent',
        element: React.createElement(SearchOpponentTeamView, null),
    },
    {
        path: '/profile',
        element: React.createElement(MyProfileView, null),
    },
    {
        path: '/profile/edit',
        element: React.createElement(MyProfileEditorView, null),
        errorElement: React.createElement(ErrorView, null),
    },
    {
        path: '/profile/my-avatar',
        element: React.createElement(MyAvatarView, null),
        errorElement: React.createElement(ErrorView, null),
    },
    {
        path: '/profile/create-avatar',
        element: React.createElement(CreateAvatarView, null),
    },
    {
        path: '/profile/avatar-badge-settings',
        element: React.createElement(AvatarBadgeSettingsView, null),
    },
    {
        path: '/profile/:profileId',
        element: React.createElement(OtherProfileView, null),
    },
    {
        path: '/challenges/:challengeId',
        element: React.createElement(ChallengeDetailsView, null),
    },
    {
        path: '/challenges/new',
        element: React.createElement(NewChallengeView, null),
    },
    {
        path: '/challenges/history',
        element: React.createElement(ChallengesHistoryView, null),
    },
    {
        path: '/challenges/history/:challengeId',
        element: React.createElement(ChallengeDetailsView, null),
    },
    {
        path: '/challenges/searchOpponent',
        element: React.createElement(SearchPlayerView, null),
    },
    {
        path: '/challenges/classroomChallenge',
        element: React.createElement(ClassroomChallengeView, null),
    },
    {
        path: '/challenges/:challengeId/participants',
        element: React.createElement(ChallengeParticipantsView, null),
    },
    {
        path: '/challenges/:challengeId/:roundIndex',
        element: React.createElement(ChallengeRoundView, null),
    },
    {
        path: '/challenges/:challengeId/:roundIndex/:questionIndex',
        element: React.createElement(ClearStateOnNavigate, { component: QuestionView }),
    },
    {
        path: '/imprint',
        element: React.createElement(ImprintView, null),
    },
    {
        path: '/privacy-policy',
        element: React.createElement(PrivacyPolicyView, null),
    },
    { path: '/myQuestions', element: React.createElement(CustomQuestionsOverview, null) },
    { path: '/myQuestions/:id', element: React.createElement(CustomQuestionDetailView, null) },
    { path: '/kiosk', element: React.createElement(KioskBeforeView, null) },
    { path: '/kiosk-start', element: React.createElement(KioskEntryView, null) },
];
export const routes = rawRoutes.map((route) => (Object.assign(Object.assign({}, route), { element: (React.createElement(ChallengeContextParamsUpdate, null, route.element)), errorElement: React.createElement(ErrorView, null) })));
export function MikaContext({ children, profile, }) {
    return (React.createElement(ConsentProvider, null,
        React.createElement(SafeSpaceContextProvider, null,
            React.createElement(NestClientProvider, null,
                React.createElement(OfflineProvider, null,
                    React.createElement(ErrorProvider, null,
                        React.createElement(DefaultImageContextProvider, null,
                            React.createElement(ProfileProvider, { profile: profile },
                                React.createElement(LocalUserProvider, null,
                                    React.createElement(PushNotificationProvider, null,
                                        React.createElement(CategoriesInfoContextProvider, null,
                                            React.createElement(ChallengeContextProvider, null, children))))))))))));
}
