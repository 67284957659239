import { Button } from '@components/basic-components/button';
import { Popup } from '@components/basic-components/popup';
import React from 'react';
export default function NotificationConsentPopUp({ handleConsent, }) {
    return (React.createElement(Popup, { popupOpen: true, dataTestId: 'notification-consent-popup', hideCloseButton: true, withoutFooter: true, className: "z-20", centerScrollPanel: {
            header: (React.createElement("h2", { className: "font-bold text-darkBlue" }, "Zustimmung von Benachrichtigungen")),
            footer: (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: "w-full mb-2", priority: "primary", onClick: () => void handleConsent(true), dataTestId: 'notification-consent-popup-accept-button' }, "Ich akzeptiere"),
                React.createElement(Button, { className: "w-full ", priority: "decline", onClick: () => void handleConsent(false), dataTestId: 'notification-consent-popup-decline-button' }, "Ich lehne ab"))),
        } },
        React.createElement("p", { className: "leading-5 text-darkBlue text-sm" },
            "Wir m\u00F6chten Dir gerne Benachrichtigungen senden, damit Du keine Spiele-Updates mehr verpasst. Wir empfehlen Dir, f\u00FCr ein besseres Spielerlebnis, die Benachrichtigungen zu erlauben.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Deine Einwilligung kannst Du jederzeit in den Einstellungen \u00E4ndern oder widerrufen.",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("u", null, "Wichtig:"),
            " Du musst f\u00FCr Benachrichtigungen diese auch in den Einstellungen deines Betriebssystems f\u00FCr deinen entsprechenden Webbrowser erlauben.")));
}
