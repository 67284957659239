import { Draggable, Droppable } from 'react-beautiful-dnd';
import { classNames } from '@utils/class-names';
import React from 'react';
export default function DraggableList({ items, }) {
    return (React.createElement("div", { className: 'w-full' },
        React.createElement(Droppable, { droppableId: 'draggable-list-playlist-view', direction: 'vertical' }, (droppableProvided) => (React.createElement("div", Object.assign({ ref: droppableProvided.innerRef }, droppableProvided.droppableProps),
            items.map((item, index) => (React.createElement(Draggable, { draggableId: item.key, index: index, key: item.key }, (draggableProvided, snapshot) => (React.createElement("div", Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps, draggableProvided.dragHandleProps, { className: classNames(snapshot.isDragging &&
                    'shadow-xl bg-lightGray', 'mb-4') }), item))))),
            droppableProvided.placeholder)))));
}
