import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
export const useAuthCode = () => {
    const [searchParams] = useSearchParams();
    const [ssoAuthCode, setSsoAuthCode] = useState();
    useEffect(() => {
        if (searchParams.get('code')) {
            setSsoAuthCode(searchParams.get('code'));
        }
    }, [searchParams]);
    return ssoAuthCode;
};
