import { RegisteredOnlyPopup } from '@components/basic-components/registeredOnlyPopup';
import { ViewsType } from '@typesSrc/views';
import FooterItem from '@components/footer/footer-item';
import React, { useState } from 'react';
function Footer({ activeItem, onRedirect, onBackToTopClicked, featuredIndex, isGuest, }) {
    const [showRegisteredOnlyPopup, setShowRegisteredOnlyPopup] = useState(false);
    function handleShowRegisteredOnlyPopup() {
        setShowRegisteredOnlyPopup(true);
    }
    return (React.createElement("div", null,
        React.createElement("footer", { "data-cy": "footer", className: "desktop:hidden relative bg-white w-full h-[var(--footerHeight)] pt-4 border-t border-border z-10" },
            React.createElement("div", { className: "px-4 max-w-2xl mx-auto grid grid-cols-4" },
                React.createElement(FooterItem, { item: ViewsType.HOME, isActive: activeItem === ViewsType.HOME, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 0 }),
                React.createElement(FooterItem, { item: ViewsType.CHALLENGES_OVERVIEW, isActive: activeItem === ViewsType.CHALLENGES_OVERVIEW ||
                        activeItem === ViewsType.CHALLENGE_DETAILS ||
                        activeItem === ViewsType.CHALLENGE_ROUND, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 1 }),
                React.createElement("div", Object.assign({}, (isGuest
                    ? { onClick: handleShowRegisteredOnlyPopup }
                    : {})),
                    React.createElement(FooterItem, { item: ViewsType.TEAMS, isActive: activeItem === ViewsType.TEAMS, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 2, className: isGuest ? 'pointer-events-none opacity-50' : '' })),
                React.createElement(FooterItem, { item: ViewsType.PROFILE, isActive: activeItem === ViewsType.PROFILE, onRedirect: onRedirect, onBackToTopClicked: onBackToTopClicked, featured: featuredIndex === 3 }))),
        React.createElement(RegisteredOnlyPopup, { popupOpen: showRegisteredOnlyPopup, setPopupOpen: setShowRegisteredOnlyPopup, withoutFooter: false, className: "z-5" })));
}
export default Footer;
