import { AUTHORIZATION_FAILED } from '@utils/constants';
import { ApiError, NewTeamImageUploadError } from '@api/errors';
import { Environment } from '../environment.global';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { ServerErrorCode } from '@mika-bibb/shared/server-error-codes';
import Axios from 'axios';
export class NestClient {
    constructor() {
        this.responseSuccessHandlers = [];
        this.addError = () => {
            // noop
        };
        this.axios = Axios.create();
        this.axios.defaults.validateStatus = () => true;
        this.axios.defaults.withCredentials = true;
    }
    addResponseHandler(handler) {
        this.responseSuccessHandlers.push(handler);
    }
    notifyResponseSuccess(response) {
        return Promise.all(this.responseSuccessHandlers.map((handler) => handler.onSuccessfulResponse(response)));
    }
    setErrorHandler(addError) {
        this.addError = addError;
    }
    checkForbidden(errorCode) {
        if (errorCode === 403) {
            window.dispatchEvent(new Event(AUTHORIZATION_FAILED));
        }
    }
    async checkResponse(response, handleError) {
        if (response.status < 300 && response.status >= 200) {
            await this.notifyResponseSuccess(response);
            return response.data;
        }
        this.checkForbidden(response.status);
        const newError = new ApiError(response.status, response.data.message);
        if (handleError) {
            this.addError(newError, true);
        }
        throw newError;
    }
    async getRoute(route, params = {}, handleError = true, responseType) {
        const response = await this.get(route, params, responseType);
        return this.checkResponse(response, handleError);
    }
    async get(route, params, responseType) {
        var _a;
        return this.axios
            .get(Environment.NEST_API_URL + route, Object.assign({ responseType,
            params }, (await ((_a = this.beforeRequestHandler) === null || _a === void 0 ? void 0 : _a.onBeforeRequest({
            params,
        })))))
            .catch(() => {
            const apiError = new ApiError(503, ServerErrorCode.GET_ROUTE_FAILED);
            this.addError(apiError, true);
            throw apiError;
        });
    }
    async postRoute(route, body, headers, handleError = true) {
        const response = await this.post(route, body, headers);
        return this.checkResponse(response, handleError);
    }
    async post(route, body, headers) {
        var _a;
        return this.axios
            .post(Environment.NEST_API_URL + route, body, Object.assign({ headers }, (await ((_a = this.beforeRequestHandler) === null || _a === void 0 ? void 0 : _a.onBeforeRequest({
            headers,
        })))))
            .catch(() => {
            const apiError = new ApiError(503, ServerErrorCode.POST_ROUTE_FAILED);
            this.addError(apiError, true);
            throw apiError;
        });
    }
    async deleteRoute(route, params = {}, handleError = true) {
        const response = await this.delete(route, params);
        return this.checkResponse(response, handleError);
    }
    async delete(route, params) {
        var _a;
        return this.axios
            .delete(Environment.NEST_API_URL + route, Object.assign({ params }, (await ((_a = this.beforeRequestHandler) === null || _a === void 0 ? void 0 : _a.onBeforeRequest({
            params,
        })))))
            .catch(() => {
            const apiError = new ApiError(503, ServerErrorCode.DELETE_ROUTE_FAILED);
            this.addError(apiError, true);
            throw apiError;
        });
    }
    getMyChallenges() {
        return this.getRoute('/challenge/myChallenges');
    }
    getMyChallengeHistory() {
        return this.getRoute('/challenge/myChallengeHistory');
    }
    getMyTeams() {
        return this.getRoute('/team/myTeams');
    }
    async getMyRecentChallenge() {
        return (await this.getRoute('/challenge/myRecentChallenge')).challenge;
    }
    createSinglePlayerChallenge(settings) {
        return this.postRoute('/challenge/createSingleplayer', { settings });
    }
    create1v1RandomChallenge() {
        return this.postRoute('/challenge/create1v1Random');
    }
    declineChallenge(id) {
        return this.postRoute(`/challenge/${id}/declineChallenge`);
    }
    acceptChallenge(id) {
        return this.postRoute(`/challenge/${id}/acceptChallenge`);
    }
    endChallenge(id) {
        return this.postRoute(`/challenge/${id}/endChallenge`);
    }
    getIndividualStatistics(since) {
        return this.getRoute('/statistics/individual/' + since);
    }
    getInTeamStatistics(teamId, memberId, since) {
        return this.getRoute(`/statistics/team/${teamId}/${memberId}/${since}`);
    }
    getChallengeRankingInfo(challengeId) {
        return this.getRoute(`/challenge/${challengeId}/challengeRankingInfo`);
    }
    create1v1Challenge(opponentId, settings) {
        return this.postRoute('/challenge/create1v1', { opponentId, settings });
    }
    getChallengeState(challengeId) {
        return this.getRoute('/challenge/' + challengeId + '/state');
    }
    getChallengeRoundState(challengeId, roundId) {
        return this.getRoute(`/challenge/${challengeId}/roundState/${roundId}`);
    }
    chooseCategory(challengeId, roundIndex, categoryId) {
        return this.postRoute(`/challenge/chooseCategory/`, {
            challengeId,
            roundIndex,
            categoryId,
        });
    }
    getCategories() {
        return this.getRoute('/challenge/categories');
    }
    getQuestions() {
        return this.getRoute(`/questions`);
    }
    getCustomQuestions() {
        return this.getRoute(`/questions/customQuestions`);
    }
    createQuestion(categoryId) {
        return this.postRoute('/questions/create', { categoryId });
    }
    getCustomQuestion(id) {
        return this.getRoute(`/questions/${id}`);
    }
    getRoundQuestion(roundID, index) {
        return this.getRoute(`/questions/roundQuestion/${roundID}/${index}`);
    }
    updateQuestion(question) {
        return this.postRoute(`/questions/update`, question);
    }
    deleteQuestion(question) {
        return this.deleteRoute(`/questions/${question.id}`);
    }
    answerQuestion(challengeId, roundIndex, questionIndex, answerIndex) {
        return this.postRoute(`/challenge/answerQuestion/`, {
            challengeId,
            roundIndex,
            questionIndex,
            answerIndex,
        });
    }
    // -- Profile ---
    async initializeSsoFlow(action) {
        return this.getRoute(`/auth/init-sso/${action}`);
    }
    async login(code) {
        return this.postRoute('/auth/login', {
            code,
        });
    }
    async logout() {
        return this.postRoute('/auth/logout');
    }
    updateNotificationToken(token) {
        return this.postRoute('/profile/updateNotificationToken', { token });
    }
    async getProfile() {
        return this.getRoute('/profile', {}, false);
    }
    async getProfilePicture(pictureId) {
        return await this.getRoute(`/image/${pictureId}`, undefined, undefined, 'blob');
    }
    getOtherProfile(profileId) {
        return this.getRoute(`/profile/${profileId}`);
    }
    async getMyScore() {
        return (await this.getRoute('/profile/score')).score;
    }
    async updateProfile(profile) {
        return this.postRoute('/profile', profile);
    }
    deleteProfile(profile) {
        return this.postRoute('/profile/delete', profile);
    }
    async createGuestProfile() {
        return this.postRoute('/profile/createGuest');
    }
    // istanbul ignore next
    async createRegisteredUser() {
        return this.postRoute('/profile/createRegisteredUser');
    }
    searchPlayer(searchTerm, registeredOnly) {
        return this.getRoute(`/profile/${registeredOnly ? 'searchRegistered' : 'search'}/${encodeURI(searchTerm)}`);
    }
    searchTeam(searchTerm) {
        return this.getRoute(`/team/search/${encodeURI(searchTerm)}`);
    }
    createClassroomChallenge() {
        return this.postRoute('/challenge/createClassroom');
    }
    startClassroomChallenge(challengeID, settings) {
        return this.postRoute('/challenge/startClassroom', {
            challengeID,
            settings,
        });
    }
    startTeamChallenge(teamId, settings) {
        return this.postRoute(`/challenge/createTeamChallenge`, { teamId, settings });
    }
    startTeamVsTeamChallenge(teamId, opponentId, settings) {
        return this.postRoute(`/challenge/createTeamVsTeamChallenge`, {
            teamId,
            opponentId,
            settings,
        });
    }
    renameChallenge(data) {
        return this.postRoute('/challenge/setChallengeName', data);
    }
    joinClassroom(joinCode) {
        return this.postRoute('/challenge/joinClassroom', { joinCode }, undefined, false);
    }
    async updateProfilePicture(data, fileName) {
        const formData = new FormData();
        formData.append('file', data, fileName);
        try {
            return await this.postRoute('/profile/profilePicture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }
        catch (e) {
            if (e instanceof ApiError &&
                e.status === 403 &&
                FEATURE_FLAG_DIDACTA_VERSION) {
                throw new ApiError(403, '403_OUTDATED');
            }
            else {
                throw e;
            }
        }
    }
    async createTeam(name, teamPicture) {
        const team = await this.postRoute('/team/create', {
            name,
        });
        await this.updateTeamPicture(teamPicture, team.uuid).catch((e) => {
            if (e instanceof ApiError) {
                throw new NewTeamImageUploadError(e.status, team.uuid, e.message);
            }
            else {
                throw e;
            }
        });
        return team;
    }
    async getDefaultTeamPicture() {
        return this.getRoute('/team/defaultImage');
    }
    async updateTeamPicture(teamPicture, teamId) {
        if (teamPicture) {
            const formData = new FormData();
            formData.append('file', teamPicture);
            await this.postRoute(`/team/${teamId}/picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }
    }
    async updateTeam(teamId, name, teamPicture) {
        const team = await this.postRoute('/team/update', {
            teamId,
            name,
        });
        await this.updateTeamPicture(teamPicture, teamId);
        return team;
    }
    getTeam(teamId, handleError = true) {
        return this.getRoute(`/team/${teamId}`, undefined, handleError);
    }
    getTeamChallenges(teamId) {
        return this.getRoute(`/team/${teamId}/challenges`, undefined, false);
    }
    getTeamRanking(teamId) {
        return this.getRoute(`/team/${teamId}/ranking`);
    }
    deleteTeam(teamId) {
        return this.postRoute(`/team/${teamId}/delete`);
    }
    addTeamMember(teamId, newPlayer) {
        return this.postRoute(`/team/${teamId}/addMember`, {
            profileId: newPlayer,
        });
    }
    leaveTeam(teamId) {
        return this.postRoute(`/team/${teamId}/leave`);
    }
    toggleAdmin(teamId, profileId) {
        return this.postRoute(`/team/${teamId}/toggleAdmin`, {
            profileId,
        });
    }
    removeMember(teamId, profileId) {
        return this.postRoute(`/team/${teamId}/removeMember`, {
            profileId,
        });
    }
    getSinglePlayerRankingInfo() {
        return this.getRoute('/rankings/singlePlayerRankingInfo');
    }
    getTeamsRankingInfo() {
        return this.getRoute('/rankings/teamsRankingInfo');
    }
    checkOnline() {
        return this.getRoute('/health');
    }
    getTeamCSV(teamId) {
        return this.getRoute(`/statistics/teamCSV/${teamId}`, undefined, undefined, 'blob');
    }
}
