import { Button } from '@components/basic-components/button';
import { ReactComponent as Edit } from '@public/icons/edit.svg';
import { Link } from 'react-router-dom';
import { SectionTitle } from '@components/basic-components/section-title';
import { classNames } from '@utils/class-names';
import { nestClient } from '../../context/nest-client-context';
import { useNavigate } from 'react-router';
import ListEntry from '@components/basic-components/list-entry';
import React from 'react';
export default function CustomQuestionsList({ category, questions, }) {
    const navigate = useNavigate();
    const createNewQuestion = async () => {
        const questionId = await nestClient.createQuestion(category.id);
        navigate(`/myQuestions/${questionId}`);
    };
    return (React.createElement("div", { className: "m-4" },
        React.createElement(SectionTitle, { className: "pb-1" },
            "Kategorie: ",
            category.text),
        React.createElement("div", { className: classNames('bg-white px-4', questions.length > 0 && 'py-2 my-1') }, questions.map((question) => {
            return (React.createElement(ListEntry, { key: question.id, middle: React.createElement("p", null, question.text), right: React.createElement(Link, { to: `/myQuestions/${question.id}`, className: "flex justify-end", "data-testid": `settings-${question.id}-button` },
                    React.createElement(Edit, { className: "h-6 w-6" })) }));
        })),
        React.createElement("div", { className: "pb-4 pt-1" },
            React.createElement(Button, { onClick: () => void createNewQuestion(), small: true }, "+ Frage hinzuf\u00FCgen"))));
}
