import { Preferences } from '@capacitor/preferences';
import { useCallback, useEffect, useState } from 'react';
/**
 * A variant of React.useState that uses Capacitor's Preferences plugin to persist the value.
 * Only one component instance should use this hook for a given key, otherwise the multiple instances
 * of this state might have differing states.
 */
export default function usePreferencesState(key) {
    const [value, setValue] = useState(null);
    useEffect(() => {
        void Preferences.get({ key }).then((result) => {
            setValue(result.value);
        });
    }, [key]);
    const wrappedSet = useCallback((value) => {
        if (value === null) {
            void Preferences.remove({ key });
        }
        else {
            void Preferences.set({ key, value }).then(() => {
                setValue(value);
            });
        }
    }, [key]);
    return [value, wrappedSet];
}
/**
 * A variant of usePreferencesState that stores a boolean value.
 */
export function usePreferencesStateBool(key) {
    const [value, setValue] = usePreferencesState(key);
    const boolValue = value === null ? null : value === 'true';
    const boolSet = useCallback((value) => {
        if (value === null) {
            setValue(null);
        }
        else {
            setValue(value ? 'true' : 'false');
        }
    }, [setValue]);
    return [boolValue, boolSet];
}
