import { ReactComponent as Checkmark } from '@public/icons/check-pur.svg';
import { classNames } from '@utils/class-names';
import React from 'react';
export default function Checkbox({ checked, label, id, onChange, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { className: "hidden", type: 'checkbox', id: id, checked: checked, onChange: (event) => onChange(event.target.checked) }),
        React.createElement("label", { htmlFor: id, className: "flex flex-row gap-2 items-center py-2" },
            React.createElement("div", { role: "checkbox", "aria-checked": checked, tabIndex: 0, className: classNames('w-[18px] h-[18px] inline-block rounded border-2 flex justify-items-center flex-center', checked
                    ? 'bg-orange border-orange'
                    : 'border-darkBlue/70'), onKeyDown: (event) => (event.key === ' ' || event.key === 'Enter') &&
                    onChange(!checked) }, checked && (React.createElement(Checkmark, { className: "text-white", width: 13, height: 13 }))),
            React.createElement("div", { className: "font-medium text-sm text-greyDark" }, label))));
}
