import { Avatar } from '@components/basic-components/avatar/avatar';
import { ReactComponent as BarChart } from '@public/icons/bar_chart.svg';
import { Check } from '@components/basic-components/check';
import { Counter } from '@components/basic-components/counter';
import { Link } from 'react-router-dom';
import { Loading } from '@components/basic-components/loading';
import { classNames } from '@utils/class-names';
import { overflowEllipsis } from '@utils/mixed';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../../context/nest-client-context';
import { usePolling } from '../../hooks/usePolling';
import { useProfile } from '../../context/profile-context';
import React, { useCallback } from 'react';
function GeneralRankingEntry({ data, highlighted, link, scoreText, markAsWinner = false, additionalComponent, }) {
    const navigate = useNavigate();
    const onClick = useCallback(() => {
        if (link) {
            navigate(link);
        }
    }, [link, navigate]);
    return (React.createElement("div", { className: classNames('px-6', highlighted ? 'bg-green/10' : 'bg-white/50') },
        React.createElement("div", { className: classNames('flex flex-row h-16 content-center items-center gap-2'), onClick: onClick },
            markAsWinner ? (React.createElement("div", { className: "icon-trophy text-green text-2xl inline w-6" })) : (React.createElement(Counter, { filled: highlighted }, data.rank)),
            React.createElement(Avatar, { image: data.imageUrl, bannerType: data.bannerType, className: "h-12 w-12 flex-shrink-0", sizes: "96px" }),
            React.createElement("span", { className: classNames(overflowEllipsis, 'text-darkBlue font-bold') }, data.name),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("span", { className: classNames('flex-[0_0_auto]', highlighted
                    ? 'text-darkBlue font-semibold'
                    : 'text-darkBlue/50 font-regular') }, scoreText),
            additionalComponent)));
}
export function SinglePlayerRankingEntry({ data, }) {
    const { state: { profile }, } = useProfile();
    const isMe = data.profileId === (profile === null || profile === void 0 ? void 0 : profile.uuid);
    return (React.createElement(GeneralRankingEntry, { data: data, highlighted: isMe, link: isMe ? undefined : `/profile/${data.profileId}`, scoreText: React.createElement(React.Fragment, null,
            data.score.toLocaleString('de-DE', {
                useGrouping: true,
            }),
            ' ',
            "Punkte") }));
}
function StatsLink({ profileID }) {
    return (React.createElement(Link, { className: classNames('w-12 text-white text-center align-middle text flex items-center justify-center h-12', 'bg-green'), "data-testid": "stats-link", to: `./../statistics/${profileID}`, onClick: (e) => e.stopPropagation() },
        React.createElement(BarChart, null)));
}
export function SinglePlayerInTeamRankingEntry({ data, }) {
    const { state: { profile }, } = useProfile();
    const isMe = data.profileId === (profile === null || profile === void 0 ? void 0 : profile.uuid);
    return (React.createElement(GeneralRankingEntry, { data: data, highlighted: isMe, link: isMe ? undefined : `/profile/${data.profileId}`, scoreText: React.createElement(React.Fragment, null,
            data.score.toLocaleString('de-DE', {
                useGrouping: true,
            }),
            ' ',
            "Punkte"), additionalComponent: profile ? React.createElement(StatsLink, { profileID: data.profileId }) : undefined }));
}
export function ChallengeParticipantRankingEntry({ data, showWinner = false, }) {
    const { state: { profile }, } = useProfile();
    const isMe = data.profileId === (profile === null || profile === void 0 ? void 0 : profile.uuid);
    const markAsWinner = showWinner && data.rank === 1 && data.score > 0;
    return (React.createElement(GeneralRankingEntry, { data: data, highlighted: isMe, link: isMe ? undefined : `/profile/${data.profileId}`, scoreText: React.createElement("div", { className: "flex flex-row" },
            data.score.toLocaleString('de-DE', {
                useGrouping: true,
            }),
            ' ',
            "Punkte",
            React.createElement(Check, { className: "ml-2", checked: data.finished })), markAsWinner: markAsWinner }));
}
function TeamRankingEntry({ data }) {
    const scorePercent = Math.round((data.totalAnswers === 0
        ? 0
        : data.correctAnswers / data.totalAnswers) * 100);
    return (React.createElement(GeneralRankingEntry, { data: data, highlighted: data.teamId !== undefined, link: data.teamId ? `/teams/${data.teamId}` : undefined, scoreText: React.createElement(React.Fragment, null,
            scorePercent,
            "% (",
            data.correctAnswers,
            "/",
            data.totalAnswers,
            ")") }));
}
function EllipsisRankingEntry() {
    return (React.createElement("div", { className: "px-6" },
        React.createElement("div", { className: classNames('flex flex-row h-8 content-center items-center', 'justify-center text-darkBlue/50 font-regular') },
            React.createElement("div", { className: "w-6" },
                React.createElement("div", { className: "rotate-90 px-4 -mt-2 font-bold" }, "...")),
            React.createElement("div", { className: "flex-grow" }))));
}
function Separator() {
    return (React.createElement("div", { className: "px-6" },
        React.createElement("hr", { className: "border-1 border-t border-darkBlue/10" })));
}
export function RankingsList({ getData, Entry, emptyMessage, desktopHeader = '', desktopHeaderRight, showEllipsis = true, showWinner = false, markListAsWinner = false, showDesktopHeader = true, }) {
    const [data, setData] = React.useState(undefined);
    usePolling(() => {
        void getData().then(setData);
    }, 15000, [getData, Entry]);
    if (!data) {
        return React.createElement(Loading, { className: "mt-10" });
    }
    return (React.createElement("div", null,
        showDesktopHeader && (React.createElement("div", { className: "hidden desktop:block bg-white pb-6" },
            React.createElement("p", { className: "px-5 py-6 border-b border-darkBlue/10 text-darkBlue/70 font-bold flex flex-row w-full" },
                markListAsWinner && (React.createElement("div", { className: "icon-trophy text-green inline mr-2 align-middle text-xl leading-5" })),
                React.createElement("div", { className: "flex flex-row justify-between grow" },
                    React.createElement("p", null, desktopHeader),
                    React.createElement("p", null, desktopHeaderRight))))),
        data.length ? (React.createElement("div", { className: "flex-shrink flex-grow-0 overflow-x-hidden overflow-y-auto force-scrollbar desktop:bg-white" },
            data.map((entry, i) => (React.createElement(React.Fragment, null,
                showEllipsis &&
                    i > 0 &&
                    entry.rank !== data[i - 1].rank + 1 && (React.createElement(React.Fragment, null,
                    React.createElement(EllipsisRankingEntry, { key: `${desktopHeader}-${entry.rank - 1}-ellipsis` }),
                    React.createElement(Separator, { key: `${desktopHeader}-${entry.rank}-ellipsis-separator` }))),
                React.createElement(Entry, { key: `${desktopHeader}-${entry.rank}-entry`, data: entry, showWinner: showWinner }),
                React.createElement(Separator, { key: `${desktopHeader}-${entry.rank}-separator` })))),
            showEllipsis && (React.createElement(EllipsisRankingEntry, { key: `${desktopHeader}-last` })))) : (React.createElement("div", { className: "desktop:bg-white desktop:py-9 text-center text-darkBlue/70" }, emptyMessage))));
}
export function SinglePlayerRankingsList() {
    const { nestClient } = useNestClientContext();
    const getData = useCallback(() => nestClient.getSinglePlayerRankingInfo(), [nestClient]);
    return (React.createElement(RankingsList, { getData: getData, Entry: SinglePlayerRankingEntry, emptyMessage: "Keine Spieler/-innen gefunden", desktopHeader: "Spieler/-in" }));
}
export function TeamsRankingsList() {
    const { nestClient } = useNestClientContext();
    const getData = useCallback(() => nestClient.getTeamsRankingInfo(), [nestClient]);
    return (React.createElement(RankingsList, { getData: getData, Entry: TeamRankingEntry, emptyMessage: "Keine Teams gefunden", desktopHeader: "Teams" }));
}
