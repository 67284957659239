import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ChallengeType, } from '@mika-bibb/shared/challenge-status-dto';
import { ChallengeUser } from '@components/challenge/challenge-user';
import { Loading } from '@components/basic-components/loading';
import { Panel } from '@components/basic-components/panel';
import { ParticipantsCount } from '@components/challenge/participants-count';
import { Popup } from '@components/basic-components/popup';
import { SectionTitle } from '@components/basic-components/section-title';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { getBonusPoints, isMultiplayerChallenge, isSoloChallenge, isTwoPlayerChallenge, } from '@mika-bibb/shared/util/challenge';
import { nestClient } from '../context/nest-client-context';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { usePolling } from '../hooks/usePolling';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import ChallengeRoundsOverview from '@components/challenge/challenge-rounds-overview';
import React, { useCallback, useMemo } from 'react';
import useChallengeContext from '../context/challenge-context';
function ChallengePlayButton({ challenge, finished, }) {
    const path = useLocation().pathname;
    const buttonText = useMemo(() => {
        if (!challenge.started) {
            return 'Warte auf Quizstart';
        }
        else if (!challenge.isUsersTurn) {
            return 'Warte auf Gegner/-in';
        }
        else {
            return 'Spielen';
        }
    }, [challenge.isUsersTurn, challenge.started]);
    if (challenge.gameOver ||
        (!challenge.isUsersTurn && challenge.currentRound === undefined)) {
        if (isMultiplayerChallenge(challenge.type)) {
            return (React.createElement(Button, { to: `/challenges/${challenge.id}/participants`, className: "mb-6 w-full", priority: "secondary" }, "Zur Auswertung"));
        }
        return null;
    }
    return finished ? (React.createElement(Button, { to: "/challenges", className: "mb-6 w-full", "data-testid": "ChallengeDoneButton" }, "Beenden")) : (React.createElement(Button, { to: `${path}/${challenge.currentRound}`, disabled: !challenge.isUsersTurn || !challenge.started, className: "mb-6 w-full", "data-testid": "ChallengePlayButton" }, buttonText));
}
function TeamChallengeRedirectPopup({ setPopupOpen, popupOpen, teamId, }) {
    const navigate = useNavigate();
    return (React.createElement(Popup, { popupOpen: popupOpen, setPopupOpen: setPopupOpen, className: "z-20", withoutFooter: true, centerScrollPanel: {
            header: (React.createElement("span", { className: "font-bold text-darkBlue text-xl" }, "Team Quiz Details")),
            footer: (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: "mb-2 w-full", onClick: () => navigate('participants') }, "Zur Quiz Auswertung"),
                React.createElement(Button, { onClick: () => navigate(`/teams/${teamId}`), className: "w-full", priority: "secondary" }, "Zum Team"))),
        } }));
}
function getOwnInfo(challenge, profile) {
    var _a, _b, _c;
    const ownTeam = challenge.ownTeamInfo;
    const ownNickname = (_a = ownTeam === null || ownTeam === void 0 ? void 0 : ownTeam.name) !== null && _a !== void 0 ? _a : profile.nickname;
    const ownAvatar = (_b = ownTeam === null || ownTeam === void 0 ? void 0 : ownTeam.imageUrl) !== null && _b !== void 0 ? _b : profile.avatarURL;
    const ownBannerType = (_c = ownTeam === null || ownTeam === void 0 ? void 0 : ownTeam.bannerType) !== null && _c !== void 0 ? _c : profile.bannerType;
    const ownUrl = (ownTeam === null || ownTeam === void 0 ? void 0 : ownTeam.teamId) ? `/teams/${ownTeam.teamId}` : '/profile';
    return { ownNickname, ownAvatar, ownBannerType, ownUrl };
}
function getOpponentInfo(challenge, setTeamRedirectPopupOpen) {
    var _a;
    const opponentInfo = challenge.opponentInfo;
    const othersNickname = (_a = opponentInfo.name) !== null && _a !== void 0 ? _a : '';
    const othersAvatar = opponentInfo.imageUrl;
    const othersBannerType = opponentInfo.bannerType;
    let othersUrl = opponentInfo.profileId
        ? `/profile/${opponentInfo.profileId}`
        : undefined;
    let onOpponentClick = undefined;
    if (challenge.type === ChallengeType.TEAM_VS_TEAM) {
        othersUrl = 'participants';
    }
    else if (challenge.type === ChallengeType.TEAM) {
        othersUrl = undefined;
        onOpponentClick = () => setTeamRedirectPopupOpen(true);
    }
    else if (isMultiplayerChallenge(challenge.type)) {
        othersUrl = `/challenges/${challenge.id}/participants`;
    }
    return {
        opponentInfo,
        othersNickname,
        othersAvatar,
        othersBannerType,
        othersUrl,
        onOpponentClick,
    };
}
function ChallengeDetailsPlayersInfo({ challenge, profile, isSelfWinner, isOtherWinner, }) {
    var _a;
    const [teamRedirectPopupOpen, setTeamRedirectPopupOpen] = React.useState(false);
    const { ownNickname, ownAvatar, ownBannerType, ownUrl } = getOwnInfo(challenge, profile);
    const { opponentInfo, othersNickname, othersAvatar, othersBannerType, othersUrl, onOpponentClick, } = getOpponentInfo(challenge, setTeamRedirectPopupOpen);
    return (React.createElement("div", { className: classNames('grid gap-x-2', isSoloChallenge(challenge.type) ? 'grid-cols-1' : 'grid-cols-2') },
        React.createElement(Panel, { className: "min-w-0", rounded: "all" },
            React.createElement(ChallengeUser, { score: (_a = challenge.me.teamTotalScore) !== null && _a !== void 0 ? _a : challenge.me.totalScore, align: isSoloChallenge(challenge.type) ? 'wide' : 'left', nickname: ownNickname, avatarUrl: ownAvatar, bannerType: ownBannerType, isWinner: isSelfWinner, url: ownUrl, scoreIsAverage: challenge.type === ChallengeType.TEAM_VS_TEAM })),
        challenge.type !== ChallengeType.SOLO && (React.createElement(Panel, { className: "min-w-0", rounded: "all" }, challenge.waitingForOpponent ? (React.createElement("div", { className: "italic text-center text-base w-full text-darkBlue text-2xl" }, "Warte auf Gegner/-in\u2026")) : (React.createElement(ChallengeUser, { score: challenge.others.totalScore, align: "right", nickname: othersNickname, avatarUrl: othersAvatar, bannerType: othersBannerType, isMe: false, scoreIsAverage: challenge.type === ChallengeType.CODE ||
                challenge.type === ChallengeType.TEAM_VS_TEAM, isWinner: isOtherWinner, url: othersUrl, onClick: onOpponentClick })))),
        (challenge.type === ChallengeType.TEAM ||
            challenge.type === ChallengeType.TEAM_VS_TEAM) && (React.createElement(TeamChallengeRedirectPopup, { popupOpen: teamRedirectPopupOpen, setPopupOpen: setTeamRedirectPopupOpen, teamId: opponentInfo.teamId }))));
}
function ChallengeDetailsWinnerInfo({ challenge, isSelfWinner, isOtherWinner, }) {
    if (!challenge.gameOver ||
        !isSelfWinner ||
        !isTwoPlayerChallenge(challenge.type)) {
        return null;
    }
    return (React.createElement(Panel, { className: "bg-green py-1.5 flex flex-row justify-between text-white", dataTestId: "winner-notice" },
        React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("div", { className: "icon-trophy inline mr-2" }),
                React.createElement("p", { className: "inline font-semibold uppercase" }, isOtherWinner ? 'Unentschieden' : 'Du hast gewonnen!')),
            React.createElement("p", null, `+${getBonusPoints(challenge.rounds.length, challenge.questionsPerRound, isOtherWinner)} Bonus-Punkte`))));
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function ChallengeDetailsView() {
    var _a, _b;
    const { challenge, challengeId, refreshChallengeData } = useChallengeContext();
    const [closeChallengePopupOpen, setCloseChallengePopupOpen] = React.useState(false);
    const openCloseChallengePopup = useCallback(() => setCloseChallengePopupOpen(true), []);
    const navigate = useNavigate();
    const [team, setTeam] = React.useState();
    const teamId = (_b = (_a = challenge === null || challenge === void 0 ? void 0 : challenge.ownTeamInfo) === null || _a === void 0 ? void 0 : _a.teamId) !== null && _b !== void 0 ? _b : challenge === null || challenge === void 0 ? void 0 : challenge.opponentInfo.teamId;
    usePolling(() => {
        if (teamId) {
            void nestClient.getTeam(teamId).then(setTeam);
        }
    }, 30000, [teamId]);
    const { state: { profile }, } = useProfile();
    const membership = team === null || team === void 0 ? void 0 : team.members.find((membership) => membership.profile.uuid === (profile === null || profile === void 0 ? void 0 : profile.uuid));
    const isAdmin = !!(membership === null || membership === void 0 ? void 0 : membership.admin);
    const endChallenge = useCallback(() => {
        if (challengeId) {
            void nestClient.endChallenge(challengeId).then(() => {
                refreshChallengeData();
                if ((challenge === null || challenge === void 0 ? void 0 : challenge.type) === ChallengeType.SOLO) {
                    navigate('/challenges');
                }
                else {
                    navigate('participants');
                }
            });
        }
    }, [challenge, challengeId, navigate, refreshChallengeData]);
    if (!challengeId) {
        return React.createElement("h1", { className: "text-error" }, "Quiz nicht gefunden");
    }
    if (!challenge || !profile) {
        return React.createElement(Loading, { fullSize: true });
    }
    const isSelfWinner = challenge.winner === 'me' || challenge.winner === 'draw';
    const isOtherWinner = challenge.winner === 'others' || challenge.winner === 'draw';
    const isClassroomChallengeOwner = challenge.creatorId === profile.uuid &&
        challenge.type === ChallengeType.CODE;
    const isTeamChallengeAdmin = challenge.type === ChallengeType.TEAM && isAdmin;
    const canEndChallenge = isClassroomChallengeOwner ||
        isTeamChallengeAdmin ||
        challenge.type === ChallengeType.SOLO;
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_DETAILS, dataTestId: "ChallengeDetailsView", showFooter: false, header: {
            title: 'Quiz-Details',
            customHeaderAction: React.createElement(ParticipantsCount, null),
            showBack: true,
            showHelp: true,
        } },
        React.createElement(Header, { challenge: challenge, finished: isSelfWinner || isOtherWinner }),
        React.createElement("div", { className: "flex flex-col flex-1 px-2 gap-y-2" },
            React.createElement(ChallengeDetailsPlayersInfo, { challenge: challenge, profile: profile, isSelfWinner: isSelfWinner, isOtherWinner: isOtherWinner }),
            React.createElement(ChallengeDetailsWinnerInfo, { challenge: challenge, isSelfWinner: isSelfWinner, isOtherWinner: isOtherWinner }),
            React.createElement(CenterScrollPanel, { className: "flex-1", rounded: "top", header: React.createElement("div", { className: "flex flex-row justify-between" },
                    React.createElement(SectionTitle, null, "Gespielte Runden"),
                    challenge.joinCode && (React.createElement(SectionTitle, { className: "text-green/70" },
                        "Einladungscode: ",
                        challenge.joinCode))), footer: React.createElement(React.Fragment, null,
                    !challenge.gameOver && canEndChallenge && (React.createElement(Button, { className: "w-full mb-4", priority: "secondary", onClick: openCloseChallengePopup, dataTestId: "end-challenge-button" }, "Spiel beenden")),
                    isClassroomChallengeOwner &&
                        !challenge.started &&
                        !challenge.gameOver ? (React.createElement(Button, { className: "w-full mb-4", to: `/challenges/new?type=${ChallengeType.CODE}&classroom=${challengeId}` }, "Quiz konfigurieren und starten")) : (React.createElement(ChallengePlayButton, { challenge: challenge, finished: isSelfWinner || isOtherWinner }))), greyOnDesktop: false },
                React.createElement(ChallengeRoundsOverview, { challenge: challenge }))),
        canEndChallenge && (React.createElement(Popup, { popupOpen: closeChallengePopupOpen, setPopupOpen: setCloseChallengePopupOpen, hideCloseButton: true, withoutFooter: true, centerScrollPanel: {
                header: (React.createElement("h3", { className: "font-bold text-xl text-darkBlue" }, "Spiel wirklich beenden?")),
                footer: (React.createElement("div", { className: "flex flex-col space-y-4" },
                    React.createElement(Button, { priority: "tertiary", onClick: endChallenge }, "Spiel beenden"),
                    React.createElement(Button, { priority: "primary", onClick: () => setCloseChallengePopupOpen(false) }, "Zur\u00FCck"))),
            } }))));
}
const Header = ({ challenge, finished, }) => {
    let string = '';
    if (finished) {
        string = 'Endstand';
    }
    else {
        switch (challenge.type) {
            case ChallengeType.SOLO:
                string = 'Deine Punkte';
                break;
            case ChallengeType.ONE_VS_ONE:
            case ChallengeType.ONE_VS_ONE_RANDOM:
                string = 'Gesamtpunktzahl';
                break;
            case ChallengeType.TEAM:
                string = 'Durchschnittliches Teamergebnis';
                break;
            case ChallengeType.TEAM_VS_TEAM:
                string = 'Durchschnittliches Ergebnis pro Team';
                break;
            case ChallengeType.CODE:
                string = 'Durchschnittliches Ergebnis';
                break;
            default:
                string = 'Punkte';
        }
    }
    return React.createElement(SectionTitle, { className: "pb-2 text-center" }, string);
};
