import * as React from 'react';
import { Button } from '@components/basic-components/button';
import { ReactComponent as FeatureChallenge } from '@public/icons/feature_challenge.svg';
import { ReactComponent as FeatureQuestion } from '@public/icons/feature_question.svg';
import { ReactComponent as FeatureQuiz } from '@public/icons/feature_quiz.svg';
import { LandingPageLogos } from '@components/landing/landing-page-logos';
import { Link } from 'react-router-dom';
import { ReactComponent as MikaLogo } from '@public/images/logo-horizontal.svg';
import { classNames } from '@utils/class-names';
import appStore from '@public/images/appStore.png';
import desktopFeaturePreview from '@public/images/desktop-feature-preview.png';
import googlePlay from '@public/images/googlePlay.png';
import macbookPreview from '@public/images/macbook-preview.png';
import phoneFeaturePreview from '@public/images/phone-feature-preview.png';
import phonePreview from '@public/images/phone-preview.png';
const MIKA_APPSTORE_URL_IOS = 'itms-apps://apps.apple.com/de/app/mika-game/id1667090766';
const MIKA_APPSTORE_URL_IOS_WEB = 'https://apps.apple.com/de/app/mika-game/id1667090766?l=de';
const MIKA_APPSTORE_URL_ANDROID = 'market://details?id=de.foraus.mika.game.prod';
const MIKA_APPSTORE_URL_ANDROID_WEB = 'https://play.google.com/store/apps/details?id=de.foraus.mika.game.prod';
const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return 'Android';
    }
    else if (/iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return 'iOS';
    }
    return 'Other';
};
const featureInfo = [
    {
        icon: FeatureQuiz,
        title: 'Dein Quiz',
        description: 'Teste Dein Wissen mit tausenden Quizfragen rund um das Thema Medien- und IT-Kompetenz in einem interaktiven Spieleerlebnis.',
    },
    {
        icon: FeatureChallenge,
        title: 'Deine Challenge',
        description: 'Spiele im Team, im Klassenzimmer, im Freundeskreis, im Kollegium, gegen zufällige Spieler/-innen oder nur für Dich.',
    },
    {
        icon: FeatureQuestion,
        title: 'Deine Fragen',
        description: 'Erstelle Deine eigenen Fragen und verwende sie in Deinem Team oder im Klassenzimmer.',
    },
];
const featureInfo2 = [
    {
        title: 'Nur für Dich oder im Team',
        description: 'Du hast die Wahl! Such Dir einen Spielmodus aus: Fordere andere heraus, spiele im Klassenzimmer oder bilde ein Team.',
    },
    {
        title: 'Gestalte Deinen persönlichen Avatar',
        description: 'Mach Dich schick! Gib Deinem Profil ein Gesicht: Erstelle einen exklusiven Avatar für Dein MIKA-Game®.',
    },
    {
        title: 'Quizze Dich in die Bestenliste',
        description: 'Teste Dein Wissen! Vergleiche Dich mit anderen: Quizze Dich mit Deinem Wissen zu Medien- und IT-Kompetenz an die Spitze der Bestenliste.',
    },
];
function LandingPageButtons() {
    return (React.createElement("div", { className: "grid grid-cols-1 desktop:grid-cols-2 justify-center flex-wrap gap-2 mt-12 w-full desktop:w-auto px-8" },
        React.createElement(Button, { priority: 'primary', to: "/welcome", className: "w-full desktop:w-60" }, "Spiel starten"),
        React.createElement("a", { href: "#app" },
            React.createElement(Button, { priority: 'tertiary', className: "w-full desktop:w-60 bg-white relative" }, "App herunterladen"))));
}
function LandingPageFeatures() {
    return (React.createElement("div", { className: "bg-bgGray w-full px-8 desktop:px-20 py-10 desktop:py-24 flex flex-col items-center" },
        React.createElement("div", { className: "grid grid-cols-1 desktop:grid-cols-3 items-start justify-items-center gap-[64px] text-center w-full max-w-[1500px]" }, featureInfo.map((feature) => (React.createElement("div", { key: 'feature-icon' + feature.title, className: "flex flex-col items-center text-darkBlue" },
            React.createElement(feature.icon, { className: "w-12" }),
            React.createElement("div", { className: "mt-5 text-xl font-semibold leading-8 text-sky-900 whitespace-nowrap" }, feature.title),
            React.createElement("div", { className: "mt-2 text-base text-sky-900 text-opacity-70 max-w-md" }, feature.description)))))));
}
function LandingPageVideo() {
    return (React.createElement("div", { className: "px-8 desktop:px-20 py-10 desktop:py-24 w-full flex flex-col items-center" },
        React.createElement("h2", { className: "font-semibold text-darkBlue text-3xl my-6 text-center" }, "Hier geht\u2019s zum Video"),
        React.createElement("div", { className: "relative w-full max-w-[1000px] aspect-video rounded-lg overflow-hidden" },
            React.createElement("iframe", { width: "100%", height: "100%", src: "https://www.youtube-nocookie.com/embed/OKV4-iZobD0?controls=0&color=white", title: "YouTube video player", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; controls=0", allowFullScreen: true }))));
}
function LandingPageFeatureList() {
    return (React.createElement("div", { className: "flex flex-col items-start px-8 desktop:px-20 pt-0 desktop:pt-12 pb-12 w-full max-w-[1500px]" },
        React.createElement("div", { className: "mt-12 text-base font-semibold leading-6 text-darkBlue max-md:mt-10 max-md:max-w-full uppercase" }, "Das erwartet Dich"),
        React.createElement("div", { className: "mt-3 text-4xl font-semibold tracking-tighter leading-10 text-green max-w-[600px]" }, "Das (mobile) Lernspiel zur F\u00F6rderung Deiner Medien- und IT-Kompetenz!"),
        React.createElement("div", { className: "grid grid-cols-1 desktop:grid-cols-2 gap-4 w-full mt-2" },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: "flex flex-col my-auto max-w-[560px]" }, featureInfo2.map(({ title, description }) => (React.createElement("div", { key: title, className: classNames('flex flex-col py-4 pl-6 border-l-4 border-solid  border-l]', 'hover:border-orange border-bgGray hover:text-green text-darkBlue') },
                    React.createElement("div", { className: classNames('text-xl font-semibold leading-8 max-md:max-w-full') }, title),
                    React.createElement("div", { className: "mt-2 text-base leading-6 text-sky-900 text-opacity-50 max-md:max-w-full text-darkBlue" }, description)))))),
            React.createElement("div", { className: "flex flex-col ml-5" },
                React.createElement("img", { loading: "lazy", src: phoneFeaturePreview, className: "object-contain object-center grow w-full max-md:mt-10 max-md:max-w-full desktop:hidden" }),
                React.createElement("img", { loading: "lazy", src: desktopFeaturePreview, className: "object-contain object-center grow w-full max-md:mt-10 max-md:max-w-full hidden desktop:block" })))));
}
function LandingPageAppLinks() {
    return (React.createElement("div", { className: "w-full px-8", id: "app" },
        React.createElement("div", { className: "mx-auto grow max-w-[1500px] rounded-3xl p-6 desktop:p-16 bg-green/10 grid grid-cols-1 desktop:grid-cols-[1fr_auto_auto] items-center justify-items-center desktop:justify-items-start gap-3" },
            React.createElement("div", { className: "text-center desktop:text-start" },
                React.createElement("h2", { className: "text-3xl font-semibold leading-10 text-darkBlue" }, "Mach mit beim MIKA-Game\u00AE!"),
                React.createElement("div", { className: "mt-4 text-xl leading-8 text-orange max-md:max-w-full" }, "Lade die App herunter und teste Deine individuelle Medien- und IT-Kompetenz!")),
            React.createElement("a", { href: getMobileOS() === 'Android'
                    ? MIKA_APPSTORE_URL_ANDROID
                    : MIKA_APPSTORE_URL_ANDROID_WEB, target: "_blank" },
                React.createElement("img", { src: googlePlay, alt: "Google Play", className: "h-[50px]" })),
            React.createElement("a", { href: getMobileOS() === 'iOS'
                    ? MIKA_APPSTORE_URL_IOS
                    : MIKA_APPSTORE_URL_IOS_WEB, target: "_blank" },
                React.createElement("img", { src: appStore, alt: "App Store", className: "h-[50px]" })))));
}
function LandingPageFooter() {
    return (React.createElement("div", { className: "flex flex-col justify-center items-center px-16 py-12 w-full bg-white text-darkBlue/70" },
        React.createElement("div", { className: "flex flex-wrap gap-5 gap-y-6 justify-between content-center items-stretch pt-8 mt-3.5 w-full text-base border-t border-solid border-t-[color:var(--Gray-200,#EAECF0)] max-w-[1216px] max-md:max-w-full" },
            React.createElement("div", null, "\u00A9 2024 MIKA-Game."),
            React.createElement("div", { className: "flex space-x-4 whitespace-nowrap" },
                React.createElement("a", { href: "https://mika.leando.de/goto_mika_cat_23116.html" }, "MIKA-Campus"),
                React.createElement(Link, { to: '/imprint' }, "Impressum"),
                React.createElement(Link, { to: '/privacy-policy' }, "Datenschutz")))));
}
export default function LandingPageView() {
    return (React.createElement("div", { className: "flex flex-col items-center overflow-scroll force-scrollbar h-screen" },
        React.createElement("div", { className: "relative w-full" },
            React.createElement("div", { className: "absolute w-full h-screen dotted-background -z-10" })),
        React.createElement("div", { className: "flex justify-center my-9" },
            React.createElement(MikaLogo, null)),
        React.createElement("h1", { className: "font-semibold text-darkBlue text-[55px] leading-[67px] my-6 text-center" }, "Spiel mit!"),
        React.createElement("div", { className: "text-darkBlue/70 mb-2 text-center font-normal text-xl px-8" },
            "Nicht nur f\u00FCr Aus(zu)bildende:",
            React.createElement("br", null),
            "Medien- und IT-Kompetenz spielerisch lernen? Geht! Mit dem MIKA-Game\u00AE!",
            React.createElement("br", null),
            "Spiele im Team, gegen andere oder nur f\u00FCr Dich."),
        React.createElement(LandingPageButtons, null),
        React.createElement("img", { src: macbookPreview, alt: "Vorschau in Macbook", className: "w-[1000px] mt-20 hidden desktop:block" }),
        React.createElement("img", { src: phonePreview, alt: "Vorschau in iPhone", className: "w-[1000px] mt-20 desktop:hidden" }),
        React.createElement(LandingPageFeatures, null),
        React.createElement(LandingPageVideo, null),
        React.createElement(LandingPageFeatureList, null),
        React.createElement(LandingPageAppLinks, null),
        React.createElement(LandingPageLogos, null),
        React.createElement(LandingPageFooter, null)));
}
