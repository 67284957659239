import { Preferences } from '@capacitor/preferences';
import { useProfile } from './profile-context';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
export const LocalUserContext = createContext(undefined);
export const LocalUserProvider = ({ children, }) => {
    const [loaded, setLoaded] = useState(false);
    const [localUser, setLocalUser] = useState();
    const { state: { profile }, } = useProfile();
    useEffect(() => {
        if (!profile) {
            setLocalUser(undefined);
            return;
        }
        void Preferences.get({ key: profile.uuid }).then((value) => {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const item = value.value != null ? JSON.parse(value.value) : undefined;
                setLocalUser(getSanitizedItem(item));
                setLoaded(true);
            }
            catch (e) {
                // noop
            }
        });
    }, [profile]);
    const addShownOneTimePopup = useCallback((popupId) => {
        if (!profile) {
            return;
        }
        setLocalUser((upToDateUser) => {
            var _a;
            if (upToDateUser === null || upToDateUser === void 0 ? void 0 : upToDateUser.shownOneTimePopups.includes(popupId)) {
                return upToDateUser;
            }
            const updatedUser = Object.assign(Object.assign({}, upToDateUser), { shownOneTimePopups: [
                    ...((_a = upToDateUser === null || upToDateUser === void 0 ? void 0 : upToDateUser.shownOneTimePopups) !== null && _a !== void 0 ? _a : []),
                    popupId,
                ] });
            void Preferences.set({
                key: profile.uuid,
                value: JSON.stringify(updatedUser),
            });
            return updatedUser;
        });
    }, [profile]);
    const popupAlreadyShown = useCallback((popupId) => {
        if (!localUser) {
            return false;
        }
        return localUser.shownOneTimePopups.includes(popupId);
    }, [localUser]);
    const context = useMemo(() => ({
        addShownOneTimePopup,
        popupAlreadyShown,
        loaded,
    }), [addShownOneTimePopup, popupAlreadyShown, loaded]);
    return (React.createElement(LocalUserContext.Provider, { value: context }, children));
};
export function useLocalUserState() {
    const state = useContext(LocalUserContext);
    if (!state) {
        throw new Error('useLocalUserState must be used within a LocalUserProvider');
    }
    return state;
}
const getSanitizedItem = (item) => {
    if (!!item &&
        typeof item === 'object' &&
        !!item.shownOneTimePopups &&
        Array.isArray(item.shownOneTimePopups)) {
        return item;
    }
    return { shownOneTimePopups: [] };
};
