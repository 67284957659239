import { Button } from '@components/basic-components/button';
import { Device } from '@capacitor/device';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { LandingPageLogos } from '@components/landing/landing-page-logos';
import { Popup } from '@components/basic-components/popup';
import { Role } from '@mika-bibb/shared/enums';
import { TokenHandler } from '@api/token-handler';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import { isProd } from '../environment.global';
import { openInAppBrowser } from '@utils/in-app-browser';
import { useAuthCode } from '../hooks/useAuthCode';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useEffect, useState } from 'react';
import illustration from '@public/images/game.png';
import useWatchCallback from '@utils/promise-watch';
export function WelcomeView() {
    const { createGuestProfile, login, logout, state: { action, fulfilled, profile }, } = useProfile();
    const { nestClient } = useNestClientContext();
    const navigate = useNavigate();
    const authCode = useAuthCode();
    const [showConfirmLogoutPopup, setShowConfirmLogoutPopup] = useState(false);
    const warnIfAccessToken = (creator) => {
        return TokenHandler.hasTokens().then((hasTokens) => {
            if (hasTokens) {
                setShowConfirmLogoutPopup(true);
            }
            else {
                return creator();
            }
        });
    };
    const startSsoFlow = async (action) => {
        const { url } = await nestClient.initializeSsoFlow(action);
        openInAppBrowser(url);
    };
    const startLoginFlow = useWatchCallback(() => {
        return warnIfAccessToken(() => startSsoFlow('login'));
    }, []);
    const startRegistrationFlow = useWatchCallback(() => {
        return warnIfAccessToken(() => startSsoFlow('registration'));
    }, []);
    const createGuestClicked = useWatchCallback(() => {
        return warnIfAccessToken(createGuestProfile);
    }, []);
    useEffect(() => {
        if (!profile) {
            return;
        }
        if (profile.isPristine &&
            (action === 'createGuest' || action === 'login') &&
            fulfilled) {
            navigate('/profile/edit');
        }
        else {
            navigate('/home');
        }
    }, [action, fulfilled, navigate, profile]);
    useEffect(() => {
        if (authCode) {
            Device.getInfo()
                .then((deviceInfo) => {
                const runningOnMobilePhone = deviceInfo.operatingSystem === 'ios' ||
                    deviceInfo.operatingSystem === 'android';
                const runningInBrowser = deviceInfo.platform === 'web';
                if (runningOnMobilePhone && runningInBrowser && isProd) {
                    alert('Es ist ein Problem mit der App aufgetreten.');
                }
                else {
                    login(authCode);
                }
            })
                .catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCode]);
    return (React.createElement(BaseView, { showFooter: false, showHeader: false, view: ViewsType.HOME, className: "!bg-white" },
        React.createElement("div", { className: "grid grid-cols-1 grid-rows-[repeat(6,_auto)_1fr] gap-y-4 w-full h-full p-6" },
            React.createElement("div", { className: "flex justify-center pb-4" },
                React.createElement("img", { src: illustration, alt: "Illustration", className: "w-44" })),
            React.createElement("div", { className: "text-green mb-2" },
                React.createElement("h2", { className: "mb-4 font-bold text-darkBlue text-2xl" }, "Herzlich willkommen!"),
                "Ich bin die Auszubildende Mika. Sch\u00F6n, dass Du da bist! Lass uns gleich mit dem Quizzen loslegen!"),
            !FEATURE_FLAG_DIDACTA_VERSION && (React.createElement(React.Fragment, null,
                React.createElement(Button, { dataCy: "login", onClick: startLoginFlow }, "Einloggen"),
                React.createElement("div", { className: classNames('relative flex justify-center items-center text-green py-3', 'before:absolute before:top-1/2 before:left-0 before:right-0 before:h-[1px] before:bg-green') },
                    React.createElement("span", { className: "relative px-1.5 bg-white" }, "oder")),
                React.createElement(Button, { priority: "tertiary", onClick: startRegistrationFlow }, "Account erstellen"))),
            React.createElement(Button, Object.assign({ priority: FEATURE_FLAG_DIDACTA_VERSION ? 'primary' : 'tertiary', dataTestId: "create-guest-button", className: "!border-none mt-4" }, ((profile === null || profile === void 0 ? void 0 : profile.role) === Role.GUEST
                ? { to: '/home' }
                : {
                    onClick: createGuestClicked,
                })), FEATURE_FLAG_DIDACTA_VERSION
                ? 'Jetzt spielen'
                : 'Als Gast fortfahren'),
            React.createElement(LandingPageLogos, { forWelcomePage: true })),
        showConfirmLogoutPopup && (React.createElement(Popup, { popupOpen: showConfirmLogoutPopup, setPopupOpen: setShowConfirmLogoutPopup, centerScrollPanel: {
                header: (React.createElement("span", { className: "font-bold" }, "Es sind noch Login-Daten gespeichert.")),
                footer: (React.createElement(Button, { className: "w-full", onClick: logout, dataTestId: 'confirm-logout-button' }, "Ausloggen")),
            }, withoutFooter: true }, "Wenn Du einen neuen Account erstellst, werden diese gel\u00F6scht. Willst Du Dich wirklich ausloggen?"))));
}
