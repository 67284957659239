// man old
import { ReactComponent as HeadGear_ManHair1 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_m-hair1.svg';
import { ReactComponent as HeadGear_ManHair2 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_m-hair2.svg';
import { ReactComponent as HeadGear_ManHair3 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_m-hair3.svg';
import { ReactComponent as HeadGear_ManHair4 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_m-hair4.svg';
import { ReactComponent as HeadGear_ManHair5 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_m-hair5.svg';
// man new
import { ReactComponent as HeadGear_ManHair6 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_new-hair3.svg';
// woman old
import { ReactComponent as HeadGear_WomanHair1 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_w-hair1.svg';
import { ReactComponent as HeadGear_WomanHair2 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_w-hair2.svg';
import { ReactComponent as HeadGear_WomanHair3 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_w-hair3.svg';
import { ReactComponent as HeadGear_WomanHair4 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_w-hair4.svg';
import { ReactComponent as HeadGear_WomanHair5 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_w-hair5.svg';
// woman new
import { ReactComponent as HeadGear_WomanHair6 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_new-hair1.svg';
import { ReactComponent as HeadGear_WomanHair7 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_new-hair2.svg';
import { ReactComponent as HeadGear_WomanHair8 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_new-hair4.svg';
import { ReactComponent as HeadGear_WomanHair9 } from '@public/images/avatar-layers/headgear/woman-hat1/w-hat1_new-hair5.svg';
export const WomanHat1 = {
    HeadGear_WomanHair1,
    HeadGear_WomanHair2,
    HeadGear_WomanHair3,
    HeadGear_WomanHair4,
    HeadGear_WomanHair5,
    HeadGear_WomanHair6,
    HeadGear_WomanHair7,
    HeadGear_WomanHair8,
    HeadGear_WomanHair9,
    HeadGear_ManHair1,
    HeadGear_ManHair2,
    HeadGear_ManHair3,
    HeadGear_ManHair4,
    HeadGear_ManHair5,
    HeadGear_ManHair6,
};
