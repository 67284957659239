import { Environment } from '../environment.global';
import { IMAGE_QUAILTY, IMAGE_SIZES } from '@mika-bibb/shared/constants';
export const generateSourceSet = (src, sizes = IMAGE_SIZES, aspectRatio) => {
    const isBackendUrl = src.startsWith(Environment.NEST_API_URL);
    if (!isBackendUrl) {
        return src;
    }
    // istanbul ignore next
    if (aspectRatio) {
        return sizes
            .map((width) => {
            const height = Math.round(width / aspectRatio);
            const transforms = [
                [
                    'resize',
                    {
                        width,
                        height,
                        position: 'top',
                        withoutEnlargement: true,
                    },
                ],
            ];
            return `${src}?transforms=${JSON.stringify(transforms)}&format=webp&quality=${IMAGE_QUAILTY} ${width}w`;
        })
            .join(', ');
    }
    else {
        return sizes
            .map((width) => `${src}?width=${width}&withoutEnlargement=true&format=webp&quality=${IMAGE_QUAILTY} ${width}w`)
            .join(', ');
    }
};
