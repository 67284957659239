import { ViewsType } from '@typesSrc/views';
import { useParams } from 'react-router-dom';
import BaseView from '@views/base-view';
import React from 'react';
import SearchOpponentTeam from '@components/challenge/search-opponent-team';
export default function SearchOpponentTeamView() {
    const teamId = useParams().teamId;
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_SEARCH_TEAM, header: { title: 'Team wählen', showBack: true }, showFooter: false },
        React.createElement(SearchOpponentTeam, { searchingTeam: teamId })));
}
