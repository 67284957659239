import { Link } from 'react-router-dom';
import { Loading } from '@components/basic-components/loading';
import { dismantleWatchableCallback, } from '@utils/promise-watch';
import { classNames } from '@utils/class-names';
import React from 'react';
function generateStyle(inline, small, priority, disabled, customStyles, className) {
    const commonStyles = classNames('justify-center items-center px-8 font-semibold rounded-sm relative', inline ? 'inline-flex' : 'flex', small ? 'h-11 text-base' : 'h-14 text-[17px]', priority === 'primary' &&
        (disabled ? 'bg-green/30 text-green/70' : 'bg-green text-white'), priority === 'secondary' &&
        (disabled ? 'bg-orange/30 text-orange/70' : 'bg-orange text-white'), priority === 'tertiary' &&
        (disabled
            ? 'border-2 border-solid border-green/30 text-green/70'
            : 'border-2 border-solid border-green text-green'), priority === 'decline' && (disabled ? 'text-orange/70' : 'text-orange'), priority === 'delete' &&
        (disabled ? 'text-white/30 bg-delete/30' : 'text-white bg-delete'));
    return classNames(!customStyles && commonStyles, className);
}
export function Button({ onClick, to, className, children, disabled = false, dataTestId = 'button', dataCy, priority = 'primary', busySpinnerSize = 8, inline = false, small = false, customStyles = false, replace, }) {
    const [actualCallback, busy] = dismantleWatchableCallback(onClick);
    if (busy) {
        disabled = true;
    }
    const style = generateStyle(inline, small, priority, disabled, customStyles, className);
    const props = {
        'onClick': actualCallback,
        disabled,
        'data-testid': dataTestId,
        'data-cy': dataCy,
    };
    if (!to || disabled) {
        return (React.createElement("button", Object.assign({ className: style }, props),
            children,
            busy && (React.createElement(Loading, { size: busySpinnerSize, className: "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" }))));
    }
    return /^http(s)*:\/\//.test(to) ? (React.createElement("a", Object.assign({ href: to, className: classNames('block', style) }, props), children)) : (React.createElement(Link, Object.assign({ to: to, className: classNames('block', style), replace: replace }, props), children));
}
