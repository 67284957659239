import * as React from 'react';
import { ReactComponent as Bibb } from '@public/images/bibb_logo.svg';
import { ReactComponent as Leando } from '@public/images/leando_logo.svg';
import { classNames } from '@utils/class-names';
import bmbf from '@public/images/gefoerdert_vom_bmbf.png';
export function LandingPageLogos({ forWelcomePage = false, }) {
    return (React.createElement("div", { className: classNames('w-full grid desktop:grid-cols-3 items-center gap-x-8 mt-10 desktop:mt-24 mb-4 px-2 h-max', forWelcomePage
            ? 'grid-cols-2 gap-y-8'
            : 'grid-cols-1 logo:grid-cols-2 gap-y-4 logo:gap-y-4') },
        React.createElement("a", { href: "https://www.bmbf.de/", target: "_blank", className: "flex flex-row items-center justify-center" },
            React.createElement("img", { src: bmbf, alt: "Gef\u00F6rdert vom BmBF", className: "w-32" })),
        React.createElement("a", { href: "https://www.bibb.de/", target: "_blank", className: "flex flex-row items-center justify-center" },
            React.createElement(Bibb, { className: forWelcomePage ? 'w-32' : 'w-48' })),
        React.createElement("a", { href: "https://leando.de/", target: "_blank", className: classNames('flex flex-row items-center justify-center logo:col-span-2 desktop:col-span-1', forWelcomePage ? 'col-span-2' : 'col-span-1') },
            React.createElement(Leando, { className: classNames(forWelcomePage ? 'w-32' : 'w-48 h-16', 'text-green') }))));
}
