import { Button } from '@components/basic-components/button';
import { CategoryCard } from '@components/challenge/round/category-card';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { Loading } from '@components/basic-components/loading';
import { ParticipantsCount } from '@components/challenge/participants-count';
import { SectionTitle } from '@components/basic-components/section-title';
import { ViewsType } from '@typesSrc/views';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useNestClientContext } from '../context/nest-client-context';
import BaseView from '@views/base-view';
import Image from '@components/image';
import React, { useState } from 'react';
import game from '@public/images/game.png';
import useCategoriesInfoContext from '../context/categories-info-context';
import useChallengeContext from '../context/challenge-context';
import useWatchCallback from '@utils/promise-watch';
export function NextQuestionButton({ challengeRound, className, onBeforeContinue, }) {
    const navigate = useNavigate();
    const path = useLocation().pathname;
    const [start, end] = path.split('/challenges/');
    const [challengeId, roundId, ..._rest] = end.split('/');
    const newPath = `${start}/challenges/${challengeId}/${roundId}/${challengeRound.nextQuestion}`;
    const wrappedNavigate = useWatchCallback(async () => {
        await (onBeforeContinue === null || onBeforeContinue === void 0 ? void 0 : onBeforeContinue.call());
        navigate(newPath);
    }, [onBeforeContinue, newPath]);
    const to = onBeforeContinue ? undefined : newPath;
    const onClick = onBeforeContinue ? wrappedNavigate : undefined;
    return challengeRound.nextQuestion != null ? (React.createElement(Button, { to: to, onClick: onClick, dataTestId: "next-question-button", className: className, replace: true }, challengeRound.nextQuestion > 0 ? 'Weiter' : 'Start')) : null;
}
export function ChallengeRoundView() {
    var _a;
    const { challengeId, roundIndex, challengeRound, refreshChallengeData, challenge, } = useChallengeContext();
    const { nestClient } = useNestClientContext();
    // eslint-disable-next-line no-prototype-builtins
    const isKiosk = nestClient.hasOwnProperty('kiosk');
    const categories = useCategoriesInfoContext();
    const [locallyChosenCategory, setLocallyChosenCategory] = useState();
    const submitCategory = useWatchCallback(async () => {
        if (!challengeId ||
            roundIndex === undefined ||
            !locallyChosenCategory) {
            return;
        }
        await nestClient
            .chooseCategory(challengeId, roundIndex, locallyChosenCategory)
            .then(refreshChallengeData);
    }, [locallyChosenCategory]);
    if (!challengeId || roundIndex === undefined) {
        return (React.createElement("h1", { className: "text-error" },
            "Quiz-Runde nicht gefunden ",
            challengeId,
            " ",
            roundIndex));
    }
    // TODO: return <Loading /> within BaseView https://jira.sprylab.com/browse/MIKA-327
    if (!challengeRound || !challenge) {
        return React.createElement(Loading, { fullSize: true });
    }
    let options = challengeRound.categoryOptions;
    if (options.length === 0) {
        options = challengeRound.chosenCategory
            ? [challengeRound.chosenCategory]
            : [];
    }
    const categoryImageURL = categories.categories.get((_a = challengeRound.chosenCategory) !== null && _a !== void 0 ? _a : locallyChosenCategory).imageURL;
    return (React.createElement(BaseView, { view: ViewsType.CHALLENGE_ROUND, dataTestId: "ChallengeRoundView", showFooter: false, header: {
            title: challengeRound.chosenCategory !== undefined
                ? 'Gewähltes Thema'
                : 'Thema wählen',
            showBack: !isKiosk,
            isSubpage: true,
            customHeaderAction: React.createElement(ParticipantsCount, null),
            showHelp: true,
        } },
        !isKiosk && (React.createElement(SectionTitle, { className: "pb-2 text-center" },
            "Runde ",
            roundIndex + 1)),
        React.createElement("div", { className: "flex flex-col flex-1 justify-evenly px-2 gap-y-2" },
            React.createElement("div", { className: "flex justify-center h-48 p-4 bg-white" }, categoryImageURL ? (React.createElement(Image, { asset: categoryImageURL, alt: "topics", className: "h-full", sizes: "200px" })) : (React.createElement("img", { src: game, alt: "topics", className: "h-full" }))),
            React.createElement(CenterScrollPanel, { className: "flex-1", rounded: "top", header: React.createElement("div", { className: "font-medium text-sm text-darkBlue/70" }, "Themen"), footer: challengeRound.nextQuestion !== undefined &&
                    (challengeRound.chosenCategory !== undefined ||
                        locallyChosenCategory !== undefined) && (React.createElement("div", { className: "flex flex-col" },
                    React.createElement(NextQuestionButton, { onBeforeContinue: submitCategory, challengeRound: challengeRound, className: "mb-6" }))) },
                React.createElement("div", { className: "flex flex-col gap-y-4" }, options.map((category) => (React.createElement(CategoryCard, { chosen: locallyChosenCategory === category ||
                        challengeRound.chosenCategory === category, name: categories.categories.get(category).text, key: category, isDisabled: submitCategory.running ||
                        challengeRound.chosenCategory !== undefined, onClick: () => setLocallyChosenCategory(category) }))))))));
}
