import { Button } from '@components/basic-components/button';
import { useProfile } from '../context/profile-context';
import React, { useEffect, useState } from 'react';
export default function KioskBeforeView() {
    const { createGuestProfile, state: { profile, initialized }, } = useProfile();
    //Prevent strict mode from creating 2 new users
    const [createdNewUser, setCreatedNewUser] = useState(false);
    useEffect(() => {
        if (!profile && initialized && !createdNewUser) {
            setCreatedNewUser(true);
            void createGuestProfile();
        }
    }, [profile, initialized, createdNewUser, createGuestProfile]);
    return (React.createElement(Button, { to: "/kiosk-start", disabled: !profile }, "Zum Kiosk Modus"));
}
