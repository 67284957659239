import { Button } from '@components/basic-components/button';
import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { FEATURE_FLAG_DIDACTA_VERSION } from '@mika-bibb/shared/feature-flags';
import { Input } from '@components/basic-components/input';
import { Loading } from '@components/basic-components/loading';
import { MAX_JOB_TITLE_LENGTH, MAX_STATUS_MESSAGE_LENGTH, MAX_USERNAME_LENGTH, MIN_USERNAME_LENGTH, } from '@mika-bibb/shared/constants';
import { OneTimePopup } from '@components/basic-components/one-time-popup';
import { Role } from '@mika-bibb/shared/enums';
import { ViewsType } from '@typesSrc/views';
import { useNavigate } from 'react-router';
import { useProfile } from '../context/profile-context';
import BaseView from '@views/base-view';
import React, { useEffect, useState } from 'react';
import useWatchCallback from '@utils/promise-watch';
export default function MyProfileEditorView() {
    var _a, _b;
    const navigate = useNavigate();
    const { state: { profile, action, fulfilled }, updateProfile, } = useProfile();
    const [editedProfile, setEditedProfile] = useState(profile);
    const [wasPristine, setWasPristine] = useState(false);
    const [welcomePopupOpen, setWelcomePopupOpen] = useState(true);
    useEffect(() => {
        if (profile && action === 'update' && fulfilled) {
            if (wasPristine) {
                if (profile.role === Role.REGISTERED ||
                    FEATURE_FLAG_DIDACTA_VERSION) {
                    navigate('/profile/my-avatar');
                }
                else {
                    navigate('/home');
                }
            }
            else {
                navigate('/profile');
            }
        }
        if (profile && !editedProfile) {
            setEditedProfile({
                nickname: profile.nickname,
                jobTitle: profile.jobTitle,
                statusMessage: profile.statusMessage,
            });
        }
    }, [profile, action, fulfilled, editedProfile, wasPristine, navigate]);
    const saveProfile = useWatchCallback(async () => {
        if (!profile || !editedProfile) {
            return;
        }
        setWasPristine((oldWasPristine) => {
            return oldWasPristine || profile.isPristine;
        });
        return updateProfile(editedProfile);
    }, [profile, editedProfile]);
    if (!editedProfile || !profile) {
        return React.createElement(Loading, { fullSize: true });
    }
    function handleSetWelcomePopupOpen(state) {
        setWelcomePopupOpen(state);
    }
    function handleSkipClick() {
        handleSetWelcomePopupOpen(false);
    }
    function handleChangeNickname(nickname) {
        if (editedProfile) {
            setEditedProfile((oldProfile) => (Object.assign(Object.assign({}, oldProfile), { nickname })));
        }
    }
    function handleChangeJobTitle(jobTitle) {
        if (editedProfile) {
            setEditedProfile((oldProfile) => (Object.assign(Object.assign({}, oldProfile), { jobTitle })));
        }
    }
    function handleChangeStatusMessage(statusMessage) {
        if (editedProfile) {
            setEditedProfile((oldProfile) => (Object.assign(Object.assign({}, oldProfile), { statusMessage })));
        }
    }
    return (React.createElement(BaseView, { view: ViewsType.PROFILE, dataTestId: "MyProfileEditor", header: Object.assign({ title: 'Profil anpassen', showBack: true }, (profile.isPristine
            ? {
                onBack: () => {
                    navigate('/home');
                },
            }
            : {})), showFooter: false },
        React.createElement(CenterScrollPanel, { className: "flex-1 mx-2 mt-2", header: React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "font-bold text-xl text-darkBlue mb-2" }, "Dein Profil"),
                React.createElement("div", { className: "text-green mb-2" }, "Lass uns Dein Profil aktualisieren!")), footer: React.createElement(Button, { onClick: saveProfile, className: "w-full", disabled: !editedProfile.nickname ||
                    editedProfile.nickname.trim().length <
                        MIN_USERNAME_LENGTH ||
                    editedProfile.nickname.trim().length >
                        MAX_USERNAME_LENGTH ||
                    (editedProfile.statusMessage !== undefined &&
                        editedProfile.statusMessage.trim().length >
                            MAX_STATUS_MESSAGE_LENGTH) ||
                    (editedProfile.jobTitle !== undefined &&
                        editedProfile.jobTitle.trim().length >
                            MAX_JOB_TITLE_LENGTH), dataTestId: "saveProfileButton" }, "\u00C4nderungen speichern") },
            React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement(Input, { required: true, placeHolder: "Nickname", value: editedProfile.nickname, onChange: handleChangeNickname, maxLength: MAX_USERNAME_LENGTH, minLength: MIN_USERNAME_LENGTH, dataTestId: "nicknameInput", trimSpaces: true }),
                React.createElement(Input, { placeHolder: "Berufsbezeichnung (optional)", value: (_a = editedProfile.jobTitle) !== null && _a !== void 0 ? _a : '', onChange: handleChangeJobTitle, maxLength: MAX_JOB_TITLE_LENGTH }),
                React.createElement(Input, { type: "text-area", maxLength: MAX_STATUS_MESSAGE_LENGTH, placeHolder: "Statusmeldung (optional)", value: (_b = editedProfile.statusMessage) !== null && _b !== void 0 ? _b : '', onChange: handleChangeStatusMessage }))),
        React.createElement(OneTimePopup, { id: "MyProfileEditorViewWelcome", mika: "normal", popupOpen: welcomePopupOpen, setPopupOpen: handleSetWelcomePopupOpen, withoutFooter: true, centerScrollPanel: {
                header: 'Willkommen!',
                footer: (React.createElement(React.Fragment, null,
                    React.createElement(Button, { priority: "tertiary", className: "w-full mb-4", to: "/home" }, "\u00DCberspringen"),
                    React.createElement(Button, { priority: "primary", className: "w-full", onClick: handleSkipClick }, "Profil erstellen"))),
            } }, "Ich bin es noch mal, die Auszubildende Mika. Ich helfe Dir dabei, Dein Profil besonders schick zu gestalten. Legen wir los!")));
}
