import { shuffle } from 'shuffle-seed';
export function sampleSeeded(array, amount, seed) {
    const shuffled = shuffle(array, seed);
    return shuffled.slice(0, amount);
}
export function sampleSeededUnique(array, amount, seed) {
    const shuffled = shuffle(array, seed);
    let slice = Array.from(new Set(shuffled.slice(0, amount)));
    let additional = 1;
    while (slice.length < amount && amount + additional < shuffled.length) {
        //only happens if we pick the same item multiple times
        slice = Array.from(new Set(shuffled.slice(0, amount + additional)));
        additional++;
    }
    return slice;
}
