import { QuestionType } from '../enums';
export function getQuestionPrompt(type) {
    switch (type) {
        case QuestionType.TRUE_FALSE:
            return 'Bewerte die Aussage als richtig oder falsch!';
        case QuestionType.FILL_IN_THE_BLANK:
            return 'Ergänze die Lücke in der Aussage!';
        case QuestionType.ORDERING:
            return 'Bringe die Antworten in die richtige Reihenfolge!';
        case QuestionType.SINGLE_CHOICE:
        default:
            return 'Wähle die richtige Antwort!';
    }
}
