import { SafeArea } from 'capacitor-plugin-safe-area';
import { useOrientation } from '../hooks/useOrientation';
import React, { useContext, useEffect, useState, } from 'react';
const DEFAULT_INSETS = {
    insets: { top: 0, left: 0, right: 0, bottom: 0 },
};
const SafeSpaceContext = React.createContext(undefined);
export function SafeSpaceContextProvider({ children }) {
    const [insets, setInsets] = useState(DEFAULT_INSETS);
    const { orientation } = useOrientation();
    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(setInsets).catch(console.error);
    }, [orientation]);
    return (React.createElement(SafeSpaceContext.Provider, { value: insets }, children));
}
export default function useSafeSpaceContext() {
    const state = useContext(SafeSpaceContext);
    if (!state) {
        throw new Error('useSafeSpaceContext must be used within a SafeSpaceContextProvider');
    }
    return state;
}
