import { Body1 } from './import-body1-hair';
import { Body2 } from './import-body2-hair';
import { Body3 } from './import-body3-hair';
import { Body4 } from './import-body4-hair';
import { Body5 } from './import-body5-hair';
import React from 'react';
export const hairColorsOrdered = [
    '#862618',
    '#85898B',
    '#DEA65F',
    '#654023',
    '#000000',
];
export const hairShapesOrdered = [
    undefined,
    'WomanHair1',
    'WomanHair2',
    'WomanHair3',
    'WomanHair4',
    'WomanHair5',
    'ManHair1',
    'ManHair2',
    'ManHair3',
    'ManHair4',
    'ManHair5',
    'WomanHair6',
    'WomanHair7',
    'WomanHair8',
    'WomanHair9',
    'ManHair6',
];
export const hairConfigBody1 = {
    '#862618': {
        WomanHair1: React.createElement(Body1.WomanHair1a, null),
        WomanHair2: React.createElement(Body1.WomanHair2a, null),
        WomanHair3: React.createElement(Body1.WomanHair3a, null),
        WomanHair4: React.createElement(Body1.WomanHair4a, null),
        WomanHair5: React.createElement(Body1.WomanHair5a, null),
        WomanHair6: React.createElement(Body1.WomanHair6a, null),
        WomanHair7: React.createElement(Body1.WomanHair7a, null),
        WomanHair8: React.createElement(Body1.WomanHair8a, null),
        WomanHair9: React.createElement(Body1.WomanHair9a, null),
        ManHair1: React.createElement(Body1.ManHair1a, null),
        ManHair2: React.createElement(Body1.ManHair2a, null),
        ManHair3: React.createElement(Body1.ManHair3a, null),
        ManHair4: React.createElement(Body1.ManHair4a, null),
        ManHair5: React.createElement(Body1.ManHair5a, null),
        ManHair6: React.createElement(Body1.ManHair6a, null),
    },
    '#85898B': {
        WomanHair1: React.createElement(Body1.WomanHair1b, null),
        WomanHair2: React.createElement(Body1.WomanHair2b, null),
        WomanHair3: React.createElement(Body1.WomanHair3b, null),
        WomanHair4: React.createElement(Body1.WomanHair4b, null),
        WomanHair5: React.createElement(Body1.WomanHair5b, null),
        WomanHair6: React.createElement(Body1.WomanHair6b, null),
        WomanHair7: React.createElement(Body1.WomanHair7b, null),
        WomanHair8: React.createElement(Body1.WomanHair8b, null),
        WomanHair9: React.createElement(Body1.WomanHair9b, null),
        ManHair1: React.createElement(Body1.ManHair1b, null),
        ManHair2: React.createElement(Body1.ManHair2b, null),
        ManHair3: React.createElement(Body1.ManHair3b, null),
        ManHair4: React.createElement(Body1.ManHair4b, null),
        ManHair5: React.createElement(Body1.ManHair5b, null),
        ManHair6: React.createElement(Body1.ManHair6b, null),
    },
    '#DEA65F': {
        WomanHair1: React.createElement(Body1.WomanHair1c, null),
        WomanHair2: React.createElement(Body1.WomanHair2c, null),
        WomanHair3: React.createElement(Body1.WomanHair3c, null),
        WomanHair4: React.createElement(Body1.WomanHair4c, null),
        WomanHair5: React.createElement(Body1.WomanHair5c, null),
        WomanHair6: React.createElement(Body1.WomanHair6c, null),
        WomanHair7: React.createElement(Body1.WomanHair7c, null),
        WomanHair8: React.createElement(Body1.WomanHair8c, null),
        WomanHair9: React.createElement(Body1.WomanHair9c, null),
        ManHair1: React.createElement(Body1.ManHair1c, null),
        ManHair2: React.createElement(Body1.ManHair2c, null),
        ManHair3: React.createElement(Body1.ManHair3c, null),
        ManHair4: React.createElement(Body1.ManHair4c, null),
        ManHair5: React.createElement(Body1.ManHair5c, null),
        ManHair6: React.createElement(Body1.ManHair6c, null),
    },
    '#654023': {
        WomanHair1: React.createElement(Body1.WomanHair1d, null),
        WomanHair2: React.createElement(Body1.WomanHair2d, null),
        WomanHair3: React.createElement(Body1.WomanHair3d, null),
        WomanHair4: React.createElement(Body1.WomanHair4d, null),
        WomanHair5: React.createElement(Body1.WomanHair5d, null),
        WomanHair6: React.createElement(Body1.WomanHair6d, null),
        WomanHair7: React.createElement(Body1.WomanHair7d, null),
        WomanHair8: React.createElement(Body1.WomanHair8d, null),
        WomanHair9: React.createElement(Body1.WomanHair9d, null),
        ManHair1: React.createElement(Body1.ManHair1d, null),
        ManHair2: React.createElement(Body1.ManHair2d, null),
        ManHair3: React.createElement(Body1.ManHair3d, null),
        ManHair4: React.createElement(Body1.ManHair4d, null),
        ManHair5: React.createElement(Body1.ManHair5d, null),
        ManHair6: React.createElement(Body1.ManHair6d, null),
    },
    '#000000': {
        WomanHair1: React.createElement(Body1.WomanHair1e, null),
        WomanHair2: React.createElement(Body1.WomanHair2e, null),
        WomanHair3: React.createElement(Body1.WomanHair3e, null),
        WomanHair4: React.createElement(Body1.WomanHair4e, null),
        WomanHair5: React.createElement(Body1.WomanHair5e, null),
        WomanHair6: React.createElement(Body1.WomanHair6e, null),
        WomanHair7: React.createElement(Body1.WomanHair7e, null),
        WomanHair8: React.createElement(Body1.WomanHair8e, null),
        WomanHair9: React.createElement(Body1.WomanHair9e, null),
        ManHair1: React.createElement(Body1.ManHair1e, null),
        ManHair2: React.createElement(Body1.ManHair2e, null),
        ManHair3: React.createElement(Body1.ManHair3e, null),
        ManHair4: React.createElement(Body1.ManHair4e, null),
        ManHair5: React.createElement(Body1.ManHair5e, null),
        ManHair6: React.createElement(Body1.ManHair6e, null),
    },
};
export const hairConfigBody2 = {
    '#862618': {
        WomanHair1: React.createElement(Body2.WomanHair1a, null),
        WomanHair2: React.createElement(Body2.WomanHair2a, null),
        WomanHair3: React.createElement(Body2.WomanHair3a, null),
        WomanHair4: React.createElement(Body2.WomanHair4a, null),
        WomanHair5: React.createElement(Body2.WomanHair5a, null),
        WomanHair6: React.createElement(Body2.WomanHair6a, null),
        WomanHair7: React.createElement(Body2.WomanHair7a, null),
        WomanHair8: React.createElement(Body2.WomanHair8a, null),
        WomanHair9: React.createElement(Body2.WomanHair9a, null),
        ManHair1: React.createElement(Body2.ManHair1a, null),
        ManHair2: React.createElement(Body2.ManHair2a, null),
        ManHair3: React.createElement(Body2.ManHair3a, null),
        ManHair4: React.createElement(Body2.ManHair4a, null),
        ManHair5: React.createElement(Body2.ManHair5a, null),
        ManHair6: React.createElement(Body2.ManHair6a, null),
    },
    '#85898B': {
        WomanHair1: React.createElement(Body2.WomanHair1b, null),
        WomanHair2: React.createElement(Body2.WomanHair2b, null),
        WomanHair3: React.createElement(Body2.WomanHair3b, null),
        WomanHair4: React.createElement(Body2.WomanHair4b, null),
        WomanHair5: React.createElement(Body2.WomanHair5b, null),
        WomanHair6: React.createElement(Body2.WomanHair6b, null),
        WomanHair7: React.createElement(Body2.WomanHair7b, null),
        WomanHair8: React.createElement(Body2.WomanHair8b, null),
        WomanHair9: React.createElement(Body2.WomanHair9b, null),
        ManHair1: React.createElement(Body2.ManHair1b, null),
        ManHair2: React.createElement(Body2.ManHair2b, null),
        ManHair3: React.createElement(Body2.ManHair3b, null),
        ManHair4: React.createElement(Body2.ManHair4b, null),
        ManHair5: React.createElement(Body2.ManHair5b, null),
        ManHair6: React.createElement(Body2.ManHair6b, null),
    },
    '#DEA65F': {
        WomanHair1: React.createElement(Body2.WomanHair1c, null),
        WomanHair2: React.createElement(Body2.WomanHair2c, null),
        WomanHair3: React.createElement(Body2.WomanHair3c, null),
        WomanHair4: React.createElement(Body2.WomanHair4c, null),
        WomanHair5: React.createElement(Body2.WomanHair5c, null),
        WomanHair6: React.createElement(Body2.WomanHair6c, null),
        WomanHair7: React.createElement(Body2.WomanHair7c, null),
        WomanHair8: React.createElement(Body2.WomanHair8c, null),
        WomanHair9: React.createElement(Body2.WomanHair9c, null),
        ManHair1: React.createElement(Body2.ManHair1c, null),
        ManHair2: React.createElement(Body2.ManHair2c, null),
        ManHair3: React.createElement(Body2.ManHair3c, null),
        ManHair4: React.createElement(Body2.ManHair4c, null),
        ManHair5: React.createElement(Body2.ManHair5c, null),
        ManHair6: React.createElement(Body2.ManHair6c, null),
    },
    '#654023': {
        WomanHair1: React.createElement(Body2.WomanHair1d, null),
        WomanHair2: React.createElement(Body2.WomanHair2d, null),
        WomanHair3: React.createElement(Body2.WomanHair3d, null),
        WomanHair4: React.createElement(Body2.WomanHair4d, null),
        WomanHair5: React.createElement(Body2.WomanHair5d, null),
        WomanHair6: React.createElement(Body2.WomanHair6d, null),
        WomanHair7: React.createElement(Body2.WomanHair7d, null),
        WomanHair8: React.createElement(Body2.WomanHair8d, null),
        WomanHair9: React.createElement(Body2.WomanHair9d, null),
        ManHair1: React.createElement(Body2.ManHair1d, null),
        ManHair2: React.createElement(Body2.ManHair2d, null),
        ManHair3: React.createElement(Body2.ManHair3d, null),
        ManHair4: React.createElement(Body2.ManHair4d, null),
        ManHair5: React.createElement(Body2.ManHair5d, null),
        ManHair6: React.createElement(Body2.ManHair6d, null),
    },
    '#000000': {
        WomanHair1: React.createElement(Body2.WomanHair1e, null),
        WomanHair2: React.createElement(Body2.WomanHair2e, null),
        WomanHair3: React.createElement(Body2.WomanHair3e, null),
        WomanHair4: React.createElement(Body2.WomanHair4e, null),
        WomanHair5: React.createElement(Body2.WomanHair5e, null),
        WomanHair6: React.createElement(Body2.WomanHair6e, null),
        WomanHair7: React.createElement(Body2.WomanHair7e, null),
        WomanHair8: React.createElement(Body2.WomanHair8e, null),
        WomanHair9: React.createElement(Body2.WomanHair9e, null),
        ManHair1: React.createElement(Body2.ManHair1e, null),
        ManHair2: React.createElement(Body2.ManHair2e, null),
        ManHair3: React.createElement(Body2.ManHair3e, null),
        ManHair4: React.createElement(Body2.ManHair4e, null),
        ManHair5: React.createElement(Body2.ManHair5e, null),
        ManHair6: React.createElement(Body2.ManHair6e, null),
    },
};
export const hairConfigBody3 = {
    '#862618': {
        WomanHair1: React.createElement(Body3.WomanHair1a, null),
        WomanHair2: React.createElement(Body3.WomanHair2a, null),
        WomanHair3: React.createElement(Body3.WomanHair3a, null),
        WomanHair4: React.createElement(Body3.WomanHair4a, null),
        WomanHair5: React.createElement(Body3.WomanHair5a, null),
        WomanHair6: React.createElement(Body3.WomanHair6a, null),
        WomanHair7: React.createElement(Body3.WomanHair7a, null),
        WomanHair8: React.createElement(Body3.WomanHair8a, null),
        WomanHair9: React.createElement(Body3.WomanHair9a, null),
        ManHair1: React.createElement(Body3.ManHair1a, null),
        ManHair2: React.createElement(Body3.ManHair2a, null),
        ManHair3: React.createElement(Body3.ManHair3a, null),
        ManHair4: React.createElement(Body3.ManHair4a, null),
        ManHair5: React.createElement(Body3.ManHair5a, null),
        ManHair6: React.createElement(Body3.ManHair6a, null),
    },
    '#85898B': {
        WomanHair1: React.createElement(Body3.WomanHair1b, null),
        WomanHair2: React.createElement(Body3.WomanHair2b, null),
        WomanHair3: React.createElement(Body3.WomanHair3b, null),
        WomanHair4: React.createElement(Body3.WomanHair4b, null),
        WomanHair5: React.createElement(Body3.WomanHair5b, null),
        WomanHair6: React.createElement(Body3.WomanHair6b, null),
        WomanHair7: React.createElement(Body3.WomanHair7b, null),
        WomanHair8: React.createElement(Body3.WomanHair8b, null),
        WomanHair9: React.createElement(Body3.WomanHair9b, null),
        ManHair1: React.createElement(Body3.ManHair1b, null),
        ManHair2: React.createElement(Body3.ManHair2b, null),
        ManHair3: React.createElement(Body3.ManHair3b, null),
        ManHair4: React.createElement(Body3.ManHair4b, null),
        ManHair5: React.createElement(Body3.ManHair5b, null),
        ManHair6: React.createElement(Body3.ManHair6b, null),
    },
    '#DEA65F': {
        WomanHair1: React.createElement(Body3.WomanHair1c, null),
        WomanHair2: React.createElement(Body3.WomanHair2c, null),
        WomanHair3: React.createElement(Body3.WomanHair3c, null),
        WomanHair4: React.createElement(Body3.WomanHair4c, null),
        WomanHair5: React.createElement(Body3.WomanHair5c, null),
        WomanHair6: React.createElement(Body3.WomanHair6c, null),
        WomanHair7: React.createElement(Body3.WomanHair7c, null),
        WomanHair8: React.createElement(Body3.WomanHair8c, null),
        WomanHair9: React.createElement(Body3.WomanHair9c, null),
        ManHair1: React.createElement(Body3.ManHair1c, null),
        ManHair2: React.createElement(Body3.ManHair2c, null),
        ManHair3: React.createElement(Body3.ManHair3c, null),
        ManHair4: React.createElement(Body3.ManHair4c, null),
        ManHair5: React.createElement(Body3.ManHair5c, null),
        ManHair6: React.createElement(Body3.ManHair6c, null),
    },
    '#654023': {
        WomanHair1: React.createElement(Body3.WomanHair1d, null),
        WomanHair2: React.createElement(Body3.WomanHair2d, null),
        WomanHair3: React.createElement(Body3.WomanHair3d, null),
        WomanHair4: React.createElement(Body3.WomanHair4d, null),
        WomanHair5: React.createElement(Body3.WomanHair5d, null),
        WomanHair6: React.createElement(Body3.WomanHair6d, null),
        WomanHair7: React.createElement(Body3.WomanHair7d, null),
        WomanHair8: React.createElement(Body3.WomanHair8d, null),
        WomanHair9: React.createElement(Body3.WomanHair9d, null),
        ManHair1: React.createElement(Body3.ManHair1d, null),
        ManHair2: React.createElement(Body3.ManHair2d, null),
        ManHair3: React.createElement(Body3.ManHair3d, null),
        ManHair4: React.createElement(Body3.ManHair4d, null),
        ManHair5: React.createElement(Body3.ManHair5d, null),
        ManHair6: React.createElement(Body3.ManHair6d, null),
    },
    '#000000': {
        WomanHair1: React.createElement(Body3.WomanHair1e, null),
        WomanHair2: React.createElement(Body3.WomanHair2e, null),
        WomanHair3: React.createElement(Body3.WomanHair3e, null),
        WomanHair4: React.createElement(Body3.WomanHair4e, null),
        WomanHair5: React.createElement(Body3.WomanHair5e, null),
        WomanHair6: React.createElement(Body3.WomanHair6e, null),
        WomanHair7: React.createElement(Body3.WomanHair7e, null),
        WomanHair8: React.createElement(Body3.WomanHair8e, null),
        WomanHair9: React.createElement(Body3.WomanHair9e, null),
        ManHair1: React.createElement(Body3.ManHair1e, null),
        ManHair2: React.createElement(Body3.ManHair2e, null),
        ManHair3: React.createElement(Body3.ManHair3e, null),
        ManHair4: React.createElement(Body3.ManHair4e, null),
        ManHair5: React.createElement(Body3.ManHair5e, null),
        ManHair6: React.createElement(Body3.ManHair6e, null),
    },
};
export const hairConfigBody4 = {
    '#862618': {
        WomanHair1: React.createElement(Body4.WomanHair1a, null),
        WomanHair2: React.createElement(Body4.WomanHair2a, null),
        WomanHair3: React.createElement(Body4.WomanHair3a, null),
        WomanHair4: React.createElement(Body4.WomanHair4a, null),
        WomanHair5: React.createElement(Body4.WomanHair5a, null),
        WomanHair6: React.createElement(Body4.WomanHair6a, null),
        WomanHair7: React.createElement(Body4.WomanHair7a, null),
        WomanHair8: React.createElement(Body4.WomanHair8a, null),
        WomanHair9: React.createElement(Body4.WomanHair9a, null),
        ManHair1: React.createElement(Body4.ManHair1a, null),
        ManHair2: React.createElement(Body4.ManHair2a, null),
        ManHair3: React.createElement(Body4.ManHair3a, null),
        ManHair4: React.createElement(Body4.ManHair4a, null),
        ManHair5: React.createElement(Body4.ManHair5a, null),
        ManHair6: React.createElement(Body4.ManHair6a, null),
    },
    '#85898B': {
        WomanHair1: React.createElement(Body4.WomanHair1b, null),
        WomanHair2: React.createElement(Body4.WomanHair2b, null),
        WomanHair3: React.createElement(Body4.WomanHair3b, null),
        WomanHair4: React.createElement(Body4.WomanHair4b, null),
        WomanHair5: React.createElement(Body4.WomanHair5b, null),
        WomanHair6: React.createElement(Body4.WomanHair6b, null),
        WomanHair7: React.createElement(Body4.WomanHair7b, null),
        WomanHair8: React.createElement(Body4.WomanHair8b, null),
        WomanHair9: React.createElement(Body4.WomanHair9b, null),
        ManHair1: React.createElement(Body4.ManHair1b, null),
        ManHair2: React.createElement(Body4.ManHair2b, null),
        ManHair3: React.createElement(Body4.ManHair3b, null),
        ManHair4: React.createElement(Body4.ManHair4b, null),
        ManHair5: React.createElement(Body4.ManHair5b, null),
        ManHair6: React.createElement(Body4.ManHair6b, null),
    },
    '#DEA65F': {
        WomanHair1: React.createElement(Body4.WomanHair1c, null),
        WomanHair2: React.createElement(Body4.WomanHair2c, null),
        WomanHair3: React.createElement(Body4.WomanHair3c, null),
        WomanHair4: React.createElement(Body4.WomanHair4c, null),
        WomanHair5: React.createElement(Body4.WomanHair5c, null),
        WomanHair6: React.createElement(Body4.WomanHair6c, null),
        WomanHair7: React.createElement(Body4.WomanHair7c, null),
        WomanHair8: React.createElement(Body4.WomanHair8c, null),
        WomanHair9: React.createElement(Body4.WomanHair9c, null),
        ManHair1: React.createElement(Body4.ManHair1c, null),
        ManHair2: React.createElement(Body4.ManHair2c, null),
        ManHair3: React.createElement(Body4.ManHair3c, null),
        ManHair4: React.createElement(Body4.ManHair4c, null),
        ManHair5: React.createElement(Body4.ManHair5c, null),
        ManHair6: React.createElement(Body4.ManHair6c, null),
    },
    '#654023': {
        WomanHair1: React.createElement(Body4.WomanHair1d, null),
        WomanHair2: React.createElement(Body4.WomanHair2d, null),
        WomanHair3: React.createElement(Body4.WomanHair3d, null),
        WomanHair4: React.createElement(Body4.WomanHair4d, null),
        WomanHair5: React.createElement(Body4.WomanHair5d, null),
        WomanHair6: React.createElement(Body4.WomanHair6d, null),
        WomanHair7: React.createElement(Body4.WomanHair7d, null),
        WomanHair8: React.createElement(Body4.WomanHair8d, null),
        WomanHair9: React.createElement(Body4.WomanHair9d, null),
        ManHair1: React.createElement(Body4.ManHair1d, null),
        ManHair2: React.createElement(Body4.ManHair2d, null),
        ManHair3: React.createElement(Body4.ManHair3d, null),
        ManHair4: React.createElement(Body4.ManHair4d, null),
        ManHair5: React.createElement(Body4.ManHair5d, null),
        ManHair6: React.createElement(Body4.ManHair6d, null),
    },
    '#000000': {
        WomanHair1: React.createElement(Body4.WomanHair1e, null),
        WomanHair2: React.createElement(Body4.WomanHair2e, null),
        WomanHair3: React.createElement(Body4.WomanHair3e, null),
        WomanHair4: React.createElement(Body4.WomanHair4e, null),
        WomanHair5: React.createElement(Body4.WomanHair5e, null),
        WomanHair6: React.createElement(Body4.WomanHair6e, null),
        WomanHair7: React.createElement(Body4.WomanHair7e, null),
        WomanHair8: React.createElement(Body4.WomanHair8e, null),
        WomanHair9: React.createElement(Body4.WomanHair9e, null),
        ManHair1: React.createElement(Body4.ManHair1e, null),
        ManHair2: React.createElement(Body4.ManHair2e, null),
        ManHair3: React.createElement(Body4.ManHair3e, null),
        ManHair4: React.createElement(Body4.ManHair4e, null),
        ManHair5: React.createElement(Body4.ManHair5e, null),
        ManHair6: React.createElement(Body4.ManHair6e, null),
    },
};
export const hairConfigBody5 = {
    '#862618': {
        WomanHair1: React.createElement(Body5.WomanHair1a, null),
        WomanHair2: React.createElement(Body5.WomanHair2a, null),
        WomanHair3: React.createElement(Body5.WomanHair3a, null),
        WomanHair4: React.createElement(Body5.WomanHair4a, null),
        WomanHair5: React.createElement(Body5.WomanHair5a, null),
        WomanHair6: React.createElement(Body5.WomanHair6a, null),
        WomanHair7: React.createElement(Body5.WomanHair7a, null),
        WomanHair8: React.createElement(Body5.WomanHair8a, null),
        WomanHair9: React.createElement(Body5.WomanHair9a, null),
        ManHair1: React.createElement(Body5.ManHair1a, null),
        ManHair2: React.createElement(Body5.ManHair2a, null),
        ManHair3: React.createElement(Body5.ManHair3a, null),
        ManHair4: React.createElement(Body5.ManHair4a, null),
        ManHair5: React.createElement(Body5.ManHair5a, null),
        ManHair6: React.createElement(Body5.ManHair6a, null),
    },
    '#85898B': {
        WomanHair1: React.createElement(Body5.WomanHair1b, null),
        WomanHair2: React.createElement(Body5.WomanHair2b, null),
        WomanHair3: React.createElement(Body5.WomanHair3b, null),
        WomanHair4: React.createElement(Body5.WomanHair4b, null),
        WomanHair5: React.createElement(Body5.WomanHair5b, null),
        WomanHair6: React.createElement(Body5.WomanHair6b, null),
        WomanHair7: React.createElement(Body5.WomanHair7b, null),
        WomanHair8: React.createElement(Body5.WomanHair8b, null),
        WomanHair9: React.createElement(Body5.WomanHair9b, null),
        ManHair1: React.createElement(Body5.ManHair1b, null),
        ManHair2: React.createElement(Body5.ManHair2b, null),
        ManHair3: React.createElement(Body5.ManHair3b, null),
        ManHair4: React.createElement(Body5.ManHair4b, null),
        ManHair5: React.createElement(Body5.ManHair5b, null),
        ManHair6: React.createElement(Body5.ManHair6b, null),
    },
    '#DEA65F': {
        WomanHair1: React.createElement(Body5.WomanHair1c, null),
        WomanHair2: React.createElement(Body5.WomanHair2c, null),
        WomanHair3: React.createElement(Body5.WomanHair3c, null),
        WomanHair4: React.createElement(Body5.WomanHair4c, null),
        WomanHair5: React.createElement(Body5.WomanHair5c, null),
        WomanHair6: React.createElement(Body5.WomanHair6c, null),
        WomanHair7: React.createElement(Body5.WomanHair7c, null),
        WomanHair8: React.createElement(Body5.WomanHair8c, null),
        WomanHair9: React.createElement(Body5.WomanHair9c, null),
        ManHair1: React.createElement(Body5.ManHair1c, null),
        ManHair2: React.createElement(Body5.ManHair2c, null),
        ManHair3: React.createElement(Body5.ManHair3c, null),
        ManHair4: React.createElement(Body5.ManHair4c, null),
        ManHair5: React.createElement(Body5.ManHair5c, null),
        ManHair6: React.createElement(Body5.ManHair6c, null),
    },
    '#654023': {
        WomanHair1: React.createElement(Body5.WomanHair1d, null),
        WomanHair2: React.createElement(Body5.WomanHair2d, null),
        WomanHair3: React.createElement(Body5.WomanHair3d, null),
        WomanHair4: React.createElement(Body5.WomanHair4d, null),
        WomanHair5: React.createElement(Body5.WomanHair5d, null),
        WomanHair6: React.createElement(Body5.WomanHair6d, null),
        WomanHair7: React.createElement(Body5.WomanHair7d, null),
        WomanHair8: React.createElement(Body5.WomanHair8d, null),
        WomanHair9: React.createElement(Body5.WomanHair9d, null),
        ManHair1: React.createElement(Body5.ManHair1d, null),
        ManHair2: React.createElement(Body5.ManHair2d, null),
        ManHair3: React.createElement(Body5.ManHair3d, null),
        ManHair4: React.createElement(Body5.ManHair4d, null),
        ManHair5: React.createElement(Body5.ManHair5d, null),
        ManHair6: React.createElement(Body5.ManHair6d, null),
    },
    '#000000': {
        WomanHair1: React.createElement(Body5.WomanHair1e, null),
        WomanHair2: React.createElement(Body5.WomanHair2e, null),
        WomanHair3: React.createElement(Body5.WomanHair3e, null),
        WomanHair4: React.createElement(Body5.WomanHair4e, null),
        WomanHair5: React.createElement(Body5.WomanHair5e, null),
        WomanHair6: React.createElement(Body5.WomanHair6e, null),
        WomanHair7: React.createElement(Body5.WomanHair7e, null),
        WomanHair8: React.createElement(Body5.WomanHair8e, null),
        WomanHair9: React.createElement(Body5.WomanHair9e, null),
        ManHair1: React.createElement(Body5.ManHair1e, null),
        ManHair2: React.createElement(Body5.ManHair2e, null),
        ManHair3: React.createElement(Body5.ManHair3e, null),
        ManHair4: React.createElement(Body5.ManHair4e, null),
        ManHair5: React.createElement(Body5.ManHair5e, null),
        ManHair6: React.createElement(Body5.ManHair6e, null),
    },
};
// must match the order of bodyShapesOrdered
export const hairConfigsOrdered = [
    hairConfigBody1,
    hairConfigBody2,
    hairConfigBody3,
    hairConfigBody4,
    hairConfigBody5,
];
